import React, { useState } from "react";
import styles from "../../assets/ResumeExamples/SingleFrame.module.css";
import { useResume } from "../../ResumeBuilder/builder/components/context/Resume";
import { useJobs } from "../../ResumeBuilder/builder/components/context/Jobs";
import { useNavigate } from "react-router-dom";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
export const SingleFrame = ({ allresume, name, line, isbackgroundColor }) => {
  const { handleTemplateChange } = useResume();
  const { setPreviewImage } = useJobs();
  const [showAll, setShowAll] = useState(false);
  const Navigate = useNavigate(); 

  const handleChange = (templateKey, image) => {
    handleTemplateChange(templateKey);
    setPreviewImage(image);
    Navigate("/welcome");
  };

 

  return (
    <div
      className={`${styles.main} ${isbackgroundColor ? styles.background : ""}`}
    >
      <div className={styles.innercontainer}>
        <div className={styles.beedh}>
          <h2 className={styles.Job_sfas__0AKy9}>
            {name} resume examples{" "}
          </h2>
          <p className={styles.line}>{line}</p>
        </div>

        <div>
          
          <Swiper
            spaceBetween={10}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
            slidesPerView={3.5}
            breakpoints={{
              640: {
                slidesPerView: 4,
              },

              0: {
                slidesPerView: 1,
              },
            }}
          >
            <>
              {Object.values(allresume).map((template, index) => {  
                  if (template.field === name) {
                return (
                  <SwiperSlide
                    key={index}
                    className={styles.showuseresume}
                    onClick={() => handleChange(template.type , template.images)}
                  >
                    <div className={styles.template}>
                      <div className={styles.preview}>
                        <img src={template.images} alt={template.name} />
                        {/* <div
                          className={styles.show}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <span className={styles.but}>Use Template</span>
                        </div> */}
                      </div>
                      <div className={styles.name}>{template.name}</div>
                    </div>
                  </SwiperSlide>
                );
          }  })}
            </>
          </Swiper>
          

        
        </div>
      </div>
    </div>
  );
};
