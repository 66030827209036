import React from 'react'
import styles from "../../assets/ResumeExamples/Top.module.css"
import { useResume } from '../../ResumeBuilder/builder/components/context/Resume';
import { useNavigate } from 'react-router-dom';

const Top = () => {
    const Navigate = useNavigate();
    const {handleTemplateChange } = useResume();
    const handleChange = (templateKey) => {
        handleTemplateChange(templateKey);
        Navigate("/welcome");
      };
  return (
   
    <div className={styles.main}>
         
          <div className={styles.left}>
             <div className={styles.cooro}>
              
                <h1 className={styles.name}>Resume Samples by Industry and Experience.</h1>
                  <span className={styles.ddo} > <span style={{color:'#3f9cdc'}}>Resume</span>  {">"} Resume Examples</span>
                <p className={styles.location}>Give your resume a makeover with these 1800+ samples—because even your future boss deserves a good first impression!</p>
                <div className={styles.doroowoo} onClick={()=>handleChange('iite')}>
                    <button className={styles.button}>Build a Resume for Free</button>
                </div>
             </div>
          </div> 

          <div className={styles.right}> 
             <div className={styles.imgcontinaer}>
                <img src='https://res.cloudinary.com/dvepwlz9j/image/upload/v1724948425/resumeEample/xc53e0keb2irq8lzc6w7.png' alt='sdas' className={`img-fluid ${styles.imgii}`}/>
             </div>
          </div>

    </div>
  )
}

export default Top