import React, { useState } from "react";
import MyResponsiveNavbar from "./pages/Navbar";
import Footer from "./pages/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./assets/Welcome.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import prev from "./ResumeBuilder/assets/images/hj.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import arrow from "./assets/arrow.webp";
import arrow2 from "./assets/arrow2.webp";
import wave from "./assets/wave.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import doubleQuote from "./assets/double.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import BottomBar from "./components/BottomBar";
import HelmetConfig from "./components/HelmetConfig";
import ReactPlayer from "react-player";
import ResumeSlider from "./components/ResumeHomePage/ResumeSlider";
import Section3 from "./components/ResumeHomePage/Section3";
import Payment from "./components/ResumeHomePage/Payment";
import Faq from "./components/ResumeHomePage/Faq";
AOS.init();

const Welcome = () => {
  // Define state for play/pause and mute/unmute
  const [playing, setPlaying] = useState(true);
  const [muted, setMuted] = useState(true);
  const navigate = useNavigate();
  return (
    <div>
      <HelmetConfig
        title="MyFuse.in-AI Resume"
        description="Create and enhance your professional resume with AI assistance on MyFuse.in. Our platform offers personalized resume building, job matching, and career guidance to help you land your dream job. Explore opportunities across various industries and locations, and take the next step in your career journey with MyFuse.in."
        keywords="
          AI Resume Builder, AI Resume Generator, Resume Writing Tool, Automated Resume Builder, AI CV Creator,
          How to Create an AI-Powered Resume, Best AI Tools for Resume Writing, AI Resume Builder for Job Seekers,
          How to Optimize Your Resume with AI, AI Tools for Perfect Resume Creation,
          AI Resume, Resume Builder, AI CV, Resume Tools, Automated Resume,
          Resume Writing Software, AI Job Applications, AI Career Tools, Resume Optimization, Digital Resume Tools,
          How AI Enhances Resume Writing, AI Resume Tips, AI-Powered CV Builder, How to Use AI for Job Applications, AI Resume Formatting,
          How to Create a Winning Resume with AI, AI Resume Builder for Freshers, AI-Powered Resume Writing Tools for 2024,
          Advanced Resume Building Techniques with AI, AI Tools to Improve Your Resume,
          Automated CV Builder, AI Career Guidance, How to Write a Resume with AI, AI for Professional Resume Writing, AI-Powered Job Search Tools
        "
        canonical="https://myfuse.in/airesume"
      />
      <MyResponsiveNavbar />
      <div className={`${styles.topconatiner}`}>
        <div>
          <h6 className={styles.paragraphh}>
            MyFuse Resume Builder{" "}
            <img
              src="https://images.hitpaw.net/landing-pages/aiicon.svg"
              className="ms-2"
              alt="sasd"
            />
            <span className={`${styles.AIProwered}`}>AI Powered</span>
          </h6>
          <h1 className={styles.headingw}>
            Craft Your Resume.
            <br />
            Find Jobs Faster. Secure More Offers.
          </h1>
          <div className={styles.belowheadingcontainer}>
            <p className={styles.belowheading}>
              Streamline your job hunt and secure your dream role faster with
              MyFuse's powerful, all-in-one job search toolkit.
            </p>
          </div>
        </div>
        {/* button container */}
        <div className={styles.buttonContainer}>
          <div
            className={styles.primaryButton}
            onClick={() => navigate("/welcome")}
          >
            Craft My Resume
          </div>
          <div
            className={styles.secondaryButton}
            onClick={() => navigate("/resume-examples")}
          >
            View Examples
          </div>
        </div>
        <div className={styles.greendotcontainer}>
          <span className={styles.greendot}></span> 3,454 resumes created today!
          Somebody call HR, we’re on fire! 🔥🥳
        </div>
        <div className={`${styles.videoConatiner}`}>
          <ReactPlayer
            url="https://res.cloudinary.com/dvepwlz9j/video/upload/f_auto:video,q_auto/v1/all/xc6xx8n4k3zbboa9ding"
            playing={playing}
            muted={muted}
            controls
            width="100%"
            height="auto"
            loop
            onPlay={() => setPlaying(true)}
            onPause={() => setPlaying(false)}
            onClick={() => setMuted(!muted)}
          />
        </div>
        <div className={styles.sundar}>
          <img
            src="https://wp1.themevibrant.com/newwp/aizenius/wp-content/uploads/2023/08/working-1-1.png"
            className="img-fluid"
            alt="sfas"
          />
        </div>
      </div>
      <ResumeSlider />

      {/* <div
        className={`container-fluid `}
        style={{
          background: "linear-gradient(180deg, #F7F5FF 0%, #FFF 100%)",
          paddingTop: "4rem",
        }}
        data-aos="fade-right"
      >
        <h1
          className={styles.heading}
          style={{ textAlign: "center", marginTop: "1.6rem" }}
        >
          New <span style={{ color: "rgba(107,87,255)" }}>AI </span> Features
          Unleash Your Creativity
        </h1>
        <div className="row ">
          <div
            className={`col-md-6  d-flex justify-content-center align-items-center`}
          >
            <img
              src="https://aicw.iamleehom.com/assets/assets.97b477b5.gif"
              className={`img-fluid `}
              alt=""
            />
          </div>
          <div className="col-md-5">
            <div className={`container  ${styles.leftbox}`}>
              <div className={`row ${styles.leftboxinner}`}>
                <div className={`col-md-10 `}>
                  <h6 className={styles.paragraph}>AI Resume Builder</h6>
                  <h1 className={styles.heading}>
                    <span style={{ color: "rgba(107,87,255)" }}>
                      {" "}
                      AI Writes{" "}
                    </span>{" "}
                    Your Resume. <br /> Let Technology Take the Lead
                  </h1>
                  <p className={styles.paragraph}>
                    {" "}
                    Revolutionize your job hunt with our curated resume and
                    cover letter templates. Featuring 40+ ATS-friendly designs
                    crafted by HR experts and typographers, each template is
                    fully customizable. Explore endless design combinations to
                    create standout applications that captivate employers,
                    fast-tracking your path to securing your ideal position.{" "}
                  </p>
                </div>
                <div className={`col-md-10 `}>
                  <span>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      style={{ color: "#2dc08d" }}
                    />{" "}
                    <span className={styles.paragraph}>
                      Crafted with the finesse of GPT's prowess
                    </span>
                  </span>{" "}
                  <br />
                  <span style={{ color: "white" }}>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      style={{ color: "#2dc08d" }}
                    />{" "}
                    <span className={styles.paragraph}>
                      Generated in seconds
                    </span>
                  </span>{" "}
                  <br />
                  <span style={{ color: "white" }}>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      style={{ color: "#2dc08d" }}
                    />{" "}
                    <span className={styles.paragraph}>
                      Creates text with human-like fluidity and authenticity.
                    </span>
                  </span>{" "}
                  <br />
                </div>
                <div className="col-md-10">
                  <NavLink to="/welcome/chooseTemplate">
                    <button
                      className="btn   me-4"
                      style={{
                        background:
                          "linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%)",
                        fontWeight: "700",
                        padding: ".4rem",
                        marginTop: "1rem",
                        color: "white",
                      }}
                    >
                      {" "}
                      Craft My Resume
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
            <img
              className={styles.arrow}
              src={arrow2}
              style={{ height: "11rem", marginTop: "1rem" }}
              alt="sasd"
            />
          </div>
        </div>
      </div> */}

      {/* <div className={`container-fluid    mb-4`} style={{ marginTop: "4rem" }}>
        <div className="row d-flex justify-content-center align-items-center ">
          <div className="col-md-5">
            <div className={`container   `}>
              <div className={`row ${styles.leftboxinner}`}>
               

                <div
                  className={`col-md-10  mt-4 ${styles.arrow}`}
                  data-aos="fade-left"
                >
                  <h6 className={styles.paragraph}>Resume Templates</h6>
                  <h1 className={styles.heading}>
                    Select a template and personalize your{" "}
                    <span style={{ color: "rgba(107,87,255)" }}>
                      {" "}
                      AI resume
                    </span>
                  </h1>
                  <p className={styles.paragraph}>
                    Accelerate your job search with visually striking resumes
                    and cover letters. Discover our collection of 40+
                    ATS-friendly templates, expertly crafted by HR professionals
                    and typographers. Customize every aspect to suit your style
                    and preferences, unleashing over a million unique design
                    combinations. Stand out and land your dream job faster.
                  </p>
                </div>

              
                <div className={`col-md-10 `}>
                  <span style={{ color: "white" }}>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      style={{ color: "#2dc08d" }}
                    />{" "}
                    <span className={styles.paragraph}>
                      Personalize with your information.
                    </span>
                  </span>{" "}
                  <br />
                  <span style={{ color: "white" }}>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      style={{ color: "#2dc08d" }}
                    />{" "}
                    <span className={styles.paragraph}>
                      AI-driven resume creation.
                    </span>
                  </span>{" "}
                  <br />
                  <span style={{ color: "white" }}>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      style={{ color: "#2dc08d" }}
                    />{" "}
                    <span className={styles.paragraph}>
                      Effortless and effective resume building.
                    </span>
                  </span>{" "}
                  <br />
                </div>
                <div className="col-md-10">
                  <NavLink to="/resume-examples">
                    <button
                      className="btn   me-4"
                      style={{
                        background:
                          "linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%)",
                        fontWeight: "700",
                        padding: ".4rem",
                        marginTop: "1rem",
                        color: "white",
                      }}
                    >
                      {" "}
                      Craft My Resume
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
            <img
              className={styles.arrow}
              src={arrow}
              style={{ height: "11rem" }}
              alt="sasd"
            />
          </div>
          <div
            className={`col-md-7  ${styles.middleTOP} `}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "4rem!important",
            }}
          >
            <img
              src={prev}
              className={`img-fluid ${styles.previewImage} `}
              alt=""
            />
          </div>
        </div>
      </div> */}

      {/* <div className={`container-fluid   `} style={{ marginTop: "5rem" }}>
        <div className="row ">
          <div
            className={`col-md-7  d-flex  justify-content-center align-items-center `}
          >
            <img
              src="https://static-cse.canva.com/blob/566484/modernresumes.jpg"
             

              className={`img-fluid   `}
              style={{ width: "85%", borderRadius: "16px" }}
              alt=""
            />
          </div>
          <div className="col-md-5">
            <div className={`container  ${styles.leftbox}`}>
              <div
                className={`row ${styles.leftboxinner}`}
                data-aos="fade-right"
              >
                <div className={`col-md-10 mt-4 `}>
                  <h6 className={styles.paragraph}>Resume Examples</h6>
                  <h1 className={styles.heading}>
                    Inspiration from
                    <span style={{ color: "rgba(107,87,255)" }}>
                      Real Resume
                    </span>
                    Examples
                  </h1>
                  <p className={styles.paragraph}>
                    Explore our diverse collection of real resume examples for
                    inspiration and insight. From various industries and roles,
                    find valuable guidance to craft your standout resume
                    effectively. Tailor your resume to showcase your skills and
                    achievements confidently, whether you're starting your
                    career or aiming for advancement
                  </p>
                </div>
                <div className={`col-md-10 `}>
                  <span style={{ color: "white" }}>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      style={{ color: "#2dc08d" }}
                    />{" "}
                    <span className={styles.paragraph}>
                      Real-world examples for inspiration.
                    </span>
                  </span>{" "}
                  <br />
                  <span style={{ color: "white" }}>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      style={{ color: "#2dc08d" }}
                    />{" "}
                    <span className={styles.paragraph}>
                      Tailor your resume confidently.
                    </span>
                  </span>{" "}
                  <br />
                  <span style={{ color: "white" }}>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      style={{ color: "#2dc08d" }}
                    />{" "}
                    <span className={styles.paragraph}>
                      Craft standout resumes effectively.
                    </span>
                  </span>{" "}
                  <br />
                </div>
                <div className="col-md-10">
                  <NavLink to="/welcome/chooseTemplate">
                    <button
                      className="btn   me-4"
                      style={{
                        background:
                          "linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%)",
                        marginTop: "1rem",
                        padding: ".4rem",
                        fontWeight: "700",
                        color: "white",
                      }}
                    >
                      Craft My Resume
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div
        className={`container`}
        style={{
          position: "relative",
          padding: "2rem",
          marginTop: "3rem",
          marginBottom: "1rem",
        }}
      >
        <img
          src={wave}
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            maxWidth: "80%",
            maxHeight: "70%",
          }}
          alt="sasd"
        />
        <div
          className="row"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <div
            className={`col-md-5  d-flex justify-content-center align-items-center flex-direction-column`}
          >
            <span>
              <h1
                className={`text-center ${styles.heading}`}
                style={{
                  fontWeight: 600,
                  fontSize: "2rem",
                  lineHeight: "2.6rem",
                  marginBottom: "3rem",
                  textTransform: "capitalize",
                }}
              >
                Resumes optimized for{" "}
                <span style={{ color: "rgba(107,87,255)" }}>
                  {" "}
                  applicant tracking systems{" "}
                </span>{" "}
                (ATS)
              </h1>
              <p style={{ textAlign: "center" }}>
                Resumes and cover letters are vigorously tested against major
                ATS systems to ensure complete parsability
              </p>
              <div className="col-md-10 d-flex justify-content-center">
                <NavLink to="/welcome">
                  <button
                    className="btn "
                    style={{
                      background:
                        "linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%)",
                      fontWeight: "700",
                      padding: ".4rem",
                      color: "white",
                    }}
                  >
                    Build an ATS-Friendly Resume
                  </button>
                </NavLink>
              </div>
            </span>
          </div>
          <div className="col-md-5">
            <div className={`container ${styles.leftbox}`}>
              <div
                className={`row ${styles.leftboxinner}`}
                data-aos="fade-right"
              >
                <img
                  className="mt-4"
                  src="https://s40198.pcdn.co/au/wp-content/uploads/sites/3/2022/10/ATS-Flywheel_2_V2-1440x1440.png"
                  alt="sasd"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Section3 />
      <Payment />
      <div className={`container   ${styles.backgroundBacktest}`}>
        <h1
          className={` text-center ${styles.heading}`}
          style={{
            fontWeight: 600,
            fontSize: "2rem",
            lineHeight: "2.6rem",
            marginBottom: "3rem",
            textTransform: "capitalize",
          }}
        >
          What Student Say About Us
        </h1>
        <div
          className="row "
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <div
            className={`col-md-3 col-11  ${styles.testonomial} `}
            data-aos="flip-up"
          >
            <img src={doubleQuote} style={{ width: "3rem" }} alt="h" />
            <div className="mt-4" style={{ fontWeight: 600 }}>
              "Easy-to-use and effective. MyFuse Resume Builder streamlines
              resume creation, offering diverse templates and helpful features
              for professional-looking resumes in minutes."
            </div>

            <div
              className="d-flex justify-content-around align-items-center "
              style={{ marginTop: "3rem" }}
            >
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIjjM4p0_TtAtEGX9RFnsPk5yBhnbSbEqpV5Y3Bs_uPNRGLrNp_ZgU1cjVh1M4YFFSU7s&usqp=CAU"
                style={{ width: "5rem", height: "5rem", borderRadius: "4rem" }}
                alt="h"
              />
              <span style={{ fontWeight: 600 }}>Ishika Choudhary</span>
            </div>
          </div>

          <div
            className={`col-md-3  col-11 ${styles.testonomial} `}
            data-aos="flip-up"
          >
            <img src={doubleQuote} style={{ width: "3rem" }} alt="h" />

            <div className="mt-4" style={{ fontWeight: 600 }}>
              "seamless experience, standout resumes. MyFuse Resume Builder
              offers a streamlined process with customizable options, ideal for
              creating professional resumes with ease. "
            </div>
            <div
              className="d-flex justify-content-around align-items-center "
              style={{ marginTop: "3rem" }}
            >
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMVxRscwjvmDGbLvkvNfPGmp1a-RuGXoLkaT1cJVz2xrTfeZpM2bD_KmVzjZ_uXrqaLSI&usqp=CAU"
                style={{ width: "5rem", height: "5rem", borderRadius: "4rem" }}
                alt="h"
              />
              <span style={{ fontWeight: 600 }}>Aadhya Rajput</span>
            </div>
          </div>

          <div
            className={`col-md-3  col-11  ${styles.testonomial} `}
            data-aos="flip-up"
          >
            <img src={doubleQuote} style={{ width: "3rem" }} alt="h" />
            <div className="mt-4" style={{ fontWeight: 600 }}>
              "Effortless and polished results. MyFuse Resume Builder offers a
              user-friendly interface with diverse templates, perfect for
              crafting professional resumes quickly"
            </div>
            <div
              className="d-flex justify-content-around align-items-center "
              style={{ marginTop: "3rem" }}
            >
              <img
                src="https://userphotos2.teacheron.com/1641770-35898.jpg"
                style={{ width: "5rem", height: "5rem", borderRadius: "4rem" }}
                alt="h"
              />
              <span style={{ fontWeight: 600 }}>Himanshu Mishra</span>
            </div>
          </div>
        </div>
      </div>

      <div className={`container-fluid    mb-4`} style={{ marginTop: "4rem" }}>
        <div className="row d-flex justify-content-center align-items-center ">
          <div className="col-md-7">
            <div className={`container   `}>
              <div className={`row ${styles.leftboxinner}`}>
                <div className={`col-md-10 mt-4`}>
                  <h1 className={styles.heading}>
                    Are you ready to be part of a{" "}
                    <span style={{ color: "rgba(107,87,255)" }}>
                      {" "}
                      MyFuse.In{" "}
                    </span>
                    ?
                  </h1>
                  <p className={styles.paragraph}>
                    Your ultimate placement portal. Connect with opportunities,
                    share experiences, and thrive in your career journey.
                    Empowering professionals to find their perfect fit. Join now
                    and elevate your career
                  </p>
                </div>
                <div className={`col-md-10 `}>
                  <span style={{ color: "white" }}>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      style={{ color: "#2dc08d" }}
                    />{" "}
                    <span className={styles.paragraph}>
                      MyFuse.In: Your ultimate placement portal.
                    </span>
                  </span>{" "}
                  <br />
                  <span style={{ color: "white" }}>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      style={{ color: "#2dc08d" }}
                    />{" "}
                    <span className={styles.paragraph}>
                      Connect with opportunities and share experiences.
                    </span>
                  </span>{" "}
                  <br />
                  <span style={{ color: "white" }}>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      style={{ color: "#2dc08d" }}
                    />{" "}
                    <span className={styles.paragraph}>
                      Thrive in your career journey with us.
                    </span>
                  </span>{" "}
                  <br />
                  <span style={{ color: "white" }}>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      style={{ color: "#2dc08d" }}
                    />{" "}
                    <span className={styles.paragraph}>
                      Elevate your career - join now!
                    </span>
                  </span>{" "}
                  <br />
                </div>
                <div className="col-md-10">
                  <NavLink to="/welcome">
                    <button
                      className="btn   me-4"
                      style={{
                        backgroundColor: "#ffb30b",
                        padding: ".6rem",
                        color: "rgb(35, 45, 63)",
                        fontWeight: 600,
                        marginTop: "1rem",
                      }}
                    >
                      {" "}
                      Explore MyFuse
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`col-md-5  ${styles.middleTOP} `}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "4rem!important",
            }}
          >
            <img
              src="https://assets.softr-files.com/applications/8f7af9fb-a550-425d-b327-48195c193a5f/assets/cd5e62b0-d912-431b-91c0-6f8ba206accb.png"
              className={`img-fluid ${styles.previewImage} `}
              alt=""
            />
          </div>
        </div>
      </div>

      <Faq />

      <div className={`container-fluid   ${styles.background4Back}`}>
        <div
          className="row  "
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className={`col-md-12 ${styles.background5} `}>
            <h1
              className={styles.heading}
              style={{ color: "rgb(35, 45, 63)", textAlign: "center" }}
            >
              Start building today. It's Free!
            </h1>
            <p
              className={styles.paragraph}
              style={{
                color: "rgb(35, 45, 63)",
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
                marginBottom: "2rem",
              }}
            >
              Build and Download your first Resume in under 30 minutes.
            </p>
            <NavLink to="/welcome">
              <button
                className="btn   me-4 mb-3"
                style={{
                  backgroundColor: "#ffb30b",

                  padding: ".6rem",

                  fontWeight: 600,
                  fontSize: "1rem",
                }}
              >
                {" "}
                Craft My Resume
              </button>
            </NavLink>
          </div>
        </div>
      </div>

      <Footer />
      <BottomBar />
    </div>
  );
};

export default Welcome;
