import React, { useEffect, useState } from 'react'
import MyResponsiveNavbar from './Navbar'
import HelmetConfig from '../components/HelmetConfig'
import styles from "../assets/Job.module.css";
import axios from "axios";
import {
    faLocationDot,
    faBriefcase,
    faUserTie,
    faMoneyCheckDollar,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../ResumeBuilder/builder/components/context/auth';
import { History } from 'lucide-react';
const JobsForYou = () => {
    const [skillfromdbapi, setskillfromdibapi] = useState();
    const [ setLoadingJobs] = useState(false);
    const [jobs, setJobs] = useState([]);
    const Navigate = useNavigate(); 
    const resume = JSON.parse(localStorage.getItem("resume"));
    const role = resume?.sections?.technicalSkills?.data?.[0]?.skills;
    const skill_from_resume = role?.join(",");
    const { auth } = useAuth();
    const handleNavigate = (id) => {
      Navigate(`/jobs/id=${id}`);
    };

    let userIdfromdb = auth?.user?.[0]?.id_user;

    const getUserSkill = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/skillsbyid?id_user=${userIdfromdb}`,
          {
            headers: {
              Authorization: "Bearer madebyashutosh",
            },
          }
        );
  
        setskillfromdibapi(data?.skillsfromdb?.[0].skills);
      } catch (error) {
        console.log(error);
      }
    };

    const mergedskill = `${skill_from_resume},${skillfromdbapi}`;

    const getAllJobs = async () => {
      try {
        setLoadingJobs(true);
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/filterjob?skills=${mergedskill}`,
          {
            headers: {
              Authorization: "Bearer madebyashutosh",
            },
          }
        );
  
        if (data?.success) {
          const jobs = data;
          jobs.jobsWithPercentage.sort(
            (a, b) => b.percentageMatched - a.percentageMatched
          );
          setJobs(jobs.jobsWithPercentage);
        }
        setLoadingJobs(false);
      } catch (error) {
        console.log(error);
      }
    };
  
    useEffect(() => {
      getUserSkill();
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    useEffect(() => {
      getAllJobs();
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

  return (
    <div>
         <HelmetConfig
          title="MyFuse.in-Jobs For You"
          description="Create and enhance your professional resume with AI assistance on MyFuse.in. Our platform offers personalized resume building, job matching, and career guidance to help you land your dream job. Explore opportunities across various industries and locations, and take the next step in your career journey with MyFuse.in."
          keywords="AI resume builder, job opportunities, career development, resume creation, job matching, professional resume, employment search, industry-specific jobs, location-based job search, career guidance, MyFuse.in jobs, resume enhancement, job portal, job search platform, AI Resume"
          canonical="https://myfuse.in/resume"
        />
        <MyResponsiveNavbar/>
     <div className='container mt-4'>
        <div className='row'>
        {jobs?.map((job , index) => {
                const timestamp = job.createdat; // Assuming job.createdAt is the timestamp
                const date = new Date(timestamp);
                const formattedDate = date.toLocaleDateString("en-US", {
                  weekday: "short",
                  month: "short",
                  day: "numeric",
                });
                date.setDate(date.getDate() + 8); // Adding 8 days

                // Formatting date to only show date and month
                const options = {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                };
                const increasedDate = date.toLocaleDateString("en-US", options);

                return (
                  <div className="col-md-8 col-11 mb-4" key={job.companyName} style={{margin:"auto"}}>
                    <div className="card" style={{borderRadius:'20px' , boxShadow:'0 6px 12px rgba(30,10,58,.04)' , border:'2px solid white' , fontSize:'14px'}}>
                      <div className="card-body">

                         <div className="d-flex justify-content-between">  
                              <div>
                                 <h3 className={styles.hddh} > {job.jobtitle}  </h3>
                                 <h5 className={styles.down} > {job.companyName} | {job.job_nature} </h5>  
                               </div>
                                
                               <div>
                                  <img
                                    src={`https://old.myfuse.in/uploads/logo/${job.companyLogo}`}
                                    style={{ width: "5rem" }} 
                                    className="img-fluid"
                                    alt="i" 
                                  />
                               </div>
                        </div>
                        
                        {/* this  skill section end */}
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 col-md-12 mt-4">
                              <div className="container">
                                <div className="row">
                                  <div className="col-6 col-md-6" style={{padding:'0px'}}>
                                    <p className={styles.pard}>
                                      {" "}
                                      <FontAwesomeIcon
                                        icon={faBriefcase}
                                        style={{
                                          marginRight: ".5rem",
                                          color: "#739ee8",
                                        }}
                                      />
                                      {job.qualification.length > 10
                                        ? `${job.qualification.substring(
                                            0,
                                            10
                                          )}...`
                                        : job.qualification}
                                    </p>
                                    <p
                                      className={styles.pard}
                                      style={{ marginBottom: "1rem" }}
                                    >
                                      {" "}
                                      <FontAwesomeIcon
                                        icon={faLocationDot}
                                        style={{
                                          marginRight: ".5rem",
                                          color: "#739ee8",
                                        }}
                                      />{" "}
                                       {job.location.length > 10
                                        ? `${job.location.substring(
                                            0,
                                            10
                                          )}...`
                                        : job.location}
                                       
                                    </p>
                                  </div>
                                  <div className={`col-6 col-md-6 ${styles.parad}`} >
                                    <p  className={styles.pard}>
                                      {" "}
                                      <FontAwesomeIcon
                                        icon={faMoneyCheckDollar}
                                        style={{
                                          marginRight: ".5rem",
                                          color: "#739ee8",
                                        }}
                                      />
                                      {job.minimumsalary.length > 20
                                        ? `${job.minimumsalary.substring(
                                            0,
                                            20
                                          )}...`
                                        : job.minimumsalary}
                                    </p>
                                    <p  className={styles.pard}>
                                      {" "}
                                      <FontAwesomeIcon
                                        icon={faUserTie}
                                        style={{
                                          marginRight: ".5rem",
                                          color: "#739ee8",
                                        }}
                                      />{" "}
                                      {job.experience}
                                    </p>
                                  </div>
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="container">
                            <div className="row d-flex ">
                             
                              <div className="col-10 col-md-12 mt-4 " style={{padding:'0px'}}>
                                <span
                                  className={`${styles.viewDetails}`}
                                  onClick={() => handleNavigate(job.id_jobpost)}
                                  style={{ marginRight: ".5rem" }}
                                >
                                  View Details
                                </span>

                                {auth.token === "" ? (
                                  <a
                                    className={styles.jobBottom}
                                    href="/#/login"
                                  >
                                    Apply
                                  </a>
                                ) : (
                                  <a
                                    className={styles.jobBottom}
                                    href={job.link}
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                  >
                                    {job.link ? "Apply" : "Easy Apply"}
                                  </a>
                                )}

                                
                              </div>
                              <div
                                className={styles.jas}
                  
                              > <span style={{backgroundColor:"#F1FFE5" , padding:'.4rem' , borderRadius:"1rem"}}>
                                <History size={13}/>   Apply by {increasedDate} • Posted {"  "}
                                {formattedDate}
                              </span>
                              <div className="col-12  d-flex justify-content-center mt-4 text-success">
                                {Math.min(job.percentageMatched, 100) === 0
                                  ? "Partially Matched"
                                  : `${Math.min(
                                      job.percentageMatched,
                                      100
                                    )}% Matched`}
                              </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> 
             
                          

                  </div>
                );
              })}
        </div>
     </div>
       
    </div>
  )
}

export default JobsForYou