import React from "react";
import styles from "../../assets/HomePage/Placed.module.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

import "../../assets/companyList.css";

// import required modules

const SuccesfullyPlaced = () => {
  let array = [];
  array = [
    {
      Name: "Ishika Kumari",
      img: "https://img.freepik.com/premium-photo/headshot-photos-indian-women-dynamic-professions-occassions-indian-girl_978786-292.jpg",
      companyLogo:
        "https://is1-ssl.mzstatic.com/image/thumb/Purple211/v4/32/97/f7/3297f7b8-e057-fbcc-7a6c-e819960bba3c/AppIcon-0-0-1x_U007emarketing-0-6-0-0-85-220.png/1200x600wa.png",
      Companyname: "Amazon",
      jobpost: "SDE 2",
      description:
        "Job searching as a fresher felt challenging. Myfuse found positions that suited my skills, making my entry into marketing and other areas smoother.",
    },
    {
      Companyname: "Amazon",
      Name: "Rahul Bhairwa",
      img: "rahul.jpeg",
      companyLogo:
        "https://is1-ssl.mzstatic.com/image/thumb/Purple211/v4/32/97/f7/3297f7b8-e057-fbcc-7a6c-e819960bba3c/AppIcon-0-0-1x_U007emarketing-0-6-0-0-85-220.png/1200x600wa.png",
      jobpost: "Sales",
      description:
        "As a recent graduate with little experience, job hunting was tough. Myfuse provided opportunities that matched my skills, making it easier to start my career in marketing and beyond.",
    },
    {
      Companyname: "Microsoft",
      img: "rajesh.jpeg",
      Name: "Rajesh Kumawat ",
      companyLogo:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBWjxuZx__a25hgj1JCqKkQF-6_UiqrUG7_g&s",
      jobpost: "MERN ",
      description:
        "job searching as a fresher felt overwhelming. Myfuse found roles that aligned with my abilities, easing my entry into marketing and other fields.",
    },
    {
      Companyname: "Apple",
      Name: "Shreya Singh",
      img: "Shreya.jpeg",
      companyLogo:
        "https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg",
      jobpost: "Manager",
      description:
        "Being new to the job market was challenging. Myfuse matched me with positions that suited my skills, helping me begin my career in marketing and more.",
    },
    {
      Companyname: "IBM",
      Name: "Kashish Meena",
      img: "https://images.unsplash.com/photo-1623091410901-00e2d268901f?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW5ub2NlbnQlMjBnaXJsfGVufDB8fDB8fHww",
      companyLogo:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdccX-HSF5ogQj1I2F4FIhDWQmhHKbgLrPdQ&s",
      jobpost: "SDE",
      description:
        "Navigating job hunting as a fresh graduate was daunting. Myfuse offered opportunities that fit my skill set, simplifying my start in marketing and other industries.",
    },
    {
      Companyname: "HP",
      img: "https://img.freepik.com/premium-photo/south-indian-girl-hoodie-shirt_905085-77.jpg",
      companyLogo:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/HP_logo_2012.svg/800px-HP_logo_2012.svg.png",
      jobpost: "Associate Man..",
      Name: "Romi Sehgal",
      description:
        "As a new graduate, finding a job was intimidating. Myfuse connected me with roles that matched my skills, making my career start in marketing easier.",
    },
  ];

  return (
    <div className={styles.main}>
      <div className={styles.inner}>
        <div>
          <h2 className={styles.headd}> Successfully Placed Candidates </h2>
          <span className="_ngcontents">
            Our students have been successfully placed in top multinational
            companies, showcasing their skills and expertise{" "}
          </span>
        </div>

        <div
          style={{ marginTop: "2rem",  height: "15rem" }}
        >
          <Swiper
            spaceBetween={10}
            // centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            // navigation={true}

            modules={[Autoplay, Pagination, Navigation]}
            className={`mySwiper ${styles.do}`}
            slidesPerView={1} // default to 1 for small screens
            breakpoints={{
              640: {
                slidesPerView: 2, // 1 slide at a time on mobile devices
              },
              768: {
                slidesPerView: 3, // 2 slides at a time on tablets
              },
              1024: {
                slidesPerView: 3.5, // 4 slides at a time on larger screens
              },
            }}
          >
            {array.map((ele, inde) => {
              return (
                <SwiperSlide>
                 <div>
                  
                  <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1720699375/655dc734b7246_frame_ldoapp.svg" className={`${styles.image}`} alt="sdfd"/>
                     <p className={styles.de}>
                       {ele.description}
                     </p>
                     <div className={styles.ell}>
                        <span >
                          <img src={ele.img}   className={styles.dll} alt="d"/>
                        </span> 
                        <span className={styles.dllld}>
                          {ele.Name}
                        </span>
                     </div>
                  </div> 
                 
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default SuccesfullyPlaced;
