import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import styles from "../assets/ModelChoseTemplate.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { ChevronDown} from "lucide-react";
import axios from "axios";
import { useResume } from "../ResumeBuilder/builder/components/context/Resume";
import { useNavigate } from "react-router-dom";
import { useJobs } from "../ResumeBuilder/builder/components/context/Jobs";

const options = [
  "Choose your domain",
  "IT",
  "Finance",
  "Accounts",
  "Marketing",
  "Sales & Development",
  "Business Management ",
  "Data Science and Analytics",
  "Design And Ux",
  "Human Resource ",
];

function ModelChoseTemplate({ modalShow, setModalShow }) {
  const [isOpen, setIsOpen] = useState(false);
  const [allresume, setallresume] = useState([]);
  const [filterdResume, setFilterResume] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Choose your domain");
  const { selectedTemplate, templates, handleTemplateChange } = useResume();
  const { setPreviewImage } = useJobs();
  const navigate = useNavigate();
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const showresumefrombackend = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/admin/getallresume`
      );
      if (data?.success) {
        await setallresume(data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterByField = (resumes, field) => { 
    console.log(resumes)
    return resumes.filter((resume) => resume.field === field);
  };

  const searchContainer = () => {
    return (
      <div className={styles.SearchContainer}>
        <div className={styles.dropdownSecarchMainConatiner}>
          <div className={styles.dropdownSecarchConatiner}>
            <div className={styles.dropdownToggle} onClick={toggleDropdown}>
              {selectedOption}
              <span className={styles.chevronDownIcon}>
                <ChevronDown size={14} />
              </span>
            </div>
            {isOpen && (
              <div className={styles.dropdownMenu}>
                {options.map((option, index) => (
                  <div
                    key={index}
                    className={styles.dropdownItem}
                    onClick={() => handleOptionClick(option)}
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleOptionClick = async (option) => { 

    setSelectedOption(option);
    const serFilterResume = filterByField(allresume, option);
    setFilterResume(serFilterResume);
    setIsOpen(false);
  };

  const handleNavigate = (templateKey) => {
    handleTemplateChange(templateKey);
    setPreviewImage("");
    navigate("/welcome");
 
  };

  const handleChange = (templateKey, image) => {
    handleTemplateChange(templateKey);
    setPreviewImage(image);
    navigate("/welcome");
  };

  const truncateText = (text, wordLimit) => {
    if (text.length > wordLimit) {
      return text.slice(0, wordLimit) + "...";
    }
    return text;
  };

  useEffect(()=>{
      showresumefrombackend();
  },[])

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      centered
    >
      <Modal.Body className={styles.bodys}>
        <div className="container" style={{ position: "relative" }}>
          <div className={styles.close}>
            <span
              className={`${styles.closeButton} close`}
              onClick={() => setModalShow(false)}
            >
              <FontAwesomeIcon icon={faClose} className={styles.iconcut} />
            </span>
          </div>
          <div className="row">
            <div className={`col-12 col-md-12 ${styles.second}`}>
              <div className={styles.container}>
                <div className={styles.topHeadingContainer}>
                  <div className={styles.headtitle}>
                    {" "}
                    Let’s pick out a template for you!
                  </div>
                  <div className={styles.headtitlparagraph}>
                    Narrow down your domain for personalised template
                    suggestions
                  </div>
                  {searchContainer()}
                </div>

                {(filterdResume.length === 0 ||
                  selectedOption == "Choose your domain") && (
                  <div className={styles.ResumeContainer}>
                    {Object.values(templates).map((template, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => handleNavigate(template?.key)}
                          className={` ${styles.dooo}`}
                        >
                          <div className={styles.resumeImageContainer}>
                            <img
                              className={styles.resumeImage}
                              src={template.preview}
                              alt={template.name}
                            />
                          </div>
                          <span className={styles.templateName}>
                            {template?.name}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}

                {filterdResume.length != 0 && (
                  <div className={styles.ResumeContainer}>
                    {filterdResume.map((template, index) => (
                      <div
                        key={index}
                        onClick={() =>
                          handleChange(template.type, template.images)
                        }
                        className={`${styles.dooo}`}
                      >
                       
                        <div className={styles.resumeImageContainer}>
                          <img
                            className={styles.resumeImage}
                            src={template.images}
                            alt={template.name}
                          />
                        </div>
                        <span className={styles.templateName}>
                          {truncateText(template.subFiledName, 14)}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </Modal.Body>
    </Modal>
  );
}

export default ModelChoseTemplate;
