import React, { useEffect, useRef, useState } from "react";
import styles from "./Config.module.css";
import { useResume } from "../../context/Resume";
import { Trash2, FilePlus2, Plus, ChevronDown, ChevronUp } from "lucide-react";
import { AIPoints, AiDescription, AISkillsPoints } from "./AI";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const truncateText = (text, maxLength) => {
  return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
};

const BuildResume = ({ config }) => {
  const { get, CurentSection } = useResume();
  return (
    <div className={styles.build}>
      <div className={styles.sections}>
        {config.sections.map((section, index) => {
          //  console.log(section.name);
          if (section.type === "static" && section.name === CurentSection) {
            return (
              <StaticSection
                key={index}
                name={section.name}
                fields={section.fields}
                sectionKey={section.key}
                desc={section.description}
                section={get(section.key)}
                displayKey={section.displayKey}
              />
            );
          } else if (
            section.type === "dynamic" &&
            section.name === CurentSection
          ) {
            return (
              <DynamicSection
                key={index}
                name={section.name}
                fields={section.fields}
                sectionKey={section.key}
                section={get(section.key)}
                desc={section.description}
                displayKey={section.displayKey}
              />
            );
          } else if (section.type === "list-string") {
            return (
              <DynamicListString
                key={index}
                name={section.name}
                sectionKey={section.key}
                section={get(section.key)}
                desc={section.description}
                displayKey={section.displayKey}
              />
            );
          } else return null;
        })}
      </div>
    </div>
  );
};

const StaticSection = ({ name, fields, desc, section }) => {
  return (
    <div className={styles.section}>
      <div className={styles.headingContainer}>
        <h2>{name}</h2>
        {desc && <p>{desc}</p>}
      </div>

      <div className={styles.TipsContainer}>
        <div className={styles.tipleft}>
          <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1730031255/all/crw1wqjgdwuae4lb7ybc.svg" />
        </div>
        <div className={styles.tipright}>
          <div className={styles.tipheading}>QUICK TIPS</div>
          <div className={styles.tippara}>
            Create an email that includes your name, avoiding nicknames or
            numbers that may seem unprofessional.{" "}
          </div>
          <div className={styles.tipparMobile}>
            Create an email that includes your name, ...{" "}
          </div>
        </div>
      </div>
      <div className={styles.section_fields}>
        {fields.map((field, index) => (
          <Input
            key={index}
            type={field.type}
            label={field.label}
            name={field.name}
            placeholder={field.placeholder}
            options={field.options}
            fields={field.fields}
            inputKey={field.key}
            defaultValue={field.defaultValue}
            value={section[field.key.split(".").pop()]}
            displayKey={field.displayKey}
          />
        ))}
      </div>
    </div>
  );
};

const DynamicSection = ({
  name,
  fields,
  sectionKey,
  section,
  desc,
  displayKey,
}) => {
  const { handleResumeChange } = useResume();
  const sectionRefs = useRef([]);
  const [showSection, setShowSection] = useState(false);
  useEffect(() => {
    if (!section) return;
    sectionRefs.current = sectionRefs.current.slice(0, section?.length);
  }, [section]);

  const handleElementAdd = (e) => {
    e.stopPropagation();
    const newElement = {};
    fields.forEach((field) => {
      if (field.type === "text" || field.type === "textarea") {
        newElement[field.name] = "";
      }
    });
    handleResumeChange(sectionKey, [...section, newElement]);
  };

  const handleElementDelete = (e, index) => {
    e.stopPropagation();
    const element = sectionRefs.current[index];
    element.classList.add(styles.slidefadeout);
    setTimeout(() => {
      handleResumeChange(sectionKey, [
        ...section.slice(0, index),
        ...section.slice(index + 1),
      ]);
    }, 480);
  };
  return (
    <div className={styles.section}>
      <h2>{name}</h2>
      {desc && <p>{desc}</p>}

      <div className={styles.TipsContainer}>
        <div className={styles.tipleft}>
          <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1730031255/all/crw1wqjgdwuae4lb7ybc.svg" />
        </div>
        <div className={styles.tipright}>
          <div className={styles.tipheading}>QUICK TIPS</div>
          <div className={styles.tippara}>
            Create an email that includes your name, avoiding nicknames or
            numbers that may seem unprofessional.{" "}
          </div>
          <div className={styles.tipparMobile}>
            Create an email that includes your name, ...{" "}
          </div>
        </div>
      </div>
      <div className={styles.section_elements}>
        {section?.map((element, _index) => (
          <details
            ref={(el) => (sectionRefs.current[_index] = el)}
            key={_index}
            className={`${styles.section_fields} ${styles.details_collapsed}`}
            open
          >
            <summary>
              {element[displayKey] || <span style={{ color: "white" }}>.</span>}
              <div className={styles.section_element_actions}>
                <button onClick={(e) => handleElementDelete(e, _index)}>
                  <Trash2 size={15} />
                </button>
                <div>
                  {" "}
                  {sectionRefs.current[_index]?.open ? (
                    <ChevronUp size={15} />
                  ) : (
                    <ChevronDown size={15} />
                  )}
                </div>
              </div>
            </summary>

            <div className={styles.section_fields_each}>
              {fields?.map(
                (field, index) =>
                  field.type !== "textarea" &&
                  field.type !== "list-string" && ( // Check if field type is not textarea
                    <div className={styles.eachContainer} key={index}>
                      <Input
                        type={field.type}
                        label={field.label}
                        name={field.name}
                        placeholder={field.placeholder}
                        value={element[field.name]}
                        defaultValue={element[field.name] || field.defaultValue}
                        inputKey={`${sectionKey}.${_index}.${field.key}`}
                        index_={_index}
                        options={field.options}
                        fields={field.fields}
                        displayKey={field.displayKey}
                        element={element}
                        partiCularName={name}
                      />
                    </div>
                  )
              )}
            </div>

            <div>
              {fields?.map(
                (field, index) =>
                  field.type === "textarea" && ( // Check if field type is \\ textarea
                    <div key={index}>
                      <Input
                        type={field.type}
                        label={field.label}
                        name={field.name}
                        placeholder={field.placeholder}
                        value={element[field.name]}
                        defaultValue={element[field.name] || field.defaultValue}
                        inputKey={`${sectionKey}.${_index}.${field.key}`}
                        index_={_index}
                        options={field.options}
                        fields={field.fields}
                        displayKey={field.displayKey}
                        element={element}
                        partiCularName={name}
                      />
                    </div>
                  )
              )}
            </div>

            <div>
              {fields?.map(
                (field, index) =>
                  field.type === "list-string" && ( // Check if field type is \\ textarea
                    <div key={index}>
                      <Input
                        type={field.type}
                        label={field.label}
                        name={field.name}
                        placeholder={field.placeholder}
                        value={element[field.name]}
                        defaultValue={element[field.name] || field.defaultValue}
                        inputKey={`${sectionKey}.${_index}.${field.key}`}
                        index_={_index}
                        options={field.options}
                        fields={field.fields}
                        displayKey={field.displayKey}
                        element={element}
                        partiCularName={name}
                      />
                    </div>
                  )
              )}
            </div>
          </details>
        ))}
      </div>
      <div className={styles.section_actions}>
        <span className={styles.addButtons} onClick={handleElementAdd}>
          {" "}
          <Plus color={"#6B4DC4"} size={14} /> Add {name}
        </span>
      </div>
      {/* <div className={styles.hr}></div> */}
    </div>
  );
};

const Input = ({
  type,
  label,
  name,
  placeholder,
  defaultValue,
  value,
  fields,
  inputKey,
  options,
  displayKey,
  index_,
  element,
  partiCularName,
}) => {
  const { handleResumeChange, handlePhoto, setLoading } = useResume();
  const handleInputChange = async (e) => {
    if (type === "image") {
      const photo = await handlePhoto(e);
      handleResumeChange(inputKey, photo);
    } else if (
      type === "text" ||
      type === "textarea" ||
      type === "title" ||
      type === "dropdown"
    ) {
      // console.log(inputKey)
      handleResumeChange(inputKey, e.target.value);
    } else if (type === "toggle") {
      handleResumeChange(inputKey, e.target.checked);
    }
  };

  const handleInputChangeWithDebounce = (e) => {
    setLoading(true);
    handleInputChange(e);

    // const debounceId = setTimeout(() => {
    //   clearTimeout(debounceId);

    //   handleInputChange(e);
    // }, 1000);
  };

  const updateDescription = (newdescriptiono) => {
    handleResumeChange(inputKey, newdescriptiono);
  };
  switch (type) {
    case "title":
      return (
        <div className={styles.input_text}>
          <input
            className={styles.input_title}
            name={name}
            sectionKey
            type="text"
            id={name}
            placeholder={placeholder}
            defaultValue={value || defaultValue}
            onChange={(e) => {
              handleInputChangeWithDebounce(e);
            }}
          />
        </div>
      );
    case "text":
      return (
        <div className={styles.input_text}>
          <label htmlFor={name}>{label}</label>
          <input
            name={name}
            type="text"
            id={name}
            placeholder={placeholder}
            defaultValue={value || defaultValue}
            onChange={(e) => {
              handleInputChangeWithDebounce(e);
            }}
          />
        </div>
      );
    case "textarea":
      return (
        <div className={styles.input_textarea}>
          <label htmlFor={name}>{label}</label>
          <ReactQuill
            theme="snow"
            value={value || ""}
            onChange={(content) => {
              handleResumeChange(inputKey, content);
            }}
            placeholder={placeholder}
            modules={{
              toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                [{ 'list': 'bullet' }],         // superscript/subscript       // indent
                [{ 'color': [] }, { 'background': [] }],         // dropdown with colors
                
              ],
            }}
            className={styles.customQuill}
          />
          <AiDescription
            updateDescription={updateDescription}
            element={element}
            sectionKey={inputKey}
            name={name}
            section={value}
            partiCularName={partiCularName}
          />
        </div>
      );
    case "image":
      return (
        <div className={styles.input_image}>
          <label htmlFor={name}>
            <img src={value} alt="" />
            {label}
          </label>
          <input
            name={name}
            id={name}
            type="file"
            accept="image/*"
            placeholder={placeholder}
            onChange={(e) => {
              handleInputChange(e);
            }}
          />
        </div>
      );
    case "dropdown":
      return (
        <div className={styles.input_dropdown}>
          <label htmlFor={name}>{label}</label>
          <select
            name={name}
            id={name}
            onChange={(e) => {
              handleInputChange(e);
            }}
            defaultValue={value || defaultValue}
          >
            {placeholder && <option value="">{placeholder}</option>}
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option}
              </option>
            ))}
          </select>
        </div>
      );
    case "list-string":
      return (
        <DynamicListString
          name={label}
          desc={placeholder}
          sectionKey={inputKey}
          section={value}
          displayKey={displayKey}
          fields={fields}
          partiCularName={partiCularName}
          element={element}
        />
      );
    case "list":
      return (
        <DynamicList
          name={label}
          desc={placeholder}
          sectionKey={inputKey}
          section={value}
          fields={fields}
          displayKey={displayKey}
        />
      );
    case "toggle":
      return (
        <div className={styles.input_toggle}>
          <input
            name={name}
            id={name}
            type="checkbox"
            onChange={handleInputChange}
            defaultChecked={value || defaultValue}
          />
          <label htmlFor={name}>
            <span>{label}</span>
            <span className={styles.toggle}></span>
          </label>
        </div>
      );
    default:
      return null;
  }
};

const DynamicListString = ({
  name,
  sectionKey,
  section,
  desc,
  partiCularName,
  element,
}) => {
  const { handleResumeChange, setLoading } = useResume();
  const itemRefs = useRef([]);

  useEffect(() => {
    if (!section) return;
    itemRefs.current = itemRefs.current.slice(0, section?.length);
  }, [section]);

  const handleAdd = (e) => {
    e.stopPropagation();
    handleResumeChange(sectionKey, [...(section || []), ""]);
  };

  const handleDelete = (e, index) => {
    e.stopPropagation();

    // Ensure itemRefs.current is correctly defined and holds references to DOM elements
    if (itemRefs.current[index]) {
      const element = itemRefs.current[index];
      element.classList.add(styles.slidefadeout);

      // Delay the state update to allow the fade-out animation to complete
      setTimeout(() => {
        handleResumeChange(sectionKey, [
          ...section.slice(0, index),
          ...section.slice(index + 1),
        ]);
      }, 480); // Match this duration with the CSS animation duration
    } else {
      console.warn("Element reference not found for index:", index);
    }
  };

  const handleInputChange = (e, index) => {
    e.stopPropagation();
    handleResumeChange(`${sectionKey}.${index}`, e.target.value);
  };

  const handleInputChangeWithDebounce = (e, index) => {
    e.stopPropagation();
    setLoading(true);
    handleInputChange(e, index);
  };

  return (
    <div className={styles.input_list}>
      <label htmlFor={name} className={styles.input_list_label}>
        {name}
      </label>
      {desc && <p>{desc} </p>}

      <div>
        {name === "Skills" ? (
          <>
            <div className={styles.input_list_elements}>
              {section?.map((element, index) => (
                <div
                  ref={(el) => (itemRefs.current[index] = el)}
                  key={index}
                  className={styles.input_list_String_element}
                >
                  <input
                    type="text"
                    value={element}
                    onChange={(e) => {
                      handleInputChangeWithDebounce(e, index);
                    }}
                  />
                  <button onClick={(e) => handleDelete(e, index)}>
                    <Trash2 size={15} />
                  </button>
                </div>
              ))}
            </div>
            <div className={styles.section_actions}>
              <span className={styles.addButtons} onClick={handleAdd}>
                <FilePlus2 style={{ height: "15px" }} /> Add {name}
              </span>
            </div>
            <AISkillsPoints
              element={element}
              sectionKey={sectionKey}
              name={name}
              section={section}
              partiCularName={partiCularName}
            />
          </>
        ) : (
          <>
            <div className={styles.input_list_elements}>
              {section?.map((element, index) => (
                <div
                  ref={(el) => (itemRefs.current[index] = el)}
                  key={index}
                  className={styles.input_list_String_element}
                >
                  <input
                    type="text"
                    value={element}
                    onChange={(e) => {
                      handleInputChangeWithDebounce(e, index);
                    }}
                  />
                  <button onClick={(e) => handleDelete(e, index)}>
                    <Trash2 size={15} />
                  </button>
                </div>
              ))}
            </div>
            <div className={styles.section_actions}>
              <span className={styles.addButtons} onClick={handleAdd}>
                <FilePlus2 style={{ height: "15px" }} /> Add {name}
              </span>
            </div>
            <AIPoints
              element={element}
              sectionKey={sectionKey}
              name={name}
              section={section}
              partiCularName={partiCularName}
            />
          </>
        )}
      </div>
    </div>
  );
};

const DynamicList = ({
  name,
  sectionKey,
  section,
  desc,
  fields,
  displayKey,
}) => {
  const { handleResumeChange } = useResume();
  const itemRefs = useRef([]);

  const handleAdd = (e) => {
    e.stopPropagation();
    const newElement = {};
    fields.forEach((field) => {
      if (field.type === "text" || field.type === "textarea") {
        newElement[field.name] = "";
      } else if (field.type === "dropdown") {
        newElement[field.name] = field.placeholder || "";
      } else if (field.type === "list-string") {
        newElement[field.name] = [];
      }
    });
    handleResumeChange(sectionKey, [...(section || []), newElement]);
  };

  const handleDelete = (e, index) => {
    e.stopPropagation();
    const element = itemRefs.current[index];
    element.classList.add(styles.slidefadeout);
    setTimeout(() => {
      handleResumeChange(sectionKey, [
        ...section.slice(0, index),
        ...section.slice(index + 1),
      ]);
    }, 480);
  };

  return (
    <div className={styles.input_list}>
      <label htmlFor={name} className={styles.input_list_label}>
        {name}
      </label>
      {desc && <p>{desc}</p>}
      <div className={styles.input_list_elements}>
        {section?.map((element, _index) => (
          <details
            ref={(el) => (itemRefs.current[_index] = el)}
            key={_index}
            className={styles.input_list_element}
          >
            <summary>
              {element[displayKey] || " "}
              <button onClick={(e) => handleDelete(e, _index)}>
                <Trash2 />
              </button>
            </summary>
            {fields.map((field, index) => (
              <Input
                key={index}
                type={field.type}
                label={field.label}
                name={field.name}
                placeholder={field.placeholder}
                value={element[field.name]}
                defaultValue={element[field.name]}
                inputKey={`${sectionKey}.${_index}.${field.key}`}
                options={field.options}
                fields={field.fields}
              />
            ))}
          </details>
        ))}
      </div>
      <div className={styles.section_actions}>
        <button onClick={handleAdd}>+ Add {name}</button>
      </div>
    </div>
  );
};

export default BuildResume;
