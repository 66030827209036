import React from "react";
import styles from "./SideNavbar.module.css";
import { useNavigate } from "react-router-dom";
const SideNavbar = ({image,auth}) => {
  const navigate = useNavigate();
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <span className={styles.logo} onClick={()=>navigate("/welcome/dashboard")}>
          MyFuse<span className={styles.dorcolor}>.</span>In
        </span>
        <div className={styles.subcontainer}>
          <div className={styles.profilecontainer}>
            <div className={styles.imageContainer}>
              <img
                src={image}
                alt="profile"
                className={styles.profileimage}
              />
              <span onClick={() => navigate("/welcome/account")}>
                <img
                  src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729186563/all/bqrjzzlw6qfepvwn0o3g.svg"
                  alt="pen"
                  className={styles.penimage}
                />
              </span>
            </div>
          </div>
          <div className={styles.detailsContainer}>
            <span className={styles.name}>{auth?.user?.[0]?.firstname} {auth?.user?.[0]?.lastname} </span>
          </div>

          <div className={styles.navigationContainer}>
            <div className={`${styles.ee} ${styles.active}`}>
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z"
                />
              </svg>
              <div className={styles.bar}></div>{" "}
            </div>
            <div
              className={styles.ee}
              onClick={() => navigate("/jobs-in-india")}
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                width="24"
                height="24"
                stroke="currentColor"
                class="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
                />
              </svg>
            </div>
            <div className={styles.ee}>
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                 width="24"
                height="24"
                class="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
                />
              </svg>
            </div>
            <div
              className={styles.ee}
              onClick={() => navigate("/resume-examples")}
            >
              {" "}
              <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729191042/all/lzu9h5j3quekpekqlxm2.svg" />{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNavbar;
