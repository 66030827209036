import React, { useState } from 'react'
import styles from "../../assets/HomePage/Oper.module.css"
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "../../assets/companyList.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
const Oper = () => {
    let array = [
   {
        id: 1,
        image:"https://res.cloudinary.com/dvepwlz9j/image/upload/v1721918216/c1_1_ncocxo.jpg"
    },
    {
         id: 2,
          image:"https://res.cloudinary.com/dvepwlz9j/image/upload/v1721918216/c1_1_ncocxo.jpg"
    },
    {
        id: 1,
        image:"https://res.cloudinary.com/dvepwlz9j/image/upload/v1721918216/c1_1_ncocxo.jpg"
    },
    {
         id: 2,
         image:"https://res.cloudinary.com/dvepwlz9j/image/upload/v1721918216/c1_1_ncocxo.jpg"
    },
    {
        id: 1,
        image:"https://res.cloudinary.com/dvepwlz9j/image/upload/v1721918216/c1_1_ncocxo.jpg"
    },
    {
         id: 2,
          image:"https://res.cloudinary.com/dvepwlz9j/image/upload/v1721918216/c1_1_ncocxo.jpg"
    }
] 

let arrayu = [
    {
         id: 1,
         image:"https://res.cloudinary.com/dvepwlz9j/image/upload/v1721918216/c1_1_ncocxo.jpg"
     },
     
 ] 

 const [active , setActive] = useState(1)

  return (
    <div className={styles.main}>
 

  <div className={styles.inner}> 
  <div>
          <h2 className={styles.headd}>
            {" "}

            Recent Opportunities         
          </h2> 
          <span className="_ngcontents" style={{marginBottom:'20px'}}>
          Explore the latest job openings in various industries on our portal.
          </span>
        </div> 
 
    <div className={styles.llsl}>
    <div className={`${styles.eii} ${active===1 ? styles.active : ""}`} onClick={()=>setActive(1)} >Completed</div>
           <div className={`${styles.eii} ${active===2 ? styles.active : ""}`} onClick={()=>setActive(2)}>Upcoming</div>
    </div>
 
  <Swiper
           spaceBetween={10}
           
           autoplay={{
             delay: 2500,
             disableOnInteraction: false,
           }}
            

           modules={[Autoplay, Pagination, Navigation]}
           className={`mySwiper ${styles.do}`}
           slidesPerView={1}  
           breakpoints={{
             640: {
               slidesPerView: 2,  
             },
             768: {
               slidesPerView: 2, 
             },
             1024: {
               slidesPerView: 2,  
             },
           }} 
           navigation={{
            nextEl: '.custom-swiper-button-next',
            prevEl: '.custom-swiper-button-prev',
          }}
          pagination={{
            clickable: true,
            el: '.swiper-pagination',
          }}

        >  

           { active===1 && Object.keys(array).map((ele,index)=>{
                return (
                <SwiperSlide className={styles.dlll}>

                    <img src={array[ele].image}  className={ ` img-fluid ${styles.dldo}`} alt='dsd'  />
                
                </SwiperSlide>
                );
           })}



           { active===2 && Object.keys(arrayu).map((ele,index)=>{
                return (
                <SwiperSlide className={styles.dlllg}>

                    <div>  
                         <div style={{height:'5rem' , display:'flex' , justifyContent:'center' , alignItems:'center'}}>
                            <img src='https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhXNVuhKverooFl_iCQamdD7sFKeM1jeFu4jJt7mDDnP3NtQYlfLYiuekMa4q7YYY8E-u1H4QZS-w5NOdJI8wNWJUMbhp61txfoLDuzmnJqaSTPS89hFpo9Y8FDfCosYp286NAc4ixdsH4/s1600/oops-smiley.jpg'  style={{height:"6rem" , width:"7rem"}} className='img-fluid' alt='d'/>

                         </div>
                    
                       <div className={`text-center ${styles.dle}`}>
                       No opportunities? Sounds like a great opportunity to make one!
                       </div>

                    </div>
                
                </SwiperSlide>
                );
           })}
           
          
        </Swiper>
  </div>

    </div>
  )
}

export default Oper