import React from 'react'
import CompanyList from '../CompanyList'
import styles from "../../assets/Job.module.css"
const TopCompany = () => {
  return (
    <div className={styles.nough}    > 
         
           <div className={styles.fjj}>
              <CompanyList/>
           </div> 
    </div>
  )
}

export default TopCompany