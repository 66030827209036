import React from 'react';
import { motion } from 'framer-motion';
import Modal from "react-bootstrap/Modal";
import { Cross, Zap } from 'lucide-react';
import { FaCrown, FaCheckCircle, FaFile, FaLock, FaRocket, FaChartLine, FaSearch } from 'react-icons/fa';
import styles from '../../assets/ResumeScore/PaymentModal.module.css';
import { usePayment } from "../PaymentCashFree";
const features = [
  { text: "ATS Compatibility Score", icon: <FaChartLine /> },
  { text: "Keyword Analysis", icon: <FaSearch /> },
  { text: "Industry-Specific Tips", icon: <FaRocket /> },
  { text: "Detailed Recommendations", icon: <FaCheckCircle /> }
];

// Helper function to get feature descriptions
const getFeatureDescription = (featureText) => {
  const descriptions = {
    "ATS Compatibility Score": "Ensure your resume passes automated screening systems",
    "Keyword Analysis": "Optimize for industry-specific keywords and phrases",
    "Industry-Specific Tips": "Get tailored advice for your target role",
    "Detailed Recommendations": "Receive actionable improvements for each section"
  };
  return descriptions[featureText] || "";
};

const PaymentModal = ({ show, onHide, file }) => {
    const { initiatePayment } = usePayment(); 
    const onProceed = () => initiatePayment(49);
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      centered
      className={styles.modalWrapper}
    >
      <Modal.Body className={styles.modernBody}>
        <motion.button
          className={styles.closeBtn}
          onClick={onHide}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <Cross />
        </motion.button>

        <div className={styles.contentWrapper}>
          <motion.div
            className={styles.leftSection}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className={styles.leftContent}>
              <motion.div
                className={styles.badge}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: 0.3 }}
              >
                <FaCrown className={styles.crownIcon} />
                <span>Premium Analysis</span>
              </motion.div>
              
              <motion.h1
                className={styles.modalTitle}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
              >
                Unlock Professional Resume Analysis
              </motion.h1>

              <motion.p
                className={styles.modalSubtitle}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
              >
                Get instant insights and improve your chances of landing your dream job
              </motion.p>

             

              <div className={styles.pricingBox}>
                <div className={styles.priceHeader}>Limited Time Offer! 🔥</div>
                <div className={styles.priceTag}>
                  <span className={styles.originalPrice}>
                    <span className={styles.currency}>₹</span>
                    <span className={styles.strikethrough}>149</span>
                  </span>
                  <span className={styles.currency}>₹</span>
                  <span className={styles.amount}>49</span>
                  <span className={styles.period}>only</span>
                </div>
                <div className={styles.saveTag}>Save ₹100 Today!</div>
                <div className={styles.pricingFeatures}>
                  <div className={styles.pricingFeature}>
                    <FaCheckCircle className={styles.checkIcon} />
                    <span>Instant Analysis</span>
                  </div>
                  <div className={styles.pricingFeature}>
                    <FaCheckCircle className={styles.checkIcon} />
                    <span>Detailed Report</span>
                  </div>
                </div>
              </div>

              <motion.button
                className={styles.upgradeBtn}
                onClick={onProceed}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Zap className={styles.buttonIcon} size={16} />
                Proceed to Secure Payment
              </motion.button>

              <div className={styles.securePayment}>
                <FaLock className={styles.lockIcon} />
                <span>100% Secure Payment</span>
              </div>
            </div>
          </motion.div>

          <motion.div
            className={styles.rightSection}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <motion.h3 
              className={styles.featuresTitle}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
            >
              <div className={styles.featuresTitleWrapper}>
                <FaRocket className={styles.rocketIcon} />
                <span>Unlock Premium Features</span>
              </div>
              <div className={styles.featuresTitleDivider}></div>
            </motion.h3>
            
            <div className={styles.featuresList}>
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  className={styles.featureItem}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.2 + index * 0.1 }}
                  whileHover={{ x: 10 }}
                >
                  <div className={styles.featureIconWrapper}>
                    {feature.icon}
                  </div>
                  <div className={styles.featureContent}>
                    <span className={styles.featureTitle}>{feature.text}</span>
                    <span className={styles.featureDescription}>
                      {getFeatureDescription(feature.text)}
                    </span>
                  </div>
                </motion.div>
              ))}
            </div>

            <div className={styles.testimonial}>
              <div className={styles.testimonialContent}>
                "This analysis helped me land my dream job!"
              </div>
              <div className={styles.testimonialAuthor}>
                - Recent Success Story
              </div>
            </div>
          </motion.div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentModal;
