import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "../assets/Job.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
 
import {
  faLocationDot,
  faBriefcase,
  faUserTie,
  faMoneyCheckDollar,
} from "@fortawesome/free-solid-svg-icons";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import {ArrowUpRight } from "lucide-react";
// import required modules
 

const JobRecomandation = ({ skillsfromdb, userIdfromdb }) => {
  const resume = JSON.parse(localStorage.getItem("resume"));
  const role = resume?.sections?.technicalSkills?.data?.[0]?.skills;

  const Navigate = useNavigate();

  const [jobs, setJobs] = useState([]);
  const [skillfromdbapi, setskillfromdibapi] = useState();
  const [loadingJobs, setLoadingJobs] = useState(false);
  const [showcompletereco, setshowrecom] = useState(false);

  const skill_from_resume = role?.join(",");

  const getUserSkill = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/skillsbyid?id_user=${userIdfromdb}`,
        {
          headers: {
            Authorization: "Bearer madebyashutosh",
          },
        }
      );

      setskillfromdibapi(data?.skillsfromdb?.[0].skills);
    } catch (error) {
      console.log(error);
    }
  };

  const mergedskill = `${skill_from_resume},${skillfromdbapi}`;

  const getAllJobs = async () => {
    try {
      setLoadingJobs(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/filterjob?skills=${mergedskill}`,
        {
          headers: {
            Authorization: "Bearer madebyashutosh",
          },
        }
      );

      if (data?.success) {
        const jobs = data;
        jobs.jobsWithPercentage.sort(
          (a, b) => b.percentageMatched - a.percentageMatched
        );
        setJobs(jobs.jobsWithPercentage);
      }
      setLoadingJobs(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserSkill();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllJobs();
  }, [skillfromdbapi, mergedskill]); // Call getAllJobs when skillfromdbapi is updated

  const handleNavigate = (id) => {
    Navigate(`/jobs/id=${id}`);
  };

  return (
    <>
      {/* <MyResponsiveNavbar /> */}
      {showcompletereco === false ? (
        <div
          style={{
            marginTop: "3rem",
            // background: "#f0fbff",
            padding: "1rem",
            borderRadius: "10px",
          }}
        >
          <div>
            <h1
              className={styles.sfas}
              
            >
              Job Recommendation
            </h1>
            <p  className={styles.sfs} >As Per Your Preference</p>
          </div>

          <Swiper
            spaceBetween={10}
            // centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
            }}
            // navigation={true}
            loop={true}
            modules={[ Autoplay, Pagination, Navigation]}
            className="mySwiper"
            slidesPerView={1} // default to 1 for small screens
            breakpoints={{
              640: {
                slidesPerView: 1, // 1 slide at a time on mobile devices
              },
              768: {
                slidesPerView: 4, // 2 slides at a time on tablets
              },
              1024: {
                slidesPerView: 4, // 4 slides at a time on larger screens
              },
            }}
            navigation={{
              nextEl: ".custom-swiper-button-next",
              prevEl: ".custom-swiper-button-prev",
            }}
          >  

           
            {jobs?.map((job) =>{
                const timestamp = job.createdat; // Assuming job.createdAt is the timestamp
                const date = new Date(timestamp);
                const formattedDate = date.toLocaleDateString("en-US", {
                  weekday: "short",
                  month: "short",
                  day: "numeric",
                });
                date.setDate(date.getDate() + 8); // Adding 8 days
  
                // Formatting date to only show date and month
                const options = {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                };
                const increasedDate = date.toLocaleDateString("en-US", options);
                return (
                  <SwiperSlide className="mb-5" style={{ borderRadius: "20px"  }}>
                  <div  >
                    {/* role companyname logo */}
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>
                        <h5 style={{ fontSize: "14px", fontWeight: "bolder" }}>
                        {job.jobtitle.length > 20
                                  ? `${job.jobtitle.substring(0, 20)}...`
                                  : job.jobtitle}
                        </h5>
                        <p> {job.companyName.length > 40
                                  ? `${job.companyName.substring(0, 40)}...`
                                  : job.companyName}</p>
                      </div>
                      {/* // image of the companyLogo  */}
                      <div>
                        <img
                          className="img-fluid"
                          src={`https://old.myfuse.in/uploads/logo/${job.companyLogo}`}
                          style={{ height: "40px", width: "60px" , objectFit:'contain'  }}
                          alt="s"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        width: "94%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <div
                        style={{ border: "1px solid #EEE", width: "80%" }}
                      ></div>{" "}
                    </div>
    
                    <div className="container" style={{ marginTop: "2rem" }}>
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <p className="card-text">
                            {" "}
                            <FontAwesomeIcon
                              icon={faBriefcase}
                              style={{
                                marginRight: ".5rem",
                                color: "#739ee8",
                              }}
                            />
                            {job.qualification.length > 4
                                        ? `${job.qualification.substring(
                                            0,
                                            7
                                          )}...`
                                        : job.qualification}
                          </p>
                          <p
                            className="card-text "
                            style={{ marginBottom: "1rem" }}
                          >
                            {" "}
                            <FontAwesomeIcon
                              icon={faLocationDot}
                              style={{
                                marginRight: ".5rem",
                                color: "#739ee8",
                              }}
                            />{" "}
                             {job.location.length > 10
                                        ? `${job.location.substring(0, 10)}...`
                                        : job.location}
                          </p>
                        </div>
                        <div className="col-12 col-md-6">
                          <p className="card-text">
                            {" "}
                            <FontAwesomeIcon
                              icon={faMoneyCheckDollar}
                              style={{
                                marginRight: ".5rem",
                                color: "#739ee8",
                              }}
                            />
                            {job.minimumsalary.length > 10
                                        ? `${job.minimumsalary.substring(
                                            0,
                                            10
                                          )}...`
                                        : job.minimumsalary}
                          </p>
                          <p className="card-text">
                            {" "}
                            <FontAwesomeIcon
                              icon={faUserTie}
                              style={{
                                marginRight: ".5rem",
                                color: "#739ee8",
                              }}
                            />{" "}
                            {job.experience}
                          </p>
                        </div>
                        <div className="col-12  d-flex justify-content-center mt-4 text-success">
                                {Math.min(job.percentageMatched, 100) === 0
                                  ? "Partially Matched"
                                  : `${Math.min(
                                      job.percentageMatched,
                                      100
                                    )}% Matched`}
                              </div>
                        <div className="col-12  d-flex justify-content-center mt-4 gap-2">
                                <a
                                   
                                  onClick={() => handleNavigate(job.id_jobpost)}
                                  style={{fontSize:"14px" , padding:"10px 14px" , borderRadius:"10px" , textDecoration:"none" , border:"1px solid #eee"}}

                                >
                                  View Details
                                </a>
                                <a
                                   
                                  href={job.link}
                                  target="_blank" 
                                  style={{fontSize:"14px" ,fontWeight:"600" ,padding:"10px 14px" , borderRadius:"10px" , textDecoration:"none" , color:'white' , backgroundColor:'#fb246a'}}
                                >
                                  {job.link ?  (
                                     <>
                                      Apply <ArrowUpRight size={14}/>
                                     </>
                                     
                                                                         
                                   ) : "Easy Apply"}
                                </a>
                              </div>
                              <div
                                className="col-12   mt-4"
                                style={{ color: "#e67519" ,fontSize:"10px" }}
                              >
                                Apply by {increasedDate} • Posted {"  "}
                                {formattedDate}
                              </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                )
              
            })}
           

            <div className="custom-swiper-button-next">
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
            <div className="custom-swiper-button-prev">
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
          </Swiper>
        </div>
      ) : (
        <div className="container mt-4 ">
          <h3 style={{ color: "#9eaab7" }}>Recomanded Jobs</h3>
          <div className="row d-flex justify-content-center">
            {jobs?.map((job) => {
              const timestamp = job.createdat; // Assuming job.createdAt is the timestamp
              const date = new Date(timestamp);
              const formattedDate = date.toLocaleDateString("en-US", {
                weekday: "short",
                month: "short",
                day: "numeric",
              });
              date.setDate(date.getDate() + 8); // Adding 8 days

              // Formatting date to only show date and month
              const options = {
                day: "numeric",
                month: "short",
                year: "numeric",
              };
              const increasedDate = date.toLocaleDateString("en-US", options);

              return (
                <>
                  <div className="col-md-4 col-12 mb-4" key={job.companyName}>
                    <div className="card">
                      <div className="card-body">
                        <div className="container">
                          <div className="row">
                            <div className="col-10  mt-4">
                              <h4 className="card-title">
                                {job.jobtitle.length > 20
                                  ? `${job.jobtitle.substring(0, 20)}...`
                                  : job.jobtitle}
                              </h4>
                              <h6
                                className="card-title"
                                style={{ color: "#9eaab7" }}
                              >
                                {job.companyName.length > 40
                                  ? `${job.companyName.substring(0, 40)}...`
                                  : job.companyName}
                              </h6>
                            </div>
                            <div className="col-2  mt-4">
                              <img
                                src={`https://myfuse.in/uploads/logo/${job.companyLogo}`}
                                style={{ width: "7rem" }}
                                className="img-fluid"
                                alt="sfas"
                              />
                            </div>
                          </div>
                        </div>
                        
                        {/* this  skill section end */}
                        <div className="container">
                          <div className="row">
                            <div className="col-12   mt-4">
                              <div className="container">
                                <div className="row">
                                  <div className="col-12 col-md-6">
                                    <p className="card-text">
                                      {" "}
                                      <FontAwesomeIcon
                                        icon={faBriefcase}
                                        style={{
                                          marginRight: ".5rem",
                                          color: "#739ee8",
                                        }}
                                      />
                                      {job.qualification.length > 10
                                        ? `${job.qualification.substring(
                                            0,
                                            10
                                          )}...`
                                        : job.qualification}
                                    </p>
                                    <p
                                      className="card-text "
                                      style={{ marginBottom: "1rem" }}
                                    >
                                      {" "}
                                      <FontAwesomeIcon
                                        icon={faLocationDot}
                                        style={{
                                          marginRight: ".5rem",
                                          color: "#739ee8",
                                        }}
                                      />{" "}
                                      {job.location.length > 10
                                        ? `${job.location.substring(0, 10)}...`
                                        : job.location}
                                    </p>
                                  </div>
                                  <div className="col-12 col-md-6">
                                    <p className="card-text">
                                      {" "}
                                      <FontAwesomeIcon
                                        icon={faMoneyCheckDollar}
                                        style={{
                                          marginRight: ".5rem",
                                          color: "#739ee8",
                                        }}
                                      />
                                      {job.minimumsalary.length > 20
                                        ? `${job.minimumsalary.substring(
                                            0,
                                            20
                                          )}...`
                                        : job.minimumsalary}
                                    </p>
                                    <p className="card-text">
                                      {" "}
                                      <FontAwesomeIcon
                                        icon={faUserTie}
                                        style={{
                                          marginRight: ".5rem",
                                          color: "#739ee8",
                                        }}
                                      />{" "}
                                      {job.experience}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="container">
                            <div className="row">
                              <div className="col-12  d-flex justify-content-center mt-4 text-success">
                                {Math.min(job.percentageMatched, 100) === 0
                                  ? "Partially Matched"
                                  : `${Math.min(
                                      job.percentageMatched,
                                      100
                                    )}% Matched`}
                              </div>

                              <div
                                className="col-12   mt-4"
                                style={{ color: "#e67519" }}
                              >
                                Apply by {increasedDate} • Posted {"  "}
                                {formattedDate}
                              </div>

                              <div className="col-12  d-flex justify-content-center mt-4">
                                <a
                                  className={styles.viewDetails}
                                  onClick={() => handleNavigate(job.id_jobpost)}
                                  style={{ marginRight: ".5rem" }}
                                >
                                  View Details
                                </a>
                                <a
                                  className={styles.jobBottom}
                                  href={job.link}
                                  target="_blank"
                                >
                                  {job.link ? "Apply" : "Easy Apply"}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default JobRecomandation;
