import React, { useEffect, useState } from "react";
import styles from "../../assets/Analysis.module.css";
import axios from "axios";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useResume } from "./components/context/Resume";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { MdCancel , MdCheckCircle } from "react-icons/md";
import { useJobs } from "./components/context/Jobs";
import OpenAI from "openai";
import BottomBar from "../../components/BottomBar";
import Model from "../../components/Model";
const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});
const Analysis = () => {
  const { values } = useResume();
  const [isActive, setActive] = useState();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState();
  let [Selected, SetSelected] = useState("1");
  const [activeEditor, setActiveEditor] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  let [datas, setdata] = useState({
    score: {
      totalScore: 0,
      sections: {
        ImpactAndInfluence: 0,
        ClarityAndFocus: 0,
        AchievementMetrics: 0,
        FormatAndStructure: 0,
        CareerProgression: 0,
      },
      details: {
        ImpactAndInfluence: { has: [], missing: [] },
        ClarityAndFocus: { has: [], missing: [] },
        AchievementMetrics: { has: [], missing: [] },
        FormatAndStructure: { has: [], missing: [] },
        CareerProgression: { has: [], missing: [] },
      },
    },
  });
  let [resumestructure, setresumeStructure] = useState(5);
  const { sections } = values;
  const { payment } = useJobs();
  console.log("this is payment", payment);
  const calculateScore = () => {
    let score = 0;
    const maxScore = 100;
    const pointsPerSection = 10;
    let store = 0;
    const excludedSections = [
      "Settings",
      "Sports Programming",
      "Profile",
      "Positions of Responsibility",
      "Awards",
    ];

    Object.keys(sections).forEach((key) => {
      const section = sections[key];
      if (
        !excludedSections.includes(section.name) &&
        section.data &&
        (Array.isArray(section.data)
          ? section.data.length > 0
          : Object.keys(section.data).length > 0)
      ) {
        score += pointsPerSection;
        store++;
        setresumeStructure(store);
      }
    });

    return Math.min(score, maxScore);
  };

  const getResoponse = async () => {
    if (response) return;
    try {
      setLoading(true);
      const jsonString = JSON.stringify(values);
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content: "You are resume anlysist  ",
          },

          { role: "user", content: `Resume Content= ${jsonString}` },
          {
            role: "user",
            content:
              "Give me detailed suggestions of resume  in array of object with sectiontitle",
          },
        ],
        model: "gpt-3.5-turbo",
        temperature: 1,
        max_tokens: 1000,
      });

      let data = completion.choices[0].message.content;

      const json_data = JSON.parse(data);
      setResponse(json_data);
      console.log(json_data); // Print the content in a readable format
      setLoading(false);
      // setAts(json_data.ats);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getData = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/pdf/ExtractJson`,
        values
      );
      console.log("data", data);
      setdata(data);
    } catch (error) {
      console.log("Error fetching data:", error); // Log errors
    }
  };

  const getPathColor = (score) => {
    // Define start and end colors for the gradient
    const startColor = { r: 255, g: 193, b: 140 }; // #FFC18C
    const midColor = { r: 255, g: 213, b: 135 }; // #FFD587
    const endColor = { r: 255, g: 224, b: 133 }; // #FFE085

    let r, g, b;

    if (score <= 67) {
      // Interpolate between startColor and midColor
      const t = score / 67; // Normalize to 0-1
      r = Math.round(startColor.r + t * (midColor.r - startColor.r));
      g = Math.round(startColor.g + t * (midColor.g - startColor.g));
      b = Math.round(startColor.b + t * (midColor.b - startColor.b));
    } else {
      // Interpolate between midColor and endColor
      const t = (score - 67) / (100 - 67); // Normalize to 0-1
      r = Math.round(midColor.r + t * (endColor.r - midColor.r));
      g = Math.round(midColor.g + t * (endColor.g - midColor.g));
      b = Math.round(midColor.b + t * (endColor.b - midColor.b));
    }

    return `rgb(${r}, ${g}, ${b})`;
  };

  const ScoreContainer = () => {
    return (
      <div className={styles.scoreContainerMain}>
        <div className={styles.upparScoreContainer}>
          <div className={styles.circularScoreContainerAnalysis}>
            <CircularProgressbar
              value={datas?.score?.totalScore || 0}
              text={`${datas?.score?.totalScore}%`}
              styles={buildStyles({
                rotation: 0.25,
                textColor: "#333",
                trailColor: "#d6d6d6",
                pathColor: getPathColor(datas?.score?.totalScore || 0),
                textSize: "26px",
              })}
            />
          </div>
          <div className={styles.ScoreContainer}>
            <div className={styles.score}>
              Your Resume scored {datas?.score?.totalScore} out of 100
            </div>
            <div className={styles.scorepara}>
              We ran 20+ checks on your resume. Start by fixing the sections
              with a score of less than 50.
            </div>
          </div>
        </div>
      </div>
    );
  };

  const SwitchATSEditor = () => {
    return (
      <div className={styles.SwitchATSEditorContainer}>
        <div
          className={`${styles.eachSwitch} ${
            !activeEditor ? styles.activeswitch : ""
          }`}
          onClick={() => setActiveEditor(!activeEditor)}
        >
          ATS Score
        </div>
        <div
          className={`${styles.eachSwitch} ${
            activeEditor ? styles.activeswitch : ""
          }`}
          onClick={() => setActiveEditor(!activeEditor)}
        >
          Editor
        </div>
      </div>
    );
  };

  useEffect(() => {
    // getResoponse();
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let score = calculateScore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleclose = (index) => {
    if (isActive === index) {
      setActive();
    } else {
      setActive(index);
    }
  };
  return (
    <div className={styles.main}>
      {ScoreContainer()}
      {SwitchATSEditor()}

      <div className={styles.maint}>
        <div
          className={`${
            payment?.paymentStatus !== "success" ? styles.blur : ""
          }`}
        >
          {/* {Selected === "2" && (
            <div className={styles.suffestions} style={{ paddingTop: "1rem" }}>
              <div className={styles.llo}>
                {Object.keys(sections).map((key, index) => {
                  const section = sections[key];
                  if (section.name == "Settings") {
                    return null;
                  }
                  return (
                    <>
                      {section.data && section.data.length > 0 ? (
                        ""
                      ) : (
                        <div
                          className={`${styles.flfo} ${
                            isActive === index && styles.flfoac
                          }`}
                          key={index}
                          onClick={() => handleclose(index)}
                        >
                          <div className={styles.headree}>
                            <div className={styles.firstno}>
                              <div className={styles.ddepal}>
                                {" "}
                                {section.name}
                              </div>
                            </div>
                            <div>
                              {isActive === index ? (
                                <ChevronUp size={17} />
                              ) : (
                                <ChevronDown size={17} />
                              )}
                            </div>
                          </div>

                          {section.name === "Education" && (
                            <div
                              className={`${styles.full}   ${
                                isActive === index && styles.sho
                              } `}
                            >
                              <ul>
                                <li>
                                  In the "Education" section of your resume,
                                  list your highest degree first, including the
                                  title, institution, location, and graduation
                                  date.
                                </li>
                                <li>
                                  Highlight any honors or awards, and mention
                                  relevant coursework or projects, especially
                                  those related to the job you're applying for.
                                </li>
                                <li>
                                  If your GPA is strong, include it. Keep the
                                  information concise and use reverse
                                  chronological order.
                                </li>
                                <li>
                                  Tailor this section to emphasize aspects most
                                  relevant to the job, presenting your
                                  educational background clearly and effectively
                                </li>
                              </ul>
                            </div>
                          )}

                          {section.name === "Experience" && (
                            <div
                              className={`${styles.full}   ${
                                isActive === index && styles.sho
                              } `}
                            >
                              <ul>
                                <li>
                                  In the "Experience" section, list jobs in
                                  reverse chronological order, including your
                                  job title, company name, location, and dates.
                                </li>
                                <li>
                                  Briefly describe your key responsibilities and
                                  achievements, focusing on quantifiable results
                                  and relevant skills.
                                </li>
                                <li>
                                  Use bullet points and start with strong action
                                  verbs.
                                </li>
                                <li>
                                  Tailor this section to highlight experiences
                                  most relevant to the job you're applying for,
                                  ensuring a clear and concise presentation.
                                </li>
                              </ul>
                            </div>
                          )}

                          {section.name === "Projects" && (
                            <div
                              className={`${styles.full}   ${
                                isActive === index && styles.sho
                              } `}
                            >
                              <ul>
                                <li>
                                  In the "Projects" section, list projects in
                                  reverse chronological order.
                                </li>
                                <li>
                                  Include the project title, your role, and a
                                  brief description.
                                </li>
                                <li>
                                  Highlight key achievements and technologies
                                  used, focusing on those relevant to the job
                                  you're applying for.
                                </li>
                                <li>
                                  Use bullet points for clarity and emphasize
                                  quantifiable results
                                </li>
                              </ul>
                            </div>
                          )}
                          {section.name === "Profile" && (
                            <div
                              className={`${styles.full}   ${
                                isActive === index && styles.sho
                              } `}
                            >
                              <ul>
                                <li>
                                  Add a brief summary highlighting your key
                                  skills and accomplishments.
                                </li>
                                <li>
                                  Include relevant keywords that align with the
                                  job you are applying for.
                                </li>
                              </ul>
                            </div>
                          )}
                          {section.name === "Technical Skills" && (
                            <div
                              className={`${styles.full}   ${
                                isActive === index && styles.sho
                              } `}
                            >
                              <ul>
                                <li>
                                  In the "Technical Skills" section, list
                                  relevant skills and technologies in a clear,
                                  organized manner.
                                </li>
                                <li>
                                  Use categories if needed (e.g., Programming
                                  Languages, Tools, Frameworks).
                                </li>
                                <li>
                                  Include proficiency levels if applicable
                                  (e.g., Beginner, Intermediate, Advanced).
                                </li>
                                <li>
                                  Focus on skills directly related to the job
                                  you’re applying for to demonstrate your
                                  technical capabilities effectively.
                                </li>
                              </ul>
                            </div>
                          )}

                          {section.name === "Awards" && (
                            <div
                              className={`${styles.full} ${
                                isActive === index && styles.sho
                              } `}
                            >
                              <ul>
                                <li>
                                  In the "Awards" section, list the prestigious
                                  recognitions and honors you've received.
                                </li>
                                <li>
                                  Include awards from academic, professional, or
                                  extracurricular activities that highlight your
                                  expertise and dedication.
                                </li>
                                <li>
                                  Provide a brief description of each award,
                                  mentioning the criteria, context, and
                                  significance of the accomplishment.
                                </li>
                                <li>
                                  Ensure that each entry showcases how it adds
                                  value to your profile and aligns with your
                                  professional aspirations.
                                </li>
                                <li>
                                  Use bullet points for clarity and focus on how
                                  these awards reflect your commitment,
                                  excellence, and leadership qualities.
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          )} */}

          {Selected === "1" && (
            <div className={styles.suffestions} style={{ paddingTop: "1rem" }}>
              <div className={styles.llo}>
                {Object.keys(datas?.score?.sections ?? {}).map((key, index) => {
                  const section = key;
                  const details = datas.score?.details[section];
                  let title;
                  if (section === "ImpactAndInfluence") {
                    title = "Impact and Influence";

                    return (
                      <div
                        className={`${styles.flfo} ${
                          isActive != index && styles.flfoac
                        }`}
                        key={index}
                        onClick={() => handleclose(index)}
                      >
                        <div className={styles.headree}>
                          <div className={styles.firstno}>
                            <div className={styles.ddepal}>
                              <div className={styles.leftSideContainerTitle}>
                                {title}
                              </div>
                              <div className={styles.leftSideContainerSubTitle}>
                                <span
                                  className={
                                    styles.leftSideContainerSubTitleSpan
                                  }
                                >
                                  {datas?.score?.sections?.ImpactAndInfluence}
                                </span>{" "}
                                out of{" "}
                                <span
                                  className={
                                    styles.leftSideContainerSubTitleSpan
                                  }
                                >
                                  30
                                </span>{" "}
                                missing
                              </div>
                            </div>
                          </div>

                          <div className={styles.rightSideConatainer}>
                            <div className={styles.eachScorePercentage}>
                              {" "}
                              {datas?.score?.sections?.ImpactAndInfluence}
                            </div>

                            {isActive === index ? (
                              <ChevronUp size={24} />
                            ) : (
                              <ChevronDown size={24} />
                            )}
                          </div>
                        </div>

                        <div
                          className={`${styles.full} ${
                            isActive === index && styles.sho
                          }`}
                        >
                          {/* Missing Elements Guidance */}
                          {details?.missing.length > 0 && (
                            <div className={styles.EcahConatinerMain}>
                              <div className={styles.innerContainerUniversal}>
                                <div>
                                  <MdCancel color={"#F04141"} size={17} />
                                </div>
                                <div>
                                  <div className={styles.eachHeading}>
                                    Use Specific and Impactful Verbs:
                                  </div>

                                  <div className={styles.eachSubHeading}>
                                    Example: Led a team of 5 to deliver a new
                                    product feature ahead of schedule.
                                  </div>
                                </div>
                              </div>
                              <div className={styles.innerContainerUniversal}>
                                <div>
                                  <MdCancel color={"#F04141"} size={17} />
                                </div>
                                <div>
                                  <div className={styles.eachHeading}>
                                    Include Quantifiable Outcomes:
                                  </div>

                                  <div className={styles.eachSubHeading}>
                                    Example: Increased customer satisfaction by
                                    30% through the development of a new support
                                    process.
                                  </div>
                                </div>
                              </div>
                              <div className={styles.innerContainerUniversal}>
                                <div>
                                  <MdCancel color={"#F04141"} size={17} />
                                </div>
                                <div>
                                  <div className={styles.eachHeading}>
                                    Highlight Your Initiative:
                                  </div>

                                  <div className={styles.eachSubHeading}>
                                    Example: Spearheaded a cross-functional team
                                    to implement process improvements, reducing
                                    lead time by 15%.
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {/* Present Elements Guidance */}
                          {details?.has.length > 0 && (
                            <div className={styles.EcahConatinerMain}>
                              <div className={styles.innerContainerUniversal}>
                                <div>
                                  <MdCheckCircle color={"#F04141"} size={17} />
                                </div>
                                <div>
                                  <div className={styles.eachHeading}>
                                    Ensure Action Verbs Match Your Role:
                                  </div>

                                  <div className={styles.eachSubHeading}>
                                    If you’ve used action verbs like{" "}
                                    <em>“Managed”</em> or <em>“Led,”</em> ensure
                                    they accurately represent your
                                    responsibilities and are appropriate for
                                    your level of experience. E.g.,{" "}
                                    <em>
                                      “Managed a $1M project budget while
                                      coordinating with key stakeholders to
                                      ensure successful delivery.”
                                    </em>
                                  </div>
                                </div>
                              </div>

                              <div className={styles.innerContainerUniversal}>
                                <div>
                                  <MdCancel color={"#F04141"} size={17} />
                                </div>
                                <div>
                                  <div className={styles.eachHeading}>
                                    Focus on Key Achievements:
                                  </div>

                                  <div className={styles.eachSubHeading}>
                                    Prioritize verbs that highlight measurable
                                    accomplishments, such as{" "}
                                    <em>“Achieved,” “Enhanced,”</em> or{" "}
                                    <em>“Optimized.”</em> E.g.,{" "}
                                    <em>
                                      “Achieved a 20% increase in sales through
                                      targeted marketing campaigns.”
                                    </em>
                                  </div>
                                </div>
                              </div>

                              <div className={styles.innerContainerUniversal}>
                                <div>
                                  <MdCancel color={"#F04141"} size={17} />
                                </div>
                                <div>
                                  <div className={styles.eachHeading}>
                                    Integrate Technical Proficiency:
                                  </div>

                                  <div className={styles.eachSubHeading}>
                                    When listing technical skills or tasks, pair
                                    action verbs with specific technologies or
                                    methodologies. E.g.,{" "}
                                    <em>
                                      “Designed a scalable database system using
                                      PostgreSQL, enhancing data retrieval speed
                                      by 40%.”
                                    </em>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  } else if (section === "CareerProgression") {
                    title = "Career Progression";
                    return (
                      <div
                        className={`${styles.flfo} ${
                          isActive != index && styles.flfoac
                        }`}
                        key={index}
                        onClick={() => handleclose(index)}
                      >
                        <div className={styles.headree}>
                          <div className={styles.firstno}>
                            <div className={styles.ddepal}>
                              <div className={styles.leftSideContainerTitle}>
                                {title}
                              </div>
                              <div className={styles.leftSideContainerSubTitle}>
                                <span
                                  className={
                                    styles.leftSideContainerSubTitleSpan
                                  }
                                >
                                  {datas?.score?.sections?.CareerProgression}
                                </span>{" "}
                                out of{" "}
                                <span
                                  className={
                                    styles.leftSideContainerSubTitleSpan
                                  }
                                >
                                  10
                                </span>{" "}
                                missing
                              </div>
                            </div>
                          </div>

                          <div className={styles.rightSideConatainer}>
                            <div className={styles.eachScorePercentage}>
                              {" "}
                              {datas?.score?.sections?.CareerProgression}
                            </div>

                            {isActive === index ? (
                              <ChevronUp size={24} />
                            ) : (
                              <ChevronDown size={24} />
                            )}
                          </div>
                        </div>

                        <div
                          className={`${styles.full} ${
                            isActive === index && styles.sho
                          }`}
                        >
                          <div className="EcahConatinerMain">
                            {details?.missing.length > 0 && (
                              <div className={styles.EcahConatinerMain}>
                                <div className={styles.innerContainerUniversal}>
                                  <div>
                                    <MdCancel color={"#F04141"} size={17} />
                                  </div>
                                  <div>
                                    <div className={styles.eachHeading}>
                                      Include Specific Career Growth Terms:
                                    </div>
                                    <div className={styles.eachSubHeading}>
                                      Transitioned from [Old Role] to [New Role].
                                    </div>
                                  </div>
                                </div>

                                <div className={styles.innerContainerUniversal}>
                                  <div>
                                    <MdCancel color={"#F04141"} size={17} />
                                  </div>
                                  <div>
                                    <div className={styles.eachHeading}>
                                      Highlight Key Promotions and Role Changes:
                                    </div>
                                    <div className={styles.eachSubHeading}>
                                      Promoted from Software Engineer to Senior Engineer after demonstrating leadership in project execution.
                                    </div>
                                  </div>
                                </div>

                                <div className={styles.innerContainerUniversal}>
                                  <div>
                                    <MdCancel color={"#F04141"} size={17} />
                                  </div>
                                  <div>
                                    <div className={styles.eachHeading}>
                                      Use Dynamic Verbs to Indicate Career Movement:
                                    </div>
                                    <div className={styles.eachSubHeading}>
                                      Propelled to a managerial role by consistently exceeding performance metrics.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {details?.has.length > 0 && (
                              <div>
                                <div className={styles.innerContainerUniversal}>
                                  <div>
                                    <MdCheckCircle color={"#4BB543"} size={17} />
                                  </div>
                                  <div>
                                    <div className={styles.eachHeading}>
                                      Present elements:
                                    </div>
                                    <div className={styles.eachSubHeading}>
                                      {details?.has.map((item, idx) => (
                                        <span key={idx}>{item}, </span>
                                      ))}
                                    </div>
                                  </div>
                                </div>

                                <div className={styles.innerContainerUniversal}>
                                  <div>
                                    <MdCheckCircle color={"#4BB543"} size={17} />
                                  </div>
                                  <div>
                                    <div className={styles.eachHeading}>
                                      Ensure Career Advancement is Clearly Structured:
                                    </div>
                                    <div className={styles.eachSubHeading}>
                                      If you have multiple roles listed within the same company, organize them to show a natural progression. E.g., "Started as a Junior Developer, promoted to Team Lead within 2 years."
                                    </div>
                                  </div>
                                </div>

                                <div className={styles.innerContainerUniversal}>
                                  <div>
                                    <MdCheckCircle color={"#4BB543"} size={17} />
                                  </div>
                                  <div>
                                    <div className={styles.eachHeading}>
                                      Focus on the Impact of Your Role Changes:
                                    </div>
                                    <div className={styles.eachSubHeading}>
                                      Whenever you mention a role change or promotion, illustrate the impact you made. E.g., "Advanced to Senior Analyst for driving a 15% improvement in client satisfaction ratings."
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  } else if (section === "ClarityAndFocus") {
                    title = "Clarity And Focus";
                    return (
                      <div
                        className={`${styles.flfo} ${
                          isActive != index && styles.flfoac
                        }`}
                        key={index}
                        onClick={() => handleclose(index)}
                      >
                        <div className={styles.headree}>
                          <div className={styles.firstno}>
                            <div className={styles.ddepal}>
                              <div className={styles.leftSideContainerTitle}>
                                {title}
                              </div>
                              <div className={styles.leftSideContainerSubTitle}>
                                <span
                                  className={
                                    styles.leftSideContainerSubTitleSpan
                                  }
                                >
                                  {datas?.score?.sections?.ClarityAndFocus}
                                </span>{" "}
                                out of{" "}
                                <span
                                  className={
                                    styles.leftSideContainerSubTitleSpan
                                  }
                                >
                                  20
                                </span>{" "}
                                missing
                              </div>
                            </div>
                          </div>

                          <div className={styles.rightSideConatainer}>
                            <div className={styles.eachScorePercentage}>
                              {" "}
                              {datas?.score?.sections?.ClarityAndFocus}
                            </div>

                            {isActive === index ? (
                              <ChevronUp size={24} />
                            ) : (
                              <ChevronDown size={24} />
                            )}
                          </div>
                        </div>

                        <div
                          className={`${styles.full} ${
                            isActive === index && styles.sho
                          }`}
                        >
                          {details?.missing.length > 0 && (
                            <div className={styles.EcahConatinerMain}>
                              <div className={styles.innerContainerUniversal}>
                                <div>
                                  <MdCancel color={"#F04141"} size={17} />
                                </div>
                                <div>
                                  <div className={styles.eachHeading}>
                                    Missing elements:
                                  </div>
                                  <div className={styles.eachSubHeading}>
                                    {details?.missing.map((item, idx) => (
                                      <span key={idx}>{item}, </span>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {details?.has.length > 0 && (
                            <div>
                              <div className={styles.innerContainerUniversal}>
                                <div>
                                  <MdCheckCircle color={"#4BB543"} size={17} />
                                </div>
                                <div>
                                  <div className={styles.eachHeading}>
                                    Present elements:
                                  </div>
                                  <div className={styles.eachSubHeading}>
                                    {details?.has.map((item, idx) => (
                                      <span key={idx}>{item}, </span>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  } else if (section === "FormatAndStructure") {
                    title = "Format And Structure";
                    return (
                      <div
                        className={`${styles.flfo} ${
                          isActive != index && styles.flfoac
                        }`}
                        key={index}
                        onClick={() => handleclose(index)}
                      >
                        <div className={styles.headree}>
                          <div className={styles.firstno}>
                            <div className={styles.ddepal}>
                              <div className={styles.leftSideContainerTitle}>
                                {title}
                              </div>
                              <div className={styles.leftSideContainerSubTitle}>
                                <span
                                  className={
                                    styles.leftSideContainerSubTitleSpan
                                  }
                                >
                                  {datas?.score?.sections?.FormatAndStructure}
                                </span>{" "}
                                out of{" "}
                                <span
                                  className={
                                    styles.leftSideContainerSubTitleSpan
                                  }
                                >
                                  15
                                </span>{" "}
                                missing
                              </div>
                            </div>
                          </div>

                          <div className={styles.rightSideConatainer}>
                            <div className={styles.eachScorePercentage}>
                              {" "}
                              {datas?.score?.sections?.FormatAndStructure}
                            </div>

                            {isActive === index ? (
                              <ChevronUp size={24} />
                            ) : (
                              <ChevronDown size={24} />
                            )}
                          </div>
                        </div>

                        <div
                          className={`${styles.full} ${
                            isActive === index && styles.sho
                          }`}
                        >
                          <div className="EcahConatinerMain">
                            {details?.missing.length > 0 && (
                              <div className={styles.EcahConatinerMain}>
                                <div className={styles.innerContainerUniversal}>
                                  <div>
                                    <MdCancel color={"#F04141"} size={17} />
                                  </div>
                                  <div>
                                    <div className={styles.eachHeading}>
                                      Use Consistent Formatting:
                                    </div>
                                    <div className={styles.eachSubHeading}>
                                      Ensure all headers, subheaders, and text use a consistent font, size, and style.
                                    </div>
                                  </div>
                                </div>

                                <div className={styles.innerContainerUniversal}>
                                  <div>
                                    <MdCancel color={"#F04141"} size={17} />
                                  </div>
                                  <div>
                                    <div className={styles.eachHeading}>
                                      Avoid Using Images and Tables:
                                    </div>
                                    <div className={styles.eachSubHeading}>
                                      Keep the format clean and simple. Use bullet points to highlight skills and achievements.
                                    </div>
                                  </div>
                                </div>

                                <div className={styles.innerContainerUniversal}>
                                  <div>
                                    <MdCancel color={"#F04141"} size={17} />
                                  </div>
                                  <div>
                                    <div className={styles.eachHeading}>
                                      Maintain Adequate Spacing:
                                    </div>
                                    <div className={styles.eachSubHeading}>
                                      Use proper spacing between sections and bullet points. Use 1.15 line spacing and ensure clear gaps between sections.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {details?.has.length > 0 && (
                              <div>
                                <div className={styles.innerContainerUniversal}>
                                  <div>
                                    <MdCheckCircle color={"#4BB543"} size={17} />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  } else if (section === "AchievementMetrics") {
                    title = "Achievement Metrics";
                    return (
                      <div
                        className={`${styles.flfo} ${
                          isActive != index && styles.flfoac
                        }`}
                        key={index}
                        onClick={() => handleclose(index)}
                      >
                        <div className={styles.headree}>
                          <div className={styles.firstno}>
                            <div className={styles.ddepal}>
                              <div className={styles.leftSideContainerTitle}>
                                {title}
                              </div>
                              <div className={styles.leftSideContainerSubTitle}>
                                <span
                                  className={
                                    styles.leftSideContainerSubTitleSpan
                                  }
                                >
                                  {datas?.score?.sections?.AchievementMetrics}
                                </span>{" "}
                                out of{" "}
                                <span
                                  className={
                                    styles.leftSideContainerSubTitleSpan
                                  }
                                >
                                  25
                                </span>{" "}
                                missing
                              </div>
                            </div>
                          </div>

                          <div className={styles.rightSideConatainer}>
                            <div className={styles.eachScorePercentage}>
                              {" "}
                              {datas?.score?.sections?.AchievementMetrics}
                            </div>

                            {isActive === index ? (
                              <ChevronUp size={24} />
                            ) : (
                              <ChevronDown size={24} />
                            )}
                          </div>
                        </div>

                        <div
                          className={`${styles.full} ${
                            isActive === index && styles.sho
                          }`}
                        >
                          <div className="EcahConatinerMain">
                            {details?.missing.length > 0 && (
                              <div className={styles.EcahConatinerMain}>
                                <div className={styles.innerContainerUniversal}>
                                  <div>
                                    <MdCancel color={"#F04141"} size={17} />
                                  </div>
                                  <div>
                                    <div className={styles.eachHeading}>
                                      Include Measurable Outcomes:
                                    </div>
                                    <div className={styles.eachSubHeading}>
                                      Reduced development time by 20% through the implementation of automated testing.
                                    </div>
                                  </div>
                                </div>

                                <div className={styles.innerContainerUniversal}>
                                  <div>
                                    <MdCancel color={"#F04141"} size={17} />
                                  </div>
                                  <div>
                                    <div className={styles.eachHeading}>
                                      Showcase Business Impact:
                                    </div>
                                    <div className={styles.eachSubHeading}>
                                      Increased user engagement by 35% by revamping the UI and optimizing content placement.
                                    </div>
                                  </div>
                                </div>

                                <div className={styles.innerContainerUniversal}>
                                  <div>
                                    <MdCancel color={"#F04141"} size={17} />
                                  </div>
                                  <div>
                                    <div className={styles.eachHeading}>
                                      Provide Context for Your Contributions:
                                    </div>
                                    <div className={styles.eachSubHeading}>
                                      Led a cross-functional team to build a new feature, resulting in a 15% increase in customer retention.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {details?.has.length > 0 && (
                              <div>
                                <div className={styles.innerContainerUniversal}>
                                  <div>
                                    <MdCheckCircle color={"#4BB543"} size={17} />
                                  </div>
                                  <div>
                                    <div className={styles.eachHeading}>
                                      Present elements:
                                    </div>
                                    <div className={styles.eachSubHeading}>
                                      {details?.has.map((item, idx) => (
                                        <span key={idx}>{item}, </span>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          )}

          {/* {Selected === "3" && (
            <div className={styles.suffestions} style={{ paddingTop: "1rem" }}>
              <div className={styles.llo}>
                {!response &&
                  (loading ? (
                    <div className={styles.ResumeBuilder_buttonsall__4y5cd}>
                      <span> Loading...</span>
                    </div>
                  ) : (
                    <div
                      className={styles.ResumeBuilder_buttonsall__4y5cd}
                      onClick={() => getResoponse()}
                    >
                      <span>Generate </span>
                    </div>
                  ))}

                {response?.map((section, sectionIndex) => (
                  <div
                    className={`${styles.flfo} ${
                      isActive === sectionIndex && styles.flfoake
                    }`}
                    key={sectionIndex}
                    onClick={() => handleclose(sectionIndex)}
                  >
                    <div className={styles.headree}>
                      <div className={styles.firstno}>
                        <div className={styles.ddepal}>
                          <div className={styles.blinkdotp}></div>{" "}
                          {section.sectionTitle}
                        </div>
                        <div className={styles.dooso}>in AI Response</div>
                      </div>
                      <div>
                        {isActive === sectionIndex ? (
                          <ChevronUp size={17} />
                        ) : (
                          <ChevronDown size={17} />
                        )}
                      </div>
                    </div>
                    <div
                      className={`${styles.full}   ${
                        isActive === sectionIndex && styles.sho
                      } `}
                    >
                      <ul>
                        {section.suggestions.map(
                          (suggestion, suggestionIndex) => (
                            <li
                              key={suggestionIndex}
                              className={`${
                                payment?.paymentStatus !== "success"
                                  ? styles.blur
                                  : ""
                              }`}
                            >
                              {suggestion}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )} */}
        </div>
        {payment?.paymentStatus !== "success" && (
          <div className={styles.ddd}>
            <div className={styles.ddodd}>
              <h5 className={styles.dd}>
                Unlock Premium Resume Analytics
              </h5>
              <p className={styles.paraa}>
                Get detailed insights, personalized recommendations, and expert tips to make your resume stand out from the competition. Upgrade now to access our comprehensive resume analysis tools.
              </p>
              <div className={styles.upgradde}>
                <button className={styles.eeo} onClick={() => setModalShow(true)}>
                  Upgrade to Premium
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <BottomBar/>
      <Model modalShow={modalShow} setModalShow={setModalShow} />
    </div>
  );
};

export default Analysis;
