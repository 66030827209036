import React from "react";
import styles from "../../assets/HomePage/Offering.module.css";
import {
  Presentation,
  MousePointerClick,
  NotepadText,
  ShieldCheck,
  Cctv,
  Repeat, 
  Rocket
} from "lucide-react";

const OurOffring = () => {
  return (
    <div className={styles.main}>
      <div className="container">
        <div className="row d-flex  justify-content-center ">
          <div className="col-11  col-md-11 d-flex justify-content-center flex-column ">
            <h2 className={styles.ngcontent}>
              {" "}
              Our Offering
              <span className={styles._ngcontents}>
                {" "}
                Explore Our Diverse and Customized Range of Solutions.
              </span>
            </h2>

            <div
              className="d-flex justify-content-center align-items-center flex-wrap "
              style={{ gap: "20px" }}
            >
              <div className={styles.cate}>
                {" "}
                <span className={styles.icon}>
                  <Presentation />{" "}
                </span>
                One to One Coverstatiion With HR's{" "}
              </div>{" "}
              <div className={styles.cate}>
                {" "}
                <span className={styles.icon2}>
                  <MousePointerClick />{" "}
                </span>{" "}
                Single Click Apply{" "}
              </div>
              <div className={styles.cate}>
                {" "}
                <span className={styles.icon3}>
                  <NotepadText />{" "}
                </span>{" "}
                Experience Not Required
              </div>{" "}
              <div className={styles.cate}>
                {" "}
                <span className={styles.icon4}>
                  <ShieldCheck />{" "}
                </span>{" "}
                User-friendly interface
              </div>
              <div className={styles.cate}>
                {" "}
                <span className={styles.icon5}>
                  <Cctv />{" "}
                </span>{" "}
                Personalized recommendations
              </div>{" "}
              <div className={styles.cate}>
                {" "}
                <span className={styles.icon6}>
                  <Repeat />{" "}
                </span>{" "}
                Constant updates
              </div>
              <div className={styles.cate}>
                {" "}
                <span className={styles.icon6}>
                  <Rocket />{" "}
                </span>{" "}
                Seamless application process
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurOffring;
