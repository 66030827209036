import React from "react";
import MyResponsiveNavbar from "./Navbar";
import styles from "../ResumeBuilder/builder/ResumeBuilder.module.css";
import { useResume } from "../ResumeBuilder/builder/components/context/Resume";
import Preview from "../ResumeBuilder/builder/components/Preview/Preview";
import previewStyles from "../ResumeBuilder/builder/components/Preview/Preview.module.css";
import ChooseTemplate from "../ResumeBuilder/builder/components/templates/ChooseTemplate/ChooseTemplate";
import chooseTemplateStyle from '../ResumeBuilder/builder/components/templates/ChooseTemplate/ChooseTemplate.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
const TemplatePreview = () => {
  const {
    selectedTemplate,
    values,
    printContainerRef,
    templates,
    handleTemplateChange,
  } = useResume();
  const Resume = selectedTemplate?.resume;
  
  return (
    <div>
      <MyResponsiveNavbar />
      {/* For only print purposes */}
      <div className={previewStyles.print_only_resume_page} id="print_content">
        <div
          ref={printContainerRef}
          className={previewStyles.container}
          style={{
            width: "100%",
            padding: selectedTemplate?.page_margins,
          }}
        >
          {Resume && <Resume sections={values.sections} />}
        </div>
      </div>

      {selectedTemplate ? (
        <div className={styles.containerP}>
          <div className={styles.left} style={{background:'rgb(73, 81, 99)', borderRight:'1px solid rgba(255, 255, 255, 0.15)'}}>
            <div className={chooseTemplateStyle.templatesGridc}>
              {Object.values(templates).map((template, index) => {
                return (
                  <div
                    key={index}
                    className={chooseTemplateStyle.templatefores}
                    onClick={() => handleTemplateChange(template.key)}
                  > 
                    <div className={`${chooseTemplateStyle.preview} ${template.key === selectedTemplate.key ? chooseTemplateStyle.selectedTemplate : ''}`}>
                      <img src={template.preview} alt={template.name} />
                      {template.key === selectedTemplate.key && (
                        <div className={chooseTemplateStyle.tickMark}>
                        {/* Add your tick mark icon or component here */}
                         <FontAwesomeIcon icon={faCircleCheck} style={{ color: 'hsl(207, 88%, 52%)', fontSize: '27px' }} />
                        
                        </div>
                        
  )}
                    </div>

                    <div className={chooseTemplateStyle.name} style={{color:'white' , textAlign:'center'}}>{template.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.right}>
            <Preview template={selectedTemplate} preview={true} />
          </div>
        </div>
      ) : (
        <ChooseTemplate />
      )}
    </div>
  );
};

export default TemplatePreview;
