import React, { useState } from 'react';
import { motion, useScroll, useTransform, AnimatePresence } from 'framer-motion';
import { FaUpload, FaCheckCircle, FaRocket, FaChartLine, FaTimes, FaLightbulb, FaLock } from 'react-icons/fa';
import styles from '../assets/ResumeScore.module.css';
import axios from 'axios';
import MyResponsiveNavbar from './Navbar';
import PaymentModal from '../components/ResumeScore/PaymentModal';
import OpenAI from "openai";
import Header from '../components/ResumeScore/Header';
import Dropzone from '../components/ResumeScore/DropZone';
import ScoreModal from '../components/ResumeScore/ScoreModal';
import { useJobs } from '../ResumeBuilder/builder/components/context/Jobs';


const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

const generateAISuggestions = async (extractedText, resumeScore) => {
  const prompt = `
    As a professional resume reviewer, provide detailed analysis of this resume:
    
    For each section, provide:
    1. Key strengths found in the resume (4-5 specific points)
    2. Areas that need improvement (4-5 actionable points)
    3. Include specific examples or suggestions where possible

    Sections to analyze:
    - Impact & Influence (focus on leadership, results, and influence on business outcomes)
    - Clarity & Focus (analyze writing style, relevance, and organization)
    - Achievement Metrics (examine quantifiable results and KPIs)
    - Format & Structure (evaluate layout, readability, and visual hierarchy)
    - Career Progression (assess growth, responsibilities, and skill development)

    Consider these aspects in your analysis:
    - Industry best practices
    - ATS optimization
    - Modern resume trends
    - Recruiter preferences
    - Role-specific requirements

    Base your analysis on this resume text and scores:
    Resume Scores: ${JSON.stringify(resumeScore, null, 2)}
    Resume Text: ${extractedText}

    Format response as JSON:
    {
      "sectionName": {
        "strengths": ["detailed point1", "detailed point2", "detailed point3", "detailed point4", "detailed point5"],
        "improvements": ["actionable point1", "actionable point2", "actionable point3", "actionable point4", "actionable point5"]
      }
    }

    Ensure each point is specific, actionable, and provides clear value or direction for improvement.
  `;

  try {
    const completion = await openai.chat.completions.create({
      messages: [{ role: "user", content: prompt }],
      model: "gpt-3.5-turbo",
      temperature: 0.7,
      max_tokens: 1500,
    });

    return JSON.parse(completion.choices[0].message.content);
  } catch (error) {
    console.error("Error generating AI suggestions:", error);
    return null;
  }
};

const ResumeScore = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState("");
  const [resumeScore, setResumeScore] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { payment } = useJobs();
  const [extractedText, setExtractedText] = useState("");
  const [aiSuggestions, setAiSuggestions] = useState({});
  const [isGeneratingAI, setIsGeneratingAI] = useState(false);
  const [showScoreModal, setShowScoreModal] = useState(false);
  const handleFileUpload = async (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      if (uploadedFile.size > 5 * 1024 * 1024) {
        setError('File size should be less than 5MB');
        return;
      }
      setFile(uploadedFile);
      await handleSubmit(uploadedFile);
    }
  };

  const handleSubmit = async (uploadedFile) => {
    if (!uploadedFile) {
      setError("Please select a PDF file to upload.");
      return;
    }

    setUploading(true);
    setError("");
    setResumeScore(null);
    setIsGeneratingAI(true);

    const formData = new FormData();
    formData.append("pdf", uploadedFile);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/pdf/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setResumeScore(response.data.resumeScore);
      setExtractedText(response.data.extractedText);

      // Generate AI suggestions
      const suggestions = await generateAISuggestions(response.data.extractedText, response.data.resumeScore);
      setAiSuggestions(suggestions);
      
      // Automatically show the score modal when AI processing is complete
      setShowScoreModal(true);
    } catch (error) {
      console.error("Error uploading file:", error);
      setError("Error uploading file. Please try again.");
    } finally {
      setUploading(false);
      setIsGeneratingAI(false);
    }
  };

  const scrollToAnalysis = () => {
    setTimeout(() => {
      const analysisSection = document.getElementById('resume-analysis-section');
      if (analysisSection) {
        analysisSection.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }
    }, 100);
  };
 console.log(payment.paymentAmount);
  const isDetailedResultsLocked = () => {
     if (payment?.paymentAmount === 0 || payment?.paymentAmount === null || payment?.paymentAmount === undefined  || payment?.paymentAmount === '') {
      return true; 
     } 
     return false;
  };

  const isPaidUser = () => {
    return payment?.paymentAmount > 0;
  };

  return ( 
    <>
      <MyResponsiveNavbar />
      {isGeneratingAI && (
        <motion.div 
          className={styles.aiLoadingOverlay}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className={styles.backgroundElements}>
            <div className={styles.gradientOrb1} />
            <div className={styles.gradientOrb2} />
            <div className={styles.gridPattern} />
          </div>

          <div className={styles.aiLoadingContainer}>
            <div className={styles.loadingHeader}>
              <motion.div 
                className={styles.aiLogo}
                animate={{ 
                  rotate: 360,
                  scale: [1, 1.2, 1]
                }}
                transition={{ 
                  rotate: { duration: 4, repeat: Infinity, ease: "linear" },
                  scale: { duration: 2, repeat: Infinity }
                }}
              >
                <div className={styles.logoInner}>AI</div>
              </motion.div>

              <motion.h2
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                Analyzing Your Resume
              </motion.h2>
              
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                Using advanced AI to evaluate your profile
              </motion.p>
            </div>

            <div className={styles.processingSteps}>
              {[
                { icon: "📄", label: "Scanning Document", progress: 100 },
                { icon: "🤖", label: "AI Processing", progress: 80 },
                { icon: "✨", label: "Generating Insights", progress: 60 }
              ].map((step, index) => (
                <motion.div 
                  key={index}
                  className={styles.step}
                  initial={{ x: -50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: index * 0.2 }}
                >
                  <div className={styles.stepIcon}>{step.icon}</div>
                  <div className={styles.stepContent}>
                    <span>{step.label}</span>
                    <div className={styles.progressBar}>
                      <motion.div 
                        className={styles.progressFill}
                        initial={{ width: 0 }}
                        animate={{ width: `${step.progress}%` }}
                        transition={{ duration: 1.5, delay: index * 0.2 }}
                      />
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>

            <div className={styles.loadingIndicator}>
              <div className={styles.pulseRings}>
                {[...Array(3)].map((_, i) => (
                  <div key={i} className={styles.ring} />
                ))}
              </div>
              <motion.p
                animate={{ opacity: [1, 0.5, 1] }}
                transition={{ duration: 1.5, repeat: Infinity }}
              >
                Processing your document...
              </motion.p>
            </div>
          </div>
        </motion.div>
      )}
      <div className={styles.container}>
        
          <Header/>
        {/* Upload Section */} 
        <Dropzone handleFileUpload={handleFileUpload} uploading={uploading}/>
      

        {error && (
          <motion.div 
            className={styles.errorMessage}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {error}
          </motion.div>
        )}

        {/* Results Section */}
        <AnimatePresence>
          {resumeScore && (
            <motion.div 
              id="resume-analysis-section"
              className={styles.analysisContainer}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
            >
              {/* Decorative Elements */}
              <div className={`${styles.decorativeCircle} ${styles.circle1}`} />
              <div className={`${styles.decorativeCircle} ${styles.circle2}`} />

              {/* Score Header */}
              <motion.div 
                className={styles.scoreHeader}
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                <motion.h2
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.4 }}
                >
                  Resume Analysis Results
                </motion.h2>
                <motion.p 
                  className={styles.scoreSubtitle}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.5 }}
                >
                  Comprehensive evaluation based on ATS and recruiter preferences
                </motion.p>
              </motion.div>

              {/* Total Score Circle */}
              <motion.div 
                className={styles.totalScoreSection}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ 
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                  delay: 0.6 
                }}
              >
                <div className={styles.scoreCircle}>
                  <div className={styles.scoreInner}>
                    <motion.span 
                      className={styles.scoreNumber}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.8 }}
                    >
                      {calculateTotalScore(resumeScore.sections)}
                    </motion.span>
                    <motion.span 
                      className={styles.scoreLabel}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.9 }}
                    >
                      Overall Score
                    </motion.span>
                  </div>
                </div>
              </motion.div>

              {/* Categories Grid */}
              <motion.div 
                className={styles.categoriesGrid}
                initial="hidden"
                animate="visible"
                variants={{
                  visible: {
                    transition: {
                      staggerChildren: 0.1
                    }
                  }
                }}
              >
                {Object.entries(resumeScore?.sections)?.map(([category, score], index) => {
                  const getMaxScore = (category) => {
                    switch(category) {
                      case 'ImpactAndInfluence': return 30;
                      case 'ClarityAndFocus': return 20;
                      case 'AchievementMetrics': return 25;
                      case 'FormatAndStructure': return 15;
                      case 'CareerProgression': return 10;
                      default: return 100;
                    }
                  };

                  const maxScore = getMaxScore(category);
                  const scorePercentage = (score / maxScore) * 100;
                  const isPerfectScore = score === maxScore;

                  return (
                    <motion.div
                      key={category}
                      className={`${styles.categoryCard} ${isPerfectScore ? styles.perfectScore : ''}`}
                      variants={{
                        hidden: { opacity: 0, y: 20 },
                        visible: { opacity: 1, y: 0 }
                      }}
                      transition={{ duration: 0.6 }}
                      whileHover={{ scale: 1.02 }}
                    >
                      <div className={styles.categoryHeader}>
                        <div className={styles.categoryTitle}>
                          <div className={styles.categoryIcon}>
                            <FaChartLine />
                          </div>
                          <h4>{category.replace(/([A-Z])/g, ' $1').trim()}</h4>
                        </div>
                        <div className={styles.categoryScore}>
                          <div className={styles.scoreWrapper}>
                            <span className={styles.scoreValue}>
                              {score}
                              <span className={styles.scoreGlow} />
                            </span>
                            <span className={styles.scoreMax}>/{maxScore}</span>
                          </div>
                          <div className={styles.scoreProgress}>
                            <div 
                              className={styles.progressFill} 
                              style={{ width: `${scorePercentage}%` }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className={styles.categoryProgress}>
                        <motion.div 
                          className={styles.progressBar}
                          initial={{ width: 0 }}
                          animate={{ width: `${scorePercentage}%` }}
                          transition={{ duration: 1, delay: 1 }}
                        />
                      </div>

                      <div className={styles.detailsContainer}>
                        {isDetailedResultsLocked() ? (
                          <motion.div 
                            className={styles.lockedContent}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                          >
                            <div className={styles.lockMessage}>
                              <FaLock className={styles.lockIcon} />
                              <h5>Unlock Detailed Analysis</h5>
                              <p>Upgrade to Pro to access detailed strengths and improvements</p>
                              <motion.button 
                                className={styles.unlockButton}
                                onClick={() => setShowModal(true)}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                              >
                                Upgrade Now
                              </motion.button>
                            </div>
                          </motion.div>
                        ) : (
                          <>
                            <div className={styles.present}>
                              <h5>
                                <FaCheckCircle />
                                Strengths
                              </h5>
                              <ul className={styles.detailsList}>
                                {aiSuggestions[category]?.strengths?.map((strength, idx) => (
                                  <motion.li 
                                    key={`strength-${idx}`}
                                    initial={{ opacity: 0, x: -20 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ delay: 1.2 + (idx * 0.1) }}
                                  >
                                    <FaLightbulb className={styles.suggestionIcon} />
                                    {strength}
                                  </motion.li>
                                ))}
                              </ul>
                            </div>

                            {isPerfectScore ? (
                              <motion.div 
                                className={styles.perfectScoreMessage}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 1.4 }}
                              >
                                <FaCheckCircle className={styles.perfectIcon} />
                                <div className={styles.perfectText}>
                                  <h5>Excellent Work!</h5>
                                  <p>You've achieved a perfect score in this section. Keep up the great work!</p>
                                </div>
                              </motion.div>
                            ) : (
                              <div className={styles.missing}>
                                <h5>
                                  <FaTimes />
                                  Areas for Improvement
                                </h5>
                                <ul className={styles.detailsList}>
                                  {aiSuggestions[category]?.improvements?.map((improvement, idx) => (
                                    <motion.li 
                                      key={`improvement-${idx}`}
                                      initial={{ opacity: 0, x: -20 }}
                                      animate={{ opacity: 1, x: 0 }}
                                      transition={{ delay: 1.4 + (idx * 0.1) }}
                                    >
                                      <FaLightbulb className={styles.suggestionIcon} />
                                      {improvement}
                                    </motion.li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </motion.div>
                  );
                })}
              </motion.div>

              {/* Professional Stats Dashboard Section */}
              <motion.div 
                className={styles.statsContainer}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.2 }}
              >
                <div className={styles.statsGrid}>
                  {/* Strengths Card */}
                  <motion.div 
                    className={`${styles.statCard} ${styles.strengthCard}`}
                    whileHover={{ scale: 1.01 }}
                    transition={{ type: "spring", stiffness: 300 }}
                  >
                    <div className={styles.statHeader}>
                      <div className={styles.statIconWrapper}>
                        <FaCheckCircle className={styles.statIcon} />
                      </div>
                      <span className={styles.statNumber}>
                        {Object.keys(resumeScore.details).reduce((total, category) => 
                          total + resumeScore.details[category].has.length, 0
                        )}
                      </span>
                    </div>
                    <div className={styles.statInfo}>
                      <h4>Strengths Found</h4>
                      <div className={styles.progressBar}>
                        <motion.div 
                          className={styles.progressFill}
                          initial={{ width: 0 }}
                          animate={{ width: `${(resumeScore.details[Object.keys(resumeScore.details)[0]].has.length / 10) * 100}%` }}
                          transition={{ duration: 1 }}
                        />
                      </div>
                    </div>
                  </motion.div>

                  {/* Areas to Improve Card */}
                  <motion.div 
                    className={`${styles.statCard} ${styles.improvementCard}`}
                    whileHover={{ scale: 1.01 }}
                    transition={{ type: "spring", stiffness: 300 }}
                  >
                    <div className={styles.statHeader}>
                      <div className={styles.statIconWrapper}>
                        <FaLightbulb className={styles.statIcon} />
                      </div>
                      <span className={styles.statNumber}>
                        {Object.keys(resumeScore.details).reduce((total, category) => 
                          total + resumeScore.details[category].missing.length, 0
                        )}
                      </span>
                    </div>
                    <div className={styles.statInfo}>
                      <h4>Areas to Improve</h4>
                      <div className={styles.progressBar}>
                        <motion.div 
                          className={styles.progressFill}
                          initial={{ width: 0 }}
                          animate={{ width: `${(resumeScore.details[Object.keys(resumeScore.details)[0]].missing.length / 10) * 100}%` }}
                          transition={{ duration: 1 }}
                        />
                      </div>
                    </div>
                  </motion.div>

                  {/* Overall Score Card */}
                  <motion.div 
                    className={`${styles.statCard} ${styles.scoreCard}`}
                    whileHover={{ scale: 1.01 }}
                    transition={{ type: "spring", stiffness: 300 }}
                  >
                    <div className={styles.statHeader}>
                      <div className={styles.statIconWrapper}>
                        <FaChartLine className={styles.statIcon} />
                      </div>
                      <span className={styles.statNumber}>
                        {calculateTotalScore(resumeScore.sections)}%
                      </span>
                    </div>
                    <div className={styles.statInfo}>
                      <h4>Overall Score</h4>
                      <div className={styles.progressBar}>
                        <motion.div 
                          className={styles.progressFill}
                          initial={{ width: 0 }}
                          animate={{ width: `${calculateTotalScore(resumeScore.sections)}%` }}
                          transition={{ duration: 1 }}
                        />
                      </div>
                    </div>
                  </motion.div>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Only show upgrade section for non-paid users */}
        {!isPaidUser() && (
          <motion.div 
            className={styles.upgradeSection}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <div className={styles.upgradeBackground}>
              <div className={styles.glowOrb}></div>
              <div className={styles.glowOrb2}></div>
            </div>
            
            <motion.div
              className={styles.upgradeBadge}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              <FaRocket className={styles.rocketIcon} />
              <span>Premium Features</span>
            </motion.div>

            <motion.h2
              className={styles.upgradeTitle}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              Transform Your Career Journey
              <span className={styles.gradientText}> With Pro Access</span>
            </motion.h2>
            
            <motion.p
              className={styles.upgradeDescription}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.5 }}
            >
              Unlock powerful AI-driven insights and expert recommendations to create a standout resume
            </motion.p>

            <div className={styles.benefitsGrid}>
              {[
                { 
                  icon: <FaChartLine />, 
                  title: 'Smart Analysis',
                  text: 'AI-powered resume scanning with detailed scoring'
                },
                { 
                  icon: <FaRocket />, 
                  title: 'Tailored Insights',
                  text: 'Industry-specific recommendations for improvement'
                },
                { 
                  icon: <FaCheckCircle />, 
                  title: 'Expert Support',
                  text: '24/7 priority access to resume specialists'
                },
                { 
                  icon: <FaChartLine />, 
                  title: 'Market Alignment',
                  text: 'Real-time job market trend analysis'
                }
              ].map((benefit, index) => (
                <motion.div
                  key={index}
                  className={styles.benefitCard}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.6 + index * 0.1 }}
                  whileHover={{ 
                    scale: 1.02,
                    boxShadow: '0 8px 30px rgba(0, 0, 0, 0.12)'
                  }}
                >
                  <div className={styles.benefitIconWrapper}>
                    <div className={styles.benefitIcon}>{benefit.icon}</div>
                  </div>
                  <h3 className={styles.benefitTitle}>{benefit.title}</h3>
                  <p className={styles.benefitText}>{benefit.text}</p>
                </motion.div>
              ))}
            </div>

            <motion.div
              className={styles.pricingContainer}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 1 }}
            >
              <div className={styles.pricingHeader} onClick={() => setShowModal(true)}>
                <div className={styles.specialOffer}>
                  <span className={styles.originalPrice}>Rs 149</span>
                  <span className={styles.discount}>67% OFF</span>
                </div>
                <div className={styles.priceTag}>
                  <span className={styles.currency}>Rs</span> 
                  <span className={styles.amount}>49</span>
                  <span className={styles.period}>/month</span>
                </div>
                <div className={styles.pricingSubtext}>
                  <span className={styles.limitedTime}>🔥 Limited Time Offer</span>
                  <span className={styles.terms}>Cancel anytime • No commitments</span>
                </div>
              </div>

              <motion.button 
                className={styles.upgradeButton}
                onClick={() => setShowModal(true)}
                whileHover={{ 
                  scale: 1.02,
                  boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)"
                }}
                whileTap={{ scale: 0.98 }}
              >
                <span>Upgrade to Pro Now</span>
                <FaRocket className={styles.buttonIcon} />
                <div className={styles.buttonGlow} />
              </motion.button>

              <div className={styles.securePayment}>
                🔒 Secure Payment • Instant Access
              </div>
            </motion.div>
          </motion.div>
        )}
      </div>
      <PaymentModal 
        show={showModal}
        onHide={() => setShowModal(false)}
        resumeScore={resumeScore}
      />
      {resumeScore && (
        <ScoreModal 
          show={showScoreModal}
          onHide={() => setShowScoreModal(false)}
          resumeScore={resumeScore}
          onShowAnalysis={scrollToAnalysis}
        />
      )}
    </>
  );
};

// Update the total score calculation
const calculateTotalScore = (sections) => {
  const totalPossibleScore = 100; // 30 + 20 + 25 + 15 + 10
  const actualScore = Object.values(sections).reduce((sum, score) => sum + score, 0);
  return Math.round((actualScore / totalPossibleScore) * 100);
};

export default ResumeScore;