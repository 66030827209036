import React, { useState } from "react";
import OpenAI from "openai";
import { useEffect } from "react";
import { useResume } from "../../context/Resume";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlus, faClose, faPencilAlt, faRedo } from "@fortawesome/free-solid-svg-icons";
import HashLoader from "react-spinners/HashLoader";
import { useJobs } from "../../context/Jobs";
import Model from "../../../../../components/Model";
import styles from "./Config.module.css";
import { RotateCcw, Plus } from "lucide-react";
import { useCredits } from "../../context/CreditsContext";
import { motion, AnimatePresence } from "framer-motion";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

export const AIPoints = ({ sectionKey, element, partiCularName, section }) => {
  const [loadingAi, setLoadingAi] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalpaymentshow, setModalPaymentShow] = useState(false);
  const [modelshowskills, setModalShowskills] = useState(false);
  const { handleResumeChange } = useResume();
  const [jobRole, setJobRole] = useState("");
  const [skills, setSkills] = useState("");
  const [timePeriod, setTimePeriod] = useState("");
  const [company, setCompany] = useState("");
  const [current, setCurrent] = useState("");
  const [arrays, setArray] = useState([]);
  const [tickPoint, setTickPoint] = useState(false);
  const [pointArray, setPointArray] = useState([]);
  const [contentArray, setContentArray] = useState([]);
  const [jobDetails, setJobDetails] = useState();
  const { payment } = useJobs();
  const [task, setTask] = useState("");
  const [tools, setTools] = useState("");

  const [showResults, setShowResults] = useState(false);
  const { credits, updateCredits } = useCredits();

  useEffect(() => {
    setJobRole(element.role);
    setCompany(element.company);
    setTimePeriod(element.timePeriod);
    setSkills(element?.techstack);
  }, [
    element.company,
    element.role,
    element.company,
    element.timePeriod,
    element?.techstack,
  ]);

  const generatePoint = async () => {
    if (payment?.paymentStatus !== "success" && credits <= 0) {
      setModalPaymentShow(true);
      return;
    }

    try {
      setLoadingAi(true);
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content:
              'You\'re a resume maker. Respond ONLY with a valid JSON array of strings. Do not include markdown formatting, code blocks, or any other text. Example response format: ["point 1", "point 2"]',
          },
          {
            role: "user",
            content: `Generate professional resume bullet points based on:
              - Job Title: ${jobRole}
              - Tasks Performed: ${task}
              - Technical Skills Used: ${skills}
              - Tools/Software Used: ${tools}
  
              Requirements:
              - Generate 8 impactful bullet points
              - Include quantifiable achievements (%, numbers, metrics)
              - Focus on results and impact
              - Incorporate mentioned skills and tools naturally
              - Response must be a valid JSON array only`,
          },
        ],
        model: "gpt-3.5-turbo",
        temperature: 0.7,
        max_tokens: 1000,
      });

      let jsonString = completion.choices[0].message.content;

      // Clean the response if needed
      jsonString = jsonString
        .replace(/```json\n?/g, "") // Remove ```json
        .replace(/```\n?/g, "") // Remove closing ```
        .trim(); // Remove extra whitespace

      // Attempt to parse with error handling
      let suggestions;
      try {
        suggestions = JSON.parse(jsonString);
      } catch (parseError) {
        console.error("Failed to parse AI response:", parseError);
        console.log("Raw response:", jsonString);
        throw new Error("Invalid response format from AI");
      }

      // Validate that we got an array
      if (!Array.isArray(suggestions)) {
        throw new Error("AI response is not an array");
      }

      if (payment?.paymentStatus !== "success") {
        updateCredits(credits - 1);
      }

      setCurrent(jobRole);
      handleResumeChange(sectionKey, [...(section || []), ""]);
      setArray(suggestions);
      setShowResults(true);
      setLoadingAi(false);
    } catch (error) {
      console.error("Error generating points:", error);
      setLoadingAi(false);
      // Optionally show error to user
      // setError("Failed to generate points. Please try again.");
    }
  };

  const generateSkills = async () => {
    try {
      setLoadingAi(true);
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content:
              "You're a resume maker, helping users build strong resumes . only give me point in array only and make sure  only skills filter out from given job details ",
          },
          { role: "user", content: `job Details= ${jobDetails}` },
          {
            role: "user",
            content: `give 8  skills in array in one or two words `,
          },
        ],
        model: "gpt-3.5-turbo",
        temperature: 1,
        max_tokens: 1000,
      });

      const skillsString = completion.choices[0].message.content;
      console.log("skills", skillsString);

      // Attempt to parse the skills string to an array
      let suggestions;
      try {
        suggestions = JSON.parse(skillsString);
      } catch (error) {
        console.error("Failed to parse JSON:", error);
        suggestions = skillsString.split(",").map((skill) => skill.trim());
      }

      // Ensure suggestions is an array
      if (!Array.isArray(suggestions)) {
        throw new Error("Parsed suggestions are not an array");
      }
      handleResumeChange(sectionKey, [...(section || []), ...suggestions]);
      setLoadingAi(false);
      setModalShowskills(false);
    } catch (error) {
      console.log(error);
    }
  };

  const pointHandler = (index, point) => {
    setPointArray(prevArray => {
      // Check if point already exists to prevent duplicates
      const exists = prevArray.some(item => item.index === index);
      if (exists) return prevArray;
      
      // Add new point
      const newArray = [...prevArray, { index, content: point }];
      
      // Update content array immediately
      const extractedContent = newArray.map(item => item.content);
      setContentArray(extractedContent);
      
      // Update resume with new content
      extractedContent.forEach((value, idx) => {
        handleResumeChange(`${sectionKey}.${idx}`, value);
      });
      
      return newArray;
    });
  };

  const removePointHandler = (index) => {
    setPointArray(prevArray => {
      // Remove point with matching index
      const newArray = prevArray.filter(item => item.index !== index);
      
      // Update content array immediately
      const extractedContent = newArray.map(item => item.content);
      setContentArray(extractedContent);
      
      // Update resume with new content
      extractedContent.forEach((value, idx) => {
        handleResumeChange(`${sectionKey}.${idx}`, value);
      });
      
      // Clear any empty slots in the resume
      const remainingIndices = extractedContent.length;
      if (remainingIndices < prevArray.length) {
        handleResumeChange(`${sectionKey}.${remainingIndices}`, '');
      }
      
      return newArray;
    });
  };

  // now getting all the point set it to the vlues and local storage

  const AIButtonRenderContent = () => {
    if (loadingAi) {
      return (
        <div className={styles.aibuttt}>
          <div className={styles.spinner}></div>
          Generating Content...
        </div>
      );
    } else if (!loadingAi && arrays.length > 0) {
      return (
        <div className={styles.aibuttt} onClick={() => setModalShow(true)}>
          <RotateCcw size={14} />
          Regenerate {payment?.paymentStatus !== "success" && `(${credits} credits left)`}
        </div>
      );
    } else {
      return (
        <div 
          className={styles.aibuttt} 
          onClick={payment?.paymentStatus !== "success" && credits <= 0 ? 
            () => setModalPaymentShow(true) : 
            () => setModalShow(true)}
        >
          <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1730053517/all/axclicveze1ms96nrkww.svg" />{" "}
          Generate with AI {payment?.paymentStatus !== "success" && `(${credits} credits left)`}
        </div>
      );
    }
  };

  return (
    <div className="d-flex justify-content-end">
      <AIButtonRenderContent />

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="custom-modal"
      >
        <Modal.Body className={styles.modelBody}>
          <div className="container" style={{ position: "relative" }}>
            <div className={styles.close}>
              <span
                className={`${styles.closeButton} close`}
                onClick={() => setModalShow(false)}
              >
                <FontAwesomeIcon icon={faClose} className={styles.iconcut} />
              </span>
            </div>

            <div className="row">
              <div className={`col-12 col-md-12 ${styles.second}`}>
                {!showResults ? (
                  <div className={styles.containerModal}>
                    <div className={styles.topHeadingContainer}>
                      <div className={styles.headtitle}>
                        {" "}
                        Generate Key Points with AI
                      </div>
                      <div className={styles.headtitlparagraph}>
                        Generate points for your Resume
                      </div>
                    </div>
                    <div className={styles.ModalinputContainerMain}>
                      <div className={styles.mb}>
                        <label htmlFor="field2" className={styles.label}>
                          Job Title:
                        </label>
                        <input
                          type="text"
                          name="field2"
                          id="field2"
                          placeholder="Job Roles"
                          className={styles.jobRole}
                          value={jobRole}
                          onChange={(e) => setJobRole(e.target.value)}
                        />
                      </div>

                      <div className={styles.mb}>
                        <label htmlFor="field2" className={styles.label}>
                          Task:
                        </label>

                        {/* Task Container with Suggestions */}
                        <div className={styles.taskContainer}>
                          <input
                            type="text"
                            name="field2"
                            id="field2"
                            placeholder="Example: Presentations, Demos, Cold Calling etc "
                            className={styles.task}
                            value={task}
                            onChange={(e) => setTask(e.target.value)}
                          />
                          <div className={styles.suggestions}>
                            {[
                              "Campaign",
                              "Infuencer",
                              "Inspired",
                              "Marketing",
                              "Communication",
                              "Order",
                              "Analysis",
                              "Content",
                              "Design",
                              "Development",
                              "Management",
                            ].map((suggestion, index) => {
                              const currentTasks = task
                                ? task
                                    .split(",")
                                    .map((t) => t.trim())
                                    .filter(Boolean)
                                : [];
                              const isSelected =
                                currentTasks.includes(suggestion);

                              return (
                                <div
                                  key={index}
                                  className={styles.suggestionItem}
                                  onClick={() => {
                                    if (isSelected) {
                                      // Remove the task
                                      const newTasks = currentTasks
                                        .filter((t) => t !== suggestion)
                                        .join(", ");
                                      setTask(newTasks);
                                    } else {
                                      // Add the task
                                      const newTask =
                                        currentTasks.length > 0
                                          ? `${task}, ${suggestion}`
                                          : suggestion;
                                      setTask(newTask);
                                    }
                                  }}
                                >
                                  {suggestion}
                                  {isSelected ? (
                                    <span className={styles.crossIcon}>×</span>
                                  ) : (
                                    <Plus
                                      className={styles.plusIcon}
                                      size={16}
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div className={styles.mb}>
                        <label htmlFor="field3" className={styles.label}>
                          Skills:
                        </label>

                        {/* Skills Container with Suggestions */}
                        <div className={styles.taskContainer}>
                          <input
                            type="text"
                            name="field3"
                            id="field3"
                            placeholder="Example: React, JavaScript, Node.js etc "
                            className={styles.task}
                            value={skills}
                            onChange={(e) => setSkills(e.target.value)}
                          />
                          <div className={styles.suggestions}>
                            {[
                              "JavaScript",
                              "React",
                              "Node.js",
                              "Python",
                              "Java",
                              "HTML",
                              "CSS",
                              "SQL",
                              "AWS",
                              "Git",
                              "Docker",
                            ].map((suggestion, index) => {
                              const currentSkills = skills
                                ? skills
                                    .split(",")
                                    .map((s) => s.trim())
                                    .filter(Boolean)
                                : [];
                              const isSelected =
                                currentSkills.includes(suggestion);

                              return (
                                <div
                                  key={index}
                                  className={styles.suggestionItem}
                                  onClick={() => {
                                    if (isSelected) {
                                      // Remove the skill
                                      const newSkills = currentSkills
                                        .filter((s) => s !== suggestion)
                                        .join(", ");
                                      setSkills(newSkills);
                                    } else {
                                      // Add the skill
                                      const newSkills =
                                        currentSkills.length > 0
                                          ? `${skills}, ${suggestion}`
                                          : suggestion;
                                      setSkills(newSkills);
                                    }
                                  }}
                                >
                                  {suggestion}
                                  {isSelected ? (
                                    <span className={styles.crossIcon}>×</span>
                                  ) : (
                                    <Plus
                                      className={styles.plusIcon}
                                      size={16}
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div className={styles.mb}>
                        <label htmlFor="field3" className={styles.label}>
                          Tools:
                        </label>

                        <div className={styles.taskContainer}>
                          <input
                            type="text"
                            name="field3"
                            id="field3"
                            placeholder="Example: Jira, Slack, Microsoft Office etc"
                            className={styles.task}
                            value={tools}
                            onChange={(e) => setTools(e.target.value)}
                          />
                          <div className={styles.suggestions}>
                            {[
                              "Jira",
                              "Microsoft Office",
                              "Confluence",
                              "Slack",
                              "Trello",
                              "Asana",
                              "Microsoft Teams",
                              "Zoom",
                              "Google Workspace",
                              "Notion",
                              "Monday.com",
                            ].map((suggestion, index) => {
                              const currentTools = tools
                                ? tools
                                    .split(",")
                                    .map((t) => t.trim())
                                    .filter(Boolean)
                                : [];
                              const isSelected =
                                currentTools.includes(suggestion);

                              return (
                                <div
                                  key={index}
                                  className={styles.suggestionItem}
                                  onClick={() => {
                                    if (isSelected) {
                                      // Remove the tool
                                      const newTools = currentTools
                                        .filter((t) => t !== suggestion)
                                        .join(", ");
                                      setTools(newTools);
                                    } else {
                                      // Add the tool
                                      const newTools =
                                        currentTools.length > 0
                                          ? `${tools}, ${suggestion}`
                                          : suggestion;
                                      setTools(newTools);
                                    }
                                  }}
                                >
                                  {suggestion}
                                  {isSelected ? (
                                    <span className={styles.crossIcon}>×</span>
                                  ) : (
                                    <Plus
                                      className={styles.plusIcon}
                                      size={16}
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.ModalButtonContainerMain}>
                      <div className={styles.ModalButtonContainer}>
                        <button 
                          className={styles.skipandgenerate}
                          disabled={loadingAi}
                        >
                          {loadingAi ? (
                            <div className={styles.buttonContent}>
                              <div className={styles.spinnerContainer}>
                                <div className={styles.spinnerRing}></div>
                              </div>
                              <span>Processing...</span>
                            </div>
                          ) : (
                            "Skip & Generate"
                          )}
                        </button>
                        <button
                          className={styles.generateModalButton}
                          onClick={generatePoint}
                          disabled={loadingAi}
                        >
                          {loadingAi ? (
                            <div className={styles.buttonContent}>
                              <div className={styles.spinnerContainer}>
                                <div className={styles.spinnerRing}></div>
                              </div>
                              <span>Generating...</span>
                            </div>
                          ) : (
                            "Generate Points"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.containerModal}>
                    <motion.div 
                      className={styles.contentWrapper}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.3 }}
                    >
                      <div className={styles.topHeadingContainer}>
                        <motion.div
                          initial={{ y: -20, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ delay: 0.2 }}
                          className={styles.headingWrapper}
                        >
                          <div className={styles.headtitle}>
                            <span className={styles.titleIcon}>✨</span>
                            <span className={styles.titleText}>AI Generated Points</span>
                            <motion.span 
                              className={styles.pointCount}
                              initial={{ scale: 0 }}
                              animate={{ scale: 1 }}
                              transition={{ delay: 0.4 }}
                            >
                              {arrays.length} suggestions
                            </motion.span>
                          </div>
                          <div className={styles.headtitlparagraph}>
                            Select the most relevant points for your professional experience
                          </div>
                        </motion.div>
                      </div>

                      <motion.div 
                        className={styles.resultsWrapper}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.3 }}
                      >
                        <div className={styles.resultsContainer}>
                          <AnimatePresence mode="popLayout">
                            {arrays.map((point, index) => {
                              const isSelected = pointArray.some(item => item.index === index);
                              return (
                                <motion.div
                                  key={index}
                                  layout
                                  initial={{ opacity: 0, scale: 0.9 }}
                                  animate={{ opacity: 1, scale: 1 }}
                                  exit={{ opacity: 0, scale: 0.9 }}
                                  transition={{ duration: 0.2 }}
                                  className={`${styles.skillCard} ${isSelected ? styles.skillCardSelected : ''}`}
                                  onClick={() => isSelected ? removePointHandler(index) : pointHandler(index, point)}
                                >
                                  <div className={styles.skillContent}>
                                    <span className={styles.pointText}>{point}</span>
                                    <motion.div 
                                      className={styles.skillToggle}
                                      initial={false}
                                      animate={{ 
                                        backgroundColor: isSelected ? '#2563eb' : '#ffffff',
                                        rotate: isSelected ? 360 : 0
                                      }}
                                    >
                                      {isSelected ? (
                                        <FontAwesomeIcon 
                                          icon={faCheck} 
                                          className={styles.skillToggleIcon} 
                                        />
                                      ) : (
                                        <Plus 
                                          className={styles.skillToggleIcon}
                                          size={14}
                                        />
                                      )}
                                    </motion.div>
                                  </div>
                                </motion.div>
                              );
                            })}
                          </AnimatePresence>
                        </div>
                      </motion.div>

                      <motion.div 
                        className={styles.ModalButtonContainerMain}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.4 }}
                      >
                        <div className={styles.ModalButtonContainer}>
                          <motion.button
                            className={styles.editButton}
                            onClick={() => setShowResults(false)}
                            whileHover={{ scale: 1.02, backgroundColor: '#f5f5f5' }}
                            whileTap={{ scale: 0.98 }}
                          >
                            <FontAwesomeIcon icon={faPencilAlt} className={styles.buttonIcon} />
                            <span>Edit Details</span>
                          </motion.button>
                          <motion.button
                            className={styles.regenerateButton}
                            onClick={() => generatePoint()}
                            whileHover={{ scale: 1.02, backgroundColor: '#2563eb' }}
                            whileTap={{ scale: 0.98 }}
                          >
                            <FontAwesomeIcon icon={faRedo} className={styles.buttonIcon} />
                            <span>Regenerate Points</span>
                          </motion.button>
                        </div>
                      </motion.div>
                    </motion.div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modelshowskills}
        onHide={() => setModalShowskills(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ lineHeight: "1.5rem" }}>
            Generate With AI <br />{" "}
            <span
              style={{
                fontSize: "1rem",
                color: "hsl(223.13deg 14.68% 57.25%)",
              }}
            >
              {" "}
              For {partiCularName}
            </span>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className={styles.mb}>
                  <label
                    htmlFor="jobDescription"
                    style={{
                      display: "block",
                      marginBottom: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    Job Description:
                  </label>
                  <textarea
                    name="jobDescription"
                    id="jobDescription"
                    placeholder="Enter the job roles and responsibilities"
                    style={{
                      width: "100%",
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      boxSizing: "border-box",
                      fontSize: "16px",
                      resize: "vertical",
                      minHeight: "100px",
                    }}
                    value={jobDetails}
                    onChange={(e) => setJobDetails(e.target.value)}
                  />
                </div>
                <Button
                  style={{
                    background:
                      "linear-gradient(270deg,#a066ff 16.03%,#666cff 88.19%)",
                    color: "white",
                    padding: "10px 20px",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                  onClick={() => generateSkills()}
                  disabled={loadingAi}
                >
                  {loadingAi ? (
                    <HashLoader color="white" size={25} />
                  ) : (
                    "Generate"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Model modalShow={modalpaymentshow} setModalShow={setModalPaymentShow} />
    </div>
  );
};

export const AISkillsPoints = ({
  sectionKey,
  element,
  partiCularName,
  section,
}) => {
  const [loadingAi, setLoadingAi] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalpaymentshow, setModalPaymentShow] = useState(false);
  const { handleResumeChange , values } = useResume();
  const { payment } = useJobs();
  const [arrays, setArray] = useState([]);
  const { credits, updateCredits } = useCredits();
  const jobRole = element?.role || "Professional"; // Get job role from element or default
  const [selectedSkills, setSelectedSkills] = useState(new Set());

 const project = values.sections.projects?.data;
 const experience = values.sections.experience?.data;

  const generatePoint = async () => {
    if (payment?.paymentStatus !== "success" && credits <= 0) {
      setModalPaymentShow(true);
      return;
    }

    try {
      setLoadingAi(true);

      // Format experience context - handle any experience entries dynamically
      const experienceContext = experience?.map(exp => {
        if (exp.company || exp.role) {
          return `Role: ${exp.role || 'Not specified'} at ${exp.company || 'Not specified'} (${exp.timePeriod || 'No date specified'})`;
        }
        return '';
      }).filter(Boolean).join('\n');

      // Format project context - handle any project entries dynamically
      const projectContext = project?.map(p => {
        if (p.title || p.organisation) {
          const description = p.description?.replace(/<[^>]*>/g, '').trim() || '';
          return `Project: ${p.title || 'Untitled'} at ${p.organisation || 'No organization'} (${p.year || 'No year specified'}). ${description}`;
        }
        return '';
      }).filter(Boolean).join('\n');

      console.log("experienceContext",experienceContext);
      console.log("projectContext",projectContext);

      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content: 
              'You are a professional resume writer. Analyze the provided background and extract relevant skills, ensuring a strong match with the experience and projects provided. Always include soft skills.'
          },
          {
            role: "user",
            content: `Based on this background:

              Professional Experience:
              ${experienceContext || 'No experience provided'}
              
              Project Experience:
              ${projectContext || 'No projects provided'}

              Requirements:
              1. Always include these two base categories:
                 - "soft_skills": Essential professional skills
                 - At least one technical/professional category based on background
              
              2. Additional categories should be selected based on evidence in the background:
                 - "technical_skills" (for programming/technical work)
                 - "tools_and_technologies" (for specific software/tools used)
                 - "industry_knowledge" (for domain-specific expertise)
                 - "methodologies" (for specific work approaches)
              
              3. Skills Selection Rules:
                 - Prioritize skills explicitly mentioned in experience/projects
                 - Include complementary skills commonly associated with the background
                 - 5-8 most relevant skills per category
                 - Soft skills should reflect the role requirements
              
              Format as JSON. Example:
              {
                "soft_skills": ["skill1", "skill2"],
                "category_name": ["skill1", "skill2"]
                // additional relevant categories
              }`
          }
        ],
        model: "gpt-3.5-turbo",
        temperature: 0.7,
        max_tokens: 1000,
      });

      let jsonString = completion.choices[0].message.content.trim();
      jsonString = jsonString.replace(/```json\n?/g, "").replace(/```\n?/g, "");
      let skillsData = JSON.parse(jsonString);

      // Nice category names for UI
      const categoryNames = {
        soft_skills: "Soft Skills",
        technical_skills: "Technical Skills",
        tools_and_technologies: "Tools & Technologies",
        industry_knowledge: "Industry Knowledge",
        methodologies: "Methodologies"
      };

      // Format skills for UI, ensuring Soft Skills appears first
      const formattedSkills = Object.entries(skillsData)
        .sort(([catA], [catB]) => catA === 'soft_skills' ? -1 : catB === 'soft_skills' ? 1 : 0)
        .map(([category, skills]) => ({
          category: categoryNames[category] || category.split('_').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1)
          ).join(' '),
          skills: skills.map(skill => ({
            name: skill,
            id: Math.random().toString(36).substr(2, 9)
          }))
        }));

      if (payment?.paymentStatus !== "success") {
        updateCredits(credits - 1);
      }

      setArray(formattedSkills);
      setLoadingAi(false);

    } catch (error) {
      console.error("Error generating skills:", error);
      setLoadingAi(false);
    }
  };

  const toggleSkill = (skillId, skillName) => {
    setSelectedSkills(prev => {
      const newSelected = new Set(prev);
      if (newSelected.has(skillId)) {
        newSelected.delete(skillId);
        // Remove from resume immediately
        const currentSkills = section || [];
        handleResumeChange(sectionKey, currentSkills.filter(skill => skill !== skillName));
      } else {
        newSelected.add(skillId);
        // Add to resume immediately
        handleResumeChange(sectionKey, [...(section || []), skillName]);
      }
      return newSelected;
    });
  };

  return (
    <div className="d-flex justify-content-end">
      <div 
        className={styles.aibuttt} 
        onClick={payment?.paymentStatus !== "success" && credits <= 0 ? 
          () => setModalPaymentShow(true) : 
          () => setModalShow(true)}
      >
        <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1730053517/all/axclicveze1ms96nrkww.svg" alt="AI icon" />{" "}
        Generate with AI {payment?.paymentStatus !== "success" && `(${credits} credits left)`}
      </div>

      <Modal
        size="lg"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="custom-modal"
      >
        <Modal.Body className={styles.modelBody}>
          <div className={styles.modalContainer}>
            <div className={styles.modalHeader}>
              <h2 className={styles.modalTitle}>
                <span className={styles.titleIcon}>✨</span>
                AI Skills Generator
              </h2>
              <button 
                className={styles.closeButton} 
                onClick={() => setModalShow(false)}
              >
                <FontAwesomeIcon icon={faClose} />
              </button>
            </div>

            {arrays.length === 0 ? (
              <div className={styles.generateContainer}>
                <div className={styles.generateContent}>
                  <h3>Generate Skills with AI</h3>
                  <p>Click generate to get AI-powered skill suggestions for your resume</p>
                  <button
                    className={styles.generateButton}
                    onClick={generatePoint}
                    disabled={loadingAi}
                  >
                    {loadingAi ? (
                      <div className={styles.buttonContent}>
                        <div className={styles.spinner}></div>
                        <span>Generating...</span>
                      </div>
                    ) : (
                      <>
                        <img 
                          src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1730053517/all/axclicveze1ms96nrkww.svg" 
                          alt="AI icon" 
                        />
                        Generate Skills
                      </>
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <div className={styles.skillsContainer}>
                {arrays.map((category, index) => (
                  <motion.div 
                    key={index}
                    className={styles.categorySection}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <div className={styles.categoryHeader}>
                      <div className={styles.categoryTitleWrapper}>
                        <h3 className={styles.categoryTitle}>
                          {category.category}
                        </h3>
                        <span className={styles.skillCount}>
                          {category.skills.length} skills
                        </span>
                      </div>
                      <div className={styles.categoryDivider} />
                    </div>

                    <div className={styles.skillsGrid}>
                      {category.skills.map((skill) => {
                        const isSelected = selectedSkills.has(skill.id);
                        return (
                          <motion.div
                            key={skill.id}
                            className={`${styles.skillCard} ${isSelected ? styles.skillCardSelected : ''}`}
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            whileHover={{ 
                              scale: 1.02,
                              boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
                            }}
                            onClick={() => toggleSkill(skill.id, skill.name)}
                          >
                            <div className={styles.skillContent}>
                              <span className={styles.skillName}>{skill.name}</span>
                              <motion.div 
                                className={styles.skillToggle}
                                initial={false}
                                animate={{ 
                                  backgroundColor: isSelected ? '#2563eb' : '#ffffff',
                                  rotate: isSelected ? 360 : 0
                                }}
                              >
                                {isSelected ? (
                                  <FontAwesomeIcon 
                                    icon={faCheck} 
                                    className={styles.skillToggleIcon} 
                                  />
                                ) : (
                                  <Plus 
                                    className={styles.skillToggleIcon}
                                    size={14}
                                  />
                                )}
                              </motion.div>
                            </div>
                          </motion.div>
                        );
                      })}
                    </div>
                  </motion.div>
                ))}
                
                <div className={styles.regenerateContainer}>
                  <button
                    className={styles.regenerateBtn}
                    onClick={generatePoint}
                    disabled={loadingAi}
                  >
                    <FontAwesomeIcon icon={faRedo} />
                    Regenerate
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Model modalShow={modalpaymentshow} setModalShow={setModalPaymentShow} />
    </div>
  );
};

export const AiDescription = ({
  element,
  updateDescription,
  partiCularName,
  name,
}) => {
  const { setLoading  , values} = useResume();
  const { payment } = useJobs();
  const [loadingAi, setLoadingAi] = useState(false);
  const [modalpaymentshow, setModalPaymentShow] = useState(false);
  const [description, setDescription] = useState(element?.description);
  const { credits, updateCredits } = useCredits();

  const generateDescExperience = async () => {
    if (payment?.paymentStatus !== "success" && credits <= 0) {
      setModalPaymentShow(true);
      return;
    }

    try {
      setLoading(true);
      setLoadingAi(true);
      if (name === "Professionalsummary") {
        partiCularName = "Professional Summary";
      }

      console.log("values", values.sections.profile.data.position);

      let systemMessage = "";
      let prompt = "";

      // Configure prompts based on section type
      switch (partiCularName) {
        case "Experience":
          systemMessage =
            "You're a professional resume writer specializing in crafting impactful job descriptions.";
          prompt = `I have provided the company name, job roles, and time period. Company: ${element?.company}, Job Role: ${element?.role}, Time Period: ${element?.timePeriod}. Please generate a professional job description in 30-40 words highlighting key responsibilities, achievements, and impact.`;
          break;

        case "Projects":
          systemMessage =
            "You're a technical writer specializing in describing software and engineering projects.";
          prompt = `Please generate a 30-40 word project description for a project named "${
            element?.title
          }" that used ${
            element?.organisation || "various technologies"
          }. Focus on the project's purpose, technical implementation, and outcomes. Time frame: ${
            element?.year
          }.`;
          break;

         case "Certification":
          systemMessage =
            "You're a career advisor specializing in professional certifications and credentials.";
          prompt = `Please suggest 5 relevant and popular professional certifications for a ${
            values.sections.profile.data.position || "professional"
          } role. Format each as: "Certification Name - Issuing Organization". Focus on industry-recognized certifications that would enhance career prospects.`;
          break;

          case "Professional Summary":
          systemMessage =
            "You're an expert resume writer specializing in professional summaries and career highlights.";
          prompt = `Please generate a compelling 40-50 word professional summary for a ${
            values.sections.profile.data.position || "professional"
          } position. 
                   Include: 
                   - Years of experience (if provided: ${values.sections.profile.data.position})
                   - Key skills and expertise
                   - Career achievements and value proposition
                   - Professional goals and industry focus
                   Make it powerful, concise, and achievement-oriented.`;
           break;

        default:
          systemMessage = "You're a professional resume writer.";
          prompt = `Please generate appropriate content for the ${partiCularName} section.`;
      }

      const completion = await openai.chat.completions.create({
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: systemMessage,
          },
          {
            role: "user",
            content: prompt,
          },
        ],
        temperature: 0.7,
        max_tokens: partiCularName === "Certification" ? 200 : 100, // Allow more tokens for certification lists
      });

      const aiResponse = completion.choices[0].message.content;
      setDescription(aiResponse);

      if (payment?.paymentStatus !== "success") {
        updateCredits(credits - 1);
      }

    } catch (error) {
      console.error("Error generating description:", error);
    } finally {
      setLoading(false);
      setLoadingAi(false);
    }
  };

  useEffect(() => {
    // handleResumeChange(sectionKey,description)
    updateDescription(description);
  }, [description]);

  const AIButtonRenderContent = () => {
    if (loadingAi) {
      return (
        <div className={styles.aiButtonDiv}>
          <div className={styles.spinner}></div>
          Generating Content...
        </div>
      );
    } else if (!loadingAi && description) {
      return (
        <div className={styles.aiButtonDiv} onClick={generateDescExperience}>
          <RotateCcw size={14} />
          Regenerate {payment?.paymentStatus !== "success" && `(${credits} credits left)`}
        </div>
      );
    } else {
      return (
        <div className={styles.aiButtonDiv} onClick={generateDescExperience}>
          <img
            src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1730053517/all/axclicveze1ms96nrkww.svg"
            alt="AI icon"
          />{" "}
          Generate with AI {payment?.paymentStatus !== "success" && `(${credits} credits left)`}
        </div>
      );
    }
  };

  return (
    <div className={styles.mian}>
      <div className={styles.aibuttt}>
        <AIButtonRenderContent />
      </div>

      <Model 
        modalShow={modalpaymentshow} 
        setModalShow={setModalPaymentShow} 
        message={credits <= 0 ? "You've used all your free credits. Upgrade to continue using AI features!" : undefined}
      />
    </div>
  );
};
