import React from "react";
import MyResponsiveNavbar from "./Navbar";
import Footer from "./Footer";
import styles from "../assets/Sitemap.module.css"
import HelmetConfig from "../components/HelmetConfig";
const Sitemap = () => {
  return (
    <>
     <HelmetConfig
          title="MyFuse Sitemap | Navigate Myfuse.in Easily"
          description="Explore the sitemap of MyFuse to find pages and navigate through our website effortlessly. Learn how to use the sitemap for easy navigation and discover the structure of Myfuse.in."
          keywords="
          Myfuse Sitemap, Website Sitemap Myfuse.in, Myfuse Site Map, Sitemap for Myfuse, Myfuse Web Sitemap,
          Sitemap of Myfuse.in for Easy Navigation, How to Use Myfuse Sitemap, Myfuse Sitemap Structure, Website Navigation through Myfuse Sitemap, Find Your Way on Myfuse.in,
          Sitemap, Website Map, Site Structure, Navigation Map, Site Map,
          Web Navigation, Page Index, Website Layout, Website Directory, Site Links,
          Myfuse Site Directory, Navigating Myfuse with Sitemap, Myfuse Sitemap Page, Complete Sitemap Myfuse.in, Website Pathway Myfuse,
          How to Find Pages on Myfuse.in, Myfuse Sitemap Overview, Using Sitemap to Navigate Myfuse, Detailed Sitemap of Myfuse.in, Myfuse Sitemap for Better User Experience,
          Site Navigation Tool, Web Sitemap Guide, Myfuse Web Structure, Navigation Directory, Sitemap Usage
        "
          
          canonical="https://myfuse.in/sitemap"
        />
      <MyResponsiveNavbar />
      <div className="container " style={{minHeight:'55vh'}}>
        <div className="row m-3" >
        <h1 className="text-center mt-4 mb-4">Sitemap</h1>
          <div className={`col-md-3 col-6  ${styles.nodr}`}>
           
            <ul> 
                <h3>Information</h3>
              <li>
                <a href="https://myfuse.in">Home</a>
              </li>
              <li>
                <a href="https://myfuse.in/aboutus">About</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>

              {/* <li>
                <a href="/services">Services</a>
              </li>
              <li>
                <a href="/portfolio">Portfolio</a>
              </li> */}
              <li>
                <a href="https://myfuse.in/blog">Blog</a>
              </li>
              {/* <li>
                <a href="/testimonial">Testimonial</a>
              </li> */}
              <li>
                <a href="https://myfuse.in/pricing">Pricing</a>
              </li>
              {/* <li>
                <a href="/faq">FAQ</a>
              </li> */}
              {/* <li>
                <a href="/career">Career</a>
              </li> */}
              <li>
                <a href="https://myfuse.in/contactus">Contact</a>
              </li>
              
            </ul>
          </div>
          <div className={`col-md-3 col-6 ${styles.nodr}`}>
            
            <ul>
            <h3>Jobs By Location</h3>
              <li>
                <a href="https://myfuse.in/jobs?location=Bangalore">Jobs in Bangalore</a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?location=Pune">Jobs in Pune</a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?location=hyderabad">Jobs in hyderabad </a>
              </li>

              <li>
                <a href="https://myfuse.in/jobs?location=Mumbai">Jobs in Mumbai </a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?location=Patna"> Jobs in Patna</a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?location=Lucknow">Jobs in Lucknow </a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?location=Gurugram">Jobs in Gurugram </a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?location=Delhi">Jobs in Delhi</a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?location=Jaipur">Jobs in Jaipur</a>
              </li>
              
            </ul>
            <ul>
            <h3>Jobs By Industry</h3>
              <li>
                <a href="https://myfuse.in/jobs?industry=Technology+and+IT">Technology and IT</a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?industry=Education">Education</a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?industry=Transportation">Transportation </a>
              </li>

              <li>
                <a href="https://myfuse.in/jobs?industry=Travel">Travel And Tourism </a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?industry=Health"> Health Care</a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?industry=Retail">Retail and Consumer </a>
              </li>
               
              
            </ul>
          </div>
          <div className={`col-md-3 col-6 ${styles.nodr}`}>
           
            <ul>
            <h3>Jobs By Position</h3>
               
              <li>
                <a href="https://myfuse.in/jobs?position=Software">Software Developer</a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?position=Java">Java Developer</a>
              </li>

              <li>
                <a href="https://myfuse.in/jobs?position=Associate">Associate</a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?position=Consultant">Consultant</a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?position=Data+Analyst"> Data Analyst</a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?position=Travel+Agent">Travel Agent</a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?position=Fullstack"> Fullstack developer</a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?position=Django">Django developer</a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?position=App">App developer</a>
              </li>
              
            </ul>
            <ul>
            <h3>Internship</h3>
            <li>
                <a href="https://myfuse.in/jobs?jobMode=Internship&location=Bangalore">Internship in Bangalore</a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?jobMode=Internship&location=Pune">Internship in Pune</a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?jobMode=Internship&location=hyderabad">Internship in hyderabad </a>
              </li>

              <li>
                <a href="https://myfuse.in/jobs?jobMode=Internship&location=Mumbai">Internship in Mumbai </a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?jobMode=Internship&location=Patna"> Internship in Patna</a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?jobMode=Internship&location=Lucknow">Internship in Lucknow </a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?jobMode=Internship&location=Gurugram">Internship in Gurugram </a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?jobMode=Internship&location=Delhi">Internship in Delhi</a>
              </li>
              <li>
                <a href="https://myfuse.in/jobs?jobMode=Internship&location=Jaipur">Internship in Jaipur</a>
              </li>
              
            </ul>
          </div>
          <div className={`col-md-3 col-6 ${styles.nodr}`}>
            
            <ul>
            <h3>Resume</h3>
              <li>
                <a href="https://myfuse.in/airesume">Home</a>
              </li>
              <li>
                <a href="https://myfuse.in/resume-examples">Resume Examples</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Sitemap;
