import React from 'react'
import AboveNavbar from '../components/AboveNavbar'
import MyResponsiveNavbar from './Navbar'
import Top from '../components/HomePage/Top'
import Category from '../components/HomePage/Category'
import JobsCategroy from '../components/HomePage/JobsCategroy'
import Footer from './Footer'
import SuccesfullyPlaced from '../components/HomePage/SuccesfullyPlaced'
import OurOffring from '../components/HomePage/OurOffring'
import TopCompany from '../components/HomePage/TopCompany'
import CompanyScroll from '../components/HomePage/CompanyScroll'
import Resume from '../components/HomePage/Resume'
import Faq from '../components/HomePage/Faq'
import Partofmyfuse from '../components/HomePage/Partofmyfuse'
import BottomBar from '../components/BottomBar'
import HelmetConfig from '../components/HelmetConfig'
import NetworkinProblem from '../components/HomePage/NetworkinProblem'
import Blog from '../components/HomePage/Blog'
import Oper from '../components/HomePage/Oper'

const Home = () => {
  return (
    <div> 
         <HelmetConfig 
           title="MyFuse: AI-Powered Job Portal & Resume Builder | Find Your Dream Job Today"
           description="Welcome to MyFuse, your AI-powered job portal and resume builder. Discover job opportunities, create a standout resume, and take your career to the next level with our advanced tools."
           keywords="Job Search India, Professional Networking, Job Portal India, Job Opportunities in India, Career Networking, 
          Best Job Search Platforms in India 2024, Top Job Portals in India for Freshers, How to Find Jobs in India Online, 
          Best Sites to Find Jobs in India, Networking for Job Seekers in India, 
          Job Search, Job Network, Employment India, Job Listings, Careers India, 
          Career Opportunities, Employment India, Find Jobs Online, Job Networking Sites, Job Search Engines, 
          Online Job Search India, Indian Job Market, Job Vacancies India, Best Job Boards in India, Indian Job Search Engines, 
          How to Network for Jobs in India, Indian Employment Portals, Job Search Tips for Indians, How to Get Hired in India, 
          Best Job Sites for Experienced Professionals in India, 
          Job Portal for Freshers in India, Indian Professional Network, Career Growth in India, 
          Online Job Applications India, Job Search Tools India"
           canonical="https://myfuse.in/"
        />
        <AboveNavbar/>
        <MyResponsiveNavbar/> 
        <Top/> 
         <CompanyScroll/> 
         <Oper/>
        <Resume/>
        <OurOffring/>
        <JobsCategroy/>   
        <Category/> 
        <TopCompany/>  
         <NetworkinProblem/>
        <SuccesfullyPlaced/> 
        <Blog/>
        <Faq/> 
        <Partofmyfuse/>
        <Footer/>
        <BottomBar/>
    </div>
  )
}

export default Home