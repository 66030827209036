import React from "react";
import MyResponsiveNavbar from "./Navbar";
import styles from "../assets/NotFound.module.css"
import { useNavigate } from "react-router-dom";
const NotFound = () => { 
  const navigate = useNavigate(); 
  return (
    <div>
      <MyResponsiveNavbar />
      <section className={styles.page_404}>
        <div className={styles.container}>
          <div className="row">
            <div className="col-sm-12 d-flex justify-content-center">
              <div className="col-sm-12 col-sm-offset-1  text-center">
                <div className={styles.four_zero_four_bg}>
                  {/* <h1 className="text-center ">404</h1> */}
                </div>

                <div className={styles.contant_box_404}>
                  <h3 className="h2">Look like you're lost</h3>

                  <p>the page you are looking for not Available!</p>

                  <span onClick={()=>(navigate("/"))} className={styles.link_404}>
                    Go to Main Page 
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NotFound;
