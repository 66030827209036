import io from "socket.io-client";

let socket = null;
let connectionAttempts = 0;
const maxConnectionAttempts = 4;

const establishSocketConnection = () => {
  if (connectionAttempts < maxConnectionAttempts) {
    socket = io(`${process.env.REACT_APP_BASE_BACKEND_URL}`);
    connectionAttempts++;

    // socket.on("connect", () => {
    //   console.log("Socket connection established");
    // });

    socket.on("connect_error", (error) => {
      console.error("Socket connection error:", error);
      if (connectionAttempts < maxConnectionAttempts) {
        console.log(`Retrying connection (attempt ${connectionAttempts + 1} of ${maxConnectionAttempts})`);
        establishSocketConnection();
      } else {
        console.error("Exceeded maximum connection attempts");
        cleanupSocket();
      }
    });
  } else {
    console.error("Exceeded maximum connection attempts");
    cleanupSocket();
  }
};

const cleanupSocket = () => {
  if (socket) {
    socket.close();
    socket = null;
  }
};

establishSocketConnection();

export default socket;
