import React from "react";
import BuildResume from "../config/BuildResume";

const LLLResumeBuild = () => {
  const config = {
    name: "",
    language: "en",
    direction: "ltr",
    company: "",
    sections: [
      
      {
        name: "Personal Details",
        key: "sections.profile.data",
        description: "Please fill the fields below with your contact and personal details.",
        type: "static",
        displayKey: "sections.profile.data.name",
        fields: [
          {
            name: "name",
            key: "sections.profile.data.name",
            label: "Name",
            placeholder: "",
            type: "text",
          },
          {
            name: "email",
            key: "sections.profile.data.email",
            label: "Email",
            placeholder: "",
            type: "text",
          },
          {
            name: "phone",
            key: "sections.profile.data.phone",
            label: "Phone",
            placeholder: "",
            type: "text",
          },
          {
            name: "githubUsername",
            key: "sections.profile.data.githubUsername",
            label: "Github Username",
            placeholder: "",
            type: "text",
          },
          {
            name: "linkedinUsername",
            key: "sections.profile.data.linkedinUsername",
            label: "LinkedIn Username",
            placeholder: "",
            type: "text",
          },
          {
            name: "address",
            key: "sections.profile.data.address",
            label: "Location",
            placeholder: "",
            type: "text",
          },
          {
            name: "Position",
            key: "sections.profile.data.position",
            label: "Position",
            placeholder: "",
            type: "text",
          },
        ],
      },
      {
        name: "Education",
        key: "sections.education.data",
        description: "Your academic achievements",
        type: "dynamic",
        displayKey: "degree",
        fields: [
          {
            name: "degree",
            key: "degree",
            label: "Degree",
            placeholder: "e.g. B.Tech, Class 12th, etc.",
            type: "text",
          },
          {
            name: "branch",
            key: "branch",
            label: "Branch",
            placeholder: "e.g. Computer Science, Science (PCM), etc.",
            type: "text",
          },
          {
            name: "college",
            key: "college",
            label: "College",
            placeholder: "",
            type: "text",
          },
          {
            name: "year",
            key: "year",
            label: "Year",
            placeholder: "",
            type: "text",
          },
          {
            name: "cgpa",
            key: "cgpa",
            label: "GPI",
            placeholder: "",
            type: "text",
          },
          {
            name: "percentage",
            key: "percentage",
            label: "Percentage",
            placeholder: "",
            type: "text",
          },
        ],
      },
      {
        name: "Experience",
        key: "sections.experience.data",
        description: "Experience always counts! Add your experience here.",
        type: "dynamic",
        displayKey: "company",
        fields: [
          {
            name: "company",
            key: "company",
            label: "Company Name",
            placeholder: "",
            type: "text",
          },
          {
            name: "role",
            key: "role",
            label: "Role",
            placeholder: "",
            type: "text",
          },
          {
            name: "timePeriod",
            key: "timePeriod",
            label: "Time Period",
            placeholder: "e.g. (Jan 2020 - Feb 2021)",
            type: "text",
          },
          {
            name: "location",
            key: "location",
            label: "Location",
            placeholder: "e.g. Banglore",
            type: "text",
          },
          // {
          //   name: "description",
          //   key: "description",
          //   label: "Description",
          //   placeholder: "e.g. (Worked on ...)",
          //   type: "textarea",
          // },
          {
            name: "points",
            key: "points",
            label: "Key Points",
            placeholder: "",
            type: "list-string",
          },
        ],
      },
       
      {
        name: "Certification",
        key: "sections.awards.data",
        description: "Your awards",
        type: "dynamic",
        displayKey: "",
        fields: [
          {
            name: "Name",
            key: "name",
            label: "Name",
            placeholder: "Example: Fullstack Developer Bootcamp",
            type: "text",
          },
          {
            name: "Organisation",
            key: "organisation",
            label: "Issuing Organisation",
            placeholder: "Example: J-Spider Coding School",
            type: "text",
          },
          {
            name: "issuedDate",
            key: "issuedDate",
            label: "Issued Date",
            placeholder: "MM/YY",
            type: "text",
          },
          {
            name: "ExpirationDate",
            key: "expirationdate",
            label: "Expiration Date",
            placeholder: "MM/YY",
            type: "text",
          },
          {
            name: "description",
            key: "description",
            label: "Description",
            placeholder: "",
            type: "textarea",
          },
          
        ],
      },
      {
        name: "Projects",
        key: "sections.projects.data",
        description: "Good and relevant projects are always a plus!",
        type: "dynamic",
        displayKey: "title",
        fields: [
          {
            name: "title",
            key: "title",
            label: "Title",
            placeholder: "",
            type: "text",
          },
          {
            name: "organisation",
            key: "organisation",
            label: "Organisation",
            placeholder: "e.g. Institute technical Summer Project, etc.",
            type: "text",
          },
          {
            name: "year",
            key: "year",
            label: "Time Period",
            placeholder: "",
            type: "text",
          },
          {
            name: "description",
            key: "description",
            label: "Description",
            placeholder: "",
            type: "textarea",
          }, 
        ],
      },
      {
        name: "Skills",
        key: "sections.technicalSkills.data",
        description: "Your technical skills",
        type: "dynamic",
        displayKey: "name",
        fields: [
          {
            name: "name",
            key: "name",
            label: "Name",
            placeholder: "E.g Basic, intermediate, Advance etc",
            type: "text",
          },
          {
            name: "skills",
            key: "skills",
            label: "Skills",
            placeholder: "",
            type: "list-string",
          },
        ],
      },
      {
        name: "Professional Summary",
        key: "sections.Professionalsummary.data",
        description: "Please fill the fields below with your Summary details.",
        type: "static",
        displayKey: "sections.Professionalsummary.data.summary",
        fields: [
          {
            name: "Professionalsummary",
            key: "sections.Professionalsummary.data.summary",
            label: "Professional Summary",
            placeholder: "",
            type: "textarea",
          }
        ],
      },
      {
        name: "Optional",
        key: "sections.optional.data",
        description: "Tell a little more about yourself",
        type: "dynamic",
        displayKey: "name",
        fields: [
          {
            name: "Name",
            key: "name",
            label: "Name",
            placeholder: "Example: Achievements, Extra Curricular",
            type: "text",
          },
          {
            name: "description",
            key: "description",
            label: "Description",
            placeholder: "",
            type: "textarea",
          },
          // {
          //   name: "points",
          //   key: "points",
          //   label: "Feature Points",
          //   placeholder: "",
          //   type: "list-string",
          // },
        ],
      },
      
    ],
  };
  return <BuildResume config={config} />;
};

export default LLLResumeBuild;
