import React from 'react';
import {Outlet,   Navigate } from 'react-router-dom';  
import { useAuth } from '../components/ResumeBuilder/builder/components/context/auth';
import Spinner from './Spinner';

const PrivateRoute = ({ children, ...rest }) => {
  const { authstatus } = useAuth();   
  if (authstatus === null) {
    return <Spinner />;  
}
return authstatus ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
