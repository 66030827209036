import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../assets/Filter.module.css";
import { ChevronUp } from "lucide-react";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const Filter = ({ setJobs, jobs, pageNumber,joblocation }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loadingJobs, setLoadingJobs] = useState(false);

  const getFilterFromURL = (key) => {
    const params = new URLSearchParams(location.search);
    return params.get(key) ? params.get(key).split(",") : [];
  };

  const handleCheckboxChange = (e, category) => {
    const { name, checked } = e.target;
    const params = new URLSearchParams(window.location.search);
    let currentFilters = params.get(category)
      ? params.get(category).split(",")
      : [];
    if (checked) {
      currentFilters.push(name);
    } else {
      currentFilters = currentFilters.filter((filter) => filter !== name);
    }
    if (currentFilters.length > 0) {
      params.set(category, currentFilters.join(","));
    } else {
      params.delete(category);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl); 
  };

  const getFilterJobs = async () => {
    try {
      setJobs([]);
      setLoadingJobs(true);
      let filters = {
        jobType: getFilterFromURL("jobType"),
        location: getFilterFromURL("location"),
        industry: getFilterFromURL("industry"),
        position: getFilterFromURL("position"),
        jobMode: getFilterFromURL("jobMode"),
      }; 

      if (joblocation) {
        filters = {
          ...filters,
          location: Array.isArray(filters.location) 
            ? [...filters.location, joblocation]  
            : [filters.location, joblocation].filter(Boolean), 
        };
      }
      console.log(filters);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/filterJobPost?page=${pageNumber}`,
        filters
      );
      const data = response.data;
      if (data?.success) {
        setJobs(data.users);
      }
      setLoadingJobs(false);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setLoadingJobs(false);
    }
  };
  useEffect(() => { 
    getFilterJobs();
  }, [location.search, pageNumber,joblocation]);

  return (
    <div className={`col-md-3 mb-4 col-11 d-none d-lg-block`}>
      <div className={styles.mains}>
        <div>
          <span className={styles.dasdf}>
            <FontAwesomeIcon icon={faFilter} className={styles.ci} /> Filters
          </span>
        </div>

        <div className={styles.pro}></div>
        <div>
          <div className={styles.dlsr}>
            <span>Experience Level</span>{" "}
            <span>
              <ChevronUp />
            </span>
          </div>

          <div className={styles.fr}>
            <input
              type="checkbox"
              id="part time"
              name="part time"
              checked={getFilterFromURL("jobMode").includes("part time")}
              onChange={(e) => handleCheckboxChange(e, "jobMode")}
            />
            <label htmlFor="part time" className={styles.marginle}>
              Part Time
            </label>
          </div>
          <div className={styles.fr}>
            <input
              type="checkbox"
              id="Full Time"
              name="Full Time"
              checked={getFilterFromURL("jobMode").includes("Full Time")}
              onChange={(e) => handleCheckboxChange(e, "jobMode")}
            />
            <label htmlFor="Full Time" className={styles.marginle}>
              Full Time
            </label>
          </div>
          <div className={styles.fr}>
            <input
              type="checkbox"
              id="Internship"
              name="Internship"
              checked={getFilterFromURL("jobMode").includes("Internship")}
              onChange={(e) => handleCheckboxChange(e, "jobMode")}
            />
            <label htmlFor="Internship" className={styles.marginle}>
              Internship
            </label>
          </div>
        </div>

        <div className={styles.pro}></div>
        <div>
          <div className={styles.dlsr}>
            <span>Experience Level</span>{" "}
            <span>
              <ChevronUp />
            </span>
          </div>

          <div className={styles.fr}>
            <input
              type="checkbox"
              id="freshers"
              name="Freshers"
              checked={getFilterFromURL("jobType").includes("Freshers")}
              onChange={(e) => handleCheckboxChange(e, "jobType")}
            />
            <label htmlFor="freshers" className={styles.marginle}>
              Fresher
            </label>
          </div>
          <div className={styles.fr}>
            <input
              type="checkbox"
              id="Experience"
              name="Experience"
              checked={getFilterFromURL("jobType").includes("Experience")}
              onChange={(e) => handleCheckboxChange(e, "jobType")}
            />
            <label htmlFor="Experience" className={styles.marginle}>
              Experienced
            </label>
          </div>
        </div>

        <div className={styles.pro}></div>

        {/* Location Filter */}
        <div>
          <div className={styles.dlsr}>
            <span>Location</span>{" "}
            <span>
              <ChevronUp />
            </span>
          </div>
          {["Bangalore", "Pune", "Delhi", "Mumbai", "Lucknow", "Gurugram"].map(
            (location) => (
              <div className={styles.fr} key={location}>
                <input
                  type="checkbox"
                  id={location}
                  name={location}
                  checked={getFilterFromURL("location").includes(location)}
                  onChange={(e) => handleCheckboxChange(e, "location")}
                  className={styles.flr}
                />
                <label htmlFor={location} className={styles.marginle}>
                  {location}
                </label>
              </div>
            )
          )}
        </div>

        <div className={styles.pro}></div>

        <div>
          <div className={styles.dlsr}>
            <span>Industry</span>{" "}
            <span>
              <ChevronUp />
            </span>
          </div>
          {[
            "Technology and IT",
            "Education",
            "Transportation And Logistics",
            "Travel And Tourism",
            "Health Care",
            "Retail and Consumer",
          ].map((industry) => (
            <div className={styles.fr} key={industry}>
              <input
                type="checkbox"
                id={industry}
                name={industry}
                checked={getFilterFromURL("industry").includes(industry)}
                onChange={(e) => handleCheckboxChange(e, "industry")}
                className={styles.flr}
              />
              <label htmlFor={industry} className={styles.marginle}>
                {industry}
              </label>
            </div>
          ))}
        </div>

        <div className={styles.pro}></div>
      </div>
      <div>
        {loadingJobs ? (
          <p>Loading jobs...</p>
        ) : (
          jobs.map((job) => (
            <div key={job.id}>
              <p>{job.title}</p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Filter;
