import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles 
import styles from "../assets/HomePage/companyH.module.css"
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// Import Swiper styles
import '../assets/swipper.css';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useJobs } from '../ResumeBuilder/builder/components/context/Jobs';
import { ArrowUpRight } from 'lucide-react';
 

const TopCompainesHireingWithUs = () => {
  const navigate = useNavigate(); 
  const {setComminJobs , setIsfirst} = useJobs(); 
  const handleClick = async(value) =>{
    // navigate(`/jobs?param=${value}`);
    setComminJobs(value);
    setIsfirst(true)
    navigate(`/jobs`);

 }  

   let arrayObject = [
      { 
         heading:'React Developer ',
        title:'500+ Are Actively hiring',
         image1:'https://img.naukimg.com/logo_images/groups/v1/3607752.gif',
         image2:'https://assets.channelinsider.com/uploads/2022/03/CI.Profile.Deloitte-1024x1024.jpg',
         image3:'https://lh3.googleusercontent.com/tRfu1KvmJn1aHAbGs92QLk9A2e-M73U_c8mV6S8HD1RxTxxoY-cWI-fq_r3e5JO3Ru9kTdZJoZzcs61E9j0sIw6Ac6cLDe4f4qzEjkQ'
      },
      { 
        heading:'Software Developer ',
       title:'430+ Are Actively hiring',
        image1:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYwcZrKMPEnG6HEH8NIr6m_82SnHioSsFER-UZCcR0GJEAPrQbOf6Gg3gw2mq6vmw0hMI&usqp=CAU',
        image2:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSkBCbMWdmqxR5C0Y-vmRuKTBm9Z3TzyqTaA&s',
        image3:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9kcYBgdcBWGzxYTokz_FNkTu5_jfkBFxgBA&s'
     },
     { 
      heading:'Travel',
     title:'340+ Are Actively hiring',
      image1:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8hbFucXzJMWTjwwrutT9AQrkr3T9itpzMFg&s',
      image2:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJp-Tr81CGvKx7JnuXEKouBhvHwu4azNilwSnG0kdHf7X7_AE9t6TlRFvxYRjIRyQaz18&usqp=CAU',
      image3:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTamRqd8Px7oP-bMHppUX4vHw8qCCP3l3uQRstsTfu0RBnditpODaOQgc54PdXmObxB2yE&usqp=CAU'
   },
   { 
    heading:'Java Developer ',
   title:'554+ Are Actively hiring',
    image1:'https://img.naukimg.com/logo_images/groups/v1/3607752.gif',
    image2:'https://assets.channelinsider.com/uploads/2022/03/CI.Profile.Deloitte-1024x1024.jpg',
    image3:'https://lh3.googleusercontent.com/tRfu1KvmJn1aHAbGs92QLk9A2e-M73U_c8mV6S8HD1RxTxxoY-cWI-fq_r3e5JO3Ru9kTdZJoZzcs61E9j0sIw6Ac6cLDe4f4qzEjkQ'
 },
 { 
  heading:'Associate',
 title:'230+ Are Actively hiring',
  image1:'https://img.naukimg.com/logo_images/groups/v1/3607752.gif',
  image2:'https://assets.channelinsider.com/uploads/2022/03/CI.Profile.Deloitte-1024x1024.jpg',
  image3:'https://lh3.googleusercontent.com/tRfu1KvmJn1aHAbGs92QLk9A2e-M73U_c8mV6S8HD1RxTxxoY-cWI-fq_r3e5JO3Ru9kTdZJoZzcs61E9j0sIw6Ac6cLDe4f4qzEjkQ'
},
{ 
  heading:'Consultant',
 title:'250+ Are Actively hiring',
  image1:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfkVpNb7jByDDSpkHYUZvO0d6yiKR3fF5Meg&s',
  image2:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzVJyOguefikV6ENOHgGoSh7TOmh90Wagsyw&s',
  image3:'https://lh3.googleusercontent.com/tRfu1KvmJn1aHAbGs92QLk9A2e-M73U_c8mV6S8HD1RxTxxoY-cWI-fq_r3e5JO3Ru9kTdZJoZzcs61E9j0sIw6Ac6cLDe4f4qzEjkQ'
},
{ 
  heading:'Manager ',
 title:'500+ Are Actively hiring',
  image1:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJPg-qsZ2Z7sfw_y9nvNOoh_iCqJXfjPjXcjMB_IhBaqnuTURNcqxew0HZZ6dVBWXe21Q&usqp=CAU',
  image2:'https://assets.channelinsider.com/uploads/2022/03/CI.Profile.Deloitte-1024x1024.jpg',
  image3:'https://lh3.googleusercontent.com/tRfu1KvmJn1aHAbGs92QLk9A2e-M73U_c8mV6S8HD1RxTxxoY-cWI-fq_r3e5JO3Ru9kTdZJoZzcs61E9j0sIw6Ac6cLDe4f4qzEjkQ'
},
{ 
  heading:'Analyst',
 title:'530+ Are Actively hiring',
  image1:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfkVpNb7jByDDSpkHYUZvO0d6yiKR3fF5Meg&s',
  image2:'https://assets.channelinsider.com/uploads/2022/03/CI.Profile.Deloitte-1024x1024.jpg',
  image3:'https://lh3.googleusercontent.com/tRfu1KvmJn1aHAbGs92QLk9A2e-M73U_c8mV6S8HD1RxTxxoY-cWI-fq_r3e5JO3Ru9kTdZJoZzcs61E9j0sIw6Ac6cLDe4f4qzEjkQ'
},
      
   ]

  return (
    <>
                    
          <div className={styles.dlo}> 
         
          <div className={`custom-swiper-button-prev ${styles.dlod}`}> <FontAwesomeIcon icon={faChevronLeft}   className={styles.dlll}/></div>
          <div className={`custom-swiper-button-next ${styles.dlod} `}> <FontAwesomeIcon icon={faChevronRight}  className={styles.dlll} /></div>
         
         </div>
      <Swiper
        spaceBetween={10}
        
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
         
        
        modules={[ Autoplay, Pagination, Navigation]}
       
        className="mySwiper"
        slidesPerView={1.3}  
        breakpoints={{
          640: {
            slidesPerView: 2, 
          },
          768: {
            slidesPerView: 3,  
          },
          1024: {
            slidesPerView: 4,  
          },
          1224: {
            slidesPerView: 5,
          },
        }}
        navigation={{
            nextEl: '.custom-swiper-button-next',
            prevEl: '.custom-swiper-button-prev',
          }}
          pagination={{
            clickable: true,
            el: '.swiper-pagination',
          }}
      >  

        {Object.keys(arrayObject)?.map((ele,index)=>{
           return (
              <SwiperSlide onClick={()=> handleClick(arrayObject[ele].heading)} key={index} className={styles.ggo}>
                       <span className={styles.dlsls}> 
                         <span>
                         <h1 style={{fontSize:'15px'}}>{arrayObject[ele].heading} </h1> 
                         </span> 
                         <span className={styles.llos}>  <ArrowUpRight size={18}/></span>
                        </span> 
                        <p style={{fontSize:'13px'}}>{arrayObject[ele].title}</p> 
                        <div>
                        <div className={`chip-image ${styles.dldodd} `}>
                        <img src={arrayObject[ele].image1} alt='dd'  />
                        </div> 
                        <div className={`chip-image ${styles.dldodd} `}>
                        <img src={arrayObject[ele].image2} alt='erex'  />
                        </div> 
                        <div className={`chip-image ${styles.dldodd} `}>
                        <img  src={arrayObject[ele].image3}  alt='asd' />
                        </div> 
                        
                        </div>
                        
              </SwiperSlide>
           )
        })}
         
        
        
      </Swiper>
      <div className={`swiper-pagination ${styles.sslo}`}></div>
      
    </>
  )
}

export default TopCompainesHireingWithUs