import React from "react";
import image1 from "../../ResumeBuilder/assets/images/IITA.png";
import image2 from "../../ResumeBuilder/assets/images/ashu.png";
import image3 from "../../ResumeBuilder/assets/images/temp2.png";
import image4 from "../../ResumeBuilder/assets/images/IITL.png";
import image5 from "../../ResumeBuilder/assets/images/IITC.png";
import image6 from "../../ResumeBuilder/assets/images/IIT.png";
import image7 from "../../ResumeBuilder/assets/images/IITN.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { CheckCircle2, ArrowUpRight } from "lucide-react";
import styles from "../../assets/Job.module.css";
import "swiper/css";
import "swiper/css/effect-cards";

// import required modules
import { EffectCards, Autoplay } from "swiper/modules";
import { useNavigate } from "react-router-dom";
const Resume = () => {
  const navigate = useNavigate();

  return (
    <div>
      {" "}
      <div
        className="card  "
        style={{
          boxShadow: "0 6px 12px rgba(30,10,58,.04)",
          border: "0px solid white",
          fontSize: "14px",
          backgroundColor: "#fff3dd",
          padding: "1rem",
        }}
      >
        <div
          className="container"
          style={{ marginTop: "4rem", marginBottom: "2rem" }}
        >
          <div
            className="row"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="col-8 col-md-3 d-flex justify-end
        "
            >
              <Swiper
                effect={"cards"}
                grabCursor={true}
                modules={[EffectCards, Autoplay]}
                className="mySwiper"
                // loop={true} // Enables looping
                autoplay={{
                  delay: 3000, // Delay between transitions (in ms)
                  disableOnInteraction: false, // Continue autoplay after user interaction
                }}
              >
                <SwiperSlide
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={image2}
                    alt="Slide"
                    className="img-fluid"
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </SwiperSlide>
                <SwiperSlide
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={image1}
                    alt="Slide"
                    className="img-fluid"
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </SwiperSlide>
                <SwiperSlide
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={image3}
                    alt="Slide"
                    className="img-fluid"
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </SwiperSlide>
                <SwiperSlide
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={image4}
                    alt="Slide"
                    className="img-fluid"
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </SwiperSlide>
                <SwiperSlide
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={image5}
                    alt="Slide"
                    className="img-fluid"
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </SwiperSlide>
                <SwiperSlide
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={image6}
                    alt="Slide"
                    className="img-fluid"
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </SwiperSlide>
                <SwiperSlide
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={image7}
                    alt="Slide"
                    className="img-fluid"
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </SwiperSlide>
              </Swiper>
            </div>
            <div
              className="col-12 col-md-6"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h1 className={styles.aiheading}>
                Use Our{" "}
                <span style={{ color: "rgb(107, 87, 255)" }}> AI Resume </span>{" "}
                Builder for Free!
              </h1>

              <div
                className={`d-flex justify-content-center align-center  `}
                style={{ gap: "1rem" }}
              >
                <span className={styles.aipara}>
                  {" "}
                  <CheckCircle2 size={17} color={"green"} /> Free Job
                  Recomandation
                </span>{" "}
                <span className={styles.aipara}>
                  {" "}
                  <CheckCircle2 size={17} color={"green"} /> 100% AI Assistance
                </span>
              </div>
              <div
                className={`d-flex justify-content-center  `}
                style={{ gap: "1rem", margin: "1rem" }}
              >
                <span className={styles.aipara}>
                  {" "}
                  <CheckCircle2 size={17} color={"green"} /> Specific Job Resume
                </span>{" "}
                <span className={styles.aipara}>
                  {" "}
                  <CheckCircle2 size={17} color={"green"} /> ATS Friendly Resume{" "}
                </span>
              </div>

              <div
                className="d-flex justify-content-center"
                onClick={() => navigate("/airesume")}
              >
                <span
                  className={styles.jobBottom}
                  style={{ marginTop: "1.5rem", fontSize: "13px" }}
                >
                  Use Resume Builder <ArrowUpRight width={15} height={15} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
