import React, { useState } from "react";
import MyResponsiveNavbar from "../Navbar";
import styles from "../../assets/DataSharing/DataSharing.module.css";
import { motion } from "framer-motion";
import {
  FaFilePdf,
  FaDownload,
  FaPlayCircle,
  FaBriefcase,
  FaRobot,
  FaAward,
  FaChartLine,
  FaUsers,
  FaMapMarkerAlt,
  FaBuilding,
  FaClock,
  FaDollarSign,
  FaRegClock,
  FaArrowRight,
  FaChevronRight,
} from "react-icons/fa";

// Custom Modal Component
const CustomModal = ({ isOpen, setShowModal }) => {
  if (!isOpen) return null;

  return (
    <div className={styles["custom-modal-overlay"]} role="dialog" aria-modal="true" aria-labelledby="modal-title">
      <div className={styles["custom-modal"]}>
        <button 
          className={styles["close-button"]} 
          onClick={() => setShowModal(false)} 
          aria-label="Close modal"
        >
          &times;
        </button>
        
        <div className={styles["modal-content-wrapper"]}>
          {/* Left side content */}
          <div className={styles["modal-content"]}>
            <h2 id="modal-title" className={styles["modal-title"]}>
              Discover Our AI Resume Builder
            </h2>
            <p className={styles["modal-description"]}>
              Our AI Resume Builder uses advanced technology to help you create a professional and ATS-friendly resume in minutes. 
              With smart formatting suggestions and industry-specific keywords, you can ensure your resume stands out to employers.
            </p>
            <div className={styles["features-section"]}>
              <p className={styles["modal-key-features"]}>Key Features:</p>
              <ul className={styles["modal-features-list"]}>
                <li>Smart formatting & layout suggestions</li>
                <li>Industry-specific keywords</li>
                <li>Real-time ATS compatibility check</li>
              </ul>
            </div>
            <button className={styles["modal-close-button"]} onClick={() => setShowModal(false)}>
              Get Started Now
            </button>
          </div>

          {/* Right side video */}
          <div className={styles["modal-video"]}>
            <video 
              autoPlay 
              loop 
              
              playsInline
              className={styles["features-video"]}
            >
              <source 
                src="https://res.cloudinary.com/dvepwlz9j/video/upload/v1733159868/video/i87lzqx37oqdfiwhwzui.mp4" 
                type="video/mp4" 
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

const DataSharing = () => {
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [recentVideos] = useState([
    {
      id: 1,
      title: "How to Ace Your Job Interview",
      thumbnail: "https://example.com/thumbnail1.jpg",
      duration: "5:30",
      views: "12K"
    },
    {
      id: 2,
      title: "Resume Writing Tips 2024",
      thumbnail: "https://example.com/thumbnail2.jpg",
      duration: "8:45",
      views: "8.5K"
    },
    {
      id: 3,
      title: "Master Your LinkedIn Profile",
      thumbnail: "https://example.com/thumbnail3.jpg",
      duration: "6:15",
      views: "15K"
    }
    // Add more videos as needed
  ]);

  const handleVideoPlay = () => {
    const video = document.querySelector("video");
    if (video) {
      if (video.paused) {
        video.play();
        setIsPlaying(true);
      } else {
        video.pause();
        setIsPlaying(false);
      }
    }
  };

  const recentJobs = [
    {
      title: "Senior Software Engineer",
      company: "TechCorp",
      logoUrl: "https://logo.clearbit.com/techcorp.com",
      type: "Full-time",
      location: "Remote",
      experience: "5+ years",
      salaryRange: "$120k - $160k",
      tags: ["React", "Node.js", "AWS"],
      postedDate: "Posted 2 days ago",
      isFeatured: true,
    },
    {
      title: "Senior Software Engineer",
      company: "TechCorp",
      logoUrl: "https://logo.clearbit.com/techcorp.com",
      type: "Full-time",
      location: "Remote",
      experience: "5+ years",
      salaryRange: "$120k - $160k",
      tags: ["React", "Node.js", "AWS"],
      postedDate: "Posted 2 days ago",
      isFeatured: true,
    },
    {
      title: "Senior Software Engineer",
      company: "TechCorp",
      logoUrl: "https://logo.clearbit.com/techcorp.com",
      type: "Full-time",
      location: "Remote",
      experience: "5+ years",
      salaryRange: "$120k - $160k",
      tags: ["React", "Node.js", "AWS"],
      postedDate: "Posted 2 days ago",
      isFeatured: true,
    },

    // Add more job objects as needed
  ];

  const JobCard = ({ job }) => (
    <motion.div
      className={styles["job-card"]}
      whileHover={{ scale: 1.02 }}
      transition={{ duration: 0.2 }}
    >
      <div className={styles["job-card-header"]}>
        <div className={styles["company-info"]}>
          <div className={styles["company-logo-wrapper"]}>
            <img
              src={job.logoUrl}
              alt={`${job.company} logo`}
              className={styles["company-logo"]}
              onError={(e) => (e.target.src = "/default-company-logo.png")}
              loading="lazy"
            />
          </div>
          <div className={styles["company-details"]}>
            <h3>{job.title}</h3>
            <span className={styles["company-name"]}>
              <FaBuilding className={styles["company-icon"]} />
              {job.company}
            </span>
          </div>
        </div>
      </div>

      <div className={styles["job-info"]}>
        <div className={styles["job-meta"]}>
          <span className={styles["job-type"]}>
            <FaClock /> {job.type}
          </span>
          <span className={styles["job-location"]}>
            <FaMapMarkerAlt /> {job.location}
          </span>
          {/* <span className={styles['experience']}>
            <FaBriefcase /> {job.experience}
          </span> */}
        </div>

        {/* <div className={styles['job-tags']}>
          {job.tags.map((tag, index) => (
            <motion.span 
              key={index} 
              className={styles['tag']}
              whileHover={{ scale: 1.1 }}
            >
              {tag}
            </motion.span>
          ))}
        </div> */}

        <div className={styles["job-footer"]}>
          <span className={styles["posted-date"]}>
            <FaRegClock /> {job.postedDate}
          </span>
          <motion.button
            className={styles["apply-btn"]}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Apply Now
            <FaArrowRight className={styles["arrow-icon"]} />
          </motion.button>
        </div>
      </div>
    </motion.div>
  );

  return (
    <div>
      <MyResponsiveNavbar />
      <div className={styles["data-sharing-container"]}>
        <div className={styles["content-wrapper"]}>
          <motion.div
            className={styles["hero-section"]}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            <div className={styles["hero-content"]}>
              <motion.div
                className={styles["resume-warning"]}
                initial={{ scale: 0.95 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <div className={styles["warning-header"]}>
                  <motion.div
                    className={styles["title-wrapper"]}
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <motion.span
                      className={styles["floating-emoji"]}
                      animate={{ y: [-10, 10, -10] }}
                      transition={{ duration: 3, repeat: Infinity }}
                    >
                      ✨
                    </motion.span>
                    <h2 className={styles["warning-title"]}>
                      <span className={styles["gradient-text"]}>
                        Elevate Your Career
                      </span>
                      <span className={styles["highlight-text"]}>
                        With AI Excellence
                      </span>
                    </h2>
                    <div className={styles["title-badges"]}>
                      <motion.span
                        className={styles["badge"]}
                        whileHover={{ scale: 1.05 }}
                      >
                        95% Success Rate
                      </motion.span>
                      <motion.span
                        className={styles["badge"]}
                        whileHover={{ scale: 1.05 }}
                      >
                        🚀 3x Faster Results
                      </motion.span>
                    </div>
                  </motion.div>

                  <motion.h3
                    className={styles["warning-subheader"]}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                  >
                    Join <span className={styles["highlight"]}>150,000+</span>{" "}
                    Professionals Who've
                    <span className={styles["gradient-text"]}>
                      {" "}
                      Transformed Their Career Path
                    </span>
                  </motion.h3>
                </div>

                {/* <div className={styles['key-points']}>
                {[
                  {
                    icon: '🎯',
                    title: 'Smart Analysis',
                    description: 'Instant AI feedback on your resume'
                  },
                  {
                    icon: '⚡',
                    title: 'ATS-Optimized',
                    description: '90% success rate with screening systems'
                  },
                  {
                    icon: '🚀',
                    title: 'Fast-Track',
                    description: 'Land interviews 3x faster'
                  }
                ].map((point, index) => (
                  <motion.div 
                    key={index}
                    className={styles['point']}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.4 + (index * 0.2) }}
                    whileHover={{ scale: 1.03 }}
                  >
                    <span className={styles['icon']}>{point.icon}</span>
                    <div className={styles['point-content']}>
                      <strong className={styles['point-title']}>{point.title}</strong>
                      <p className={styles['point-description']}>{point.description}</p>
                    </div>
                  </motion.div>
                ))}
              </div> */}
             <motion.div className={styles['download-button-wrapper']}>
                <div className={styles["download-description"]}>
                  <h3 className={styles["download-title"]}>Get Your Resume Cheatsheet</h3>
                  <p className={styles["download-text"]}>
                    Download our comprehensive guide to enhance your resume and stand out in the job market.
                  </p>
                </div>
                <motion.button
                  className={`${styles["upload-button"]} ${styles["download-button"]}`}
                  aria-label="Download Resume Cheatsheet"
                  title="Download Resume Cheatsheet"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => {
                    setIsLoading(true);
                    console.log("Downloading Cheatsheet...");
                    setTimeout(() => {
                      setIsLoading(false);
                      setShowModal(true); // Show custom modal after download
                    }, 2000);
                  }}
                >
                  {isLoading ? (
                    <>
                      <FaDownload className={styles["loading-icon"]} />
                      <span style={{ marginLeft: "10px" }}>Loading...</span>
                    </>
                  ) : (
                    <>
                      <FaDownload /> Download Cheatsheet
                    </>
                  )}
                </motion.button>
                </motion.div>
              </motion.div>
            </div>

            <div className={styles["video-section"]}>
              <div className={styles["video-wrapper"]}>
                <video
                  controls
                  preload="auto"
                  playsInline
                  autoPlay
                  controlsList="nodownload"
                  onError={(e) => console.error("Video Error:", e)}
                >
                  <source
                    src="https://res.cloudinary.com/dvepwlz9j/video/upload/v1733159868/video/i87lzqx37oqdfiwhwzui.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
                {!isPlaying && (
                  <div
                    className={styles["video-overlay"]}
                    onClick={handleVideoPlay}
                    style={{ cursor: "pointer" }}
                  >
                    <FaPlayCircle className={styles["play-icon"]} />
                  </div>
                )}
              </div>
            </div>
          </motion.div>

          <motion.div
            className={styles["features-section"]}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            <div className={styles["features-grid"]}>
              <div className={styles["feature-card"]}>
                <FaRobot />
                <h3>AI Analysis</h3>
                <p>
                  Smart resume scanning with industry-specific recommendations
                </p>
              </div>
              <div className={styles["feature-card"]}>
                <FaBriefcase />
                <h3>Job Matching</h3>
                <p>Intelligent job recommendations based on your profile</p>
              </div>
              <div className={styles["feature-card"]}>
                <FaChartLine />
                <h3>Skills Gap Analysis</h3>
                <p>Identify and improve key skills for your target role</p>
              </div>
            </div>
          </motion.div>

          {/* <motion.div 
          className={styles['upload-section']}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
        >
          <h2 className={styles['section-title']}>Get Your Free Resume Cheatsheet</h2>
          <p className={styles['upload-description']}>
            Download our comprehensive resume guide with expert tips and templates.
          </p>
          
          <motion.button 
            className={`${styles['upload-button']} ${styles['download-button']}`}
             
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <FaDownload /> Download Resume Cheatsheet
          </motion.button>
        </motion.div> */}

          <motion.div
            className={styles["ai-builder-section"]}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
          >
            <div className={styles["ai-builder-content"]}>
              <div className={styles["ai-builder-text"]}>
                <h2 className={styles["section-title"]}>
                  <FaRobot className={styles["title-icon"]} />
                  AI-Powered Resume Builder
                </h2>
                <p className={styles["section-description"]}>
                  Create an ATS-friendly resume in minutes using our advanced AI
                  technology
                </p>

                <div className={styles["builder-features"]}>
                  <div className={styles["feature-item"]}>
                    <FaChartLine className={styles["feature-icon"]} />
                    <span>Smart formatting & layout suggestions</span>
                  </div>
                  <div className={styles["feature-item"]}>
                    <FaBriefcase className={styles["feature-icon"]} />
                    <span>Industry-specific keywords</span>
                  </div>
                  <div className={styles["feature-item"]}>
                    <FaUsers className={styles["feature-icon"]} />
                    <span>Real-time ATS compatibility check</span>
                  </div>
                </div>

                <div className={styles["builder-actions"]}>
                  <motion.button
                    className={styles["primary-button"]}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => {
                      window.location.href = "/airesume";
                    }}
                  >
                    Build Your Resume Now
                    <FaArrowRight className={styles["arrow-icon"]} />
                  </motion.button>
                  <span className={styles["time-estimate"]}>
                    <FaClock className={styles["clock-icon"]} />
                    Takes only 10 minutes
                  </span>
                </div>
              </div>

              <div className={styles["ai-builder-preview"]}>
                <div className={styles["preview-card"]}>
                  <div className={styles["preview-header"]}>
                    <span className={styles["live-badge"]}>Live Preview</span>
                  </div>
                  <div className={styles["preview-content"]}>
                    <img
                      src="https://myfuse.in/static/media/exp.861bdb791e8687c1549a.png"
                      alt="Resume Builder Preview"
                      className={styles["preview-image"]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>

        <div className={styles["sidebar"]}>
          <div className={styles["sidebar-section"]}>
            <div className={styles["section-header"]}>
              <h2 className={styles["sidebar-title"]}>
                <motion.span
                  className={styles["ai-title-wrapper"]}
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <motion.span
                    animate={{ 
                      scale: [1, 1.1, 1],
                      rotate: [0, 5, -5, 0]
                    }}
                    transition={{ 
                      duration: 2,
                      repeat: Infinity,
                      repeatType: "reverse"
                    }}
                  >
                    <FaRobot className={`${styles["section-icon"]} ${styles["ai-icon"]}`} />
                  </motion.span>
                  <span className={styles["ai-text"]}>AI</span> Resume Analysis
                </motion.span>
              </h2>
              <div className={styles["badges-wrapper"]}>
                <span className={styles["feature-badge"]}>New</span>
                <span
                  className={`${styles["feature-badge"]} ${styles["trending-badge"]}`}
                >
                  🔥 Trending
                </span>
              </div>
            </div>

            <div className={styles["video-container"]}>
              <img
                src="https://myfuse.in/static/media/low%20score.7da13386a3f345f8f04d.gif"
                alt="AI Resume Analysis"
                className={styles["feature-video"]}
              />
            </div>

            <div className={styles["ai-features"]}>
              <div className={styles["feature-item"]}>
                <div className={styles["feature-icon-wrapper"]}>
                  <FaChartLine className={styles["feature-icon"]} />
                </div>
                <div className={styles["feature-content"]}>
                  <h3>Smart Score</h3>
                  <p>Get instant feedback on your resume's effectiveness</p>
                </div>
              </div>

              <div className={styles["feature-item"]}>
                <div className={styles["feature-icon-wrapper"]}>
                  <FaUsers className={styles["feature-icon"]} />
                </div>
                <div className={styles["feature-content"]}>
                  <h3>ATS Optimization</h3>
                  <p>Ensure your resume passes applicant tracking systems</p>
                </div>
              </div>

              <div className={styles["feature-item"]}>
                <div className={styles["feature-icon-wrapper"]}>
                  <FaBriefcase className={styles["feature-icon"]} />
                </div>
                <div className={styles["feature-content"]}>
                  <h3>Job Match</h3>
                  <p>Find roles that match your skills and experience</p>
                </div>
              </div>
            </div>

            <button
              className={styles["try-ai-btn"]}
              onClick={() => {
                window.location.href = "/resume-score";
              }}
            >
              Try AI Analysis
              <FaArrowRight className={styles["arrow-icon"]} />
            </button>
          </div>

          <div className={styles["sidebar-section"]}>
            <div className={styles["section-header"]}>
              <h2 className={styles["sidebar-title"]}>
                <FaBriefcase className={styles["section-icon"]} />
                Recent Jobs
              </h2>
              <motion.span
                className={styles["jobs-count"]}
                whileHover={{ scale: 1.1 }}
              ></motion.span>
            </div>

            <div className={styles["job-listings"]}>
              {recentJobs.map((job, index) => (
                <JobCard key={index} job={job} />
              ))}

              <motion.button
                className={styles["view-more-btn"]}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                View All Opportunities
                <FaChevronRight className={styles["chevron-icon"]} />
              </motion.button>
            </div>
          </div>
          
        </div>

       

       
      </div>
        
      <CustomModal isOpen={showModal}  setShowModal={setShowModal} onClose={() => setShowModal(false)}/>
       
 
    </div>
  );
};

export default DataSharing;
