import React, { useState } from "react";
import axios from "axios";

const Test2 = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState("");
  const [resumeScore, setResumeScore] = useState(null);
  const [scoringDetails, setScoringDetails] = useState([]);

 
  
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedFile) {
      setError("Please select a PDF file to upload.");
      return;
    }

    setUploading(true);
    setError("");
    setResumeScore(null);
    setScoringDetails([]);

    const formData = new FormData();
    formData.append("pdf", selectedFile);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/pdf/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const { resumeScore } = response.data;
      setResumeScore(resumeScore);
      // setScoringDetails(details);
    } catch (error) {
      console.error("Error uploading file:", error);
      setError("Error uploading file. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="upload-container">
      <h1>Upload PDF and get score</h1>

      <form onSubmit={handleSubmit}>
        <input
          type="file"
          accept="application/pdf"
          onChange={handleFileChange}
        />
        <button type="submit" disabled={uploading}>
          {uploading ? "Uploading..." : "Upload PDF"}
        </button>
      </form>
   
      {resumeScore && (
        <div className="resume-score">
          <h2>Resume Score: {resumeScore.totalScore}/100</h2>

          <h3>Score Breakdown:</h3>
          <ul>
            <li>
              <strong>Impact and Influence:</strong> {resumeScore.sections.ImpactAndInfluence}/30
              <ul>
                <li><strong>Has:</strong> {resumeScore.details.ImpactAndInfluence.has.length > 0 ? resumeScore.details.ImpactAndInfluence.has.join(', ') : 'None'}</li>
                <li><strong>Missing:</strong> {resumeScore.details.ImpactAndInfluence.missing.length > 0 ? resumeScore.details.ImpactAndInfluence.missing.join(', ') : 'None'}</li>
              </ul>
            </li>
            <li>
              <strong>Clarity and Focus:</strong> {resumeScore.sections.ClarityAndFocus}/20
              <ul>
                <li><strong>Has:</strong> {resumeScore.details.ClarityAndFocus.has.length > 0 ? resumeScore.details.ClarityAndFocus.has.join(', ') : 'None'}</li>
                <li><strong>Missing:</strong> {resumeScore.details.ClarityAndFocus.missing.length > 0 ? resumeScore.details.ClarityAndFocus.missing.join(', ') : 'None'}</li>
              </ul>
            </li>
            <li>
              <strong>Achievement Metrics:</strong> {resumeScore.sections.AchievementMetrics}/25
              <ul>
                <li><strong>Has:</strong> {resumeScore.details.AchievementMetrics.has.length > 0 ? resumeScore.details.AchievementMetrics.has.join(', ') : 'None'}</li>
                <li><strong>Missing:</strong> {resumeScore.details.AchievementMetrics.missing.length > 0 ? resumeScore.details.AchievementMetrics.missing.join(', ') : 'None'}</li>
              </ul>
            </li>
            <li>
              <strong>Format and Structure:</strong> {resumeScore.sections.FormatAndStructure}/15
              <ul>
                <li><strong>Has:</strong> {resumeScore.details.FormatAndStructure.has.length > 0 ? resumeScore.details.FormatAndStructure.has.join(', ') : 'None'}</li>
                <li><strong>Missing:</strong> {resumeScore.details.FormatAndStructure.missing.length > 0 ? resumeScore.details.FormatAndStructure.missing.join(', ') : 'None'}</li>
              </ul>
            </li>
            <li>
              <strong>Career Progression:</strong> {resumeScore.sections.CareerProgression}/10
              <ul>
                <li><strong>Has:</strong> {resumeScore.details.CareerProgression.has.length > 0 ? resumeScore.details.CareerProgression.has.join(', ') : 'None'}</li>
                <li><strong>Missing:</strong> {resumeScore.details.CareerProgression.missing.length > 0 ? resumeScore.details.CareerProgression.missing.join(', ') : 'None'}</li>
              </ul>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Test2;
