import React from 'react'
import styles from "../../assets/HomePage/Job.module.css"
import TopCompainesHireingWithUs from '../TopCompainesHireingWithUs'
const JobsCategroy = () => {
  return (
    <div className={styles.main} > 

    <div className='container'>
       <div className='row d-flex  justify-content-center '>
       
           <div className='col-11  col-md-11 d-flex justify-content-center flex-column ' style={{position:'relative'}}> 
           <h2 className ={styles.ngcontent }> Explore passions, skills, align, find perfect fit. 
           <span className={styles._ngcontents}  > Apply to roles matching your skills from 500+ trending options. 
           </span>
           </h2>
           <TopCompainesHireingWithUs/>
           </div>
       </div>
    </div>
      
    </div>
  )
}

export default JobsCategroy