import React from 'react'
import MyResponsiveNavbar from './Navbar'
import PricingComponent  from '../components/ResumeHomePage/Payment'

const Pricing = () => {
  return (
    <div>
        <MyResponsiveNavbar/>
       <PricingComponent/>
    </div>
  )
}

export default Pricing