import React from "react";
import styles from "./LLLResume.module.css";
import { getSafeMarkdownString } from "../../../../utils/markdown";

const LLLResume = ({ sections }) => {
  return (
    <div className={styles.resume}>
      <Header profile={sections.profile.data} />
      <About Professionalsummary={sections.Professionalsummary.data} />
      <Education education={sections.education?.data} />
      <Experience experience={sections.experience?.data} />
      <Projects projects={sections.projects?.data} /> 
      <Optional optional={sections.optional?.data} />
      <TechnicalSkills technicalSkills={sections.technicalSkills?.data} />
       <Awards awards={sections.awards?.data} />
    </div>
  );
};

const Header = ({ profile }) => {
  if (!profile) return null;
  return (
    <div className={styles.headerContainer}>
      <div className={styles.nameashu}>{profile.name}</div>
      <div className={styles.nameashudown}>{profile.position}</div>
      <div className={styles.resumesampletexttop}>
        <span>
          {" "}
          <span className={styles.bold}>{profile.address}</span>{" "}
        </span>

        <span>
          {" "}
          {profile.phone ? "|" : ""}{" "}
          <span className={styles.bold}>{profile.phone}</span>{" "}
        </span>
        <span>
          {profile.email ? "|" : ""}{" "}
          <span className={styles.bold}>{profile.email}</span>{" "}
        </span>
        <span>
          {" "}
          {profile.linkedinUsername ? "|" : ""}{" "}
          <span className={styles.bold}>{profile.linkedinUsername}</span>{" "}
        </span>
        <span>
          {" "}
          {profile.githubUsername ? "|" : ""}{" "}
          <span className={styles.bold}>{profile.githubUsername}</span>{" "}
        </span>
      </div>
    </div>
  );
};

const About = ({ Professionalsummary }) => { 
  if (!Professionalsummary ) return null;
  return (
    <div>
      <div className={styles.secondline}>
        <span className={styles.postionashu}> PROFESSIONAL SUMMARY </span>
        <div className={styles.hr}></div>
        <div
          className={styles.resumesampletextabout}
          dangerouslySetInnerHTML={{ __html: Professionalsummary?.summary }}
        />
      </div>
    </div>
  );
};

const Points = ({ points, years }) => {
  if (!points) return null; 
  return (
    <ul className={styles.points}>
      {points?.map((point, index) => (
        <li key={index} className={styles.li}>
            <div className={styles.eeor}>
              <div
                key={index}
                dangerouslySetInnerHTML={{
                  __html: getSafeMarkdownString(point),
                }}
              />
              {years && <i >{years[index]}</i>}
            </div>
         
        </li>
      ))}
    </ul>
  );
};

const Experience = ({ experience }) => {
  if (!experience || experience.length === 0) return null;
  return (
    <div>
      <span className={styles.postionashu}> WORK EXPERIENCE </span>
      <div className={styles.hr}></div>
      {experience?.map((exp, index) => (
        <div className={index > 0 ? styles.bordertop : ""} key={index}>
          <div className={styles.her}>
            <h3 className={styles.resumesampleh2}>{exp.role}</h3>
          </div>
          <div className={styles.her}>
            <h3 className={styles.resumesampleh4}>
              <span> {exp.company} </span>{" "}
            </h3>
            <span>{exp.timePeriod}</span>{" "}
          </div>
           
          {/* <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: exp.description }}
        /> */}
          <div>
            <Points points={exp.points} markdown={true} secondary={true} />
          </div>
        </div>
      ))}
    </div>
  );
};

const Projects = ({ projects }) => {
  if (!projects || projects.length === 0) return null;
  return (
    <div>
      <span className={styles.postionashu}> PROJECTS </span>
      <div className={styles.hr}></div>
      {projects?.map((project, index) => (
        <div className={index > 0 ? styles.bordertop : ""} key={index}>
          <div className={styles.her}>
            <h3 className={styles.resumesampleh2}>{project.title}</h3>
          </div>
          <div className={styles.her}>
            <h3 className={styles.resumesampleh3}>
              <span> {project.organisation} </span>{" "}
            </h3>
            <span className={styles.resumesampletextt}> {project.year} </span>
          </div>
                    <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: project.description }}
        />
          <div>
            <Points points={project.points} markdown={true} secondary={true} />
          </div>
        </div>
      ))}
    </div>
  );
};

const Education = ({ education }) => {
  if (!education || education.length === 0) return null;
  return (
    <div>
      <span className={styles.postionashu}> EDUCATION </span>
      <div className={styles.hr}></div>
      {education.map((edu, index) => (
        <span key={index} className={styles.doro}>
          <div className={styles.edud}>
            <span className={styles.resumesampleh2}>{edu.college}</span>
            <span>{edu.year}</span>
          </div>
          <div className={styles.edud}>
            <span>
              {" "}
              {edu.degree} {edu.branch ? `in ${edu.branch} ` : " "}{" "}
            </span>
            <span>
              {edu.cgpa
                ? `${edu.cgpa}`
                : "" || edu.percentage
                ? `${edu.percentage}%`
                : ""}
            </span>
          </div>
        </span>
      ))}
    </div>
  );
};

const TechnicalSkills = ({ technicalSkills }) => {
  if (!technicalSkills || technicalSkills.length === 0) return null;
  return (
    <div>
      
      <span className={styles.postionashu}> SKILLS </span> 
      <div className={styles.hr}></div>
      {technicalSkills.map((tech, index) => (
        <div key={index}>
          <strong>{tech.name}</strong> {tech.name ? ":" : ""}  
          <span >{tech.skills?.join(",")}</span>
        </div>
      ))}
    </div>
  );
};

const Optional = ({ optional }) => {
  if (!optional || optional.length === 0) return null;
  return (
    <div>
      {optional.map((item, index) => (
        <div key={index}>
          <span className={styles.postionashu}>{item.name}</span>
          <div className={styles.hr}></div>
          <div className={styles.her}>
            <h3 className={styles.resumesampleh2}>{item.title}</h3>
          </div>
          <div className={styles.description} 
          dangerouslySetInnerHTML={{ __html: item.description }}
          ></div>
          {/* <div>
            <Points points={item.points} markdown={true} secondary={true} />
          </div> */}
        </div>
      ))}
    </div>
  );
};

const Awards = ({ awards }) => {
  if (!awards || awards.length === 0) return null; 
  return (
    <div>
        <span className={styles.postionashu}>  CERTIFICATION </span>
        <div className={styles.hr}></div>
       {awards.map((item, index) => (
      <div key={index}>
         <div className={styles.description} 
          dangerouslySetInnerHTML={{ __html: item.description }}
          ></div>
    </div>
  ))}
    </div>
  );
};

export default LLLResume;
