import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import styles from "../assets/Modeluploadscratch.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { ArrowRight } from "lucide-react";


function Modeluploadscratch({ modalShow, setModalShow , setSecondModalShow}) {


    const OpenChooseModal =  () =>{
        setModalShow(false);
        setSecondModalShow(true); 
    }

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      centered
    >
      <Modal.Body className={styles.bodys}>
        <div className="container" style={{ position: "relative" }}>
          <div className={styles.close}>
            <span
              className={`${styles.closeButton} close`}
              onClick={() => setModalShow(false)}
            >
              <FontAwesomeIcon icon={faClose} className={styles.iconcut} />
            </span>
          </div>
          <div className="row">
            <div className={`col-12 col-md-12 ${styles.second}`}>
              <div className={styles.container}>
                <div className={styles.topHeadingContainer}>
                  <div className={styles.headtitle}>
                    {" "}
                    How would you like to create your resume?{" "}
                  </div>
                  <div className={styles.headtitlparagraph}>
                    Narrow down your domain for personalised template
                    suggestions
                  </div>
                </div>

                <div className={styles.twoboxContainer}>
                  <div className={styles.eachContainersecond} onClick={()=>OpenChooseModal()}>
                    {" "}
                    <img
                      src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1730797388/all/ricwgen0tdizsqxdnmld.svg"
                      alt="s"
                    />{" "}

                    <div className={styles.textContainer}> 
                        <div className={styles.toptext}> Create from Scratch  </div>
                        <div className={styles.downtext}> Choose from our Library </div>
                    </div>
                  </div>
                  <div className={styles.eachContainerfirst} onClick={()=>OpenChooseModal()} >
                    <div className={styles.comingSoonBadge}>Coming Soon</div>
                    <img
                      src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1730796960/all/aeoqqrzow0sxcyoqajci.svg"
                      alt="shh"
                    />
                    <div className={styles.textContainer}> 
                        <div className={styles.toptext}> Upload previous Resume  </div>
                        <div className={styles.downtext}> Add from your system </div>
                    </div>
                  </div>
                </div>

                <div className={styles.SkipStepContainer} onClick={()=>OpenChooseModal()}>
                  {" "}
                  Skip Step <ArrowRight size={16} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </Modal.Body>
    </Modal>
  );
}

export default Modeluploadscratch;
