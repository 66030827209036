import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import slugify from "slugify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faBriefcase,
  faUserTie,
  faMoneyCheckDollar,
} from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
const SimilarJobs = ({skills}) => {
    const [jobs, setJobs] = useState([]);

    const getAllJobs = async () => {
        try {
         
          const { data } = await axios.get(
            `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/filterjob?skills=s`,
            {
              headers: {
                Authorization: "Bearer madebyashutosh",
              },
            }
          );
          if (data?.success) {
            
            setJobs(data.jobsWithPercentage);
          }
          
        } catch (error) {
          console.log(error);
        }
      };


    useEffect(() => {
        getAllJobs();
      }, []);  
    
       

      const handleNavigate = async (value, heading) => {
        // Ensure `slugify` is defined or imported
        const slug = `${slugify(heading)}-${value}`;
        
        // Open the new URL in a new tab
        window.open(`/jobs/details/${slug}`, '_blank');
    };

  return (
    <div style={{marginTop:'1rem'}}>
        

        <Swiper
  spaceBetween={10}
  centeredSlides={true}
  autoplay={{
    delay: 1500,
    disableOnInteraction: false, // Ensure autoplay continues after user interaction
  }}
  loop={true}
  modules={[Autoplay, Pagination]}
  className="mySwiper"
  slidesPerView={1} // default to 1 for small screens
  breakpoints={{
    640: {
      slidesPerView: 1, // 1 slide at a time on mobile devices
    },
    768: {
      slidesPerView: 3, // 2 slides at a time on tablets
    },
    1024: {
      slidesPerView: 3, // 4 slides at a time on larger screens
    },
  }}
>
  {jobs?.map((job) => (
    <SwiperSlide key={job.id_jobpost} className="mb-5" style={{ borderRadius: "20px", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", backgroundColor: "#fff", padding: "20px" }}>
      <div onClick={() => handleNavigate(job.id_jobpost,job.jobtitle)} style={{ cursor: "pointer" }}>
        <div className="d-flex justify-content-between align-items-center">
          <div style={{ flex: 1 }}>
            <h5 style={{ fontSize: "14px", fontWeight: "bold", marginBottom: "0.5rem" }}>
              {job.jobtitle.length > 20 ? `${job.jobtitle.substring(0, 20)}...` : job.jobtitle}
            </h5>
            <p style={{ fontSize: "12px", color: "#777", marginBottom: "0.5rem" }}>
              {job.companyName.length > 20 ? `${job.companyName.substring(0, 20)}...` : job.companyName}
            </p>
          </div>
          <div>
            <img
              className="img-fluid"
              src={`https://old.myfuse.in/uploads/logo/${job.companyLogo}`}
              alt="Company Logo"
              style={{ height: "40px", width: "60px", objectFit: "contain", borderRadius: "10px" }}
            />
          </div>
        </div>
        <div style={{ width: "100%", borderBottom: "1px solid #EEE", marginBottom: "1rem" }}></div>
        <div className="container">
          <div className="row">
            <div className="col-6">
              <p className="card-text" style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
                <FontAwesomeIcon icon={faBriefcase} style={{ marginRight: ".5rem", color: "#739ee8" }} />
                {job.qualification.length > 7 ? `${job.qualification.substring(0, 7)}...` : job.qualification}
              </p>
              <p className="card-text" style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
                <FontAwesomeIcon icon={faLocationDot} style={{ marginRight: ".5rem", color: "#739ee8" }} />
                {job.location.length > 10 ? `${job.location.substring(0, 10)}...` : job.location}
              </p>
            </div>
            <div className="col-6">
              <p className="card-text" style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
                <FontAwesomeIcon icon={faMoneyCheckDollar} style={{ marginRight: ".5rem", color: "#739ee8" }} />
                {job.minimumsalary.length > 10 ? `${job.minimumsalary.substring(0, 10)}...` : job.minimumsalary}
              </p>
              <p className="card-text" style={{ fontSize: "12px", marginBottom: "0.5rem" }}>
                <FontAwesomeIcon icon={faUserTie} style={{ marginRight: ".5rem", color: "#739ee8" }} />
                {job.experience}
              </p>
            </div>
          </div>
        </div>
      </div>
    </SwiperSlide>
  ))}
</Swiper>
    </div>
  )
}

export default SimilarJobs