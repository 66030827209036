import React from "react";
import styles from "../assets/Footer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer className={styles.footerdistributed}>
      <div className={styles.footerleft}>
        <h3>
          MyFuse<span>.</span>In
        </h3>
        <p className={styles.footerlinks}>
          <a href="#" className={styles.link1}>
            Home
          </a>
          <a href="/blog">Blog</a>
          {/* <a  href='#' >Pricing</a> */}
          <a href="aboutus">About Us</a>
          {/* <a href='#' >Faq</a> */}
          <a href="/contactus">Contact Us</a>
        </p>
        <div className={styles.dooo}>
          <a
            href="/term-and-conditions"
            className={styles.footercompanyname}
            style={{ textDecoration: "none", display: "block" }}
          >
            Terms & Conditions
          </a>
          <a
            href="/cancellationandrefundpolicy"
            className={styles.footercompanyname}
            style={{ textDecoration: "none", display: "block" }}
          >
            Refund & Return
          </a>
          <a
            href="/privacy-policy"
            className={styles.footercompanyname}
            style={{ textDecoration: "none" }}
          >
            Privacy Policy
          </a>
          <a
            href="/sitemap"
            className={styles.footercompanyname}
            style={{ textDecoration: "none" }}
          >
            Sitemap
          </a>

          {/* <p className={styles.footercompanyname}>Myfuse.In © 2024</p> */}
        </div>
      </div>
      <div className={styles.footercenter}>
        {/* <span>Contact Us</span> */}
        <div>
          {/* <FontAwesomeIcon icon={faMapMarker} style={{ color: "#232D3F" , width:'2rem' }} /> */}
          <p>
            <span>  
              Myfuse,
              <br/>
              We Work Galaxy , 43, Residency Rd, <br />
              Shanthala Nagar , Ashok Nagar
              <br />
              Bengaluru, Karnataka 560025 <br />
              India{" "}
            </span>
          </p>
        </div>
        <div>
          <p>+91 7975364977</p>
        </div>
        <div>
          <p>
            <a href="mailto:contact@myfuse.in" style={{ color: "white" }}>
              contact@myfuse.in
            </a>
          </p>
        </div>
      </div>
      <div className={styles.footerright}>
        <p className={styles.footercompanyabout}>
          <span>About the company</span>
          Unlock limitless career opportunities with MyFuse.in – the premier
          platform connecting students to their dream jobs in India.
        </p>
        <div className={styles.footericons}>
          <a href="https://facebook.com">
            <FontAwesomeIcon icon={faFacebook} style={{ color: "white" }} />
          </a>
          <a href="https://twitter.com">
            <FontAwesomeIcon icon={faTwitter} style={{ color: "white" }} />
          </a>
          <a href="https://www.linkedin.com/company/myfuse-india/mycompany/">
            <FontAwesomeIcon icon={faLinkedin} style={{ color: "white" }} />
          </a>
          <a href="https://www.instagram.com/myfuse.in">
            <FontAwesomeIcon icon={faInstagram} style={{ color: "white" }} />
          </a>
        </div>
      </div>

      <p
        style={{
          fontSize: "12px",
          textAlign: "center",
          color: "white",
          marginTop: "1rem",
        }}
      >
        {" "}
        Copyright ©2024 MyFuse.In. All Rights Reserved
      </p>
      {/* <p style={{fontSize:'12px' , textAlign:'center' , color:'#92999f'}} >Empowering digital experiences, managed and developed by <a href="https://www.linkedin.com/in/ashutosh-nit/" style={{color:'#92999f'}}>Ashutosh Kumar</a></p> */}
    </footer>
  );
};

export default Footer;
