import React, { useEffect, useState } from "react";
import Navbar from "../components/DashBoard.js/Navbar";
import Left from "../components/DashBoard.js/Left";
import styles from "../assets/Dashboard/dashboard.module.css";
import { useAuth } from "../ResumeBuilder/builder/components/context/auth";
import HeadCont from "../components/DashBoard.js/HeadCont";
import Resume from "../components/DashBoard.js/Resume";
import Recomanded from "../components/DashBoard.js/Recomanded";
import Blog from "../components/DashBoard.js/Blog";
import MyResponsiveNavbar from "./Navbar";
import HeadContMobile from "../components/DashBoard.js/HeadContMobile";
import BottomBar from "../components/BottomBar";
const DashBoardNew = () => {
  const [imgSrc, setImgSrc] = useState(
    "https://res.cloudinary.com/dvepwlz9j/image/upload/v1729186227/all/vzw4bjslemzuuvznzdgs.svg"
  );
  const { auth } = useAuth();
  useEffect(() => {
    if (Array.isArray(auth?.user) && auth.user.length > 0) {
      setImgSrc(
        auth.user[0]?.images ||
          "https://res.cloudinary.com/dvepwlz9j/image/upload/v1729186227/all/vzw4bjslemzuuvznzdgs.svg"
      );
    }
  }, [auth]);

  return (
    <>
      <div className={styles.navbarMobile}>
        <MyResponsiveNavbar />
      </div>
      <div className={styles.main}>
        <div className={styles.left}>
          <Left auth={auth} image={imgSrc} />
        </div>
        <div className={styles.right}>
          <div className={styles.navbar}>
            <Navbar auth={auth} image={imgSrc} />
          </div>

          <div className={styles.headcontmobile}>
            <HeadContMobile auth={auth} />
          </div>
          <div className={styles.headdesktop}>
            <HeadCont auth={auth} />
          </div>
          <Resume />
          <Recomanded />
          <Blog />
        </div>
        <BottomBar />
      </div>
    </>
  );
};

export default DashBoardNew;
