import { useState, useEffect, useContext, createContext } from "react";
import axios from "axios";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ user: null, token: "" });
  const [authstatus, setAuthStatus] = useState(null);
  const [user, setUser] = useState(null);
  // Set default axios authorization header based on the auth token
  useEffect(() => {
    if (auth?.token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${auth.token}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  }, [auth.token]);
  useEffect(() => {
    const data = localStorage.getItem("auth");
    if (data) {
      const parseData = JSON.parse(data);
      setAuth({
        user: parseData.user,
        token: parseData.token,
      });
    }
  }, []);

  useEffect(() => {
    const checkAuthStatus = async () => {
      if (auth?.token) {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/userauth`
          );
          setAuthStatus(res.data.ok);
        } catch (error) {
          console.error("Error while checking authentication status:", error);
          setAuthStatus(false);
        }
      } else {
        setAuthStatus(false);
      }
    };

    if (auth.token) {
      checkAuthStatus();
    }
  }, [auth.token]);

  const value = {
    auth,
    setAuth,
    authstatus,
    setUser,
    user,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
