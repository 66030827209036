import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../components/ResumeBuilder/builder/components/context/auth';
import Spinner from './Spinner';
import axios from 'axios';

const AdminRoute = () => {    
  const { authstatus, user, setUser  } = useAuth();
  const [loading, setLoading] = useState(true);
 
  const CheckUser = async () => { 
    const datalocal = JSON.parse(localStorage.getItem('auth'));
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/admin/userCheck`,
        {}, // Request body if needed, otherwise an empty object
        {
          headers: {
            Authorization: `Bearer ${datalocal?.token}`, // Correctly format the Authorization header
          },
        }
      );
      
      const { data } = response;
      await setUser(data.user[0].user_type);
      
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    CheckUser();
  }, [setUser]);

  if (loading || authstatus === null) {
    return <Spinner />;
  }

  // Check if the user is authenticated and has admin role
  return authstatus && user === 'Blog' ? (
    <Outlet />
  ) : (
    <Navigate to="/not-authorized" />
  );
};

export default AdminRoute;
