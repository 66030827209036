import React from "react";
import styles from "../../assets/Account.module.css";
import { BriefcaseBusiness, CircleArrowOutUpRight, DollarSignIcon, HeartIcon, MessageSquare, Notebook, Settings, User } from 'lucide-react';
import { useNavigate } from "react-router-dom";
const Left = ({ imgSrc, auth }) => { 
  const navigate = useNavigate(); 
   

   const Handlenavigate = async() =>{
       navigate("/admin/blog");
   }
  return (
    <div className={styles.leftmain}>
      <div className={styles.headingleft}>
        <div className={styles.lefttotla}>
          <div className={styles.imgcontainer}>
            <img
              width="37"
              height="37"
              alt="Ashutosh Kumar"
              src={auth?.user?.[0]?.images ? auth?.user?.[0]?.images :imgSrc}
              className={styles.imgss}
            />
          </div>
          <div className={styles.namecontainer}>
            <span className={styles.names}>
              {auth?.user?.[0]?.firstname} {auth?.user?.[0]?.lastname}  <span className={styles.greendot}></span>
            </span>
            <p className={styles.email}>{auth?.user?.[0]?.email}</p>
          </div> 
          
        </div>
        <div className={styles.statusbar}>
              <div className={styles.online}>
                 <div className={styles.fill} style={{width:"17%"}}></div>
              </div> 
              <span className={styles.percent}>
                17%
              </span>
          </div>
      </div> 

      <div className={styles.listss}>
           <ul className={styles.alltop}>
               <li className={`${styles.lists} ${styles.active}`}> <User size={15}  style={{marginRight:'6px'}}/> Profile</li>
               <li className={styles.lists}> <BriefcaseBusiness size={15} style={{marginRight:'6px'}}/> Saved Jobs</li>
               <li className={styles.lists}> <Notebook size={15} style={{marginRight:'6px'}}/> Applied Jobs</li>
               <li className={styles.lists}> <DollarSignIcon size={15}  style={{marginRight:'6px'}}/> Payment And Billing</li>
               <li className={styles.lists}> <MessageSquare size={15}  style={{marginRight:'6px'}}/> Chat and Help</li>
               <li className={styles.lists}> <HeartIcon size={15}  style={{marginRight:'6px'}}/> Feebback</li> 
                  {
                    auth?.user[0].user_type==="Blog" ?  (
                      <li className={styles.lists} onClick={Handlenavigate}> <HeartIcon size={15}  style={{marginRight:'6px'}} /> Write a Blog </li>
                    ) :"" 
                          
                  }
                
              
           </ul>
      </div>
      <div className={styles.downs}>
           <ul className={styles.alltop}>
           <li className={styles.lists}> <Settings size={15}  style={{marginRight:'6px'}}/> Settings</li>
               <li className={styles.lists}> <CircleArrowOutUpRight size={15}  style={{marginRight:'6px'}}  />Logout</li>
           </ul>
      </div>
    </div>
  );
};

export default Left;
