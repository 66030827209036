import React from "react";

import styles from "../assets/Job.module.css"
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "../assets/companyList.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
 
import { useNavigate } from "react-router-dom";
import { useJobs } from "../ResumeBuilder/builder/components/context/Jobs";
import { ArrowUpRight } from "lucide-react";
const CompanyList = () => { 
    const navigate = useNavigate(); 
    const {setComminJobs,setIsfirst} = useJobs(); 
  let array = [];
  array = [
    {
      Companyname: "Google",
      img: "https://blog.google/static/blogv2/images/google-200x200.png",
      jobpost: "32",
      description: "Google LLC is an American multinational corporation",
    },
    {
      Companyname: "Amazon",
      img: "https://is1-ssl.mzstatic.com/image/thumb/Purple211/v4/32/97/f7/3297f7b8-e057-fbcc-7a6c-e819960bba3c/AppIcon-0-0-1x_U007emarketing-0-6-0-0-85-220.png/1200x600wa.png",
      jobpost: "32",
      description: "Amazon.com, doing business as Amazon,is an American multinational",
    },
    {
      Companyname: "Microsoft",
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBWjxuZx__a25hgj1JCqKkQF-6_UiqrUG7_g&s",
      jobpost: "32",
      description: "Microsoft Corporation is an American multinational corporation",
    },
    {
        Companyname: "Sony Corporation",
        img: "https://1000logos.net/wp-content/uploads/2021/05/Sony-logo.png",
        jobpost: "32",
        description: "Sony Group Corporation, formerly known as Tokyo Tsushin Kogyo",
      },
      {
        Companyname: "IBM",
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdccX-HSF5ogQj1I2F4FIhDWQmhHKbgLrPdQ&s",
        jobpost: "23",
        description: "International Business Machines Corporation, nicknamed Big Blue,",
      },
      {
        Companyname: "Coca Cola",
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRF_2tVojDd1gBpXsiXvKyYwgLpujpU-NQh_A&s",
        jobpost: "32",
        description: "Coca-Cola, or Coke, is a carbonated soft drink with a cola flavor",
      },
      {
        Companyname: "Apple",
        img: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg",
        jobpost: "32",
        description: "Apple Inc. is an American multinational corporation  ",
      },
      {
        Companyname: "HP",
        img: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/HP_logo_2012.svg/800px-HP_logo_2012.svg.png",
        jobpost: "32",
        description: "HP   is an American multinational  technology company",
      },
      {
        Companyname: "Infosys",
        img: "https://pbs.twimg.com/profile_images/1694674619079262208/5PrLtiIO_400x400.jpg",
        jobpost: "32",
        description: "Infosys Corporation is an American multinational corporation",
      },
      {
        Companyname: "TCS",
        img: "https://assets.bizclikmedia.net/576/15854893995819287b3a084bd73a4b20:0d64277fe26583ad50056b3cac55bf79/download-20-2-2.png",
        jobpost: "32",
        description: "TCS Limited is an Indian multinational information technology services",
      },
  ]; 


     const handleClick = async(value) =>{
        // navigate(`/jobs?param=${value}`);
        setComminJobs(value);
        setIsfirst(true)
        navigate(`/jobs`);
     }

  return (
    <div
      style={{
        marginTop: "4rem",
        borderRadius: "10px",
      
      }}
    >
      <div> 

      <h2  className={styles.sfas}>  Businesses Seeking New Employees </h2>
           <span className="_ngcontents"  >Our leading companies that are actively recruiting </span>
         
      </div>

      <div
        className="  d-flex justify-center"
        style={{ height: "22rem", marginTop: "2rem", marginBottom: "2rem" , position:'relative' }}
      >

       
        <Swiper
           spaceBetween={10}
           // centeredSlides={true}
           autoplay={{
             delay: 2500,
             disableOnInteraction: false,
           }}
           // navigation={true}

           modules={[Autoplay, Pagination, Navigation]}
           className={`mySwiper ${styles.do}`}
           slidesPerView={1.2} // default to 1 for small screens
           breakpoints={{
             640: {
               slidesPerView: 2, // 1 slide at a time on mobile devices
             },
             768: {
               slidesPerView: 3, // 2 slides at a time on tablets
             },
             1024: {
               slidesPerView: 4, // 4 slides at a time on larger screens
             },
           }} 
           navigation={{
            nextEl: '.custom-swiper-button-next',
            prevEl: '.custom-swiper-button-prev',
          }}
          pagination={{
            clickable: true,
            el: '.swiper-pagination',
          }}

        >
          {array.map((ele, inde) => {
            return (
              <SwiperSlide>

               <div style={{position:'relative'}}>
               <div className={`${styles.deldo}  ${styles.color1}  `}>
                 
               </div>
               

                <div
                  className="d-flex "
                  style={{ justifyContent: "center", height: "80px" , position:'relative'   }}
                > 
                
                  <img
                    src={ele.img} 
                    alt={ele.Companyname}
                    className="img-fluid"
                    style={{
                      height: "100%",
                      width: "130px",
                      objectFit: "contain",
                      border: "1px solid #eee",
                      borderRadius: "12px",
                      background:'white'
                    }}
                  />{" "}
                  <br></br>
                </div>
                <div
                  style={{
                    backgroundColor: "rgba(191, 32, 38, 0.03)",
                    padding: "5px",
                    marginTop: "1rem",
                    borderRadius: "10px",
                  }}
                >
                  <h2
                    style={{
                      fontSize: "15px",
                      textAlign: "center",
                      marginTop: "1rem",
                      fontWeight: "800",
                    }}
                  >
                    {ele.Companyname}
                  </h2>
                  <p className="text-center" style={{fontSize:"14px"}}>More {ele.jobpost} job posted</p>
                </div>
                <p style={{ textAlign: "center", padding: "5px" , fontSize:"13px" , color:"#727272" }}>
                  {ele.description}
                </p>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <span
                     className={styles.fosloes}
                    onClick={() => handleClick(ele.Companyname)}
                  >
                    View Jobs  <span className={styles.xo}>
                            <ArrowUpRight size={15}/>
                      </span> 
                  </span>
                </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className={`swiper-pagination ${styles.sslo}`}></div>

      </div>
    </div>
  );
};

export default CompanyList;
