import React from "react";
import { motion } from "framer-motion";
import { Shield, Zap, Star, Award, Users, CheckCircle, Crown } from "lucide-react";
import styles from "../../assets/ResumeHomePage/Payment.module.css";
import { usePayment } from "../PaymentCashFree";
import { Star as StarFilled } from 'lucide-react';

const Payment = () => {
  const { initiatePayment } = usePayment();

  const plans = [
    {
      name: "Basic",
      tagline: "Get Started Free",
      price: "0",
      features: [
        "5 Resume Templates",
        "Basic Job Search",
        "Download as PDF",
        "Email Support"
      ],
      buttonText: "Start Free",
      color: "#64748b",
      icon: CheckCircle
    },
    {
      name: "Pro",
      tagline: "Most Popular",
      price: "247",
      originalPrice: "399",
      features: [
        "All Basic Features",
        "Basic Job Suggestions",
        "Unlimited AI-Assisted Resume Creation",
        "Standard Resume Scoring",
        "ATS-Compatible Template",
        "Unlimited Resume Edits",
        "Unlimited Downloads",
        "Beginner's How-To Guide",
        "Priority Support"
      ],
      popular: true,
      buttonText: "Go Pro",
      color: "#4f46e5",
      icon: Star
    },
    {
      name: "Enterprise",
      tagline: "For Serious Job Seekers",
      price: "747",
      originalPrice: "999",
      features: [
        "All Pro Features",
        "Advanced How-To Guide",
        "Professional Resume Samples",
        "AI-Enhanced Resume Optimization",
        "Comprehensive Resume Analysis & Feedback",
        "Enhanced ATS Scoring & Insights",
        "Personalized Job Recommendations",
     
        "24/7 Priority Support"
      ],
      buttonText: "Get Enterprise",
      color: "#1e293b",
      icon: Crown
    }
  ];

  return (
    <div className={styles.pageWrapper}>
      <motion.div 
        className={styles.floatingBadge}
        animate={{ 
          y: [0, -8, 0],
          rotate: [-1, 1, -1],
          scale: [1, 1.02, 1]
        }}
        transition={{ 
          duration: 3,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <Zap className={styles.badgeIcon} size={16} />
        <span>Limited Time Offer</span>
        <span className={styles.badgeTimer}>24h left</span>
      </motion.div>

      <motion.div 
        className={styles.container}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <div className={styles.header}>
          <motion.h1 
            className={styles.title}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            Choose Your Career Growth Plan
            <span className={styles.titleAccent}>✨</span>
          </motion.h1>
          <motion.p 
            className={styles.subtitle}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            Join <span className={styles.highlight}>50,000+</span> professionals who trust us for their career growth
          </motion.p>
        </div>

        <div className={styles.plansContainer}>
          {plans.map((plan, index) => (
            <motion.div
              key={plan.name}
              className={`${styles.planCard} ${plan.popular ? styles.popularPlan : ''}`}
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              whileHover={{ y: -8 }}
            >
              {plan.popular && (
                <div className={styles.popularBadge}>
                  <Star size={14} /> Most Popular
                </div>
              )}

              <div className={styles.planHeader} style={{ background: `linear-gradient(135deg, ${plan.color} 0%, ${plan.color}dd 100%)` }}>
                <plan.icon className={styles.planIcon} size={24} />
                <h3 className={styles.planName}>{plan.name}</h3>
                <p className={styles.planTagline}>{plan.tagline}</p>
                
                <div className={styles.pricing}>
                  <span className={styles.currency}>₹</span>
                  <span className={styles.amount}>{plan.price}</span>
                  {plan.price !== "0" && <span className={styles.period}>/3 months</span>}
                </div>

                {plan.originalPrice && (
                  <div className={styles.discount}>
                    <span className={styles.originalPrice}>₹{plan.originalPrice}</span>
                    <span className={styles.saveTag}>25% off</span>
                  </div>
                )}
              </div>

              <div className={styles.planFeatures}>
                {plan.features.map((feature, idx) => (
                  <motion.div
                    key={idx}
                    className={styles.feature}
                    initial={{ opacity: 0, x: -10 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.4 + idx * 0.1 }}
                  >
                    <CheckCircle className={styles.featureIcon} size={16} />
                    <span>{feature}</span>
                  </motion.div>
                ))}
              </div>

              <motion.button
                className={`${styles.planButton} ${plan.popular ? styles.popularButton : ''}`}
                onClick={() => plan.price !== "0" ? initiatePayment(plan.price) : window.location.href = '/airesume'}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                {plan.buttonText}
              </motion.button>

              {plan.price !== "0" && (
                <motion.div 
                  className={styles.guarantee}
                  whileHover={{ scale: 1.02 }}
                >
                  <div className={styles.guaranteeIcon}>
                    <Shield size={16} />
                  </div>
                  <div className={styles.guaranteeContent}>
                    <span className={styles.guaranteeTitle}>100% Money Back Guarantee</span>
                    <span className={styles.guaranteeText}>Try risk-free for 3 days</span>
                  </div>
                </motion.div>
              )}
            </motion.div>
          ))}
        </div>

        <motion.div 
          className={styles.trustSection}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8 }}
        >
          <div className={styles.trustGrid}>
            <motion.div 
              className={styles.trustCard}
              whileHover={{ y: -5 }}
            >
              <div className={styles.trustIconWrapper}>
                <Shield className={styles.trustIcon} />
              </div>
              <span>100% Secure Payment</span>
            </motion.div>

            <motion.div 
              className={styles.trustCard}
              whileHover={{ y: -5 }}
            >
              <div className={styles.trustIconWrapper}>
                <Users className={styles.trustIcon} />
              </div>
              <span>50K+ Active Users</span>
            </motion.div>

            <motion.div 
              className={styles.trustCard}
              whileHover={{ y: -5 }}
            >
              <div className={styles.trustIconWrapper}>
                <Award className={styles.trustIcon} />
              </div>
              <span>90% Success Rate</span>
            </motion.div>
          </div>
        </motion.div>

        <motion.div 
          className={styles.testimonialSection}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1 }}
        >
          <h2>What Our Users Say</h2>
          <div className={styles.testimonialGrid}>
            <motion.div 
              className={styles.testimonialCard}
              whileHover={{ y: -5 }}
            >
              <div className={styles.testimonialHeader}>
                <img 
                  src="https://img.freepik.com/free-photo/happy-businesswoman-holding-folder_74855-5075.jpg" 
                  alt="Sarah"
                  className={styles.testimonialAvatar}
                />
                <div className={styles.testimonialInfo}>
                  <h4>Ishika Singh</h4>
                  <p>Software Engineer</p>
                </div>
              </div>
              <p className={styles.testimonialContent}>
                "The AI-powered resume builder helped me land my dream job! The ATS optimization features really made a difference."
              </p>
              <div className={styles.testimonialRating}>
                {[...Array(5)].map((_, i) => (
                  <StarFilled key={i} size={16} fill="currentColor" />
                ))}
              </div>
            </motion.div>

            <motion.div 
              className={styles.testimonialCard}
              whileHover={{ y: -5 }}
            >
              <div className={styles.testimonialHeader}>
                <img 
                  src="https://thumbs.dreamstime.com/b/happy-indian-business-man-smiling-standing-office-headshot-face-portrait-bearded-leader-looking-camera-professional-297664234.jpg" 
                  alt="Michael"
                  className={styles.testimonialAvatar}
                />
                <div className={styles.testimonialInfo}>
                  <h4>Rajesh Kumar</h4>
                  <p>Marketing Manager</p>
                </div>
              </div>
              <p className={styles.testimonialContent}>
                "The 1-on-1 resume review was invaluable. The expert feedback helped me highlight my achievements effectively."
              </p>
              <div className={styles.testimonialRating}>
                {[...Array(5)].map((_, i) => (
                  <StarFilled key={i} size={16} fill="currentColor" />
                ))}
              </div>
            </motion.div>

            <motion.div 
              className={styles.testimonialCard}
              whileHover={{ y: -5 }}
            >
              <div className={styles.testimonialHeader}>
                <img 
                  src="https://st.depositphotos.com/1771835/2038/i/950/depositphotos_20380779-stock-photo-serious-man-portrait-real-high.jpg" 
                  alt="Ishaan"
                  className={styles.testimonialAvatar}
                />
                <div className={styles.testimonialInfo}>
                  <h4>Ishaan Gupta</h4>
                  <p>Product Designer</p>
                </div>
              </div>
              <p className={styles.testimonialContent}>
                "Worth every penny! The interview preparation and career planning services gave me the confidence I needed."
              </p>
              <div className={styles.testimonialRating}>
                {[...Array(5)].map((_, i) => (
                  <StarFilled key={i} size={16} fill="currentColor" />
                ))}
              </div>
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Payment;
