import React from 'react'
import {
    LinkedinShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    FacebookShareButton,
    EmailShareButton,
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    WhatsappIcon,
    TwitterIcon,
  } from 'react-share';
  import styles from "../../assets/Job.module.css";
  import QRCode from "react-qr-code";
  import Modal from "react-bootstrap/Modal";
  import { toast } from "react-hot-toast";
import { Copy, X } from 'lucide-react';
import {  Button } from 'react-bootstrap';
const ModelForShare = ({jobid,handleClose,show,slug}) => {
  console.log("tis",slug)
    const handleCopyClick = () => {
        navigator.clipboard.writeText(`https://myfuse.in/jobs/${slug}`).then(() => {
          
          toast.success('Job URL copied to clipboard!');
        }).catch(err => {
          console.error('Could not copy text: ', err);
        });
      };
  return (
    <div>
          <Modal
        show={show}
        onHide={handleClose}
        size="lg" 
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
         <Button variant="link" onClick={handleClose} className={styles.dd}>
          <X onClick={handleClose} />
        </Button>
        <Modal.Body>
          <div className="container p-4">
          <p className={styles.deo}>Share With Your Friends</p>

          <div className={styles.doo}>
                      <LinkedinShareButton url={`https://myfuse.in/jobs/${slug}`} title={`MyFuse Jobs`}>
                        <LinkedinIcon  className={styles.ldo} round />
                      </LinkedinShareButton>

                      <WhatsappShareButton url={`https://myfuse.in/jobs/${slug}`} itle={`MyFuse Jobs`} separator=":: ">
                        <WhatsappIcon className={styles.ldo} round />
                      </WhatsappShareButton>

                      <TwitterShareButton url={`https://myfuse.in/jobs/${slug}`} itle={`MyFuse Jobs`}>
                        <TwitterIcon className={styles.ldo} round />
                      </TwitterShareButton> 
                      <FacebookShareButton url={`https://myfuse.in/jobs/${slug}`} quote={"Myfuse jobs" }>
                        <FacebookIcon className={styles.ldo} round />
                      </FacebookShareButton>
                      <EmailShareButton url={`https://myfuse.in/jobs/${slug}`} subject={"myfuse jobs" } body="Check out this job: ">
                        <EmailIcon className={styles.ldo} round />
                      </EmailShareButton>
                      </div>

            <div className={styles.dloo}>
                      <div className={styles.dlod}>
                        
                        <QRCode value={`https://myfuse.in/jobs/${slug}`} viewBox={`0 0 256 256`} fgColor={"#142683"} className={styles.llo}/>
                        <p className={styles.ll}>Scan the QR For Link </p>
                        </div>
                        <div className={styles.cdlod}>
                        
                         {/* apply texarea  */}
                         <textarea className={styles.cdl} placeholder="Enter your email address"
                          rows="4"
                          cols="50" 
                          value={`I found a great job on MyFuse with fantastic perks that might interest you. 
Check it out and apply if it fits your career goals:
Link: https://myfuse.in/jobs/${slug} 
Good luck!
 `}  style={{ fontSize: '14px', color: '#333', fontWeight: '500' }}

                         />
                        
                        
                        <span className={styles.copyurl} onClick={handleCopyClick}> <Copy size={15} style={{marginRight:'10px'}}/> Copy Link</span>
                        </div>
            </div>
        
            
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ModelForShare