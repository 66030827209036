import React from 'react';
import InfiniteScroll from './scroll';



const HiringWithUs = () => {
  
  return (
    <div className={`container `}>
      <div className="row" style={{ display: 'flex', justifyContent: 'center'   }} >
        {/* <h1 className={`text-align-center ${styles.heading} mt-4`} style={{textAlign:'center'}}>Companies Hiring with us :</h1> */}
        <InfiniteScroll/>
      </div>
    </div>
  );
};

export default HiringWithUs;
