import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetConfig = ({ title, description, keywords, canonical}) => (
    <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <link rel="canonical" href={canonical} />
    </Helmet>
);

export default HelmetConfig;
