import React, { useState } from 'react'
import styles from "../../assets/BottomBar.module.css"
import { ArrowUpRight, Heart,  Share2 } from 'lucide-react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../ResumeBuilder/builder/components/context/auth'; 
import ModelForShare from './ModelForShare';

const Bottom = ({jobDetailsSingle}) => {
    const navigate = useNavigate();
    const { auth } = useAuth();
    const [showshare, setShowShare] = useState(false);
    const handleShowShare = () => setShowShare(true);
    const handleCloseShare = () => setShowShare(false);
    const [like , setlike] = useState(false); 

    const handleLike = () =>{
      setlike(!like);
    }
  return (
    <div className={styles.main}>
    <div className={styles.containeralldd}>
       <div className={styles.share}>
           <Share2 size={20}  onClick={handleShowShare} /> 
           <Heart size={20}  color={like?"red":'black'} onClick={handleLike}  />
       </div>
      <div className={`${styles.both}`}>
      <span className={styles.resumebottom} onClick={()=>navigate('/airesume')} > <img className={styles.image} src='https://res.cloudinary.com/dvepwlz9j/image/upload/f_auto,q_auto/v1/all/memtv2b2jh4iggm80vch' alt='sdf' /> AI Resume <ArrowUpRight size={15} /> </span>
        <span className={styles.applybottom} >
            <a
                  className={styles.applybottom}
                  href={auth.token===''? '/login' : jobDetailsSingle[0]?.link }
                   target="_blank"
                    rel="noopener noreferrer"
                > Apply</a>
           <ArrowUpRight size={15}/></span>
      </div>
    </div>
    <ModelForShare jobid={jobDetailsSingle[0]?.id_jobpost} show={showshare} handleClose={handleCloseShare} handleShowShare={handleShowShare}/>

  </div>
  )
}

export default Bottom