import React, { useCallback, useState } from 'react';
import styles from '../../assets/ResumeScore/uploadContainer.module.css';
import { motion } from 'framer-motion';
import { FaUpload, FaFileAlt, FaCheckCircle, FaTimesCircle, FaTools, FaChartLine } from 'react-icons/fa';
import { AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Steps = () => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  const steps = [
    {
      icon: <FaFileAlt />,
      title: "Upload Resume",
      description: "Upload your current resume in PDF format",
      delay: 0.2,
      gradient: "from-blue-400 to-blue-600"
    },
    {
      icon: <FaChartLine />,
      title: "Get Suggestions",
      description: "Receive personalized suggestions to improve your resume score",
      delay: 0.4,
      gradient: "from-green-400 to-green-600"
    },
    {
      icon: <FaTools />,
      title: "Perfect Your Resume",
      description: "Use our Resume Builder to implement changes and create the perfect resume",
      delay: 0.6,
      gradient: "from-purple-400 to-purple-600"
    }
  ];

  return (
    <div className={styles.stepsContainer} ref={ref}>
      <motion.h1 
        className={styles.stepsTitle}
        initial={{ opacity: 0, y: -20 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        <span className={styles.gradientText}>Improve Your Resume</span>
        <br />& Get Ahead of the Competition
      </motion.h1>
      
      <div className={styles.stepsWrapper}>
        {steps.map((step, index) => (
          <motion.div
            key={index}
            className={`${styles.stepCard} bg-gradient-to-r ${step.gradient}`}
            initial={{ opacity: 0, x: -50 }}
            animate={inView ? { opacity: 1, x: 0 } : {}}
            transition={{ delay: step.delay, duration: 0.6 }}
            whileHover={{ 
              scale: 1.05,
              boxShadow: "0 10px 30px rgba(0,0,0,0.15)"
            }}
          >
            <div className={styles.stepNumber}>{index + 1}</div>
            <div className={styles.stepIconWrapper}>
              <div className={styles.stepIcon}>{step.icon}</div>
            </div>
            <h3 className={styles.stepTitle}>{step.title}</h3>
            <p className={styles.stepDescription}>{step.description}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

const DropZone = ({ handleFileUpload, uploading }) => {
  const [dragActive, setDragActive] = useState(false);
  const [fileError, setFileError] = useState('');
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  const onFileChange = useCallback((event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Validate file type
    if (file.type !== 'application/pdf') {
      alert('Please upload a PDF file');
      return;
    }

    // Validate file size (5MB)
    if (file.size > 5 * 1024 * 1024) {
      alert('File size should be less than 5MB');
      return;
    }

    handleFileUpload(event);
  }, [handleFileUpload]);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    const file = e.dataTransfer.files?.[0];
    if (!file) return;

    if (file.type !== 'application/pdf') {
      setFileError('Please upload a PDF file');
      setTimeout(() => setFileError(''), 3000);
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      setFileError('File size should be less than 5MB');
      setTimeout(() => setFileError(''), 3000);
      return;
    }

    handleFileUpload({ target: { files: [file] } });
  }, [handleFileUpload]);

  return (
    <div ref={ref}>
      <Steps />
      <div className={styles.dropzoneContainer}>
        <motion.div 
          className={styles.uploadForm}
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8 }}
        >
          <motion.div 
            className={`${styles.uploadBox} ${uploading ? styles.uploading : ''} ${dragActive ? styles.dragActive : ''}`}
            whileHover={{ 
              scale: 1.02,
              boxShadow: "0 20px 40px rgba(0,0,0,0.2)"
            }}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            animate={{
              boxShadow: dragActive 
                ? '0 0 0 2px #4CAF50, 0 8px 30px rgba(0, 0, 0, 0.12)'
                : '0 8px 30px rgba(0, 0, 0, 0.12)'
            }}
          >
            <label htmlFor="file-upload" className={styles.uploadLabel}>
              <AnimatePresence mode="wait">
                {uploading ? (
                  <motion.div 
                    key="uploading"
                    className={styles.uploadIconWrapper}
                    initial={{ scale: 0 }}
                    animate={{ 
                      scale: 1,
                      rotate: 360,
                    }}
                    transition={{ 
                      rotate: { duration: 2, repeat: Infinity, ease: "linear" },
                      scale: { duration: 0.3 }
                    }}
                  >
                    <FaUpload className={`${styles.uploadIcon} ${styles.uploadingIcon}`} />
                  </motion.div>
                ) : (
                  <motion.div 
                    key="default"
                    className={styles.uploadIconWrapper}
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                  >
                    <FaFileAlt className={styles.uploadIcon} />
                  </motion.div>
                )}
              </AnimatePresence>

              <input
                id="file-upload"
                type="file"
                accept=".pdf"
                onChange={onFileChange}
                className={styles.fileInput}
                disabled={uploading}
              />

              <AnimatePresence mode="wait">
                {fileError && (
                  <motion.div
                    className={styles.errorMessage}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                  >
                    <FaTimesCircle className={styles.errorIcon} />
                    {fileError}
                  </motion.div>
                )}
              </AnimatePresence>

              <motion.p 
                className={styles.uploadText}
                animate={uploading ? {
                  opacity: [1, 0.6, 1],
                  scale: [1, 1.02, 1]
                } : {}}
                transition={{ duration: 1.5, repeat: Infinity }}
              >
                {uploading ? 'Analyzing your resume...' : 'Drop your resume here or click to upload'}
              </motion.p>

              <motion.div 
                className={styles.supportedFormats}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                <FaCheckCircle className={styles.supportedIcon} />
                <span>Supported format: PDF (Max 5MB)</span>
              </motion.div>
            </label>

            {uploading && (
              <motion.div 
                className={styles.uploadProgressContainer}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                <motion.div 
                  className={styles.uploadProgress}
                  initial={{ width: '0%', opacity: 0 }}
                  animate={{ 
                    width: '100%',
                    opacity: [0.5, 1, 0.5],
                  }}
                  transition={{ 
                    width: { duration: 2 },
                    opacity: { duration: 1.5, repeat: Infinity }
                  }}
                />
              </motion.div>
            )}
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default DropZone;