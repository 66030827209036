import React from 'react'
import Accordion from "react-bootstrap/Accordion";
const Faq = () => {
  return (
    <div className={`container-fluid   mb-4  p-4 `}  style={{background:"rgb(241 245 249)" }} >
    <div className="row d-flex justify-content-center ">
    
    <div className="col-md-4 col-11 p-4">
    <p  style={{ textAlign: "center " , fontWeight:"700" , fontSize:"1.25rem" }}>
          Your frequently asked questions.
        </p> 
        <p  style={{ textAlign: "center " , fontWeight:"700" , fontSize:"1rem" }}>
        Get answers to common queries about our platform and mentorship opportunities.
        </p>
      <img src="https://cdn.icon-icons.com/icons2/3151/PNG/512/support_question_faq_list_frequently_icon_192885.png" className="img-fluid" alt='dasd'/>
    </div>
      <div className="col-md-5 col-12 mt-4 mb-4 p-4">
        
      <Accordion defaultActiveKey="-1" className="mt-4">
  <Accordion.Item eventKey="0">
    <Accordion.Header>
      Why is MyFuse the Best Job Portal for Job Seekers?
    </Accordion.Header>
    <Accordion.Body>
      <p>
        MyFuse stands out as the top job portal because it offers a comprehensive set of tools and resources to enhance your job search experience. Here’s why:
      </p>
      <ul>
        <li>Access to a wide range of job listings across various industries.</li>
        <li>Advanced search filters to find jobs that match your skills and preferences.</li>
        <li>Personalized job recommendations based on your profile and search history.</li>
        <li>Easy application process with resume and cover letter submission.</li>
      </ul>
    </Accordion.Body>
  </Accordion.Item>

  <Accordion.Item eventKey="1">
    <Accordion.Header>
      How to Use MyFuse to Find Your Ideal Job
    </Accordion.Header>
    <Accordion.Body>
      <p>
        Getting started on MyFuse is simple. Follow these steps to find your dream job:
      </p>
      <ol>
        <li>Sign up and create a detailed profile with your skills and experience.</li>
        <li>Use the advanced search options to filter job listings by location, industry, and job type.</li>
        <li>Set up job alerts to receive notifications for new job postings that match your criteria.</li>
        <li>Apply to jobs directly through the portal with your saved resume and cover letter.</li>
      </ol>
    </Accordion.Body>
  </Accordion.Item>

  <Accordion.Item eventKey="2">
    <Accordion.Header>
      Tips for Creating a Standout Profile on MyFuse
    </Accordion.Header>
    <Accordion.Body>
      <h6>Profile Optimization:</h6>
      <ol>
        <li>
          Upload a professional photo and ensure your profile is complete and up-to-date.
        </li>
        <li>
          Highlight your key skills and achievements to attract potential employers.
        </li>
        <li>
          Include detailed descriptions of your past roles and responsibilities to showcase your experience.
        </li>
        <li>
          Regularly update your profile to reflect any new skills or accomplishments.
        </li>
      </ol>
    </Accordion.Body>
  </Accordion.Item>

  <Accordion.Item eventKey="3">
    <Accordion.Header>
      How Does MyFuse's Job Matching Algorithm Work?
    </Accordion.Header>
    <Accordion.Body>
      <p>
        MyFuse uses a sophisticated job matching algorithm to connect you with the most relevant job opportunities. It analyzes your profile details, including skills, experience, and job preferences, and compares them with job listings to suggest the best matches for you.
      </p>
    </Accordion.Body>
  </Accordion.Item>

  <Accordion.Item eventKey="4">
    <Accordion.Header>
      What Makes MyFuse the Top Choice for Employers?
    </Accordion.Header>
    <Accordion.Body>
      <p>
        Employers choose MyFuse for its extensive reach and advanced features. Here’s why:
      </p>
      <ul>
        <li>Access to a large pool of qualified candidates.</li>
        <li>Advanced filtering options to find candidates that meet specific job requirements.</li>
        <li>Easy-to-use interface for posting job listings and managing applications.</li>
        <li>Analytics and insights to track the performance of job postings.</li>
      </ul>
    </Accordion.Body>
  </Accordion.Item>

  <Accordion.Item eventKey="5">
    <Accordion.Header>
      How Can Employers Optimize Their Job Listings on MyFuse?
    </Accordion.Header>
    <Accordion.Body>
      <p>
        To attract the best candidates, employers should:
      </p>
      <ul>
        <li>Write clear and concise job descriptions with specific requirements.</li>
        <li>Use relevant keywords to improve visibility in search results.</li>
        <li>Include information about company culture and benefits to appeal to potential candidates.</li>
        <li>Regularly update job postings to ensure they remain current and relevant.</li>
      </ul>
    </Accordion.Body>
  </Accordion.Item>

  <Accordion.Item eventKey="6">
    <Accordion.Header>
      What Additional Resources Does MyFuse Offer?
    </Accordion.Header>
    <Accordion.Body>
      <p>
        MyFuse provides various resources to support both job seekers and employers:
      </p>
      <ul>
        <li>Career advice and resume writing tips.</li>
        <li>Interview preparation guides and practice questions.</li>
        <li>Networking opportunities through webinars and industry events.</li>
        <li>Customer support for any issues or inquiries regarding the platform.</li>
      </ul>
    </Accordion.Body>
  </Accordion.Item>
</Accordion>

      </div> 
     
    </div>
  </div>
  )
}

export default Faq