import html2canvas from "html2canvas";

export const htmlToCanvas = async (element) => {
  return html2canvas(element, {
    allowTaint: true,
    useCORS: true,
    scrollX: -window.scrollX,
    scrollY: -window.scrollY,
  }).then(function (canvas) {
    return canvas;
  });
};

export const htmlToCanvasImage = async (element , payment) => {
  // Generate the HTML canvas using html2canvas
  const htmlCanvas = await html2canvas(element, {
    allowTaint: true,
    useCORS: true,
    scrollX: -window.scrollX,
    scrollY: -window.scrollY,
  });

   
  // Get the width and height of the original canvas
  const width = htmlCanvas.width;
  const height = htmlCanvas.height;

  // Create a new canvas element to draw the image with a watermark
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');

  // Draw the original htmlCanvas content onto the new canvas
  ctx.drawImage(htmlCanvas, 0, 0, width, height);

  // Add watermark text
  const watermarkText = "MyFuse.In";
  ctx.font = `${Math.floor(width / 10)}px Arial`; // Scale the font size based on canvas width
  ctx.fillStyle = "rgba(0, 0, 0, 0.06)"; // Darker semi-transparent watermark (e.g., 20% opacity)

  ctx.textAlign = "center";
  ctx.textBaseline = "middle"; 

  // Rotate the context to apply the rotated watermark
  for (let i = -1; i <= 1; i++) {
    for (let j = -1; j <= 1; j++) {
      ctx.save();
      ctx.translate(width / 2 + i * width / 3, height / 2 + j * height / 3);
      ctx.rotate(-Math.PI / 4);
      ctx.fillText(watermarkText, 0, 0);
      ctx.restore();
    }
  }
 

  if(payment?.paymentStatus !=="success" )
  {
    const upgradeText = "Upgrade To Remove";
    ctx.font = `${Math.floor(width /35)}px Arial`; 
  
    
    const textWidth = ctx.measureText(upgradeText).width;
    const textHeight = Math.floor(width / 20);  
    const padding = 15;  
    const borderRadius = 15;  
  
  const gradient = ctx.createLinearGradient(0, 0, width, 0);  
  gradient.addColorStop(0.16, "rgb(160, 102, 255)");
  gradient.addColorStop(0.88, "rgb(102, 108, 255)");
  
    ctx.fillStyle = gradient;
    ctx.beginPath();
    ctx.moveTo((width - textWidth) / 2 - padding + borderRadius, (height - textHeight) / 2 - padding);
    ctx.arcTo((width + textWidth) / 2 + padding, (height - textHeight) / 2 - padding, (width + textWidth) / 2 + padding, (height + textHeight) / 2 + padding, borderRadius);
    ctx.arcTo((width + textWidth) / 2 + padding, (height + textHeight) / 2 + padding, (width - textWidth) / 2 - padding, (height + textHeight) / 2 + padding, borderRadius);
    ctx.arcTo((width - textWidth) / 2 - padding, (height + textHeight) / 2 + padding, (width - textWidth) / 2 - padding, (height - textHeight) / 2 - padding, borderRadius);
    ctx.arcTo((width - textWidth) / 2 - padding, (height - textHeight) / 2 - padding, (width + textWidth) / 2 + padding, (height - textHeight) / 2 - padding, borderRadius);
    ctx.closePath();
    ctx.fill();
  
     
    ctx.fillStyle = "white";  
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(upgradeText, width / 2, height / 2);  
  }
 

  // Convert the canvas to a data URL
  const img = canvas.toDataURL('image/png');
  return img;
};
