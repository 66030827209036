import React, { useState } from 'react';
import axios from "axios";

const PdfToHtmlConverter = () => {
  const [htmlContent, setHtmlContent] = useState('');

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('pdf', file);

    try {
      const response = await axios.post('http://localhost:8085/pdf/uploadResume', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setHtmlContent(response.data);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div>
      <h1>Upload PDF and Convert to HTML</h1>
      <input type="file" accept="application/pdf" onChange={handleFileUpload} />
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};

export default PdfToHtmlConverter;
