import React from 'react'
import MyResponsiveNavbar from './Navbar'
import ChooseTemplate from '../ResumeBuilder/builder/components/templates/ChooseTemplate/ChooseTemplate' 
import BottomBar from '../components/BottomBar';
import HelmetConfig from '../components/HelmetConfig';

const ChoseTemplate = () => {
  return ( 
    <>
     <HelmetConfig
  title="MyFuse.in-Resume Examples"
  description="Explore our wide range of resume examples on MyFuse.in. Find the perfect resume template for your profession and learn how to create an outstanding resume that stands out. Our resume examples cover various industries, helping you build a strong professional profile."
  keywords="resume examples, professional resume samples, industry-specific resumes, job-specific resumes, resume templates, career guidance, MyFuse.in resume builder, resume writing tips, resume formatting, resume templates examples, job search, career development"
  canonical="https://myfuse.in/resume-examples"
/>

    <MyResponsiveNavbar/>
    <ChooseTemplate/>
     <BottomBar/>
    </>
    
  )
}

export default ChoseTemplate