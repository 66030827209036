import React, { useState } from 'react'
import styles from '../../assets/ResumeScore/HeaderModal.module.css'
import { motion, useScroll, useTransform, AnimatePresence } from 'framer-motion';

const Header = () => {
    const { scrollY } = useScroll();
    const headerOpacity = useTransform(scrollY, [0, 100], [1, 0.8]);
    const headerY = useTransform(scrollY, [0, 100], [0, -20]);
    const [extractedText, setExtractedText] = useState("");
  return (
    <div>
        <motion.div 
          className={styles.headerSection}
          
        >
          {/* Background Gradient Elements */}
          <div className={styles.backgroundElements}>
            <div className={styles.gradientOrb1} />
            <div className={styles.gradientOrb2} />
            <div className={styles.gridPattern} />
          </div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className={styles.headerContent}
          >
            {/* Hero Section */}
            <div className={styles.heroWrapper}>
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className={styles.heroLeft}
              >
                <div className={styles.titleWrapper}>
                  <h1 className={styles.mainTitle}>
                    <span className={styles.gradientText}>AI-Powered</span>
                    <br />
                    Resume Analysis
                  </h1>
                  <div className={styles.titleAccent} />
                </div>
                
                <p className={styles.subtitle}>
                  Transform your resume with advanced ATS optimization and 
                  professional insights powered by AI technology
                </p>

                <div className={styles.statsGrid}>
                  <div className={styles.statCard}>
                    <div className={styles.statValue}>98%</div>
                    <div className={styles.statLabel}>Accuracy Rate</div>
                    <div className={styles.statProgress} />
                  </div>
                  <div className={styles.statCard}>
                    <div className={styles.statValue}>10K+</div>
                    <div className={styles.statLabel}>Resumes Analyzed</div>
                    <div className={styles.statProgress} />
                  </div>
                  <div className={styles.statCard}>
                    <div className={styles.statValue}>24/7</div>
                    <div className={styles.statLabel}>AI Support</div>
                    <div className={styles.statProgress} />
                  </div>
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                className={styles.heroRight}
              >
                <div className={styles.imageWrapper}>
                  <motion.div
                    animate={{ 
                      boxShadow: [
                        "0 0 20px rgba(80, 63, 205, 0.3)",
                        "0 0 40px rgba(80, 63, 205, 0.2)",
                        "0 0 20px rgba(80, 63, 205, 0.3)"
                      ]
                    }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: "easeInOut"
                    }}
                    className={styles.imageContainer}
                  >
                    <motion.img 
                      src={require('./low score.gif')} 
                      alt="Resume Analysis Demo" 
                      className={styles.heroImage}
                      whileHover={{ scale: 1.02 }}
                      transition={{ duration: 0.3 }}
                    />
                  </motion.div>
                  <div className={styles.decorativeElement} />
                </div>
              </motion.div>
            </div>
          </motion.div>
        </motion.div>
    </div>
  )
}

export default Header