import React from 'react'
import MyResponsiveNavbar from './Navbar'
import Footer from './Footer'
import BottomBar from '../components/BottomBar'

const Chat = () => {
  return (
    <div>
    <div className='contianer'  > 
      <MyResponsiveNavbar/>
      <div className='row d-flex justify-content-center'  style={{width:'100vw' ,  height:'100vh', backgroundColor:'#fafbfd'}}>
         <div className='col-10 d-flex justify-content-center flex-column align-items-center '    >
         <img  src='https://i.pinimg.com/originals/ec/be/42/ecbe42c7275ac408965cd8d51c0cfda9.gif' className='img-fluid' style={{height:'15rem' , width:'14rem'}} alt='dd'/>
             
             <span className='d-flex justify-content-center flex-row '>
             <h2 className='text-center' style={{fontSize:'20px'}}>🚫💬 No chat, no problem! Apply for the job and let your application shine! 😄📋</h2>
             
             </span>
         </div>
        
      </div>
     </div> 
    <Footer/>
    <BottomBar/>
    </div>
  )
}

export default Chat