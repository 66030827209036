import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "../../assets/JobResume.module.css";
import { ArrowRight, Bookmark, Lock } from "lucide-react";
import { useResume } from "./components/context/Resume";
import { motion, AnimatePresence } from "framer-motion";
import { useJobs } from "./components/context/Jobs";
import Model from "../../components/Model";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const jobCard = {
  hidden: { 
    opacity: 0,
    y: 30,
    scale: 0.8
  },
  show: { 
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8
    }
  }
};

const upgradeContainer = {
  hidden: { opacity: 0, y: 20 },
  show: { 
    opacity: 1, 
    y: 0,
    transition: {
      delay: 0.3,
      duration: 0.5
    }
  }
};

const Jobs = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const datalocal = JSON.parse(localStorage.getItem("auth"));
  const [skillsFromDb, setSkillsFromDb] = useState('');
  const { values } = useResume();
  const { payment } = useJobs();
  const [modalShow, setModalShow] = useState(false);
  
  // Optimize skills extraction
  const getResumeSkills = () => {
    const skills = values?.sections?.technicalSkills?.data?.[0]?.skills;
    return Array.isArray(skills) ? skills.join(',') : 'react';
  };

  // Fetch user skills from DB
  const getUserSkill = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/skillsbyid`,
        {
          headers: {
            Authorization: `Bearer ${datalocal?.token}`,
          },
        }
      );

      setSkillsFromDb(data?.skillsfromdb?.[0]?.skills || '');
    } catch (error) {
      console.error("Error fetching user skills:", error);
      setSkillsFromDb('');
    }
  };

  // Fetch all jobs based on merged skills
  const getAllJobs = async (mergedSkills) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/filterjob?skills=${mergedSkills}`,
        {
          headers: {
            Authorization: `Bearer ${datalocal?.token}`,
          },
        }
      );
      
      if (data?.success && data.jobs) {
        const sortedJobs = [...data.jobs].sort(
          (a, b) => b.percentageMatched - a.percentageMatched
        );
        setJobs(sortedJobs);
      } else {
        setJobs([]);
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setJobs([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    
    const fetchData = async () => {
      await getUserSkill();
      const resumeSkills = getResumeSkills();
      const mergedSkills = [resumeSkills, skillsFromDb].filter(Boolean).join(',');
      await getAllJobs(mergedSkills);
    };

    fetchData();

    return () => {
      controller.abort(); // Cleanup pending requests
    };
  }, [values]); // Only re-run when resume values change

  const handleNavigate = (id) => {
    window.open(`/jobs/id=${id}`, "_blank");
  };


  const calculateTimeAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    const secondsInMinute = 60;
    const secondsInHour = 60 * secondsInMinute;
    const secondsInDay = 24 * secondsInHour;

    if (diffInSeconds < secondsInMinute) {
      return `${diffInSeconds} seconds ago`;
    } else if (diffInSeconds < secondsInHour) {
      const minutes = Math.floor(diffInSeconds / secondsInMinute);
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < secondsInDay) {
      const hours = Math.floor(diffInSeconds / secondsInHour);
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(diffInSeconds / secondsInDay);
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    }
  };

  const handleUpgrade = () => {
    console.log("Navigate to upgrade page");
  };

  return (
    <div className={styles.main}>
      <div className={styles.headerheading}>
        <div className={styles.heading}>Jobs For You</div>
        <div className={styles.recomandedJobs}>
          <span className={styles.recomandedJobscontainer}>
            {jobs.length} recommendations for you
            {!payment?.paymentStatus === "success" && jobs.length > 2 && (
              <span className={styles.lockedJobs}>
                ({jobs.length - 2} locked)
              </span>
            )}
          </span>
        </div>
      </div>

      <motion.div 
        className={styles.jobCardContainer}
        variants={container}
        initial="hidden"
        animate="show"
      >
        <AnimatePresence>
          {jobs.slice(0, payment?.paymentStatus === "success" ? jobs.length : 2).map((ele, index) => (
            <motion.div
              className={styles.singleContainer}
              key={index}
              variants={jobCard}
              whileHover={{ 
                scale: 1.02,
                y: -5,
                boxShadow: "0px 8px 25px rgba(0,0,0,0.1)"
              }}
              layout
            >
              <motion.div 
                className={styles.percentandlogocontainer}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.2 }}
              >
                <motion.div 
                  className={styles.percentcontainer}
                  whileHover={{ 
                    scale: 1.1,
                    rotate: [0, -5, 5, 0],
                    transition: { duration: 0.3 }
                  }}
                >
                  <div className={styles.percent}>
                    {ele.percentageMatched}% Match
                  </div>
                </motion.div>
                <motion.div 
                  className={styles.logocontainer}
                  whileHover={{ 
                    scale: 1.15,
                    transition: { duration: 0.2 }
                  }}
                >
                  <motion.div 
                    className={styles.logo}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.3 }}
                  >
                    <img src={`https://old.myfuse.in/uploads/logo/${ele.companyLogo}`} alt="companyLogo" />
                  </motion.div>
                </motion.div>
              </motion.div>
              
              <motion.div 
                className={styles.companyandjobroleContainer}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4 }}
              >
                <motion.div 
                  className={styles.jobtitle}
                  whileHover={{ x: 5 }}
                >
                  {ele.jobtitle}
                </motion.div>
                <div className={styles.Companayname}>
                  {ele.ompanyName}
                </div>
              </motion.div>
              
              <motion.div 
                className={styles.detailsandApplyNow}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
              >
                <div className={styles.BasicDetails}>
                  {calculateTimeAgo(ele.createdat)} | 0-1Y | {ele.location}
                </div>
                <div className={styles.saveandappplyButton}>
                  <motion.div
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.8 }}
                  >
                    <Bookmark size={16} color={"#909AB6"}/>
                  </motion.div>
                  <motion.span 
                    className={styles.applynowbutton}
                    whileHover={{ 
                      scale: 1.05,
                      backgroundColor: "#8061DD",
                      color: "white"
                    }}
                    whileTap={{ scale: 0.95 }}
                  > 
                    Apply <ArrowRight size={14} />  
                  </motion.span>
                </div>
              </motion.div>
            </motion.div>
          ))}
        </AnimatePresence>

        {/* Upgrade Container */}
        {payment?.paymentStatus !== "success" && jobs.length > 2 && (
          <motion.div 
            className={styles.upgradeContainer}
            variants={upgradeContainer}
            initial="hidden"
            animate="show"
            onClick={handleUpgrade}
          >
            <Lock size={24} />
            <h3>Unlock {jobs.length - 2} More Jobs</h3>
            <p>Upgrade to Premium to view all matching jobs</p>
            <motion.button 
              className={styles.upgradeButton}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setModalShow(true)}
            >
              Upgrade Now
            </motion.button>
          </motion.div>
        )}
      </motion.div>

      <Model modalShow={modalShow} setModalShow={setModalShow} />

    </div>
  );
};

export default Jobs;
