import React from 'react'
import styles from "../../assets/Account.module.css";
const Last = () => {
  return (
    <div className={styles.lastmain}>
        <div className={styles.last}>
             <img  src='https://www.propeers.in/_next/image?url=%2Fimages%2Fseek-guidance-illustration.png&w=640&q=75' alt='img' className='img-fluid'/>
           <p className={styles.para} >Wondering how to upgrade your job? Ready to take the next step?</p>
        </div>
    </div>
  )
}

export default Last