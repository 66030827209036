import React from 'react'
import MyResponsiveNavbar from './Navbar'
import HelmetConfig from '../components/HelmetConfig'

const Contactus = () => {
  return ( 

    <div>


        <HelmetConfig
          title="Contact Us | MyFuse - We're Here to Help You Succeed"
          description="Get in touch with the MyFuse team for support, inquiries, or feedback. We're
here to assist you in achieving your career goals."
          keywords="
          Contact Myfuse, Myfuse Customer Support, Get in Touch with Myfuse, Myfuse.in Contact Details, Contact Information for Myfuse,
          How to Contact Myfuse Support Team, Best Ways to Reach Myfuse.in, Myfuse Customer Service Number,
          How to Email Myfuse Support, Contact Myfuse for Job Assistance,
          Contact Us, Customer Support, Help Desk, Get in Touch, Contact Details,
          Support Team, Customer Service, Contact Information, Reach Us, Help and Support,
          Myfuse.in Customer Assistance, How to Get Help from Myfuse, Myfuse Contact Page,
          How to Connect with Myfuse, Customer Service at Myfuse.in,
          Myfuse Support and Contact Information, Best Contact Methods for Myfuse.in, Myfuse Help Desk Services,
          How to Resolve Issues with Myfuse, Contacting Myfuse for Job Inquiries,
          Support Contact, Customer Queries, Contact Myfuse Directly, Customer Helpline, Myfuse Customer Care
        "
          canonical="https://myfuse.in/contactus"
        />

        <MyResponsiveNavbar/> 



        <div
      style={{
        fontFamily: 'Arial, sans-serif',
        lineHeight: 1.6,
        padding: '20px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        maxWidth: '600px',
        margin: '20px auto',
        backgroundColor: '#f9f9f9',
      }}
    >
      <h2
        style={{
          color: '#333',
          borderBottom: '2px solid #ddd',
          paddingBottom: '10px',
        }}
      >
        Merchant Information
      </h2>
      <p
        style={{
          color: '#555',
          fontSize: '14px',
          marginBottom: '10px',
        }}
      >
        <strong>Legal Entity Name:</strong> 245 INTERACTIVE SUP TECH PRIVATE LIMITED
      </p>
      <p
        style={{
          color: '#555',
          fontSize: '14px',
          marginBottom: '10px',
        }}
      >
        <strong>Operational Address:</strong> Myfuse, Wework Galaxy, 43, Residency Road, Shanthala Nagar, Ashok Nagar, Bangalore, Karnataka, PIN: 560025
      </p>
      <p
        style={{
          color: '#555',
          fontSize: '14px',
          marginBottom: '10px',
        }}
      >
        <strong>Telephone No:</strong> 7975364977
      </p>
      <p
        style={{
          color: '#555',
          fontSize: '14px',
        }}
      >
        <strong>E-Mail ID:</strong> <a href="mailto:info.myfuse@gmail.com" style={{ color: '#0066cc' }}>info.myfuse@gmail.com</a>
      </p>
    </div>

 

    </div>
  )
}

export default Contactus