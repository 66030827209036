import MyResponsiveNavbar from "./Navbar";
import Footer from "./Footer";
import styles from "../assets/Jobs2.module.css";
import Left from "../components/JobsDetails/Left";
import Right from "../components/JobsDetails/Right";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";
import Bottom from "../components/JobsDetails/Bottom";
import HelmetConfig from "../components/HelmetConfig";
const Jobs2 = () => {
 
  const { slug } = useParams();

  const parts = slug.split("-");
  const id = parts.pop(); // Last part
  const rest = parts.join("-"); // Everything before the last hyphen
  const [jobDetailsSingle, setJobDetalils] = useState([]);
  const jobDetails = async (id) => {
    try {
     
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/jobbyid?id=${id}`
      );
      
      if (data?.success) {
        setJobDetalils(data.users);
      }
    
      window.scrollTo(0, 0);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    jobDetails(id);
  }, [id]);

  return (
    <>
      <MyResponsiveNavbar />
      <HelmetConfig
        title={rest}
        description="Explore the sitemap of MyFuse to find pages and navigate through our website effortlessly. Learn how to use the sitemap for easy navigation and discover the structure of Myfuse.in."
        keywords="
          Myfuse Sitemap, Website Sitemap Myfuse.in, Myfuse Site Map, Sitemap for Myfuse, Myfuse Web Sitemap,
          Sitemap of Myfuse.in for Easy Navigation, How to Use Myfuse Sitemap, Myfuse Sitemap Structure, Website Navigation through Myfuse Sitemap, Find Your Way on Myfuse.in,
          Sitemap, Website Map, Site Structure, Navigation Map, Site Map,
          Web Navigation, Page Index, Website Layout, Website Directory, Site Links,
          Myfuse Site Directory, Navigating Myfuse with Sitemap, Myfuse Sitemap Page, Complete Sitemap Myfuse.in, Website Pathway Myfuse,
          How to Find Pages on Myfuse.in, Myfuse Sitemap Overview, Using Sitemap to Navigate Myfuse, Detailed Sitemap of Myfuse.in, Myfuse Sitemap for Better User Experience,
          Site Navigation Tool, Web Sitemap Guide, Myfuse Web Structure, Navigation Directory, Sitemap Usage
        "
        canonical="https://myfuse.in/sitemap"
      />
      <div className={styles.topContianer}>
        <div className={styles.textcontainer}>
          <span className={styles.mainhe}>
            Let AI Create Your Resume With This Job Role
          </span>
          <span className={styles.paragr}>
            Get a personalized resume for each job role and increase
          </span>
          <span className={styles.paragr}>
            your chances of getting shortlisted.
          </span>
        </div>
        <div className={styles.imgsection}>
          <img
            src="https://res.cloudinary.com/dvepwlz9j/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1720372753/360_F_194445131_0su58xS5YVSYqIjwbYmdbmBQrZD8d0HU-removebg-preview_k92tpq.png"
            className={`  ${styles.topimage}`}
            alt="FS"
          />
        </div>
      </div>

      <div className={styles.maincontainer}>
        <div className={styles.leftcontainer}>
          <Left jobDetailsSingle={jobDetailsSingle} />
        </div>
        x
        <div className={styles.Rightcontainer}>
          <Right slug={slug} jobDetailsSingle={jobDetailsSingle} />
        </div>
      </div>
      <Bottom jobDetailsSingle={jobDetailsSingle} />
      <Footer />
    </>
  );
};

export default Jobs2;
