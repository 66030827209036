import { useState,  useContext, createContext} from "react"; 

const JobContext = createContext();


const JobProvider = ({children}) =>{
    const [CommingJobs , setComminJobs] = useState(null); 
    const [selected, setSelected] = useState('1');
    const [isfirst,setIsfirst] = useState(false);  
    const [activeResume , setActiveResume] = useState(1) ; 
    const [countScore , setCountScore] = useState(0); 
    const [sectionresume , setSectionResume] = useState("") 
    const [previewResume , setPreviewImage] = useState()
     const [payment , setPayment] = useState({
        paymentStart:"" ,
        paymentEnd :"",
        paymentStatus:"" ,
        paymentAmount:"",
        produceName:""

    }); 
    return (
        <JobContext.Provider value={{previewResume , setPreviewImage,setPayment,payment, setCountScore,countScore,CommingJobs ,selected, setSelected,setComminJobs , isfirst , setIsfirst , activeResume , setActiveResume ,sectionresume , setSectionResume}}>{children}</JobContext.Provider>
    )
}


export  const useJobs = () =>useContext(JobContext);
export default JobProvider;