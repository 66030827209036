import React, { useEffect } from "react";
import MyResponsiveNavbar from "../Navbar";
import axios from "axios";
import { useJobs } from "../../ResumeBuilder/builder/components/context/Jobs";
import styles from "../../assets/Success.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
const Success = () => {
  let { setPayment } = useJobs();
  const location = useLocation();
  let [status, setStatus] = useState(false);
  const Navigate = useNavigate();
  const datalocal = JSON.parse(localStorage.getItem("auth"));  

   const getOrderId = () =>{
    const queryParams = new URLSearchParams(location.search);
    let extractedOrderId = queryParams.get("order_id"); 
    return extractedOrderId
   }

  const getData = async (authData) => {
    if (!authData?.token) {
      console.error("No auth token found");
      return;
    } 
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/checkprimiumall`,
        {orderId:getOrderId()},
        {
          headers: {
            Authorization: `Bearer ${authData.token}`,
          },
        }
      );
      setStatus(response?.data?.success || false);
      setPayment({
        paymentStart: response?.data?.user[0]?.date_joined || "",
        paymentEnd: response?.data?.user[0]?.date_end || "",
        paymentStatus: response?.data?.user[0]?.status || "",
        paymentAmount: response?.data?.user[0]?.amount || 0,
        produceName: response?.data?.user[0]?.product_name || "",
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    getData(datalocal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MyResponsiveNavbar />
      {status ? (
        <div className={styles.main}>
          <div className={styles.dldo}>
            Payment Successful
            <img
              src="https://i.pinimg.com/originals/74/2f/7e/742f7ea29cbfd7fd3f4848f17e621070.gif"
              className="img-fluid"
              style={{ maxHeight: "4rem", maxWidth: "4rem" }}
              alt="s"
            />
          </div>
          <img
            src="https://cdn.dribbble.com/users/614270/screenshots/14575431/media/4907a0869e9ed2ac4e2d1c2beaf9f012.gif"
            className="img-fluid"
            style={{ maxHeight: "18rem" }}
            alt="s"
          />

          <div
            className={styles.ddl}
            onClick={() => Navigate("/welcome/dashboard")}
          >
            Explore Primium Content
          </div>
        </div>
      ) : (
        <section className={styles.page_404}>
          <div className={styles.container}>
            <div className="row">
              <div className="col-sm-12 d-flex justify-content-center">
                <div className="col-sm-12 col-sm-offset-1  text-center">
                  <div className={styles.four_zero_four_bg}></div>

                  <div className={styles.contant_box_404}>
                    <h3 className="h2 text-danger">
                      Look like Your Payment Failed
                    </h3>

                    <p>Please Contact Adminstrator </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Success;
