import React, { useDebugValue, useEffect } from "react";

// Build
import IITBResumeBuild from "../templates/IITB/IITBResumeBuild";
import IITDResumeBuild from "../templates/IITD/IITDResumeBuild";
import IITKResumeBuild from "../templates/IITK/IITKResumeBuild";
import IITNResumeBuild from "../templates/IITN/IITNResumeBuild";
import IITGResumeBuild from "../templates/IITG/IITGResumeBuild";
import IITAResumeBuild from "../templates/IITA/IITAResumeBuild";
import IITLResumeBuild from "../templates/IITL/IITLResumeBuild";
import IITCResumeBuild from "../templates/IITC/IITCResumeBuild";
import IITEResumeBuild from "../templates/IITE/IITEResumeBuild";
import MITResumeBuild from "../templates/MIT/MITResumeBuild";
import LLKResumeBuild from "../templates/LLK/LLKResumeBuild";
import LLLResumeBuild from "../templates/LLL/LLLResumeBuild";

//Resume
import IITBResume from "../templates/IITB/IITBResume";
import IITDResume from "../templates/IITD/IITDResume";
import IITKResume from "../templates/IITK/IITKResume";
import IITNResume from "../templates/IITN/IITNResume";
import IITGResume from "../templates/IITG/IITGResume";
import IITAResume from "../templates/IITA/IITAResume";
import IITLResume from "../templates/IITL/IITLResume";
import IITCResume from "../templates/IITC/IITCResume";
import IITEResume from "../templates/IITE/IITEResume";
import MITResume from "../templates/MIT/MITResume";
import LLKResume from "../templates/LLK/LLKResume";
import LLLResume from "../templates/LLL/LLLResume";

// Preview Image
import IITBPreviewImage from "../../../assets/images/temp2.png";
import IITDPreviewImage from "../../../assets/images/IIT.png";
import IITKPreviewImage from "../../../assets/images/IITK.png";
import IITNPreviewImage from "../../../assets/images/IITN.png";
import IITAPreviewImage from "../../../assets/images/IITA.png";
import IITLPreviewImage from "../../../assets/images/IITL.png";
import IITCPreviewImage from "../../../assets/images/IITC.png";
import IITEPreviewImage from "../../../assets/images/latest.png";
import IITLLPreviewImage from "../../../assets/images/iitll.png";
import MITPreviewImage from "../../../assets/images/NEW.png";
import LLKPreviewImage from "../../../assets/images/Fresher.png";
import LLLPreviewImage from "../../../assets/images/exp.png";
import axios from "axios";
import { useAuth } from "./auth";

const ResumeContext = React.createContext();

export const useResume = () => React.useContext(ResumeContext);

const configResume = {
  name: "Untitled",
  sections: {
    profile: {
      name: "Profile",
      key: "sections.profile",
      data: {},
    },
    education: {
      name: "Education",
      key: "sections.education",
      data: [],
    },
    experience: {
      name: "Experience",
      key: "sections.experience",
      data: [],
    },
    projects: {
      name: "Projects",
      key: "sections.projects",
      data: [],
    },
    sportsProgramming: {
      name: "Sports Programming",
      key: "sections.sportsProgramming",
      data: [],
    },
    technicalSkills: {
      name: "Technical Skills",
      key: "sections.technicalSkills",
      data: [],
    },
    Professionalsummary: {
      name: "Professional Summary",
      key: "sections.Professionalsummary",
      data: [],
    },
    extraCurriculars: {
      name: "Extra Curriculars",
      key: "sections.extraCurriculars",
      data: [],
    },
    achievements: {
      name: "Achievements",
      key: "sections.achievements",
      data: [],
    },
    positionsOfResponsibility: {
      name: "Positions of Responsibility",
      key: "sections.positionsOfResponsibility",
      data: [],
    },
    awards: {
      name: "Awards",
      key: "sections.awards",
      data: [],
    },
    optional: {
      name: "Optional",
      key: "sections.optional",
      data: [],
    },
    settings: {
      name: "Settings",
      key: "sections.settings",
      data: {},
    },
   
  },
};

const mergeDataIntoConfig = (data) => {
  // console.log(data);
  const config = { ...configResume };
  if (!data) return config;
  // for every key in config, get the data from data object
  // and merge it into config
  if (data.name) config.name = data.name;
  if (!data.sections) return config;
  for (let key in config.sections) {
    // check if data is object or list of objects
    if (!data.sections[key]) continue;
    if (Array.isArray(data.sections[key].data)) {
      let temp = true;
      data.sections[key].data.forEach((item) => {
        if (typeof item !== "object") {
          temp = false;
        }
      });
      if (temp) {
        config.sections[key] = data.sections[key];
      }
    } else if (typeof data.sections[key].data === "object") {
      config.sections[key] = data.sections[key];
    }
  }
  // console.log(config);
  return config;
};

const ResumeProvider = ({ children }) => {
  const templates = {
    iitb: {
      name: "Elegant",
      key: "iitb",
      build: IITBResumeBuild,
      resume: IITBResume,
      page_margins: "0.5in",
      preview: IITBPreviewImage,
    },
    // iitl: {
    //   name: "Professional",
    //   key: "iitl",
    //   build: IITLResumeBuild,
    //   resume: IITLResume,
    //   page_margins: "0.5in",
    //   preview: IITLPreviewImage,
    // },
    iite: {
      name: "Latest",
      key: "iite",
      build: IITEResumeBuild,
      resume: IITEResume,
      page_margins: "0.5in",
      preview: IITEPreviewImage,
    },
    // iitc: {
    //   name: "Experienced",
    //   key: "iitc",
    //   build: IITCResumeBuild,
    //   resume: IITCResume,
    //   page_margins: "0.5in",
    //   preview: IITCPreviewImage,
    // },
    // iitd: {
    //   name: "Polished",
    //   key: "iitd",
    //   build: IITDResumeBuild,
    //   resume: IITDResume,
    //   page_margins: "0.5in",
    //   preview: IITDPreviewImage,
    // },

    // iita: {
    //   name: "Modern",
    //   key: "iita",
    //   build: IITAResumeBuild,
    //   resume: IITAResume,
    //   page_margins: "0.5in",
    //   preview: IITAPreviewImage,
    // },
    // iitn: {
    //   name: "Stylish",
    //   key: "iitn",
    //   build: IITNResumeBuild,
    //   resume: IITNResume,
    //   page_margins: "0.5in",
    //   preview: IITNPreviewImage,
    // },
    iitg: {
      name: "Multiple",
      key: "iitg",
      build: IITGResumeBuild,
      resume: IITGResume,
      page_margins: "0.5in",
      preview: IITLLPreviewImage,
    },
      
    mit: {
      name: "Finished",
      key: "mit",
      build: MITResumeBuild,
      resume: MITResume,
      page_margins: "0.5in",
      preview: MITPreviewImage,
    },
    llk: {
      name: "Fresher",
      key: "llk",
      build: LLKResumeBuild,
      resume: LLKResume,
      page_margins: "0.5in",
      preview: LLKPreviewImage,
    },
    lll: {
      name: "Experience2+",
      key: "lll",
      build: LLLResumeBuild,
      resume: LLLResume,
      page_margins: "0.5in",
      preview: LLLPreviewImage,
    },
  };

  const [selectedTemplate, setSelectedTemplate] = React.useState(templates.lll);
  const [values, setValues] = React.useState(
    mergeDataIntoConfig(JSON.parse(localStorage.getItem("resume")))
  );
  const { auth } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [index, setIndex] = React.useState("");
  const [CurentSection, SetCurentSection] = React.useState("Personal Details");
  const [sectionsArray, setSectionsArray] = React.useState([]);
  const [nextSection, setNextSection] = React.useState("");
  const [prevSection, setPrevSection] = React.useState("");
  const [currentStep, setCurrentStep] = React.useState({ current: 1, total: 0 }); 
  const printContainerRef = React.useRef(null);

  const [ai, setAi] = React.useState("");
  const [visitedSections, setVisitedSections] = React.useState(new Set());

  const handlePhoto = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    try {
      const result = await new Promise((resolve, reject) => {
        reader.onload = (e_1) => {
          resolve(e_1.target.result);
        };
        reader.onerror = (err) => {
          reject(err);
        };
      });
      setLoading(false);
      return result;
    } catch (err_1) {
      setLoading(false);
      return null;
    }
  };

  const handleResumeChange = (field, value) => {
    setLoading(true);
    setValues((prev) => {
      let temp = prev;
      const navigate = field.split(".");
      navigate.forEach((key, index) => {
        if (index === navigate.length - 1) {
          if (Number.isInteger(parseInt(key))) {
            console.log(parseInt(key));
            temp[parseInt(key)] = value;
          } else {
            temp[key] = value;
          }
        } else {
          if (Number.isInteger(parseInt(key))) {
            temp = temp[parseInt(key)];
          } else {
            temp = temp[key];
          }
        }
      });
      return { ...prev };
    });
    setLoading(false);
  };

  const get = (field) => { 
    const navigate = field.split(".");
    let temp = values;
    navigate.forEach((key) => {
      if (!temp) return null;
      if (Number.isInteger(parseInt(key))) {
        temp = temp[parseInt(key)];
      } else {
        temp = temp[key];
      }
    });
    return temp;
  };

  const handleTemplateChange = (templateKey) => {
    if (!templateKey) setSelectedTemplate(null);
    else {
      setSelectedTemplate(templates[templateKey]);
      setCurrentStep(prev => ({ ...prev, current: 1 }));
    }
  };

  const handleSectionChange = (key) => { 
    const currentIndex = sectionsArray.indexOf(CurentSection);
    
    if (key === "next" && currentIndex < sectionsArray.length - 1) {
      const nextSectionName = sectionsArray[currentIndex + 1];
      
      let sectionKey;
      if (nextSectionName === 'Skills') {
        sectionKey = 'technicalSkills';
      } else if (nextSectionName === 'Certifications') {
        sectionKey = 'awards';
      } else {
        sectionKey = Object.keys(values.sections).find(
          key => values.sections[key].name === nextSectionName
        );
      }

      // Only initialize if section doesn't exist or has no data
      if (sectionKey && !visitedSections.has(sectionKey) && 
          (!values.sections[sectionKey]?.data || values.sections[sectionKey].data.length === 0)) {
        const newElement = {};
        handleResumeChange(`sections.${sectionKey}.data`, [newElement]);
      }
      setVisitedSections(prev => new Set([...prev, sectionKey]));

      SetCurentSection(nextSectionName);
      setCurrentStep({ ...currentStep, current: currentStep.current + 1 });
      
    } else if (key === "prev" && currentIndex > 0) {
      const prevSectionName = sectionsArray[currentIndex - 1];
      
      let sectionKey;
      if (prevSectionName === 'Skills') {
        sectionKey = 'technicalSkills';
      } else if (prevSectionName === 'Certifications') {
        sectionKey = 'awards';
      } else {
        sectionKey = Object.keys(values.sections).find(
          key => values.sections[key].name === prevSectionName
        );
      }

      // Only initialize if section doesn't exist or has no data
      if (sectionKey && !visitedSections.has(sectionKey) && 
          (!values.sections[sectionKey]?.data || values.sections[sectionKey].data.length === 0)) {
        const newElement = {};
        handleResumeChange(`sections.${sectionKey}.data`, [newElement]);
      }
      setVisitedSections(prev => new Set([...prev, sectionKey]));

      SetCurentSection(prevSectionName);
      setCurrentStep({ ...currentStep, current: currentStep.current - 1 });
    }
  };

  const AddSectionToArray = () => {
    const config = selectedTemplate?.build
      ? selectedTemplate.build()?.props?.config
      : {};
    const sections = config.sections || [];
    const newSectionsArray = sections.map((section) => section.name);
    setSectionsArray(newSectionsArray);
    if (newSectionsArray.length > 0) {
      SetCurentSection(newSectionsArray[0]);
    }
  };

  const getData = async (id) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/resumedata`,
        { id },
        {
          headers: {
            Authorization: "Bearer madebyashutosh",
          },
        }
      );

      let resumeData = JSON.parse(res?.data?.user[0]?.resumeBuilder);
      if (!resumeData?.values) {
        setValues(configResume);
      } else {
        setValues(resumeData?.values);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const data = localStorage.getItem("resume");
    const template = localStorage.getItem("template");
    if (data) {
      setValues(mergeDataIntoConfig(JSON.parse(data)));
    }
    if (template) {
      setSelectedTemplate(templates[template]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (auth.token) {
      getData(auth.user[0].id_user);
    }
  }, [auth?.token]);

  useEffect(() => {
    AddSectionToArray();
  }, [selectedTemplate]); 

  useEffect(() => {
    const currentIndex = sectionsArray.indexOf(CurentSection);
    setCurrentStep((prev) => ({ ...prev, total: sectionsArray.length }));
    setNextSection(
      currentIndex < sectionsArray.length - 1
        ? sectionsArray[currentIndex + 1]
        : ""
    );
    setPrevSection(currentIndex > 0 ? sectionsArray[currentIndex - 1] : "");
  }, [CurentSection, sectionsArray]);

  const value = {
    values,
    setValues,
    handlePhoto,
    handleResumeChange,
    get,
    loading,
    setLoading,
    templates,
    selectedTemplate,
    handleTemplateChange,
    printContainerRef,
    setAi,
    ai,
    index,
    setIndex,
    CurentSection,
    handleSectionChange,
    prevSection,
    nextSection,
    currentStep
  };

  return (
    <ResumeContext.Provider value={value}>{children}</ResumeContext.Provider>
  );
};

export default ResumeProvider;
