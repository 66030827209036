import React from 'react'
import styles from "../../assets/Dashboard/Navbar.module.css"
import { ChevronDown, LogOut, Settings, User } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
const Navbar = ({image,auth}) => {
  const navigate = useNavigate(); 
  return (
    <div className={styles.main}>
        <div className={styles.container}>
             {/* left side */}
             <div className={styles.leftside}>
                <span className={styles.active}>Home <span className={styles.underline}></span> </span>
                <span onClick={()=>navigate('/jobs')}>Jobs</span>
                <span onClick={()=>navigate('/airesume')}>Resume</span>
                 <span onClick={()=>navigate('/welcome/account')}>Settings</span>
             </div> 
             {/* right side */} 
             <div className={styles.rightSide}>
                 <div className={styles.profileDropdown}>
                     <div className={styles.profileTrigger}>
                         <img src={image} alt='profile' />
                         <span>{auth?.user?.[0]?.firstname} {auth?.user?.[0]?.lastname} <ChevronDown size={16} color={"#909AB6"}/></span>
                     </div>
                     <div className={styles.dropdownMenu}>
                         <div className={styles.dropdownItem} onClick={() => navigate('/profile')}>
                             <User size={16} />
                             <span>Profile</span>
                         </div>
                         <div className={styles.dropdownItem} onClick={() => navigate('/welcome/account')}>
                             <Settings size={16} />
                             <span>Settings</span>
                         </div>
                         <div className={styles.divider}></div>
                         <div className={styles.dropdownItem} onClick={() => {
                             // Add logout logic here
                             navigate('/login');
                         }}>
                             <LogOut size={16} />
                             <span>Logout</span>
                         </div>
                     </div>
                 </div>
             </div>
        </div>
    </div>
  )
}

export default Navbar