import React from 'react'
import styles from "../../assets/Jobs2.module.css"
import { Building2, Calendar } from "lucide-react";
import SimilarJobs from './SimilarJobs';
const Left = ({jobDetailsSingle}) => {

   const createdAt = jobDetailsSingle[0]?.createdat;
const options = { day: 'numeric', month: 'long', year: 'numeric' };

const formattedDate = new Date(createdAt).toLocaleDateString('en-US', options);
 

  return (
    <div className={styles.leftcontanier}>
    
    <div className={styles.compnaydesccont}>
         <div className={styles.imagecontainerdetails}>

                <img
                    src={`https://old.myfuse.in/uploads/logo/${jobDetailsSingle[0]?.companyLogo}`}
                    
                    className={`img-fluid ${styles.imagesmain} `}
                    alt="company logo"
                  />
         </div>
                  <div className={styles.descrr}>
                        <span className={styles.compnaytitle}>
                        {jobDetailsSingle[0]?.jobtitle}
                        </span>

                        <span className={styles.ccss}>
                           <Building2 size={16} style={{marginRight:'5px'}}/>  {jobDetailsSingle[0]?.companyName}
                        </span>
                        {/* <span className={styles.ccss}>
                           <BriefcaseBusiness size={16} style={{marginRight:'5px'}}/>  {jobDetailsSingle[0]?.experience}
                        </span> */}
                        <span className={styles.ccss}>
                           <Calendar size={16} style={{marginRight:'5px'}}/>  {formattedDate}
                        </span>
                  </div>
                 
                  

    </div> 

                     <div  className={styles.jondescrption}> 
                         <div className={styles.dfss}>
                            <div className={styles.ddef}>
                              <div className={styles.innter}> 
                                  <div className={styles.inerere}>
                                  <h1 className={styles.innter1}>
                                     Job Location
                                    </h1>  
                                    <span className={styles.ddea}>{jobDetailsSingle[0]?.location}</span>
                                  </div> 
                                  <div className={styles.imagg}>
                                   <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQu6YcZFynK8vLqFuVoPkjn2sBpl-N8bBgx25EQY9D5JSJCUD6NptzDSpeL31dkevCApwA&usqp=CAU' alt='location' className='img-fluid' />
                                  </div>
                                  
                              </div>
                              <div className={styles.innter}> 
                                  <div className={styles.inerere}>
                                  <h1 className={styles.innter1}>
                                     Salary
                                    </h1>  
                                    <span className={styles.ddea}>{jobDetailsSingle[0]?.minimumsalary}</span>
                                  </div> 
                                  <div className={styles.imagg}>
                                   <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQArgVLG4P_tfP-OOxLitVWmU5cN7oLeJANZw&s' alt='location' className='img-fluid' />
                                  </div>
                                  
                              </div>
                            </div>
                            <div className={styles.ddef}>
                            <div className={styles.innter}> 
                                  <div className={styles.inerere}>
                                  <h1 className={styles.innter1}>
                                     Experience
                                    </h1>  
                                    <span className={styles.ddea}>{jobDetailsSingle[0]?.experience}</span>
                                  </div> 
                                  <div className={styles.imagg}>
                                   <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIzEDhdI9AkJ_nDTVXveSOov-8uswaCnNBIQ&s' alt='location' className='img-fluid' />
                                  </div>
                                  
                              </div>
                              <div className={styles.innter}> 
                                  <div className={styles.inerere}>
                                  <h1 className={styles.innter1}>
                                     Job Type
                                    </h1>  
                                    <span className={styles.ddea}>{jobDetailsSingle[0]?.job_nature}</span>
                                  </div> 
                                  <div className={styles.imagg}>
                                   <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTho6doS54clf_JkA7TpGYzBPnyUoFHvCupIg&s' alt='location' className='img-fluid' />
                                  </div>
                                  
                              </div>
                            </div>
                         </div>
                      </div>

                    {jobDetailsSingle[0]?.description !== " "  && (
                     <div  className={styles.jondescrptionconta}> 
                     <div className={styles.leftside}></div>
                        <h1 style={{ color: "black", fontSize: "1rem" , fontWeight:'800' }}>
                          
                          {jobDetailsSingle[0]?.description !== " " ? "Job Description" : "" }

                        </h1>
                        <div 
                       className={styles.customcontent}
                          dangerouslySetInnerHTML={{
                            __html: jobDetailsSingle[0]?.description,
                          }}
                        ></div>
                      </div>
                    ) }
                    


                      <div  className={styles.jondescrptionconta}>
                      <div className={styles.leftside}></div>
                      <h1 style={{ color: "black", fontSize: "1rem" , fontWeight:'800' }}>
                          {jobDetailsSingle[0]?.responsibilities !== " " ? "Responsibilities" : "" }
                        </h1>
                        <div 
                          className={styles.customcontent}
                          dangerouslySetInnerHTML={{
                            __html: jobDetailsSingle[0]?.responsibilities,
                          }}
                        ></div> 
                      </div>

                      <div  className={styles.jondescrptionconta}>
                      <div className={styles.leftside}></div>
                      <h1 style={{ color: "black", fontSize: "1rem" , fontWeight:'800' }}>
 
                        {jobDetailsSingle[0]?.key_qualifications !== " " ? "Qualifications" : "" }

                        </h1>
                        <div
                          className={styles.customcontent}
                          dangerouslySetInnerHTML={{
                            __html: jobDetailsSingle[0]?.key_qualifications,
                          }}
                        ></div>
                      </div>

                      <div  className={styles.jondescrptionconta}>
                      <div className={styles.leftside}></div>
                      <h1 style={{ color: "black", fontSize: "1rem" , fontWeight:'800' }}>
                         {" "}
                        {jobDetailsSingle[0]?.company_description !== " " ? " Company Description" : "" }

                        </h1>
                        <div 
                          className={styles.customcontent}
                          dangerouslySetInnerHTML={{
                            __html: jobDetailsSingle[0]?.company_description,
                          }}
                        ></div>
                      </div>

                      <div  className={styles.jondescrptionconta}>
                      <div className={styles.leftside}></div>
                      <h1 style={{ color: "black", fontSize: "1rem" , fontWeight:'800' }}>
                      
                        Similar Jobs

                        </h1>
                          <SimilarJobs skills={jobDetailsSingle[0]?.skills}/>
                      </div>

    </div>
  )
}

export default Left