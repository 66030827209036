import React from 'react'
import MyResponsiveNavbar from './Navbar'

const Refundandpolidcy = () => {
  return (
    <div>
      <MyResponsiveNavbar/>
      <div
        style={{
          fontFamily: 'Arial, sans-serif',
          lineHeight: 1.6,
          padding: '20px',
          border: '1px solid #ddd',
          borderRadius: '8px',
          maxWidth: '600px',
          margin: 'auto',
          backgroundColor: '#f9f9f9', 
          marginTop: '2rem'
        }}
      >
        <h2
          style={{
            color: '#333',
            borderBottom: '2px solid #ddd',
            paddingBottom: '10px',
          }}
        >
          Cancellation & Refund Policy
        </h2>
        <p
          style={{
            color: '#555',
            fontSize: '14px',
          }}
        >
          Last updated on 31-07-2024 11:16:18
        </p>
        <p
          style={{
            color: '#555',
            fontSize: '14px',
          }}
        >
          At 245 INTERACTIVE SUP TECH PRIVATE LIMITED, we prioritize customer satisfaction and
          strive to deliver the best experience through our resume creation platform. Please review
          the following terms related to cancellations, refunds, and returns.
        </p>

        <h3 style={{ color: '#333', marginTop: '1rem' }}>1. Subscription Details</h3>
        <ul
          style={{
            color: '#555',
            fontSize: '14px',
            listStyleType: 'disc',
            paddingLeft: '20px',
          }}
        >
          <li><strong>Service Description:</strong> By paying ₹247, users gain access to our resume creation tools and services for 90 days. During this period, users can create, edit, and download their resumes using all available features.</li>
        </ul>

        <h3 style={{ color: '#333', marginTop: '1rem' }}>2. Cancellation Policy</h3>
        <ul
          style={{
            color: '#555',
            fontSize: '14px',
            listStyleType: 'disc',
            paddingLeft: '20px',
          }}
        >
          <li><strong>General Cancellation Guidelines:</strong> Cancellations are allowed if the request is made immediately after the payment and before any services have been accessed. Once access has been granted or usage begins, cancellations are not eligible.</li>
        </ul>

        <h3 style={{ color: '#333', marginTop: '1rem' }}>3. Refund Policy</h3>
        <ul
          style={{
            color: '#555',
            fontSize: '14px',
            listStyleType: 'disc',
            paddingLeft: '20px',
          }}
        >
          <li><strong>Eligibility for Refunds:</strong> Refunds are available if you encounter technical issues or if the service is not functioning as expected within the first 7 days of purchase. Refund requests submitted beyond 7 days are not eligible.</li>
          <li><strong>Conditions for Refund:</strong> Refunds are provided only if:
            <ul style={{ listStyleType: 'circle', paddingLeft: '20px' }}>
              <li>A significant technical issue prevents you from using the service.</li>
              <li>The issue is reported within the 7-day refund window.</li>
            </ul>
          </li>
          <li><strong>Non-Refundable Situations:</strong> Refunds are not provided if:
            <ul style={{ listStyleType: 'circle', paddingLeft: '20px' }}>
              <li>The service has been used successfully without any technical issues.</li>
              <li>The refund request is made after the 7-day window.</li>
              <li>Dissatisfaction with resume content is subjective (e.g., design preferences).</li>
            </ul>
          </li>
          <li><strong>Refund Processing Time:</strong> Once approved, refunds will be processed within 3-5 business days and credited back to the original payment method.</li>
        </ul>

        <h3 style={{ color: '#333', marginTop: '1rem' }}>4. How to Request a Refund</h3>
        <ul
          style={{
            color: '#555',
            fontSize: '14px',
            listStyleType: 'disc',
            paddingLeft: '20px',
          }}
        >
          <li>Contact our Customer Service team within 7 days of purchase.</li>
          <li>Provide your order number and describe the issue faced.</li>
          <li>Our team will assess the request, and if eligible, approve the refund.</li>
        </ul>

        <h3 style={{ color: '#333', marginTop: '1rem' }}>5. Technical Support and Troubleshooting</h3>
        <p
          style={{
            color: '#555',
            fontSize: '14px',
          }}
        >
          For any technical difficulties encountered while using the platform, please reach out
          to our support team. We are committed to resolving issues quickly and ensuring a
          smooth experience.
        </p>
      </div>  
    </div>
  )
}

export default Refundandpolidcy
