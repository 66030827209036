import React, { useEffect, useState } from "react";
import styles from "../../assets/Jobs2.module.css";
import { ArrowUpRight, Heart, Share2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../ResumeBuilder/builder/components/context/auth";
import { useResume } from "../../ResumeBuilder/builder/components/context/Resume";
import socket from "../../components/Socket";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OpenAI from "openai";
import HashLoader from "react-spinners/HashLoader";
import {
  faLightbulb,
  faCheckCircle,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import ModelForShare from "./ModelForShare";

const Right = ({ jobDetailsSingle , slug}) => {
  // const params = useParams();

  // const [jobDetailsLoading, setJobDetalilsLoading] = useState(false);
  const { auth } = useAuth();
  const [show, setShow] = useState(false);
  const [showshare, setShowShare] = useState(false);
  const handleShowShare = () => setShowShare(true);
  const handleCloseShare = () => setShowShare(false);
  // const [showAI, setShowAI] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [setskillfromdibapi] = useState("");
  const [skills, setSkills] = useState([]);
  const userIdfromdb = auth?.user?.[0]?.id_user;
  const resume = JSON.parse(localStorage.getItem("resume"));
  const role = resume?.sections?.technicalSkills?.data?.[0]?.skills;
  const [hasloaing, sethasloading] = useState(false);
  const { setLoading } = useResume();
  const navigate = useNavigate();

  const skill_from_resume = role?.join(",");
  const getUserSkill = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/skillsbyid?id_user=${userIdfromdb}`,
        {
          headers: {
            Authorization: "Bearer madebyashutosh",
          },
        }
      );
      setskillfromdibapi(data?.skillsfromdb?.[0].skills);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };
  let count = 0;
  let userId = auth?.user?.[0]?.id_user;
  const addSkill = (skill) => {
    socket.emit("skillAdd", {
      userId: userId,
      values: skill,
    });
    count++;
  };

  useEffect(() => {
    getUserSkill();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    socket.emit("skillAdd", {
      userId: userId,
      values: "unn",
    });
    // Set up socket listeners
    socket.on("skillsUpdated", (data) => {
      setSkills(data.skills.split(",")); // Update the skills state
    });

    return () => {
      socket.off("skillsUpdated");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let jobSkillsString = jobDetailsSingle?.[0]?.skills || "";
  let jobSkills = jobSkillsString
    .split(",")
    .map((skill) => skill.trim().toLowerCase());

  let mergedSkillsString = `${skills},${skill_from_resume}`;

  let mergedSkills = mergedSkillsString
    .split(",")
    .map((skill) => skill.trim().toLowerCase());

  let matchingSkills = jobSkills.filter((skill) =>
    mergedSkills.includes(skill)
  );
  let unmatchedSkills = jobSkills.filter(
    (skill) => !mergedSkills.includes(skill)
  );

  let matchingSkillsCount = matchingSkills.length;
  let unmatchedSkillsCount = unmatchedSkills.length;

  const addtoResume = async () => {
    setLoading(true);
    sethasloading(true);
    // Retrieve the existing resume from local storage or initialize a new one
    const resume = JSON.parse(localStorage.getItem("resume")) || {};
    const currentSkills =
      resume?.sections?.technicalSkills?.data?.[0]?.skills || [];

    // Assuming jobDetailsSingle is available in our component and contains job details
    const jobSkillsString = jobDetailsSingle?.[0]?.skills || "";
    const jobSkills = jobSkillsString.split(",").map((skill) => skill.trim());

    // Combine current skills with job skills and remove duplicates
    const combinedSkills = Array.from(
      new Set([...currentSkills, ...jobSkills])
    );

    // Update the resume object
    if (!resume.sections) resume.sections = {};
    if (!resume.sections.technicalSkills)
      resume.sections.technicalSkills = { data: [{}] };

    const basicSkills = combinedSkills.slice(0, 4);
    const advancedSkills = combinedSkills.slice(4);

    // Add Basic skills
    resume.sections.technicalSkills.data[0] = {
      name: "Basic",
      skills: basicSkills,
    };

    // Add Advanced skills if present
    if (advancedSkills.length > 0) {
      resume.sections.technicalSkills.data[1] = {
        name: "Advanced",
        skills: advancedSkills,
      };
    }

    // Save updated resume back to local storage
    localStorage.setItem("resume", JSON.stringify(resume));

    // OpenAI API integration to extract skills from job responsibilities
    const openai = new OpenAI({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true,
    });

    const completion = await openai.chat.completions.create({
      messages: [
        {
          role: "system",
          content:
            "You are a resume assistant. Extract skills from the provided job responsibilities and list them as a comma-separated string.",
        },
        {
          role: "user",
          content: jobDetailsSingle[0]?.responsibilities,
        },
        {
          role: "user",
          content:
            "List the skills derived from the responsibilities, separated by commas.",
        },
      ],
      model: "gpt-3.5-turbo",
      temperature: 1,
      max_tokens: 1000,
    });

    // Extract skills from AI response
    let aiResponse = completion.choices[0].message.content;
    console.log(aiResponse);

    // Add extracted AI skills to combined skills and update resume
    const aiSkills = aiResponse.split(",").map((skill) => skill.trim());
    const allSkills = Array.from(new Set([...combinedSkills, ...aiSkills]));

    // Re-update the resume object with all skills
    const newBasicSkills = allSkills.slice(0, 8);
    const newAdvancedSkills = allSkills.slice(8);

    // Update Basic skills
    resume.sections.technicalSkills.data[0] = {
      name: "Basic",
      skills: newBasicSkills,
    };

    // Update Advanced skills if present
    if (newAdvancedSkills.length > 0) {
      resume.sections.technicalSkills.data[1] = {
        name: "Advanced",
        skills: newAdvancedSkills,
      };
    }
    // Save updated resume back to local storage
    localStorage.setItem("resume", JSON.stringify(resume));
    sethasloading(false);
    setLoading(false);
    navigate("/welcome");
  };

  return (
    <div className={styles.rightmaincontainer}>
      <div className={styles.applyConatiner}>
        <div className={styles.iconcontainer}>
          <div>
            <Heart size={17} />
          </div>
          <div className={styles.sharebutton} onClick={handleShowShare}>
            {" "}
            <Share2 size={17} /> Share
          </div>
        </div>
        <div className={styles.applybuttonContainer}>
          {auth.token === "" ? (
            <a
              className={styles.applybutton}
              href="/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              Login To Apply
            </a>
          ) : (
            <a
              className={styles.applybutton}
              href={jobDetailsSingle[0]?.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              {jobDetailsSingle[0]?.link ? "Apply" : "Easy Apply"}{" "}
              <ArrowUpRight size={17} style={{ marginLEFT: "10px" }} />
            </a>
          )}
        </div>
      </div>

      <div className={styles.coureseConatiner}>
        <div className={styles.cousrsub}>
          <div className={styles.innercourse}>
            <span className={styles.lle}>Desired Skills</span>
            <div className={styles.ddire}>
              <div className={styles.ddir1}>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{
                    marginRight: ".5rem",
                    color: "green",
                  }}
                />{" "}
                {matchingSkillsCount} skills on your profile
              </div>
              <div className={styles.dwi}>
                {matchingSkills.map((skill, index) => (
                  <span key={index} className={styles.hightleif}>
                    {skill},
                  </span>
                ))}
              </div>
            </div>
          </div>

          <div className={styles.innercourse}>
            <div className={styles.ddir1}>
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                style={{
                  marginRight: ".5rem",
                  color: "gray",
                }}
              />{" "}
              {unmatchedSkillsCount} skills missing on your profile
              <span className={styles.dwi}>
                {unmatchedSkills.map((skill, index) => (
                  <span key={index} className={styles.hightleif}>
                    {skill},
                  </span>
                ))}
              </span>
            </div>
          </div>
          <div className={styles.eef}>
            <FontAwesomeIcon
              icon={faLightbulb}
              style={{
                marginRight: ".5rem",
                color: "#739ee8",
              }}
            />{" "}
            Add Skills you have to your profile to stand out to the employer{" "}
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={handleShow}
            >
              Add Skills
            </span>
          </div>
        </div>
      </div>

      <div className={styles.resumeConatiner}>
        <div className={styles.new}>New</div>
        <div className={styles.eeff}>
          <div>
            Create a personalized resume tailored to each job role to
            significantly enhance your chances of being shortlisted.
          </div>

          <div className={styles.hei} onClick={addtoResume}>
            {hasloaing ? (
              <HashLoader color="white" size={25} />
            ) : (
              "Generate AI Resume"
            )}{" "}
            <ArrowUpRight size={17} style={{ marginLEFT: "10px" }} />
          </div>
        </div>
      </div>

      <div className={styles.coureseConatiner}>
        <div className={styles.cousrsub}>
          <div className={styles.innercourse}>
            <span className={styles.lle}>Course Allowed</span>
            <span className={styles.ddir}>
              {jobDetailsSingle[0]?.qualification
                ?.split(",")
                .map((qualification) => (
                  <span key={qualification} className={styles.hightleif}>
                    {qualification.trim()}
                  </span>
                ))}
            </span>
          </div>

          <div className={styles.innercourse}>
            <span className={styles.lle}>Stream Allowed</span>
            <span className={styles.ddir}>
              {jobDetailsSingle[0]?.stream?.split(",").map((skill) => (
                <span key={skill} className={styles.hightleif}>
                  {skill.trim()}
                </span>
              ))}
            </span>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
            Skill details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Skills Associated With The job</h6>
          <p style={{ fontSize: "14px" }}>
            skills found on your profile match the skills associated with the
            job.
          </p>

          <ul style={{ padding: "0" }}>
            {jobDetailsSingle[0]?.skills?.split(",").map((skill, index) => (
              <li
                key={index}
                style={{
                  listStyle: "none",
                }}
              >
                {skills.includes(skill.trim()) ? (
                  <>
                    <span>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{
                          color: "green",
                          marginRight: ".5rem",
                        }}
                      />
                      {skill.trim()}
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          style={{
                            marginRight: ".5rem",
                            color: "rgb(0 0 0/.3)",
                          }}
                        />
                        {skill.trim()}
                      </span>

                      <span
                        style={{
                          cursor: "pointer",
                          color: "rgb(0 0 0/.6)",
                        }}
                        onClick={() => addSkill(skill.trim())}
                      >
                        +Add
                      </span>
                    </span>
                  </>
                )}
              </li>
            ))}
            {auth.token === "" ? (
              <span style={{ color: "red" }}>
                {" "}
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  style={{
                    marginRight: ".5rem",
                    color: "red",
                  }}
                />{" "}
                Please Login To Get This Features{" "}
              </span>
            ) : (
              ""
            )}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <ModelForShare
        jobid={jobDetailsSingle[0]?.id_jobpost}
        show={showshare}
        slug={slug}
        handleClose={handleCloseShare}
        handleShowShare={handleShowShare}
      />
    </div>
  );
};

export default Right;
