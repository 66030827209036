import React, { useRef, useState } from "react";
import styles from "../../assets/Dashboard/resume.module.css";
import { Pen, Plus } from "lucide-react";
import { useResume } from "../../ResumeBuilder/builder/components/context/Resume";
import { useNavigate } from "react-router-dom";

const Resume = () => {
  const containerRef = useRef(null); // Reference to the scrollable container
  const [open, setOpen] = useState(false);
  const Navigate = useNavigate();
  // Scroll handler
  const { templates, handleTemplateChange } = useResume();

  const handleScroll = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 100, // Adjust the scroll distance as needed
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  const handleChange = (templateKey) => {
    handleTemplateChange(templateKey);
    Navigate("/welcome");
  };
  return (
    <div className={styles.main}>
      <div className={styles.headingContainer}>
        <div className={styles.headingtitle}>Resume </div>
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.eachContainermain}>
          <div className={styles.eachContTitle}> MAKE A NEW RESUME</div>
          <div className={styles.downContainer}>
            <div className={styles.innerDownContainer} onClick={() => Navigate("/airesume")}>
              <div className={styles.byparLeft}>
                Create Resume using AI Resume
              </div>
              <div className={styles.byparRight}>
                <img
                  src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729257945/all/gt0bpcxtsllahwg5bdsf.svg"
                  alt="d"
                />
              </div>
            </div>

            <div className={styles.innerDownContainer} onClick={() => Navigate("/resume-examples")}>
              <div className={styles.byparLeft}>Create from Template</div>
              <div className={styles.byparRight}>
                <img
                  src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729257945/all/gt0bpcxtsllahwg5bdsf.svg"
                  alt="d"
                />
              </div>
            </div>

            <div className={styles.innerDownContainer}>
              <span className={styles.comingSoonBadge}>Coming Soon</span>
              <div className={styles.byparLeftComingSoon}>
                Upload resume from local
              </div>
              {/* <div className={styles.byparRight}>
                <img
                  src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729257945/all/gt0bpcxtsllahwg5bdsf.svg"
                  alt="d"
                />
              </div> */}
            </div>
          </div>
        </div>

        <div className={styles.mobileContainerButton}>
          <div className={styles.createButton} onClick={() => setOpen(!open)}>
            <Plus size={15} /> Create New Resume
          </div>
          {open && (
            <div className={styles.dropdown}>
              <div className={styles.eachBox} onClick={() => Navigate("/airesume")}>
                Create Resume using AI Resume
              </div>
              <div className={styles.eachBox} onClick={() => Navigate("/resume-examples")}>Create from Template</div>
              <div className={styles.eachBox}>Upload resume from local</div>
            </div>
          )}
        </div>

        <div className={styles.ScrollContainer} ref={containerRef}>
          {Object.values(templates).map((template, index) => {
            return (
              <div className={styles.eachContainer} key={index}>
                <div className={styles.overlay}></div>
                <div className={styles.hoverButtons}  onClick={() => handleChange(template.key)}>
                  <button className={styles.iconButton}>
                    <Pen size={16} />
                    <span>Edit</span>
                  </button>
                  {/* <button className={styles.iconButton}>
                    <Download size={16} />
                    <span>Download</span>
                  </button>
                  <button className={styles.iconButton}>
                    <Copy size={16} />
                    <span>Duplicate</span>
                  </button> */}
                </div>
                <div className={styles.eachcontainerUp}>
                  <img src={template.preview} alt={template.name} />
                </div>
                <div className={styles.eachcontainerDown}>
                  <div className={styles.eachdowninte}>
                    <div className={styles.left}>
                      <span className={styles.ttitle}>{template.name}</span>
                    </div>
                    <div className={styles.badge}>
                      <span>Template</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {/* <div className={styles.eachContainer}>
          <div className={styles.overlay}></div> 
            <div className={styles.hoverButtons}>
              <button>
                {" "}
                <Pen size={14} color={"#806EED"} /> Edit
              </button>
              <button>
                {" "}
                <Download size={14} color={"#806EED"} /> Download
              </button>
              <button>
                {" "}
                <Copy size={14} color={"#806EED"} /> Duplicate
              </button>
            </div>
            <div className={styles.eachcontainerUp}>
              <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1728976392/resumeEample/dlhnxtg0oqctz0qlzceq.png" />
            </div>
            <div className={styles.eachcontainerDown}>
              <div className={styles.eachdowninte}>
                <div className={styles.left}>
                  <span className={styles.ttitle}>Full Stack Developer</span>
                  <span className={styles.dore}>1 August , 2024</span>
                </div>
                <div className={styles.Right}>
                  <span className={styles.percentround}>68%</span>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className={styles.scroll}>
          <img
            onClick={handleScroll}
            src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729442516/all/v95xfskcuxn9hcz35kvb.svg"
            alt="next"
          />
        </div>
      </div>
    </div>
  );
};

export default Resume;
