import React from 'react'
import { useJobs } from '../../ResumeBuilder/builder/components/context/Jobs';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from "../../assets/JobByRole.module.css"
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../../assets/swipper.css';


// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { ArrowUpRight } from 'lucide-react';
const JobsByRole = () => {
  let array = [];
      array = [
        {
          title: "Software Developer",
          img1: "https://img.naukimg.com/logo_images/groups/v1/3607752.gif",
          img2:"https://assets.channelinsider.com/uploads/2022/03/CI.Profile.Deloitte-1024x1024.jpg",
          img3:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFGXumdoDxB_H03XgrzH458cDPwm2YIRtsag&s",
          description: "500+ Are Actively hiring",
        }, 
        {
          title: "React Developer",
          img1: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMsiIPPH7ADmFnaTN_BIYrZ75bssytUOSfFg&s",
          img2:"https://assets.channelinsider.com/uploads/2022/03/CI.Profile.Deloitte-1024x1024.jpg",
          img3:"https://lh3.googleusercontent.com/tRfu1KvmJn1aHAbGs92QLk9A2e-M73U_c8mV6S8HD1RxTxxoY-cWI-fq_r3e5JO3Ru9kTdZJoZzcs61E9j0sIw6Ac6cLDe4f4qzEjkQ",
          description: "500+ Are Actively hiring",
        }, 
        {
          title: "Travel",
          img1: "https://img.naukimg.com/logo_images/groups/v1/3607752.gif",
          img2:"https://gumlet-images.assettype.com/afaqs%2Fimport%2Fall%2Fnews%2Fimages%2Fnews_story_grfx%2F2017%2F51150_1_home_big.jpg?w=1200&auto=format%2Ccompress&ogImage=true",
          img3:"https://lh3.googleusercontent.com/tRfu1KvmJn1aHAbGs92QLk9A2e-M73U_c8mV6S8HD1RxTxxoY-cWI-fq_r3e5JO3Ru9kTdZJoZzcs61E9j0sIw6Ac6cLDe4f4qzEjkQ",
          description: "500+ Are Actively hiring",
        }, 
        {
          title: "Java Developer",
          img1: "https://img.naukimg.com/logo_images/groups/v1/3607752.gif",
          img2:"https://assets.channelinsider.com/uploads/2022/03/CI.Profile.Deloitte-1024x1024.jpg",
          img3:"https://lh3.googleusercontent.com/tRfu1KvmJn1aHAbGs92QLk9A2e-M73U_c8mV6S8HD1RxTxxoY-cWI-fq_r3e5JO3Ru9kTdZJoZzcs61E9j0sIw6Ac6cLDe4f4qzEjkQ",
          description: "500+ Are Actively hiring",
        }, 
        {
          title: "Associate",
          img1: "https://img.naukimg.com/logo_images/groups/v1/3607752.gif",
          img2:"https://assets.channelinsider.com/uploads/2022/03/CI.Profile.Deloitte-1024x1024.jpg",
          img3:"https://lh3.googleusercontent.com/tRfu1KvmJn1aHAbGs92QLk9A2e-M73U_c8mV6S8HD1RxTxxoY-cWI-fq_r3e5JO3Ru9kTdZJoZzcs61E9j0sIw6Ac6cLDe4f4qzEjkQ",
          description: "500+ Are Actively hiring",
        }, 
        {
          title: "Consultant",
          img1: "https://img.naukimg.com/logo_images/groups/v1/3607752.gif",
          img2:"https://assets.channelinsider.com/uploads/2022/03/CI.Profile.Deloitte-1024x1024.jpg",
          img3:"https://lh3.googleusercontent.com/tRfu1KvmJn1aHAbGs92QLk9A2e-M73U_c8mV6S8HD1RxTxxoY-cWI-fq_r3e5JO3Ru9kTdZJoZzcs61E9j0sIw6Ac6cLDe4f4qzEjkQ",
          description: "500+ Are Actively hiring",
        }, 
        {
          title: "Manager",
          img1: "https://img.naukimg.com/logo_images/groups/v1/3607752.gif",
          img2:"https://assets.channelinsider.com/uploads/2022/03/CI.Profile.Deloitte-1024x1024.jpg",
          img3:"https://lh3.googleusercontent.com/tRfu1KvmJn1aHAbGs92QLk9A2e-M73U_c8mV6S8HD1RxTxxoY-cWI-fq_r3e5JO3Ru9kTdZJoZzcs61E9j0sIw6Ac6cLDe4f4qzEjkQ",
          description: "500+ Are Actively hiring",
        }, 
        {
          title: "Senior Anlysist",
          img1: "https://img.naukimg.com/logo_images/groups/v1/3607752.gif",
          img2:"https://assets.channelinsider.com/uploads/2022/03/CI.Profile.Deloitte-1024x1024.jpg",
          img3:"https://lh3.googleusercontent.com/tRfu1KvmJn1aHAbGs92QLk9A2e-M73U_c8mV6S8HD1RxTxxoY-cWI-fq_r3e5JO3Ru9kTdZJoZzcs61E9j0sIw6Ac6cLDe4f4qzEjkQ",
          description: "500+ Are Actively hiring",
        }, 
      ]
    const navigate = useNavigate(); 
    const {setComminJobs , setIsfirst} = useJobs(); 
    const handleClick = async(value) =>{
      setComminJobs(value);
      setIsfirst(true)
      navigate(`/jobs`);
   }
  return (
    <Swiper
        spaceBetween={10}
        // centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        // navigation={true}
        
        modules={[ Autoplay, Pagination, Navigation]}
       
        className="mySwiper"
        slidesPerView={1.3}  
        breakpoints={{
          640: {
            slidesPerView: 2, 
          },
          768: {
            slidesPerView: 3,  
          },
          1024: {
            slidesPerView: 3, 
          },
        }}
        navigation={{
            nextEl: '.custom-swiper-button-next',
            prevEl: '.custom-swiper-button-prev',
          }}
          style={{height:"10rem"}}
      >

         {array.map((ele,index)=>{
          return (
            <SwiperSlide key={index} onClick={()=> handleClick("Software Developer ")}  >
            <div className={styles.mains}> 
                  <div className='d-flex justify-content-between relative ' >
                      <h1 style={{fontSize:'14px' , fontWeight:600}}> {ele.title}</h1>
                      <span className={styles.navsd}><ArrowUpRight size={18} className={styles.kk}/></span>
                  </div>
                  <p style={{fontSize:"12px"}}> {ele.description} </p> 
                  <div className={styles.imgmain}>
                      <div className={styles.imgdiv}>
                        <img src={ele.img1}  alt={ele.img1} className={styles.imgss} />
                      </div> 
                      <div className={styles.imgdiv}>
                        <img src={ele.img2}  alt={ele.img1} className={styles.imgss} />
                      </div> 
                      <div className={styles.imgdiv}>
                        <img src={ele.img3}  alt={ele.img1} className={styles.imgss} />
                      </div> 
                  </div>
            </div>
            
            
          </SwiperSlide>
          )
         })}
      </Swiper>
  )
}

export default JobsByRole