import React from 'react'
import styles from '../../assets/HomePage/Category.module.css'
import { Cpu  , GraduationCap , Plane , TramFront , HeartPulse ,ScanBarcode} from "lucide-react";
import { useJobs } from '../../ResumeBuilder/builder/components/context/Jobs';
import { useNavigate } from 'react-router-dom';
const Category = () => { 
  const navigate = useNavigate(); 
  const {setComminJobs,setIsfirst} = useJobs(); 
  const handleClick = async(value) =>{
    // navigate(`/jobs?param=${value}`);
    setComminJobs(value);
    setIsfirst(true)
    navigate(`/jobs`);
 }
  return (
    <div className={styles.main}><div className='container'>
    <div className='row d-flex  justify-content-center '>
    
        <div className='col-11  col-md-11 d-flex justify-content-center flex-column '> 
        <h2 className ={styles.ngcontent }> Explore  Categories . 
        <span className={styles._ngcontents}  > Apply to roles matching your interest from 900+ trending options. 
        </span>
        </h2>
         
         <div  className='d-flex justify-content-center align-items-center flex-wrap ' style={{gap:'20px'}}>
         <div  className={styles.cate} onClick={()=>handleClick("software")} > <span className={styles.icon} ><Cpu  /> </span> Techonology And IT </div> <div   className={styles.cate} onClick={()=>handleClick("Education")} > <span className={styles.icon2}><GraduationCap  /> </span> Education </div>
         <div className={styles.cate}>  <span className={styles.icon3}>< Plane /> </span>  Travel And Tourism  </div> <div  className={styles.cate}> <span className={styles.icon4}>< TramFront /> </span>  Transportation And .... </div>
             <div className={styles.cate}> <span className={styles.icon5}>< HeartPulse /> </span> Health Care  </div> <div  className={styles.cate}> <span className={styles.icon6}>< ScanBarcode /> </span> Retail and Consumer </div>
         </div>

        </div>
    </div>
 </div></div>
  )
}

export default Category