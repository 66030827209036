import React, { useEffect, useState } from "react";
import MyResponsiveNavbar from "../Navbar";
import styles from "../../assets/Admin/Blog.module.css";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import { toast } from "react-hot-toast";
import {
  ArrowUpRight,
  MonitorPlay,
  PenBoxIcon,
  Send,
  TimerReset,
  Trash2,
} from "lucide-react";

const Blog = () => {
  const [editorData, setEditorData] = useState("");
  const [heading, setHeading] = useState("");
  const [shortDesription, setShortDescription] = useState("");
  const [tag, setTag] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [TR, setTR] = useState("");
  const [blogid, setBlogid] = useState("");
  const [loading, setLoading] = useState(false);
  const [blog, setBlog] = useState([]);
  const [isEditClick, setIsEditClick] = useState(false);
  const [isActive, setIsActive] = useState(1);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
    }
  };

  const handleEdit = (blog) => {
    setIsEditClick(true);
    setEditorData(blog.long_desc); // Set the saved HTML data to CKEditor
    setHeading(blog.blog_heading);
    setShortDescription(blog.short_desc);
    setTag(blog.blog_tag);
    setImagePreview(blog.img_link);
    setTR(blog.TR);
    setBlogid(blog.S_No);
    setIsActive(1);
  };

  const handleSubmit = async () => {
    const datalocal = JSON.parse(localStorage.getItem("auth"));
    setLoading(true); // Set loading to true at the start

    try {
      // Create a new FormData object to handle file upload
      const formData = new FormData();
      formData.append("Blog_heading", heading);
      formData.append("long_desc", editorData);
      formData.append("short_desc", shortDesription);
      formData.append("Blog_tag", tag);
      formData.append("image", image); // Append the file object
      formData.append("TR", TR);

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/admin/AddBlog`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type for form data
            Authorization: `Bearer ${datalocal?.token}`, // Set the authorization header
          },
        }
      );

      if (data?.success) {
        toast.success("Blog Added Successfully");
        // Clear form fields
        setEditorData("");
        setHeading("");
        setShortDescription("");
        setTag("");
        setImage(""); // Clear the image file input
        setTR("");
        setImagePreview("");
        await getAllBlog();
      } else {
        toast.error("Failed to Add Blog");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!!!");
    } finally {
      setLoading(false); // Set loading to false once the process is complete
    }
  };

  const handleEditSubmit = async () => {
    const datalocal = JSON.parse(localStorage.getItem("auth"));
    setLoading(true);
    try {
      // Create a new FormData object to handle the update with file upload
      const formData = new FormData();
      formData.append("blogId", blogid);
      formData.append("blog_heading", heading);
      formData.append("long_desc", editorData);
      formData.append("short_desc", shortDesription);
      formData.append("blog_tag", tag);
      formData.append("image", image); // Append the image file only if it has changed
      formData.append("TR", TR);

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/admin/updateBlogAdmin`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${datalocal?.token}`,
            "Content-Type": "multipart/form-data", // Ensure the correct content type for file upload
          },
        }
      );

      if (data?.success) {
        toast.success("Blog Updated Successfully");
        setEditorData("");
        setHeading("");
        setShortDescription("");
        setTag("");
        setImage(null);
        setTR("");
        setImagePreview("");
        await getAllBlog();
        setIsEditClick(false);
        setLoading(false);
      } else {
        toast.error("Failed to Update Blog");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!!!");
      setLoading(false);
    }
  };

  const getAllBlog = async () => {
    const datalocal = JSON.parse(localStorage.getItem("auth"));
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/admin/AllBlogByAuthor`,

        {
          headers: {
            Authorization: `Bearer ${datalocal?.token}`, // Correctly format the Authorization header
          },
        }
      );
      if (data?.success) {
        await setBlog(data?.result);
      } else {
        toast.error("Failed to get Blog");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!!!");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    const datalocal = JSON.parse(localStorage.getItem("auth"));
    try {
      setLoading(true);
      const { data } = await axios.delete(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/admin/DeleteBlogByAuthor/${id}`,
        {
          headers: {
            Authorization: `Bearer ${datalocal?.token}`, // Correctly format the Authorization header
          },
        }
      );
      if (data?.success) {
        toast.success("Blog deleted successfully");
        await getAllBlog();
      } else {
        toast.error("Failed to delete Blog");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!!!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllBlog();
  }, []);
  return (
    <>
      <MyResponsiveNavbar />
      <div className={styles.main}>
        <div className={styles.blogNavbar}>
          <div
            className={`${styles.dr} ${isActive === 1 ? styles.acticve : ""}`}
            onClick={() => setIsActive(1)}
          >
            Blog Editor
          </div>
          <div
            className={`${styles.dr} ${isActive === 2 ? styles.acticve : ""}`}
            onClick={() => setIsActive(2)}
          >
            Blog Preview
          </div>
          <div
            className={`${styles.dr} ${isActive === 3 ? styles.acticve : ""}`}
            onClick={() => setIsActive(3)}
          >
            Blog History
          </div>
        </div>
        {isActive === 2 && (
          <div className={styles.right}>
            <div className={styles.hhddo}>
              <h1 className={styles.doso}>Blog Preview</h1>
            </div>

            <div className={styles.blogcontainer}>
              <div className={styles.innercontainer}>
                <h2 className={styles.blogheadi}>{heading}</h2>

                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="sdfgsd"
                    className={`img-fluid ${styles.imagggo}`} // Display the image preview
                  />
                )}
                <div className={styles.dododd}>
                  <p>
                    {TR ? <TimerReset size={15} /> : ""} {TR}{" "}
                    {TR ? " Minutes to read" : ""}
                  </p>
                </div>

                <div>
                  <p>
                    {tag?.split(",").map((individualTag, index) => (
                      <span key={index} className={styles.tagItem}>
                        {individualTag.trim()}
                      </span>
                    ))}
                  </p>
                </div>
                <div dangerouslySetInnerHTML={{ __html: editorData }} />
              </div>
            </div>
            <div className={styles.dhocontin}>
              {shortDesription && (
                <div className={styles.shortDesriptionConatiner}>
                  <h5>Short Description</h5>
                  <p>{shortDesription}</p>
                </div>
              )}
            </div>
          </div>
        )}

        {isActive === 1 && (
          <div className={styles.left}>
            <div className={styles.hhddo}>
              <h1 className={styles.doso}>Post Blog </h1>
            </div>

            <div className={styles.formGroup}>
              <div className={styles.label}>Blog Heading</div>
              <input
                type="text"
                className={styles.input}
                placeholder="Enter blog heading"
                value={heading}
                onChange={(e) => setHeading(e.target.value)}
              />
            </div>

            <div className={styles.formGroup}>
              <div className={styles.label}>Blog Tag</div>
              <input
                type="text"
                className={styles.input}
                placeholder="Enter blog tag"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
              />
            </div>

            <div className={styles.formGroup}>
              <div className={styles.label}>Upload Image</div>
              <input
                type="file"
                className={styles.input}
                accept="image/*"
                onChange={handleImageChange} // Handle the image file change
              />
            </div>

            <div className={styles.formGroup}>
              <div className={styles.label}>Time To Read in Min </div>
              <input
                type="number"
                className={styles.input}
                placeholder="Expected Time To Read"
                value={TR}
                onChange={(e) => setTR(e.target.value)}
              />
            </div>

            <div className={styles.formGroup}>
              <div className={styles.label}>Short Description</div>
              <textarea
                className={styles.textarea}
                placeholder="Enter short description"
                value={shortDesription}
                onChange={(e) => setShortDescription(e.target.value)}
              />
            </div>

            <div className={styles.formGroup}>
              <div className={styles.label}>Blog Description</div>
              <Editor
                apiKey={process.env.REACT_APP_TINY_API}
                init={{
                  plugins: [
                    // Core editing features
                    "anchor",
                    "autolink",
                    "charmap",
                    "codesample",
                    "emoticons",
                    "image",
                    "link",
                    "lists",
                    "media",
                    "searchreplace",
                    "table",
                    "visualblocks",
                    "wordcount",
                    // Your account includes a free trial of TinyMCE premium features
                    // Try the most popular premium features until Sep 27, 2024:
                    "checklist",
                    "mediaembed",
                    "casechange",
                    "export",
                    "formatpainter",
                    "pageembed",
                    "a11ychecker",
                    "tinymcespellchecker",
                    "permanentpen",
                    "powerpaste",
                    "advtable",
                    "advcode",
                    "editimage",
                    "advtemplate",
                    "ai",
                    "mentions",
                    "tinycomments",
                    "tableofcontents",
                    "footnotes",
                    "mergetags",
                    "autocorrect",
                    "typography",
                    "inlinecss",
                    "markdown",
                  ],
                  toolbar:
                    "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                  tinycomments_mode: "embedded",
                  tinycomments_author: "Author name",
                  mergetags_list: [
                    { value: "First.Name", title: "First Name" },
                    { value: "Email", title: "Email" },
                  ],
                  ai_request: (request, respondWith) =>
                    respondWith.string(() =>
                      Promise.reject("See docs to implement AI Assistant")
                    ),
                }}
                value={editorData} // Use value to control the content
                onEditorChange={(content, editor) => {
                  setEditorData(content);
                }}
              />
            </div>

            <div className={styles.postButtonContainer}>
              {isEditClick ? (
                <span className={styles.postButton} onClick={handleEditSubmit}>
                  {loading ? "Loading" : "Update"} <Send size={15} />
                </span>
              ) : (
                <span className={styles.postButton} onClick={handleSubmit}>
                  {loading ? "Loading" : "Post"} <Send size={15} />
                </span>
              )}
            </div>
          </div>
        )}
      </div>

      {isActive === 3 && (
        <div className={styles.blogHistoryContainer}>
          <h1 className={styles.blogHistroyHeading}> Posted Blog </h1>
          <table className={styles.blogTable}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Logo</th>
                <th>Heading</th>
                <th>Short Description</th>
                <th>Tag</th>
                <th>Time Stamp</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {blog.map((value, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <img src={value.img_link} alt="sfas" />
                  </td>
                  <td>{value.blog_heading}</td>
                  <td>{value.short_desc}</td>
                  <td>{value.blog_tag}</td>
                  <td>{new Date(value.time_stamp).toLocaleString()}</td>
                  <td className={styles.do}>
                    <button onClick={() => handleEdit(value)}>
                      {" "}
                      <PenBoxIcon size={15} />{" "}
                    </button>
                    <button>
                      {" "}
                      <ArrowUpRight size={15} />{" "}
                    </button>
                    <button
                      className={styles.dele}
                      onClick={() => handleDelete(value.S_No)}
                    >
                      {" "}
                      <Trash2 size={15} />{" "}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default Blog;
