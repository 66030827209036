import React from 'react'
import MyResponsiveNavbar from './Navbar'
import Footer from './Footer'
import styles from "../assets/About.module.css"
import HelmetConfig from '../components/HelmetConfig'
const AboutUs = () => {
  return (
    
    <div>
       <HelmetConfig
          title="About Us | MyFuse - Innovating Your Job Search with AI"
          description="Learn more about MyFuse, our mission to revolutionise job searching with AI,
and the team that makes it possible"
          keywords="
          About Myfuse.in, Myfuse Company Overview, Myfuse.in Story, What is Myfuse.in, Myfuse Business Information,
          What is Myfuse.in and How Does it Work?, History of Myfuse.in Job Platform, About the Founders of Myfuse.in,
          Why Choose Myfuse.in for Job Searching?, How Myfuse.in Started and Evolved,
          About Myfuse, Company Overview, Our Story, Myfuse Info, Company Profile,
          Our Mission, Company Background, Business Overview, Team Behind Myfuse.in, Myfuse Journey,
          Myfuse Vision and Mission, Myfuse Leadership Team, Company Values at Myfuse.in,
          Why Myfuse.in is Unique, Myfuse Milestones,
          How Myfuse.in Helps Job Seekers, The Story Behind Myfuse.in's Success, Myfuse.in Company Values and Ethics,
          Introduction to Myfuse.in Platform, Overview of Myfuse.in Services,
          About Our Company, Who We Are, Myfuse Team, Business Profile, About Us Page Information
        "
          canonical="https://myfuse.in/about"
        />
        <MyResponsiveNavbar/> 
           <div className={styles.main}> 
            Page Will Create Soon , Stay Tunned
           </div>
         <Footer/>
    </div>
  )
}

export default AboutUs