import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaTimes, FaChartLine } from 'react-icons/fa';
import styles from '../../assets/ResumeScore/ScoreModal.module.css';

const ScoreModal = ({ show, onHide, resumeScore, onShowAnalysis }) => {
  if (!show || !resumeScore) return null;

  const SECTION_MAX_SCORES = {
    ImpactAndInfluence: 30,
    ClarityAndFocus: 20,
    AchievementMetrics: 25,
    FormatAndStructure: 15,
    CareerProgression: 10
  };

  const calculateTotalScore = (sections) => {
    const totalPossibleScore = Object.values(SECTION_MAX_SCORES).reduce((sum, max) => sum + max, 0);
    const actualScore = Object.entries(sections).reduce((sum, [section, score]) => {
      const maxScore = SECTION_MAX_SCORES[section];
      return sum + score;
    }, 0);
    return Math.round((actualScore / totalPossibleScore) * 100);
  };

  const totalScore = calculateTotalScore(resumeScore.sections);

  return (
    <AnimatePresence>
      <motion.div 
        className={styles.modalOverlay}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div 
          className={styles.modalContent}
          initial={{ scale: 0.9, opacity: 0, y: 50 }}
          animate={{ scale: 1, opacity: 1, y: 0 }}
          exit={{ scale: 0.9, opacity: 0, y: 50 }}
          transition={{ type: "spring", duration: 0.5 }}
        >
          <motion.button 
            className={styles.closeButton}
            whileHover={{ rotate: 90 }}
            transition={{ duration: 0.2 }}
            onClick={onHide}
          >
            <FaTimes />
          </motion.button>

          <div className={styles.modalHeader}>
            <motion.div 
              className={styles.scoreCircle}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ 
                type: "spring",
                delay: 0.2,
                duration: 0.8
              }}
            >
              <motion.div 
                className={styles.scoreValue}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
              >
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  {totalScore}
                </motion.span>
              </motion.div>
              <motion.div 
                className={styles.scoreRing}
                style={{
                  background: `conic-gradient(#4CAF50 ${totalScore}%, transparent ${totalScore}%)`
                }}
                initial={{ rotate: -90 }}
                animate={{ rotate: 270 }}
                transition={{ duration: 1.5, delay: 0.5 }}
              />
              <motion.span 
                className={styles.scoreLabel}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.7 }}
              >
                Overall Score
              </motion.span>
            </motion.div>
          </div>

          <div className={styles.sectionsGrid}>
            {Object.entries(resumeScore.sections).map(([category, score], index) => (
              <motion.div
                key={category}
                className={styles.sectionCard}
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ 
                  delay: index * 0.1 + 0.8,
                  type: "spring",
                  stiffness: 100 
                }}
                whileHover={{ 
                  scale: 1.03,
                  transition: { duration: 0.2 }
                }}
              >
                <div className={styles.sectionHeader}>
                  <h3>{category.replace(/([A-Z])/g, ' $1').trim()}</h3>
                  <motion.div 
                    className={styles.sectionScore}
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ delay: index * 0.1 + 1 }}
                  >
                    {score}/{SECTION_MAX_SCORES[category]}
                  </motion.div>
                </div>
              </motion.div>
            ))}
          </div>

          <motion.button
            className={styles.viewAnalysisButton}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.2 }}
            whileHover={{ 
              scale: 1.05,
              boxShadow: "0 8px 25px rgba(76, 175, 80, 0.5)"
            }}
            whileTap={{ scale: 0.95 }}
            onClick={() => {
              onHide();
              onShowAnalysis?.();
            }}
          >
            <span>Show Analysis Result</span>
            <motion.div
              animate={{ 
                x: [0, 5, 0],
                transition: { 
                  repeat: Infinity, 
                  duration: 1.5 
                }
              }}
            >
              <FaChartLine className={styles.buttonIcon} />
            </motion.div>
          </motion.button>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ScoreModal;