import React from 'react'
import HiringWithUs from '../HiringWithUs'

 

const CompanyScroll = () => {
  return (
    <div className='container'>
       <div className='row d-flex justify-content-center' >
                <div className=' d-lg-flex d-none col-11 col-md-2   justify-content-center align-items-center '>
                 <span style={{fontWeight:'700'}} >
                 Companies Hiring With Us
                  </span> 
                 </div>
           <div className='col-11 col-md-10'>
                <HiringWithUs/>
           </div> 
          
       </div>
    </div>
  )
}

export default CompanyScroll