import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { GoogleOAuthProvider } from "@react-oauth/google";
import AuthProvider from "./components/ResumeBuilder/builder/components/context/auth";
import ResumeProvider from "./components/ResumeBuilder/builder/components/context/Resume";
import JobProvider from "./components/ResumeBuilder/builder/components/context/Jobs";
import { CreditsProvider } from "./components/ResumeBuilder/builder/components/context/CreditsContext";
import mixpanel from "mixpanel-browser";
const root = ReactDOM.createRoot(document.getElementById("root"));
mixpanel.init("c53feb9d5c57a68dfb5cb87594fa977e", {
  debug: false,
  track_pageview: true,
  persistence: "localStorage",
});
root.render(
  <BrowserRouter>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Toaster />
      <CreditsProvider>
        <JobProvider>
          <AuthProvider>
            <ResumeProvider>
              <App />
            </ResumeProvider>
          </AuthProvider>
        </JobProvider>
      </CreditsProvider>
    </GoogleOAuthProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
