import {
  faLocationDot,
  faBriefcase,
  faUserTie,
  faMoneyCheckDollar,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../ResumeBuilder/builder/components/context/auth";
import { useJobs } from "../ResumeBuilder/builder/components/context/Jobs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CalendarDays,
  History,
  Share2,
  BookmarkCheck,
  BookmarkPlus,
  Search,
  Copy,
  X,
} from "lucide-react";
import slugify from "slugify";
import {
  LinkedinShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  FacebookShareButton,
  EmailShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  TwitterIcon,
} from "react-share";
import React, { useEffect,useState } from "react";
import { toast } from "react-hot-toast";
import HelmetConfig from "../components/HelmetConfig";
import BottomBar from "../components/BottomBar";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../assets/Job.module.css";
import Modal from "react-bootstrap/Modal";
import MyResponsiveNavbar from "./Navbar";
import Filter from "../components/Filter";
import "swiper/css/effect-cards";
import QRCode from "react-qr-code";
import Footer from "./Footer";
import axios from "axios";
import "swiper/css";
const Jobs = () => {
  const { auth } = useAuth();
  const Navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [setsaved, setissaved] = useState(false);
  let [pageNumber, setPageNumber] = useState(1);
  const { CommingJobs, isfirst, setIsfirst } = useJobs();
  const [skillfromdbapi, setskillfromdibapi] = useState("");
  const [showJobType, setShowJobType] = useState("all");
  const [show, setShow] = useState(false);
  const [slug, setslug] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (value, heading) => {
    setShow(true);
    const slugnew = `${slugify(heading)}-${value}`;
    setslug(slugnew);
  };
  const {jobsInLocation} = useParams();
  useEffect(() => {
    if (!jobsInLocation?.startsWith('jobs-in-')) {
      Navigate('/jobs-in-india'); 
    }
  }, [jobsInLocation, Navigate]);
  const joblocation = jobsInLocation?.replace('jobs-in-', '');
  if(joblocation=='india') {
    joblocation=undefined;  
  }
  const getcommingJobs = async () => {
    try { 
      setJobs([]);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/searchJob?search=${CommingJobs}`
      );
      const data = response.data;
      if (data?.success) {
        const jobs = data.users;
        setJobs(jobs);
      }
     
    } catch (error) {
     
      console.log(error);
    }
  };
  const getUserSkill = async () => {
    try {
      const datalocal = JSON.parse(localStorage.getItem("auth"));
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/skillsbyid`,
        {
          headers: {
            Authorization: `Bearer ${datalocal?.token}`,
          },
        }
      );

      setskillfromdibapi(data?.skillsfromdb?.[0].skills);
    } catch (error) {
      console.log("error is comiing from getuserskill", error);
    }
  };

  const getjobforyou = async () => {
    try {
      
      setJobs([]);
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/filterjob?skills=${skillfromdbapi}`
      );

      if (data?.success) {
        const jobss = data;
        jobss.jobsWithPercentage.sort(
          (a, b) => b.percentageMatched - a.percentageMatched
        );

        setJobs(jobss.jobsWithPercentage);
      }
     
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (CommingJobs && isfirst) {
      getcommingJobs();
      setIsfirst(false);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CommingJobs]);

  useEffect(() => {
    if(auth.token!=='') {
      getUserSkill();
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    
  };

  const handleNavigate = async (value, heading) => {
    const slug = `${slugify(heading)}-${value}`;
    window.open(`/jobs/details/${slug}`, "_blank");
  };

  const handlePrevious = () => {
    setPageNumber(pageNumber--);
  };

  const handleNext = () => {
    setPageNumber(pageNumber++);
  };

  const handleCustomNumber = (n) => {
    setPageNumber(n);
  };

  const handleSearch = async () => {
    try {
      let data; // Define data variable outside of the if-else block
      setJobs([]);
      if (searchTerm !== "") {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/searchJob?search=${searchTerm}`,
        );
        data = response.data;
      }
      if (data?.success) {
        const jobs = data.users;
        setJobs(jobs);
       
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleyearsearch = (value) => {
    Navigate(`/jobs?param=${value}`);
  };

  const handlealljob = () => {
    setShowJobType("all");
    Navigate('')
  };

  const handleInternship = () => {
    setShowJobType("internship");
     Navigate('?jobMode=Internship')
  };

  const handleJobForYou = () => {
    setShowJobType("you");
    if (auth.token !== "") {
      getjobforyou();
    } else {
      setJobs([]);
    }
  };

  const handleapplied = () => {
    setShowJobType("applied");
    setJobs([]);
  };
  const handlesaved = () => {
    setShowJobType("saved");
    setJobs([]);
  };
  const handlBookmark = () => {
    setissaved(!setsaved);
  };

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(`https://myfuse.in/jobs/details/${slug}`)
      .then(() => {
        toast.success("Job URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  return (
    <div>
      <HelmetConfig
        title="Job Listings | Find Your Next Job on MyFuse"
        description="Explore job listings on MyFuse to find your next career opportunity. Browse through a wide range of job openings and apply directly through our platform."
        keywords="
          Latest Job Openings, New Job Listings, Job Vacancies India, Job Opportunities, Find Jobs Online,
          Latest IT Jobs in India 2024, Top Job Vacancies in India This Week, How to Apply for Jobs Online in India, 
          Fresh Job Listings for Indians, Best Sites to Find Job Vacancies in India,
          Jobs India, Job Vacancies, Find Jobs, Employment Opportunities, Hiring Now India,
          Job Postings India, Job Listings, Online Job Applications, Job Boards India, Indian Employment Opportunities,
          Top Companies Hiring in India, Indian Job Search, Job Market Trends India, High-Paying Jobs India, Remote Jobs in India,
          How to Find High-Paying Jobs in India, Latest Job Postings in IT Sector India, Job Vacancies in Indian Startups,
          Remote Job Opportunities in India, How to Apply for Jobs in India Online,
          Indian Job Portals, Employment Listings, Part-time Jobs India, Full-time Jobs India, Freelance Jobs India
        "
        canonical="https://myfuse.in/jobs-in-india"
      />
      <MyResponsiveNavbar />
      <div className={styles.job_search_page_banner}>
        <div class={styles.banner_content}>
          <div class={styles.banner_text_container}>
            <div class={styles.banner_text_1}>
              Say goodbye to job search stress!
            </div>
            <div class={styles.banner_text_2}>
              Find jobs with salaries up to{" "}
              <span className="bold" style={{ fontWeight: "800" }}>
                {" "}
                20 LPA
              </span>
            </div>
          </div>
          <div class={styles.banner_image_container}>
            <div class={styles.banner_image}></div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid   d-flex justify-content-center"
        style={{ backgroundColor: "#f8f9fa", fontSize: "14px" }}
      >
        <div className="row d-flex  justify-content-center">
          <Filter setJobs={setJobs} jobs={jobs} pageNumber={pageNumber} joblocation={joblocation} />

          <div
            className={` col-md-6 scrollable-center mb-4 d-flex   flex-column align-items-start ${styles.dwo}`}
          >
            <div className={styles.search}>
              <input
                type="text"
                placeholder="Search Jobs"
                value={searchTerm}
                onChange={handleInputChange}
                className={styles.des}
                onKeyDown={handleKeyDown}
              />
              <span className={styles.sse} onClick={handleSearch}>
                <Search size={19} />
              </span>
              
            </div>

            <div className={styles.esi}>
              <div className={styles.esi1}>
                <div
                  className={`${styles.dee} ${
                    showJobType === "all" ? styles.actived : ""
                  }`}
                  onClick={handlealljob}
                >
                  All Jobs <span className={styles.greendot}></span>
                </div>
                <div
                  className={`${styles.deeww} ${
                    showJobType === "internship" ? styles.actived : ""
                  } `}
                  onClick={handleInternship}
                >
                  Internship
                </div>
                <div
                  className={`${styles.deew} ${
                    showJobType === "you" ? styles.actived : ""
                  } `}
                  onClick={handleJobForYou}
                >
                  Jobs For You
                </div>
              </div>
            </div>

            <div
              className={` ${styles.skkii} d-none d-lg-flex  justify-content-around m-lg-3  justify-content-lg-start  rounded-full gap-4 bg-white  `}
             
            >
              <div
                className={` ${styles.innerj} ${
                  showJobType === "all" ? styles.activenum : ""
                }`}
                onClick={handlealljob}
              >
                <span className={styles.jj}> Recent Jobs(500+)</span>
              </div>
              <div
                className={` ${styles.innerj} ${
                  showJobType === "internship" ? styles.activenum : ""
                }`}
                onClick={handleInternship}
              >
                Internship (100+)
              </div>
              <div
                className={` ${styles.innerj} ${
                  showJobType === "you" ? styles.activenum : ""
                }`}
                onClick={handleJobForYou}
              >
                Jobs For You(80+)
              </div>
              <div
                className={` ${styles.innerj} ${
                  showJobType === "applied" ? styles.activenum : ""
                }`}
                onClick={handleapplied}
              >
                Applied (0)
              </div>
              <div
                className={` ${styles.innerj} ${
                  showJobType === "saved" ? styles.activenum : ""
                }`}
                onClick={handlesaved}
              >
                Saved (0)
              </div>
            </div>

            {jobs.length === 0 &&
              showJobType === "all" &&
              showJobType === "you" && (
                <div
                  className="contianer d-flex justify-content-center "
                  style={{ width: "100%" }}
                >
                  <div className="row">
                    <div
                      className="col-md-9 col-10 mb-4"
                      style={{ margin: "auto" }}
                    >
                      <img
                        alt="i"
                        src="nodata.gif"
                        style={{ objectFit: "contain" }}
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              )}

            {jobs.length === 0 &&
              auth.token !== "" &&
              showJobType === "applied" && (
                <div
                  className={`contianer d-flex justify-content-center ${styles.dorog}`}
                  
                >
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-9 col-12 mb-4 ">
                      <img
                        src="https://www.recruiter.com/recruiting/wp-content/uploads/2022/08/No-one-applied-for-job-posting.jpg"
                        className="img-fluid"
                        alt="d"
                        style={{ objectFit: "contain" }}
                      />
                      <p className={styles.fdasf}>
                        No applied jobs found! Time to find your next big
                        opportunity and make some waves. 🌊💼
                      </p>
                    </div>
                  </div>
                </div>
              )}
            {jobs.length === 0 &&
              auth.token !== "" &&
              showJobType === "saved" && (
                <div
                className={`contianer d-flex justify-content-center ${styles.dorog}`}
                >
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-9 col-12 mb-4 ">
                      <img
                        src="https://media.licdn.com/dms/image/C4D12AQGX5YCK94kaZQ/article-cover_image-shrink_600_2000/0/1630513808351?e=2147483647&v=beta&t=6I3V3Hl5yi10qr-jp2y2oAWJx7eQ2NVM6QFNy1Pm4F4"
                        className="img-fluid"
                        style={{ objectFit: "contain" }}
                        alt="dasd"
                      />
                      <p className={styles.fdasf}>
                        No saved jobs found! Your dream job is out there waiting
                        to be bookmarked. 📌💼
                      </p>
                    </div>
                  </div>
                </div>
              )}

            {(showJobType === "saved" ||
              showJobType === "applied" ||
              showJobType === "you") &&
              auth.token === "" && (
                <div
                className={`contianer d-flex justify-content-center ${styles.dorog}`}
                >
                  <div className="row d-flex justify-content-center">
                    <div
                      className="col-md-9 col-12 mb-4 "
                      style={{ overflow: "hidden" }}
                    >
                      {showJobType === "you" && (
                        <>
                          <img
                            src="https://res.cloudinary.com/dvepwlz9j/image/upload/f_auto,q_auto/v1/all/vwabkm4j4fopfpbvjamg"
                            className="img-fluid" 
                            alt="dasd"
                            style={{ objectFit: "contain", overflow: "hidden" }}
                          />
                          <p className={styles.fdasf}>
                            Jobs For You: Login first to unlock your career
                            destiny! 🔑💼
                          </p>
                        </>
                      )}
                      {showJobType === "applied" && (
                        <>
                          <img
                            src="https://res.cloudinary.com/dvepwlz9j/image/upload/f_auto,q_auto/v1/all/uz56kgm5lspbafwxxgqr"
                            className="img-fluid"
                            style={{ objectFit: "contain" }}
                            alt="dd"
                          />

                          <p className={styles.fdasf}>
                            Applied Jobs: Login first to see where your career
                            adventures have taken you! 🌟🚀
                          </p>
                        </>
                      )}
                      {showJobType === "saved" && (
                        <>
                          <img
                            src="https://res.cloudinary.com/dvepwlz9j/image/upload/f_auto,q_auto/v1/all/quv2avizlbmkbaafy2wr"
                            className="img-fluid"
                            style={{ objectFit: "contain" }}
                            alt="sfs"
                          />

                          <p className={styles.fdasf}>
                            Saved Jobs: Login first to revisit your top picks
                            and keep your career goals on track! 🔑📂
                          </p>
                        </>
                      )}
                      <div className="d-flex justify-content-center">
                        <a className={styles.jobBottom} href="/login">
                          Login
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {jobs?.map((job, index) => {
              const timestamp = job.createdat; // Assuming job.createdAt is the timestamp
              const date = new Date(timestamp);

              const getRelativeDate = (date) => {
                const now = new Date();
                const diffTime = Math.abs(now - date);
                const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

                if (diffDays === 0) {
                  return "today";
                } else if (diffDays === 1) {
                  return "yesterday";
                } else if (diffDays <= 6) {
                  return `${diffDays} days ago`;
                } else if (diffDays <= 13) {
                  return "1 week ago";
                } else if (diffDays <= 20) {
                  return "2 weeks ago";
                } else {
                  return date.toLocaleDateString("en-US", {
                    weekday: "short",
                    month: "short",
                    day: "numeric",
                  });
                }
              };
              const formattedDate = getRelativeDate(date);

              return (
                <div
                  className="col-md-12 col-12"
                  key={job.id_jobpost}
                  style={{ margin: "auto", padding: "10px" }}
                >
                  <div className={`card  ${styles.jobsmaindiv}`}>
                    <div className="card-body">
                      <div
                        className="d-flex justify-content-between"
                        onClick={() =>
                          handleNavigate(job.id_jobpost, job.jobtitle)
                        }
                      >
                        <div>
                          <h3 className={styles.hddh}> {job.jobtitle} </h3>
                          <h5 className={styles.down}>
                            {" "}
                            {job.companyName} | {job.job_nature}{" "}
                          </h5>
                        </div>

                        <div>
                          <img
                            src={`https://old.myfuse.in/uploads/logo/${job.companyLogo}`}
                            style={{ width: "5rem" }}
                            className="img-fluid"
                            alt="i"
                          />
                        </div>
                      </div>

                      {/* this  skill section end */}
                      <div
                        className="container-fluid"
                        onClick={() =>
                          handleNavigate(job.id_jobpost, job.jobtitle)
                        }
                      >
                        <div className="row">
                          <div className="col-12 col-md-12 mt-2">
                            <div className="container">
                              <div className="row">
                                <div
                                  className="col-6 col-md-6"
                                  style={{ padding: "0px" }}
                                >
                                  <p className={styles.pard}>
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faBriefcase}
                                      className={styles.iconstyle}
                                    />
                                    {job.qualification.length > 10
                                      ? `${job.qualification.substring(
                                          0,
                                          10
                                        )}...`
                                      : job.qualification}
                                  </p>
                                  <p
                                    className={styles.pard}
                                    style={{ marginBottom: "1rem" }}
                                  >
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faLocationDot}
                                      className={styles.iconstyle}
                                    />{" "}
                                    {job.location.length > 10
                                      ? `${job.location.substring(0, 10)}...`
                                      : job.location}
                                  </p>
                                </div>
                                <div
                                  className={`col-6 col-md-6 ${styles.parad}`}
                                >
                                  <p className={styles.pard}>
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faMoneyCheckDollar}
                                      className={styles.iconstyle}
                                    />
                                    {job.minimumsalary.length > 20
                                      ? `${job.minimumsalary.substring(
                                          0,
                                          20
                                        )}...`
                                      : job.minimumsalary}
                                  </p>
                                  <p className={styles.pard}>
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faUserTie}
                                      className={styles.iconstyle}
                                    />{" "}
                                    {job.experience}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="container">
                          <div className="row d-flex flex-row ">
                            {showJobType === "you" && (
                              <div
                                className="col-11 col-md-12  d-flex justify-content-center m-2  "
                                style={{ color: "#13800D", fontWeight: "500" }}
                              >
                                {Math.min(job.percentageMatched, 100) === 0
                                  ? "Partially Matched"
                                  : ` ${
                                      auth?.user?.[0]?.firstname
                                    }!!! Your Profile is ${Math.min(
                                      job.percentageMatched,
                                      100
                                    )}% Matched`}
                              </div>
                            )}

                            <div className={styles.jas}>
                              <div className={styles.posted}>
                                <History size={13} /> Posted {"  "}
                                {formattedDate}
                              </div>

                              <div className={styles.iconssharebookmarkmain}>
                                <span>
                                  {" "}
                                  <Share2
                                    size={20}
                                    color={"#5811af"}
                                    onClick={() =>
                                      handleShow(job.id_jobpost, job.jobtitle)
                                    }
                                  />
                                </span>
                                <span style={{ marginLeft: "10px" }}>
                                  {!setsaved ? (
                                    <BookmarkCheck
                                      size={20}
                                      color={"#5811af"}
                                      onClick={handlBookmark}
                                    />
                                  ) : (
                                    <BookmarkPlus
                                      size={20}
                                      color={"#5811af"}
                                      onClick={handlBookmark}
                                    />
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {index === 6 && (
                    <div
                      className={`card mt-4 ${styles.searchcontainer}`}
                      
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <h1
                              className={styles.heear}
                            >
                              <CalendarDays size={18} /> Search By Experience!
                            </h1>
                            <div
                              className={styles.sala}
                            >
                              <span
                                className={styles.golao}
                                onClick={() => handleyearsearch("Fresher")}
                              >
                                0-1years
                              </span>
                              <span
                                className={styles.golao}
                                onClick={() => handleyearsearch("1-2 Years")}
                              >
                                1-2 Years
                              </span>
                              <span
                                className={styles.golao}
                                onClick={() => handleyearsearch("2-3 Years")}
                              >
                                2-3 Years
                              </span>
                              <span
                                className={styles.golao}
                                onClick={() => handleyearsearch("3-4 Years")}
                              >
                                3-4 Years
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          {/* )} */}

          <div className="col-md-3 mb-4 fixed-col col-11 d-none d-lg-block">
            <div className={styles.sogr}>
              <div className={styles.teammy}>
                <p>
                  Jobs posted on our portal come directly from employers, marked
                  with the '
                  <span
                    className={styles.sssd}
                  >
                    Easy Apply
                  </span>{" "}
                  'option, and are exclusively available through our site.
                </p>
                <p>
                  We’ve handpicked these jobs for you, ensuring you have
                  everything in one place. Share these opportunities with your
                  friends so they can benefit too.
                </p>
                <p  className={styles.fontbold} >---Team Myfuse</p>
              </div>
              <img
                className="img-fluid"
                style={{ marginTop: "1rem", borderRadius: "10px" }}
                src="https://i.ytimg.com/vi/9C9hGpkJNnk/maxresdefault.jpg"
                alt="i"
              />
            </div>
          </div>
        </div>
      </div>
      {jobs.length !== 0 && (
        <div
          className="d-flex justify-content-center  "
          style={{ gap: "10px", position: "relative", top: "-2rem" }}
        >
          <span className={`${styles.numbercss}`} onClick={handlePrevious}>
            Previous
          </span>
          <span
            className={`${styles.numbercss} ${
              pageNumber === 1 ? styles.activenum : ""
            }`}
            onClick={() => handleCustomNumber(1)}
          >
            1
          </span>
          <span
            className={`${styles.numbercss} ${
              pageNumber === 2 ? styles.activenum : ""
            }`}
            onClick={() => handleCustomNumber(2)}
          >
            2
          </span>
          <span
            className={`${styles.numbercss} ${
              pageNumber === 3 ? styles.activenum : ""
            }`}
            onClick={() => handleCustomNumber(3)}
          >
            3
          </span>
          <span
            className={`${styles.numbercss} ${
              pageNumber === 4 ? styles.activenum : ""
            }`}
            onClick={() => handleCustomNumber(4)}
          >
            4
          </span>
          <span className={`${styles.numbercss}`} onClick={handleNext}>
            Next
          </span>
        </div>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Button variant="link" onClick={handleClose} className={styles.dd}>
          <X onClick={handleClose} />
        </Button>
        <Modal.Body>
          <div className="container p-4">
            <p className={styles.deo}>Share With Your Friends</p>

            <div className={styles.doo}>
              <LinkedinShareButton
                url={`https://myfuse.in/jobs/details/${slug}`}
                title={`MyFuse Jobs`}
              >
                <LinkedinIcon className={styles.ldo} round />
              </LinkedinShareButton>

              <WhatsappShareButton
                url={`https://myfuse.in/jobs/details/${slug}`}
                itle={`MyFuse Jobs`}
                separator=":: "
              >
                <WhatsappIcon className={styles.ldo} round />
              </WhatsappShareButton>

              <TwitterShareButton
                url={`https://myfuse.in/jobs/details/${slug}`}
                itle={`MyFuse Jobs`}
              >
                <TwitterIcon className={styles.ldo} round />
              </TwitterShareButton>
              <FacebookShareButton
                url={`https://myfuse.in/jobs/details/${slug}`}
                quote={"Myfuse jobs"}
              >
                <FacebookIcon className={styles.ldo} round />
              </FacebookShareButton>
              <EmailShareButton
                url={`https://myfuse.in/jobs/details/${slug}`}
                subject={"myfuse jobs"}
                body="Check out this job: "
              >
                <EmailIcon className={styles.ldo} round />
              </EmailShareButton>
            </div>

            <div className={styles.dloo}>
              <div className={styles.dlod}>
                <QRCode
                  value={`https://myfuse.in/jobs/details/${slug}`}
                  viewBox={`0 0 256 256`}
                  fgColor={"#142683"}
                  className={styles.llo}
                />
                <p className={styles.ll}>Scan the QR For Link </p>
              </div>
              <div className={styles.cdlod}>
                {/* apply texarea  */}
                <textarea
                  className={styles.cdl}
                  placeholder="Enter your email address"
                  rows="4"
                  cols="50"
                  value={`I found a great job on MyFuse with fantastic perks that might interest you. 
                    Check it out and apply if it fits your career goals:
                    Link: https://myfuse.in/jobs/details/${slug}
                    Good luck!
                    `}
                  style={{ fontSize: "14px", color: "#333", fontWeight: "500" }}
                />

                <span className={styles.copyurl} onClick={handleCopyClick}>
                  {" "}
                  <Copy size={15} style={{ marginRight: "10px" }} /> Copy Link
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Footer />
      <BottomBar />
    </div>
  );
};

export default Jobs;
