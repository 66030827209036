import React, { useEffect, useState } from "react";
import styles from "../../assets/HomePage/Blog.module.css";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "../../assets/companyList.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import slugify from "slugify";

const Blog = () => {
  const navigate = useNavigate();
  // let array = [];
  const [blogs, setBlogs] = useState([]);
  const getAllBlog = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/Blogs`,
        {
          headers: {
            Authorization: "Bearer madebyashutosh",
          },
        }
      );

      setBlogs(data?.users);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllBlog();
  }, []);

  const handleMove = async (value, heading) => {
    const slug = `${slugify(heading)}-${value}`;
    navigate(`/blog/${slug}`);
  };

  return (
    <div className={styles.main}>
      <div className={styles.inner}>
        <div>
          <h2 className={styles.headd}> Recent Blogs</h2>
          <span className="_ngcontents">
            Stay updated with the latest trends and insights by checking out our
            recent blogs!
          </span>
        </div>

        <div className={styles.mak}>
          <Swiper
            spaceBetween={10}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className={`mySwiper`}
            slidesPerView={1} // default to 1 for small screens
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            navigation={{
              nextEl: ".custom-swiper-button-next",
              prevEl: ".custom-swiper-button-prev",
            }}
            pagination={{
              clickable: true,
              el: ".swiper-pagination",
            }}
          >
            {blogs.map((ele, index) => {
              return (
                <SwiperSlide className={styles.sliderContainer}>
                  <div className=" relative ">
                    <div className="d-flex justify-content-center relative ">
                      <img
                        src={ele.img_link}
                        alt={ele.img_link}
                        className={`img-fluid ${styles.imgagee}`}
                      />
                      <br></br>
                    </div>
                    <div className={styles.tagcont}>
                      {ele.blog_tag.split(",").map((individualTag, index) => (
                        <span key={index} className={styles.tagItem}>
                          {individualTag.trim()}
                        </span>
                      ))}
                    </div>
                    <div className={styles.dioro}>
                      <h2
                        className={styles.headings}
                        onClick={() => handleMove(ele.S_No, ele.blog_heading)}
                      >
                        {ele.blog_heading}
                      </h2>
                      <p className={styles.short}> {ele.short_desc}</p>
                    </div>

                    <div className={styles.ineoro}>
                      <div className={styles.prohfi}>
                        <div>
                          <img
                          
                            src= "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiMxOKqtdSW8ybpkCH_mVD7dmsIv5z03gaHg&s"
                            className={styles.myimages}
                            alt="ss"
                          />
                        </div>
                        <div>
                          <p className={styles.name}>
                            MyFuse.in{" "}
                          </p>
                          <p className={styles.time}>
                            {new Date(ele.time_stamp).toLocaleString()}{" "}
                          </p>
                        </div>
                      </div>
                      <div className={styles.trscontianer}>
                           <p className={styles.trs}>{ele.TR} mins to read</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Blog;
