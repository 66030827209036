import React from 'react'
import Accordion from "react-bootstrap/Accordion"; 
import styles from "../../assets/ResumeHomePage/Faq.module.css"
const Faq = () => {
  return (
    <div className={`container-fluid   mb-4  mt-4 p-lg-4 ${styles.main} `}>
        <div
          className="row d-flex justify-content-center "
          style={{ background: "rgb(241 245 249)" }}
        >
          <div className={`col-md-4 col-11 ${styles.imgrea} `}>
            <p
              className={styles.as}
            >
              Your frequently asked questions.
            </p>
            <p
              style={{
                textAlign: "center ",
                fontWeight: "700",
                fontSize: "1rem",
              }}
            >
              Get answers to common queries about our platform and mentorship
              opportunities.
            </p>
            <img
              src="https://cdni.iconscout.com/illustration/premium/thumb/faq-illustration-download-in-svg-png-gif-file-formats--customer-questions-and-answers-helpdesk-pack-network-communication-illustrations-3749051.png?f=webp"
              className="img-fluid"
              alt="sasd"
            />
          </div>
          <div className="col-md-5 col-12 mt-4 mb-4 p-lg-4">
            <Accordion defaultActiveKey="-1" className="mt-4">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {" "}
                  Why is MyFuse the best resume builder online?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Because it offers a comprehensive set of features and
                    benefits designed to make the job search journey easier and
                    more effective. Here's how:
                  </p>

                  <h6>Professionally-Designed Templates</h6>
                  <p>
                    MyFuse’s online resume builder offers a wide range of
                    professionally-designed resume templates and cover letter
                    templates with features and elements that let you customize
                    them however you like.
                  </p>

                  <h6>Autopilot Feature</h6>
                  <p>
                    MyFuse Autopilot allows you to assemble your resume from
                    more than 20,000 pre-written phrases for more than 3,200
                    positions. This helps you find the right words right from
                    the start and write your resume quickly.
                  </p>

                  <h6>AI Resume Writer</h6>
                  <p>
                    The AI resume writer generates a relevant work experience
                    section for a specific job title, making the resume writing
                    process more efficient and effective.
                  </p>

                  <h6>AI Cover Letter Writer</h6>
                  <p>
                    The AI cover letter writer can generate role-specific cover
                    letters that look and feel like cover letters written by
                    real people, enhancing your chances of making a positive
                    impression on potential employers.
                  </p>

                  <h6>AI Resume Checker</h6>
                  <p>
                    The AI resume checker analyzes your resume to find out what
                    needs to be improved, ensuring that your resume is optimized
                    for applicant tracking systems (ATS) and human recruiters
                    alike.
                  </p>

                  <h6>LinkedIn Data Import</h6>
                  <p>
                    The LinkedIn data import option makes it easy to create a
                    resume in a few clicks, saving you the hassle of starting
                    from scratch and ensuring accuracy and consistency across
                    platforms.
                  </p>

                  <h6>Resume and Cover Letter Samples</h6>
                  <p>
                    MyFuse provides resume and cover letter samples that you can
                    use to create your first draft or get inspired. The database
                    holds over 1,500 job-specific resumes belonging to real
                    people.
                  </p>

                  <p>
                    If you’re not convinced yet, we encourage you to explore
                    other resume builders and compare their features and
                    benefits. See how MyFuse stands out in terms of
                    functionality, design, and user satisfaction.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Guide to Creating Your Resume with MyFuse
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    If you don’t know where to begin, don’t worry. MyFuse will
                    guide you through every stage of writing your resume, one
                    section at a time.
                  </p>

                  <h6>Getting Started</h6>
                  <ol>
                    <li>Sign up or log in to your MyFuse account.</li>
                    <li>
                      When logged in, on the dashboard scroll down to Your
                      Documents and click on Create New.
                    </li>
                  </ol>

                  <h6>Choose Your Approach</h6>
                  <p>Next, you can choose among:</p>
                  <ul>
                    <li>
                      Creating a brand new resume using the template and
                      customization options of your choice.
                    </li>
                    <li>
                      Importing your existing data from LinkedIn and choosing
                      your preferred design.
                    </li>
                    <li>
                      Using one of 1,500+ existing resume samples and tweaking
                      the details to match yours.
                    </li>
                  </ul>

                  <h6>Adding Content</h6>
                  <p>
                    Finally, you add resume sections and populate each one with
                    relevant content. You can also assemble the first draft of
                    your resume just by clicking or using AI:
                  </p>
                  <ol>
                    <li>
                      We’ve got more than 20,000 pre-written phrases for more
                      than 3,200 jobs. Simply start editing your resume, click
                      “Add Phrases”, look up your job title, and watch your
                      resume write itself.
                    </li>
                    <li>
                      AI resume writer will generate a relevant work experience
                      section for a specific job title. You can then edit it and
                      tweak the details to make it more you.
                    </li>
                  </ol>

                  <p>
                    If you're not sure about how to write the individual
                    sections of your resume, you can visit our Help Center, read
                    the ultimate resume guide, or click the chameleon icon
                    inside the app.
                  </p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  How to Make Your Resume Stand Out with MyFuse?
                </Accordion.Header>
                <Accordion.Body>
                  <h6>Design:</h6>
                  <ol>
                    <li>
                      Choose a visually appealing yet professional template that
                      fits your industry and profession.
                    </li>
                    <li>
                      Ensure that the design is ATS-friendly to increase the
                      chances of passing through applicant tracking systems.
                    </li>
                  </ol>

                  <h6>Content:</h6>
                  <ol>
                    <li>
                      Use MyFuse's features to assemble the first draft of your
                      resume effortlessly:
                    </li>
                    <ul>
                      <li>
                        Access over 20,000 pre-written phrases tailored for more
                        than 3,200 job titles. Simply click "Add Phrases", look
                        up your job title, and select relevant phrases to
                        include in your resume.
                      </li>
                      <li>
                        Select from a library of 1,500+ job-specific resume
                        samples and customize the information to reflect your
                        unique skills and experiences.
                      </li>
                      <li>
                        Utilize MyFuse's AI resume writer, which generates a
                        relevant work experience section based on a specific job
                        title, streamlining the resume writing process.
                      </li>
                    </ul>
                  </ol>

                  <p>
                    By leveraging MyFuse's design options and content creation
                    features, you can create a standout resume that effectively
                    showcases your strengths and maximizes your chances of
                    securing interviews.
                  </p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  How does the AI Resume Writer work?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    MyFuse’s AI Resume Writer is able to generate a work
                    experience section based on a provided job title.
                  </p>

                  <p>All you have to do is:</p>

                  <ol>
                    <li>
                      Sign up or log in to your MyFuse account. When logged in,
                      on the dashboard scroll down to Your Documents and click
                      on Create New.
                    </li>
                    <li>
                      When filling in the Work Experience section, enter your
                      job title, click “Use AI Writer” and the AI will generate
                      a number of bullet points for your work experience
                      subsection.
                    </li>
                    <li>
                      If you don't like these bullet points, you can either edit
                      them or delete them and click the button again.
                    </li>
                    <li>
                      If you like the bullet points but feel like that section
                      is still too short, simply click the button again and the
                      AI will add more phrases to it.
                    </li>
                  </ol>

                  <p>
                    Our AI Resume Builder is easy to use, generates unique
                    resumes every time, and uses natural language
                    indistinguishable from resumes written by human writers.
                  </p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  What is the best AI Resume Builder?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Definitely MyFuse's AI Resume Builder. Here are the reasons
                    why:
                  </p>

                  <ul>
                    <li>
                      MyFuse AI Resume Builder is based on OpenAI’s GPT-3 neural
                      network, which is the world's most powerful natural
                      language processing model.
                    </li>
                    <li>
                      The texts produced by this language model are
                      indistinguishable from texts written by human writers.
                    </li>
                    <li>
                      Each resume that is produced by our AI Resume Builder is
                      unique and custom-built for you.
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item> 

              <Accordion.Item eventKey="5">
                <Accordion.Header>
                Why job seekers shouldn’t use tools like ChatGPT or Canva to create resumes?
                </Accordion.Header>
                <Accordion.Body>
                <p>
                While tools like ChatGPT and Canva are great for general text generation and design, they are not optimized for resume creation.
                  </p>
                  <ol>
                    <li>
                    ChatGPT: Although ChatGPT can generate well-written content, it lacks the job-specific features of platforms like MyFuse. It doesn’t tailor resumes to meet industry standards or ATS (Applicant Tracking System) requirements, which are crucial for job applications. This can result in a resume that looks good but lacks the precise structure, keywords, and formatting necessary to impress hiring managers and ATS systems.

                    </li>
                    <li>
                    formatting necessary to impress hiring managers and ATS systems.
Canva: Canva’s design focus often prioritizes aesthetics over functionality. Many of its resume templates, while visually appealing, may not be ATS-friendly. Resumes created on Canva might fail to pass through automated screening systems due to improper formatting or lack of keyword optimization.

                    </li>
                  </ol>

                  
                 
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6">
                <Accordion.Header>
                How does MyFuse ensure the security of my data?
                </Accordion.Header>
                <Accordion.Body>
                <p>
                At MyFuse, we take the security of your personal information seriously. We use industry-standard encryption protocols to protect all data transmitted to and from our platform. This includes your resume details, personal data, and any information generated by our AI tools. Additionally, our servers are protected by advanced firewalls and undergo regular security audits to prevent unauthorized access. Your privacy is our priority, and we never share your data with third parties without your explicit consent.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
  )
}

export default Faq