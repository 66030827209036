import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import styles from "../../assets/Account.module.css";
import { useAuth } from "../../ResumeBuilder/builder/components/context/auth";
import OpenAI from "openai";
import axios from "axios";
import { toast } from "react-hot-toast";
import { CirclePlus, Trash2 } from "lucide-react";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

export const AIAboutMe = ({
  showAbout,
  handleCloseAboutMe,
  loading,
  HashLoader,
  setLoading,
  updateAuthContext,
}) => {
  const { auth } = useAuth();
  const [desc, setdesc] = useState(auth?.user?.[0]?.aboutme);
  const [ans, setans] = useState("");
  const [ailoading, setloading] = useState(false);

  const handleIntro = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/updatedintro`,
        {
          email: auth?.user?.[0]?.email,
          aboutme: desc,
        },
        {
          headers: {
            Authorization: "Bearer madebyashutosh",
          },
        }
      );

      updateAuthContext(auth?.user?.[0]?.email);
      setLoading(false);
      toast.success("Intro Updated!!");
      handleCloseAboutMe();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleGenereateWithAI = async () => {
    try {
      setloading(true);
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content:
              "You're a about me section Writer for Proffestional Job seeker .",
          },
          {
            role: "user",
            content: `UserInput= ${ans}`,
          },
          {
            role: "user",
            content: `so  i provide the short descrition of job seeker  . Please provide me about me section text between 30 to 40 words  in description, give it me in paragarph add give random percentage in it `,
          },
        ],
        model: "gpt-3.5-turbo",
        temperature: 1,
        max_tokens: 1000,
      });

      // Add AI response to the conversation
      let aiResoponse = completion.choices[0].message.content;

      setdesc(aiResoponse);
      setloading(false);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  return (
    <Modal show={showAbout} onHide={handleCloseAboutMe} size="2xl">
      <Modal.Header closeButton className={styles.introheading}>
        About Me
      </Modal.Header>
      <Modal.Body>
        <div className={styles.aboutmeConatinaer}>
          <textarea
            id="heading"
            name="heading"
            className={styles.textaree}
            value={desc}
            onChange={(e) => setdesc(e.target.value)}
            placeholder="Enter Intro...."
            rows={6} // Adjust rows as needed
            cols={59} // Adjust columns as needed
          />

          <div className={styles.wantto}>
            <p className={styles.wantotpara}>Want to Generate With AI ?</p>
            <div className={styles.wantContainer}>
              <div className={styles.wantinput}>
                <input
                  type="text"
                  placeholder="Give me short description about you......"
                  id="name"
                  name="name"
                  value={ans}
                  onChange={(e) => setans(e.target.value)}
                />
              </div>
              <div
                className={styles.buttonsswant}
                onClick={handleGenereateWithAI}
              >
                {ailoading ? (
                  <HashLoader color="white" size={20} />
                ) : (
                  "Generate With AI"
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.uploadCoverContainer}>
          <div className={styles["input-container"]}>
            <span className={styles.savebutton} onClick={handleIntro}>
              {loading ? <HashLoader color="white" size={20} /> : "Update"}
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const AIExperience = ({
    showExperience,
  handleCloseExperience,
  loading,
  HashLoader,
  setLoading,
  handledeleteExperience
}) => {
  const { auth ,setAuth } = useAuth();
  
  const [ailoading, setailoading] = useState(false);
  const [array, setArray] = useState([]);
  const [companyName, setCompanyName] = useState(null);
  const [companyDesc, setCompanyDesc] = useState(null);
  const [companystartDate, setCompanystartDate] = useState(null);
  const [companyendDate, setCompanyendDate] = useState(null);
  const [companyLocation, setCompanyLocation] = useState(null);
  const [companyRole, setCompanyRole] = useState(null);
  const [point, setpoint] = useState([]);
  const [companyType, setCompanyType] = useState(null);

  useEffect(()=>{
    //   let jsonString = auth?.user?.[0]?.experience; 
    //   jsonString = jsonString?.replace(/[\u0000-\u001F\u007F-\u009F]/g, '');
    // let arrayrecieved = JSON.parse(jsonString) ? JSON.parse(jsonString) : []; 
    // setArray(arrayrecieved);
},[setAuth,handledeleteExperience])

  const updateAuthContext = async(email) =>{
   
    try {
       const { data } = await axios.post(
          `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/contextUpdate`,
          {
            email:email
          },
          {
            headers: {
              Authorization: "Bearer madebyashutosh",
            },
          },
        ); 
        setAuth({
          ...auth,
          user: data.user,
           
        });
    } catch (error) {
        console.log(error)
    }


 }


 const handleAdd = async () => {
    try {
      setLoading(true);
  
      // Create the new array item
      const newItem = {
        company: companyName,
        role: companyRole,
        timePeriod: `${companystartDate},${companyendDate}`,
        description: companyDesc,
        points: point,
        companyType:companyType,
        companyLocation:companyLocation,
      };
  
      // Update the state
      const updatedArray = [...array, newItem];
      setArray(updatedArray);
  
      // Make the API call with the updated array
      await axios.post(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/updatedintro`,
        {
          email: auth?.user?.[0]?.email,
          exp: JSON.stringify(updatedArray), // Use the updated array
        },
        {
          headers: {
            Authorization: "Bearer madebyashutosh",
          },
        }
      );
  
      // Update the auth context and other state
      updateAuthContext(auth?.user?.[0]?.email);
      setLoading(false);
      toast.success("Experience Updated!!");
      setCompanyName("");
      setCompanyRole("");
      setCompanystartDate("");
      setCompanyendDate("");
      setCompanyDesc("");
      setpoint([]);
      setCompanyType("");
      setCompanyLocation("");

      handleCloseExperience();    
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  

  const addKeyPoint = () =>{
    setpoint([...point,''])
  }

  const handleInputkeypointChange = (index,value) =>{
    const newpointarray = [...point]; 
    newpointarray[index]=value;
    setpoint(newpointarray);
  }
const handleDeletekeypoint = (index) =>{
    const newpointarray = [...point];
    //usefilter method
    newpointarray.splice(index,1);
    setpoint(newpointarray);
    }


    const generateAIDesc = async () => {
        try {
            setailoading(true)
          // Add user message to the conversation
          const completion = await openai.chat.completions.create({
            messages: [
              {
                role: "system",
                content:
                  "You're a resume maker, helping users build strong resumes.",
              },
              {
                role: "user",
                content: `company= ${companyName}, job roles= ${companyRole}, time period start = ${companyendDate} time period end = ${companyendDate}.`,
              },
              {
                role: "user",
                content: `so i alreday provide you the company name job roles and timeperiod . Please provide me descripiton for given experience in 30 to 40 words  in description first write techonoly i used by sepataing "|" , what you do there and how did you do , give it me in paragarph`,
              },
            ],
            model: "gpt-3.5-turbo",
            temperature: 1,
            max_tokens: 1000,
          });
      
          // Add AI response to the conversation
          let aiResoponse = completion.choices[0].message.content; 
          console.log("this is reso" , aiResoponse)
          setCompanyDesc(aiResoponse)
           setailoading(false)
        } catch (error) {
          console.error(error);
          setailoading(false);
        }
      };

      const handlegenerateaipoint = async() =>{
        try {
            setailoading(true)
            const completion = await openai.chat.completions.create({
                messages: [
                  {
                    role: "system",
                    content:
                      "You're a resume maker, helping users build strong resumes . only give me point in array only and make sure no string will be in response each must be 150 words",
                  },
                  { role: "user", content: `job role= ${companyRole} company = ${companyName} time period start = ${companyendDate} time period end = ${companyendDate}` },
                  {
                    role: "user",
                    // content: `give 8 point and include percentage wise point  while i am in this job role so i can add in my resume  and provide me only array `,
                    content: `give me 8 point  and include percentage wise point in between the sentence aprox  while i am in this job role so i can add in my resume  and provide me only array `,
                  },
                ],
                model: "gpt-3.5-turbo",
                temperature: 1,
                max_tokens: 1000,
              });
        
              const jsonString = completion.choices[0].message.content;
              const suggestions = JSON.parse(jsonString);
              setpoint(suggestions)
              setailoading(false);

        } catch (error) {
            console.error(error);
          setailoading(false);
        }
      }

  return (
    <Modal show={showExperience} onHide={handleCloseExperience} size="2xl">
      <Modal.Header closeButton className={styles.introheading}>
        Add Experience
      </Modal.Header>
      <Modal.Body>
        <div className={styles.aboutmeConatinaer}>
          <div className={styles.firstlastcont}>
            <div className={styles.innerexperer}>
              <label htmlFor="name"> Company Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
            <div className={styles.innerexperer}>
              <label htmlFor="name">Company Location:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={companyLocation}
                onChange={(e) => setCompanyLocation(e.target.value)}
              />
            </div>
          </div>

          <div className={styles.firstlastcont} style={{ marginTop: "10px" }}>
            <div className={styles.innerexperer}>
              <label htmlFor="name"> Job Title {"  "}</label>
              <input
                type="text"
                id="name"
                name="name"
                value={companyRole}
                onChange={(e) => setCompanyRole(e.target.value)}
              />
            </div>
            <div className={styles.innerexperer}>
              <label htmlFor="name">Job Type </label>
              <input
                type="text"
                id="name"
                name="name"
                value={companyType}
                onChange={(e) => setCompanyType(e.target.value)}
              />
            </div>
          </div>

          <div className={styles.firstlastcont} style={{ marginTop: "10px" }}>
            <div className={styles.innerexperer}>
              <label htmlFor="name"> Starting Date {"  "}</label>
              <input
                type="text"
                id="name"
                name="name"
                value={companystartDate}
                onChange={(e) => setCompanystartDate(e.target.value)}
              />
            </div>
            <div className={styles.innerexperer}>
              <label htmlFor="name">Ending Date</label>
              <input
                type="text"
                id="name"
                name="name"
                value={companyendDate}
                onChange={(e) => setCompanyendDate(e.target.value)}
              />
            </div>
          </div>

          <div className={styles.textareacontainer}>
            <label
              style={{ fontSize: "14px", marginBottom: "5px" }}
              htmlFor="heading"
            >
              Description
            </label>
            <textarea
              id="heading"
              name="heading"
              className={styles.textaree}
              value={companyDesc}
              onChange={(e) => setCompanyDesc(e.target.value)}
              placeholder="Enter Intro...."
              rows={6} // Adjust rows as needed
              cols={59} // Adjust columns as needed
            />
             <div className={styles.buttonssexper}  onClick={generateAIDesc} >
            {ailoading ? (
              <HashLoader color="white" size={20} />
            ) : (
              "AI Writter"
            )}
          </div>
          </div>

          <div
            className={styles.kepointcontainer}
            style={{ marginTop: "10px" }}
          >
            <span className={styles.addkeypoint}  >
              Key Points  
            </span>

            {point.map((ele, index) => (
              <div className={styles.keypointcontainer} key={index}>
                <input
                  type="text"
                  id={`keypoint-${index}`}
                  name={`keypoint-${index}`}
                  value={ele}
                  className={styles.keypointinput}
                  onChange={(e) => handleInputkeypointChange(index, e.target.value)}
                />
                
              <div className={styles.trashcontaiter} onClick={()=>handleDeletekeypoint(index)}><Trash2 size={16}/> </div>  
              
              </div>
            ))}
             <div className={styles.addkeypointlf} onClick={addKeyPoint}>
              Add Key Point <CirclePlus size={15} />{" "}
              <div className={styles.buttonsswantpoint} onClick={handlegenerateaipoint} >
            {ailoading ? (
              <HashLoader color="white" size={20} />
            ) : (
              "AI Writter"
            )}
          </div>
            </div>
          </div>
        </div>

        <div className={styles.uploadCoverContainerforexperience}>
         

          <div className={styles["input-container"]}>
            <span className={styles.savebutton} onClick={handleAdd}>
              {loading ? <HashLoader color="white" size={20} /> : "Add"}
            </span>
            
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const AIEducation = ({
  showEducation,
  handleCloseEducation,
loading,
HashLoader,
setLoading,
handledeleteEducation
}) => {

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  
  const years = Array.from(new Array(50), (val, index) => 2030 - index);

const { auth ,setAuth } = useAuth();

const [array, setArray] = useState([]);
const [companyDesc, setCompanyDesc] = useState(null);
const [degree, setDegree] = useState(null);
const [college , setCollege] = useState(null); 
const [companystartMonth, setCompanystartMonth] = useState('');
const [companystartYear, setCompanystartYear] = useState('');
const [companyendMonth, setCompanyendMonth] = useState('');
const [companyendYear, setCompanyendYear] = useState('');
const [branch , setBranch] = useState(''); 
const [ailoading , setailoading] = useState(false); 
useEffect(() => {
  let jsonString = auth?.user?.[0]?.education;

  if (typeof jsonString === 'string') {
    jsonString = jsonString.replace(/[\u0000-\u001F\u007F-\u009F]/g, '');
    try {
      let arrayReceived = JSON.parse(jsonString);
      setArray(Array.isArray(arrayReceived) ? arrayReceived : []);
    } catch (error) {
      console.error('Error parsing JSON:', error);
      setArray([]);
    }
  } else {
    setArray([]);
  }
}, [auth, setArray , handledeleteEducation]);

const updateAuthContext = async(email) =>{
 
  try {
     const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/contextUpdate`,
        {
          email:email
        },
        {
          headers: {
            Authorization: "Bearer madebyashutosh",
          },
        },
      ); 
      setAuth({
        ...auth,
        user: data.user,
         
      });
  } catch (error) {
      console.log(error)
  }


}


const handleAdd = async () => {
  try {
    setLoading(true);

    // Create the new array item
    const newItem = {
      college: college,
      companystartMonth:companystartMonth,
      companystartYear:companystartYear,
      companyendMonth:companyendMonth,
      companyendYear:companyendYear,
      edcuationDesc:companyDesc,
      branch:branch,
      degree:degree

    };

    // Update the state
    const updatedArray = [...array, newItem];
    setArray(updatedArray);

    // Make the API call with the updated array
    await axios.post(
      `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/updatedintro`,
      {
        email: auth?.user?.[0]?.email,
        education: JSON.stringify(updatedArray), // Use the updated array
      },
      {
        headers: {
          Authorization: "Bearer madebyashutosh",
        },
      }
    );

    // Update the auth context and other state
    updateAuthContext(auth?.user?.[0]?.email);
    setLoading(false);
    toast.success("Experience Updated!!");
   

    handleCloseEducation();    
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};


const generateAIDesc = async () => {
  try {
      setailoading(true)
    // Add user message to the conversation
    const completion = await openai.chat.completions.create({
      messages: [
        {
          role: "system",
          content:
            "You're a resume maker, helping users build strong resumes.",
        },
        {
          role: "user",
          content: ` college Name= ${college} , degree=${degree} , branch = ${branch}  `,
        },
        {
          role: "user",
          content: `so i alreday provide you the  collge name , collge branch name , collged degree . Please provide me descripiton for given collge in 30 to 40 words  in description  ,  what you do there and how did you do , give it me in paragarph`,
        },
      ],
      model: "gpt-3.5-turbo",
      temperature: 1,
      max_tokens: 1000,
    });

    // Add AI response to the conversation
    let aiResoponse = completion.choices[0].message.content; 
    console.log("this is reso" , aiResoponse)
    setCompanyDesc(aiResoponse)
     setailoading(false)
  } catch (error) {
    console.error(error);
    setailoading(false);
  }
};

 

return (
  <Modal show={showEducation} onHide={handleCloseEducation} size="2xl">
    <Modal.Header closeButton className={styles.introheading}>
      Add Education
    </Modal.Header>
    <Modal.Body>
      <div className={styles.educationConatinaer}>
       <div className={styles.inputcontaineredu}>
         <label htmlFor="contactId" className={styles.lable}>College Name</label>
         <input type="text" id="contactId" name="contactId"    value={college} onChange={(e)=>setCollege(e.target.value)}/>
       </div>

       

       <div className={styles.firstlastcont} style={{ marginTop: "10px" }}>
          <div className={styles.innerexperer}>
            <label htmlFor="name"> Degree</label>
            <input
              type="text"
              id="name"
              name="name"
              value={degree}
              onChange={(e) => setDegree(e.target.value)}
            />
          </div>
          <div className={styles.innerexperer}>
            <label htmlFor="name">Branch </label>
            <input
              type="text"
              id="name"
              name="name"
              value={branch}
              onChange={(e) => setBranch(e.target.value)}
            />
          </div>
        </div>

      

        

        <div className={styles.firstlastcontinnner} style={{ marginTop: "10px" }}>
      <div className={styles.innereducation}> 

         <div style={{display:'flex' ,flexDirection:'column' ,width:"100%"}}>

       
        <label htmlFor="startMonth">Starting Month</label>
        <select
          id="startMonth"
          name="startMonth"
          value={companystartMonth}
          onChange={(e) => setCompanystartMonth(e.target.value)}
        >
          <option value="">Select Month</option>
          {months.map((month, index) => (
            <option key={index} value={month}>{month}</option>
          ))}
        </select>
        </div> 
        <div style={{display:'flex' ,flexDirection:'column' ,width:"100%"}}>
        <label htmlFor="startYear">Starting Year</label>
        <select
          id="startYear"
          name="startYear"
          value={companystartYear}
          onChange={(e) => setCompanystartYear(e.target.value)}
        >
          <option value="">Select Year</option>
          {years.map((year, index) => (
            <option key={index} value={year}>{year}</option>
          ))}
        </select>
        </div>
      </div>
      
      <div className={styles.innereducation}>
      <div style={{display:'flex' ,flexDirection:'column' ,width:"100%"}}>
        <label htmlFor="endMonth">Ending Month</label>
        <select
          id="endMonth"
          name="endMonth"
          value={companyendMonth}
          onChange={(e) => setCompanyendMonth(e.target.value)}
        >
          <option value="">Select Month</option>
          {months.map((month, index) => (
            <option key={index} value={month}>{month}</option>
          ))}
        </select>
   </div>
          
   <div style={{display:'flex' ,flexDirection:'column' ,width:"100%"}}>
        <label htmlFor="endYear">Ending Year</label>
        <select
          id="endYear"
          name="endYear"
          value={companyendYear}
          onChange={(e) => setCompanyendYear(e.target.value)}
        >
          <option value="">Select Year</option>
          {years.map((year, index) => (
            <option key={index} value={year}>{year}</option>
          ))}
        </select>
        </div>
      </div>
       
    </div>
        <div className={styles.textareacontainer} style={{marginTop:'10px'}}>
          <label
            style={{ fontSize: "14px", marginBottom: "5px" }}
            htmlFor="heading"
          >
            Any Description
          </label>
          <textarea
            id="heading"
            name="heading"
            className={styles.textaree}
            value={companyDesc}
            onChange={(e) => setCompanyDesc(e.target.value)}
            placeholder="Enter Intro...."
            rows={5} // Adjust rows as needed
            cols={58} // Adjust columns as needed
          />
          <div className={styles.buttonsseducation}  onClick={generateAIDesc} >
            {ailoading ? (
              <HashLoader color="white" size={20} />
            ) : (
              "AI Writter"
            )}
          </div>
        </div>
      </div>

      <div className={styles.uploadCoverContainerforexperience}>
       

        <div className={styles["input-container"]}>
          <span className={styles.savebutton} onClick={handleAdd}>
            {loading ? <HashLoader color="white" size={20} /> : "Add"}
          </span>
          
        </div>
      </div>
    </Modal.Body>
  </Modal>
);
};