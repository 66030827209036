import React from "react";
import Modal from "react-bootstrap/Modal";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faCrown, faRocket } from "@fortawesome/free-solid-svg-icons";
import { CheckCircle, Zap } from "lucide-react";
import { useNavigate } from "react-router-dom";
import styles from "../assets/Model.module.css";

const features = [
  {
    title: "Professional Resume Templates",
    description: "Access to 100+ ATS-friendly templates"
  },
  {
    title: "AI-Powered Content Creation",
    description: "Generate optimized content instantly"
  },
  {
    title: "Resume Optimization",
    description: "Smart suggestions to improve visibility"
  },
  {
    title: "Job Description Matching",
    description: "AI-powered job matching system"
  },
  {
    title: "Personalized Job Recommendations",
    description: "Tailored job suggestions daily"
  },
  {
    title: "Career Progress Tracking",
    description: "Monitor your career growth"
  }
];

function Model({ modalShow, setModalShow }) {
  const navigate = useNavigate();

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      centered
      className={styles.modalWrapper}
    >
      <Modal.Body className={styles.modernBody}>
        <motion.button
          className={styles.closeBtn}
          onClick={() => setModalShow(false)}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <FontAwesomeIcon icon={faClose} />
        </motion.button>

        <div className={styles.contentWrapper}>
          <motion.div
            className={styles.leftSection}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className={styles.leftContent}>
              <motion.div
                className={styles.badge}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: 0.3 }}
              >
                <FontAwesomeIcon icon={faCrown} className={styles.crownIcon} />
                <span>Premium</span>
              </motion.div>
              
              <motion.h1
                className={styles.title}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
              >
                Upgrade to Premium
              </motion.h1>

              <div className={styles.pricingBox}>
                <div className={styles.priceTag}>
                  <span className={styles.currency}>₹</span>
                  <span className={styles.amount}>247</span>
                  <span className={styles.period}>/ 3 months</span>
                </div>
                <div className={styles.savings}>Save 40% with annual billing</div>
              </div>

              <motion.button
                className={styles.upgradeBtn}
                onClick={() => navigate('/pricing')}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Zap className={styles.buttonIcon} size={16} />
                Get Premium Access
              </motion.button>
            </div>
          </motion.div>

          <motion.div
            className={styles.rightSection}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h3 className={styles.featuresTitle}>
              <FontAwesomeIcon icon={faRocket} className={styles.rocketIcon} />
              Premium Features
            </h3>
            <div className={styles.featuresList}>
              {features.map((feature, index) => (
                <motion.div
                  key={feature.title}
                  className={styles.featureItem}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.2 + index * 0.1 }}
                  whileHover={{ x: 10 }}
                >
                  <CheckCircle className={styles.checkIcon} size={16} />
                  <span className={styles.featureTitle}>{feature.title}</span>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Model;
