import React, { useEffect, useState } from "react";
import styles from "../../assets/Matching.module.css";
import { ChevronDown, ChevronUp, Plus, PlusCircle, PlusIcon } from "lucide-react";
import { useResume } from "./components/context/Resume";
import { useJobs } from "./components/context/Jobs";
import axios from "axios";
import Model from "../../components/Model";
import OpenAI from "openai";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import BottomBar from "../../components/BottomBar";
const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});
const Matching = () => {
  const [value, setValue] = useState("");
  const [response, setResponse] = useState("");
  let [misskingSkills, setMissingSkills] = useState([]);
  let [skillss, setSkills] = useState([]);
  const [loading, setLoading] = useState(false);
  const { values, handleResumeChange, setValues } = useResume();
  const [point, setPoint] = useState([]);
  const [pointProject, setPointProject] = useState([]);
  const { payment } = useJobs();
  const [modalShow, setModalShow] = useState(false);
  const [visibleCount, setVisibleCount] = useState(10);
  const [visibleCountKey, setVisibleCountkey] = useState(10);
  const [ProffestionalSummary, setProffestionalSummary] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [active, setActive] = useState(false);
  const [openSkill, setOpenSkill] = useState(false);
  const [openKeywords, setOpenKeywords] = useState(false);
  const [openSummary, setOpenSummary] = useState(false);
  const [openPoints, setOpenPoints] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/pdf/ats`,
        {
          descrption: value,
          resumeContent: values,
        }
      );
      setLoading(false);
      setResponse(data.response);
      setMissingSkills(data.response.suggestions.missing_words);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getResoponse = async () => {
    try {
      setLoading(true);
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content: "You are resume anlysist  ",
          },

          {
            role: "user",
            content: `Missing Keywords= ${response.suggestions.missing_words}`,
          },
          {
            role: "user",
            content:
              "Give me Proffestional Summary , add all the missing keyword in sentince ,   don't improvise it ,  add the all keyword as it is ",
          },
        ],
        model: "gpt-3.5-turbo",
        temperature: 1,
        max_tokens: 1000,
      });

      let data = completion.choices[0].message.content;
      setProffestionalSummary(data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const generatePoint = async () => {
    try {
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content:
              "You're a resume maker, helping users build strong resumes . only give me point in array only and make sure no string will be in response each must be 150 words",
          },
          {
            role: "user",
            content: `job role= ${values.sections.experience?.data[0]?.role} company = ${values.sections.experience?.data[0]?.company}   missing Skills= ${response.suggestions.missing_skills}`,
          },
          {
            role: "user",
            content: `give me 5 point  and include percentage wise point in between the sentence aprox  while i am in this job role so i can add in my resume  and provide me only array `,
          },
        ],
        model: "gpt-3.5-turbo",
        temperature: 1,
        max_tokens: 1000,
      });

      const jsonString = completion.choices[0].message.content;
      const suggestions = JSON.parse(jsonString);
      setPoint(suggestions);
      // handleResumeChange(sectionKey, [...(section || []), ""]);
    } catch (error) {
      console.log(error);
    }
  };

  const generatePointProject = async () => {
    try {
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content:
              "You're a resume maker, helping users build strong resumes . only give me point in array only and make sure no string will be in response each must be 150 words",
          },
          {
            role: "user",
            content: `job role= ${values.sections.projects?.data[0]?.titlle} company = ${values.sections.projects?.data[0]?.organisation}   missing Skills= ${response.suggestions.missing_skills}`,
          },
          {
            role: "user",
            content: `give me 5 point  and include percentage wise point in between the sentence aprox  while i am in this job role so i can add in my resume  and provide me only array `,
          },
        ],
        model: "gpt-3.5-turbo",
        temperature: 1,
        max_tokens: 1000,
      });

      const jsonString = completion.choices[0].message.content;
      const suggestions = JSON.parse(jsonString);
      setPointProject(suggestions);
      // handleResumeChange(sectionKey, [...(section || []), ""]);
    } catch (error) {
      console.log(error);
    }
  };

  const showMore = () => {
    setVisibleCount((prevCount) => prevCount + 10);
  };

  const showMorekey = () => {
    setVisibleCountkey((prevCount) => prevCount + 10);
  };

  const addSummary = async () => {
    handleResumeChange(
      `sections.Professionalsummary.data.summary`,
      ProffestionalSummary
    );
  };
  const addSkills = (singleSkill) => {
    // Filter out the selected skill from missingSkills
    setMissingSkills((prevMissingSkills) =>
      prevMissingSkills.filter((skill) => skill !== singleSkill)
    );

    // Add the selected skill to the skills array
    setSkills((prevSkills) => {
      // Retrieve existing content from the `values` state
      setValues((prevState) => {
        let temp = { ...prevState }; // Copy the previous state

        // Safely navigate to `sections.technicalSkills.data`
        if (!temp.sections) {
          temp.sections = {};
        }
        if (!temp.sections.technicalSkills) {
          temp.sections.technicalSkills = {};
        }
        if (!temp.sections.technicalSkills.data) {
          temp.sections.technicalSkills.data = [];
        }

        // Determine the index where new skills should be added
        let currentIndex = 0;
        for (let i = 0; i < temp.sections.technicalSkills.data.length; i++) {
          const currentSkills =
            temp.sections.technicalSkills.data[i].skills || [];
          if (currentSkills.length > 0) {
            currentIndex = i; // Stay on the last non-empty index
          }
        }

        // Check if currentIndex already exists or if it needs to create a new one
        if (!temp.sections.technicalSkills.data[currentIndex]) {
          temp.sections.technicalSkills.data[currentIndex] = { skills: [] };
        }

        // Get the skills array at the current index
        const currentSkillsAtIndex =
          temp.sections.technicalSkills.data[currentIndex].skills || [];

        // Append the new skill if it doesn't already exist
        if (!currentSkillsAtIndex.includes(singleSkill)) {
          const updatedSkills = [...currentSkillsAtIndex, singleSkill]; // Append new skill

          // Update the skills array in the state at the current index
          temp.sections.technicalSkills.data[currentIndex].skills =
            updatedSkills;

          // Define sectionKey dynamically for handleResumeChange
          let sectionKey = `sections.technicalSkills.data.${currentIndex}.skills`;

          // Call handleResumeChange with the updated skills
          handleResumeChange(sectionKey, updatedSkills);

          // Update the state with the modified temp object
          return temp;
        } else {
          console.log("Skill already exists in the array, not adding again.");
          return temp; // Return the original state if no change
        }
      });

      // Add the skill to local skills array if needed for another purpose
      return [...prevSkills, singleSkill]; // Return the updated skills for local state
    });
  };

  const addExper = (item) => {
    setValues((prevState) => {
      let temp = { ...prevState }; // Copy the previous state

      // Safely navigate to `sections.experience.data`
      if (!temp.sections) {
        temp.sections = {};
      }
      if (!temp.sections.experience) {
        temp.sections.experience = {};
      }
      if (!temp.sections.experience.data) {
        temp.sections.experience.data = [];
      }

      // Ensure there is an object at `data[0]`, or initialize it if not present
      if (!temp.sections.experience.data[0]) {
        temp.sections.experience.data[0] = { points: [] }; // Initialize with an empty points array
      }

      // Get the current experience at index 0
      const currentExperienceAtIndex = temp.sections.experience.data[0];

      // Ensure there is a `points` field in the current experience, or initialize it
      if (!currentExperienceAtIndex.points) {
        currentExperienceAtIndex.points = [];
      }

      // Add the new `item` (points) to the `points` array
      const updatedPoints = [...currentExperienceAtIndex.points, item];

      // Update the `points` field at index 0 with the new data
      temp.sections.experience.data[0].points = updatedPoints;

      // Define sectionKey dynamically for handleResumeChange
      let sectionKey = `sections.experience.data.0.points`;

      // Call handleResumeChange with the updated points array
      handleResumeChange(sectionKey, updatedPoints);

      // Return the updated state
      return temp;
    });
  };

  const addProject = (item) => {
    setValues((prevState) => {
      let temp = { ...prevState }; // Copy the previous state

      // Safely navigate to `sections.projects.data`
      if (!temp.sections) {
        temp.sections = {};
      }
      if (!temp.sections.projects) {
        temp.sections.projects = {};
      }
      if (!temp.sections.projects.data) {
        temp.sections.projects.data = [];
      }

      // Ensure there is an object at `data[0]`, or initialize it if not present
      if (!temp.sections.projects.data[0]) {
        temp.sections.projects.data[0] = { points: [] }; // Initialize with an empty points array
      }

      // Get the current project at index 0
      const currentProjectAtIndex = temp.sections.projects.data[0];

      // Ensure there is a `points` field in the current project, or initialize it
      if (!currentProjectAtIndex.points) {
        currentProjectAtIndex.points = [];
      }

      // Add the new `item` (points) to the `points` array
      const updatedPoints = [...currentProjectAtIndex.points, item];

      // Update the `points` field at index 0 with the new data
      temp.sections.projects.data[0].points = updatedPoints;

      // Define sectionKey dynamically for handleResumeChange
      let sectionKey = `sections.projects.data.0.points`;

      // Call handleResumeChange with the updated points array
      handleResumeChange(sectionKey, updatedPoints);

      // Return the updated state
      return temp;
    });
  };

  const JobDescriptionContainer = () => {
    return (
      <div className={styles.container}>
        <label htmlFor="jobDescription" className={styles.label}>
          Enter Job Description
        </label>
        <div className={styles.textBoxContainer}>
          {!isFocused && (
            <div className={styles.placeholderText}>
              Example
              <br />
              - Company-wide committee facilitation and participation
              <br />
              - Employee safety, welfare, wellness and health reporting
              <br />
              - Provide direct support to employees during implementation of HR
              services, policies and programs
              <br />
              What skills will the intern learn:
              <br />
              - Active participation in strategic planning process, including
              developing goals, objectives and processes
              <br />
              - How to engage professionally in HR meetings and seminars with
              other HR professionals in the region
              <br />
              - Gain experience with Human Resources Information system (HRIS)
              database management and record keeping
              <br />
              - Application of HR law and compliance with governmental
              regulations
              <br />
              Qualifications:
              <br />
              - Proficient with Microsoft Word and Excel
              <br />
              - General knowledge of employment law and practices
              <br />
              - Able to maintain a high level of confidentiality
              <br />- Effective oral and written management communication skills
            </div>
          )}
          <textarea
            id="jobDescription"
            className={styles.textBox}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={(e) =>
              setIsFocused(e.target.value.length > 0 ? true : false)
            }
          />
        </div>

        <div className={styles.buttoncontainwer}>
          <div
            className={styles.buttons}
            onClick={
              payment?.paymentStatus !== "success"
                ? () => setModalShow(true)
                : handleSubmit
            }
          >
            {loading ? "Loading" : "Scan ATS"}
          </div>
        </div>
      </div>
    );
  };

  const TipContainer = () => {
    return (
      <div className={styles.TipsContainer}>
        <div className={styles.tipleft}>
          <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1730031255/all/crw1wqjgdwuae4lb7ybc.svg" />
        </div>
        <div className={styles.tipright}>
          <div className={styles.tipheading}>QUICK TIPsS</div>
          <div className={styles.tippara}>
            Create an email that includes your name, avoiding nicknames or
            numbers that may seem unprofessional.{" "}
          </div>
        </div>
      </div>
    );
  };

  const SwitchATSEditor = () => {
    return (
      <div className={styles.SwitchATSEditorContainer}>
        <div
          className={`${styles.eachSwitch} ${
            !active ? styles.activeswitch : ""
          }`}
          onClick={() => setActive(!active)}
        >
          ATS Score
        </div>
        <div
          className={`${styles.eachSwitch} ${
            active ? styles.activeswitch : ""
          }`}
          onClick={() => setActive(!active)}
        >
          Editor
        </div>
      </div>
    );
  };

  const ScoreContainer = () => {
    return (
      <div className={styles.scoreContainerMain}>
        <div className={styles.upparScoreContainer}>
          <div className={styles.circularScoreContainer}>
            {/* Calculate color stops based on actual scores */}
            <svg style={{ height: 0 }}>
              <defs>
                <linearGradient
                  id="gradient"
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="100%"
                >
                  <stop
                    offset={`${(response?.feedback?.matchScore / 48) * 100}%`}
                    stopColor="#5860FC"
                  />
                  <stop
                    offset={`${
                      (response?.feedback?.Relevant_Experience_Score / 19) * 100
                    }%`}
                    stopColor="#AC8FFF"
                  />
                  <stop
                    offset={`${
                      (response?.feedback?.Overall_Skills_Possessed / 3) * 100
                    }%`}
                    stopColor="#CF8CFF"
                  />
                  <stop
                    offset={`${
                      (response?.feedback?.Format_Readability / 10) * 100
                    }%`}
                    stopColor="#FFC4DA"
                  />
                  <stop
                    offset={`${
                      (response?.feedback?.Technical_Skills_Score / 20) * 100
                    }%`}
                    stopColor="#9DB7FF"
                  />
                </linearGradient>
              </defs>
            </svg>

            <CircularProgressbar
              value={response?.atsScore || 0}
              text={`${response?.atsScore || 0}%`}
              styles={buildStyles({
                rotation: 0.25,
                textColor: "#333",
                trailColor: "#d6d6d6",
                pathColor: "url(#gradient)",
                textSize: "16px",
              })}
            />
          </div>
          <div className={styles.ScoreContainer}>
            <div className={styles.score}>
              Your ATS Score is {response?.atsScore}
            </div>
            <div className={styles.scorepara}>
              We ran 20+ checks on your resume. You need a score of 35 to pass
              the scan.
            </div>
          </div>
        </div>
        <div className={styles.downScoreContainer}>
          <div className={styles.eachScoreHeadingContainer}>
            <div className={styles.eachScoreDotContainer}>
              <div className={styles.eachScoreDot}></div>
            </div>
            <div className={styles.eachScoreHeadingContainerUnder}>
              <div className={styles.eachScoreHeading}>Keyword Match</div>
              <div className={styles.eachScorePercentage}>
                {response?.feedback?.matchScore}
              </div>
            </div>
          </div>
          <div className={styles.eachScoreHeadingContainer}>
            <div className={styles.eachScoreDotContainer}>
              <div className={styles.eachScoreDot}></div>
            </div>
            <div className={styles.eachScoreHeadingContainerUnder}>
              <div className={styles.eachScoreHeading}>Relevant Experience</div>
              <div className={styles.eachScorePercentage}>
                {response?.feedback?.Relevant_Experience_Score}
              </div>
            </div>
          </div>
          <div className={styles.eachScoreHeadingContainer}>
            <div className={styles.eachScoreDotContainer}>
              <div className={styles.eachScoreDot}></div>
            </div>
            <div className={styles.eachScoreHeadingContainerUnder}>
              <div className={styles.eachScoreHeading}>Skills Matched</div>
              <div className={styles.eachScorePercentage}>
                {response?.feedback?.Technical_Skills_Score}
              </div>
            </div>
          </div>
          <div className={styles.eachScoreHeadingContainer}>
            <div className={styles.eachScoreDotContainer}>
              <div className={styles.eachScoreDot}></div>
            </div>
            <div className={styles.eachScoreHeadingContainerUnder}>
              <div className={styles.eachScoreHeading}>Overall Skills</div>
              <div className={styles.eachScorePercentage}>
                {response?.feedback?.Overall_Skills_Possessed}
              </div>
            </div>
          </div>
          <div className={styles.eachScoreHeadingContainer}>
            <div className={styles.eachScoreDotContainer}>
              <div className={styles.eachScoreDot}></div>
            </div>
            <div className={styles.eachScoreHeadingContainerUnder}>
              <div className={styles.eachScoreHeading}>Format Readability</div>
              <div className={styles.eachScorePercentage}>
                {response?.feedback?.Format_Readability}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const MissingSkillsContainer = () => {
    return (
      <div className={`${styles.flfo}   ${!openSkill ? styles.opneskil : ""}`} >
        <div className={styles.ddepal}>
          <div>Missing Skills</div>
          <div className={styles.ecahScoreMian}>
            {" "}
            <div className={styles.eachScorePercentage}>
              {response?.feedback?.Technical_Skills_Score}
            </div>{" "}
               <div onClick={() => setOpenSkill(!openSkill)}>
                    {openSkill ? <ChevronUp size={20}  /> : <ChevronDown size={20}  />}
              </div>
          </div>
        </div>

        <div> 
          {openSkill && (
             <div className={styles.dd}>
             {/* Slice the missing skills to show only the visible count */}
             {misskingSkills?.length === 0 ? (
               <span className={styles.dfdf}>No skills are missing</span>
             ) : (
               misskingSkills?.slice(0, visibleCountKey).map((item, index) => (
                 <span
                   key={index}
                   className={styles.dfdf}
                   onClick={() => addSkills(item)}
                 >
                   {item} <Plus size={20} />
                 </span>
               ))
             )}
           </div>
          )}
         
        </div>

        {/* Show the "Show More" button if there are more skills to display */}
        {misskingSkills?.length > visibleCount && (
          <div style={{ textAlign: "right", marginTop: "10px" }}>
            <span onClick={showMorekey} className={styles.showMoreBtn}>
              Show More
            </span>
          </div>
        )}
      </div>
    );
  };

  const MissingKeywordsContainer = () => {
    return (
      <div className={`${styles.flfo}  ${!openKeywords ? styles.opneskil : ""}`}>
        <div className={styles.ddepal}>
          <div> Missing Keywords</div>
          <div className={styles.ecahScoreMian}>
            {" "}
            <div className={styles.eachScorePercentage}>
              {response?.feedback?.matchScore}
            </div>{" "}
            <div onClick={() => setOpenKeywords(!openKeywords)}>
              {openKeywords ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </div>
          </div>
        </div>

        <div>
          {openKeywords && (
              <div className={styles.dd}>
              {/* Slice the suggestions to show only the visible count */}
            {response.suggestions.missing_words?.length === 0 ? (
              <span className={styles.dfdf}>No missing keywords found</span>
            ) : (
              response.suggestions.missing_words
                ?.slice(0, visibleCount)
                .map((item, index) => (
                  <span key={index} className={styles.dfdf}>
                    {" "}
                    {item}
                  </span>
                ))
              )}
            </div>
          )}
        </div>

        {/* Show the "Show More" button if there are more keywords to display */}
        {response.suggestions.missing_words?.length > visibleCount && (
          <div style={{ textAlign: "right", marginTop: "10px" }}>
            <span onClick={showMore} className={styles.showMoreBtn}>
              Show More
            </span>
          </div>
        )}
      </div>
    );
  };

  const proffestionalSummaryContainer = () => {
    return (
      <div className={`${styles.flfo}   ${!openSummary ? styles.opneskil : ""}`}>
        <div className={styles.ddepal}>
          <div> AI Generated Professional Summary</div>
          <div className={styles.ecahScoreMian}>
            {" "}
            <div onClick={() => setOpenSummary(!openSummary)}>
              {openSummary ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </div>
          </div>
        </div>
        <div>
          {openSummary && (
             <div className={styles.proffestionald}>
             <p className={styles.mainsfr}>{ProffestionalSummary}</p>
             <div className={styles.dfroore}>
               <span className={styles.aibuttonf} onClick={() => getResoponse()}>
                 <img
                   src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1731091848/all/sm89cm7yyer7emxfaoje.svg"
                   alt="re-genererate"
                 />
                 Re-Genererate Summary
               </span>
               <span
                 className={styles.boroerr}
                 onClick={() => {
                   addSummary();
                 }}
               >
                 {" "}
                 <PlusIcon size={14} /> Add To Resume
               </span>
             </div>
           </div>
          )}
         
        </div>
      </div>
    );
  };

  const AIgeneratedPointsContainer = () => {
    return (
      <div className={`${styles.flfo}   ${!openPoints ? styles.opneskil : ""}`}>
        <div className={styles.ddepal}>
          <div>AI Generated Points</div>
          <div className={styles.ecahScoreMian}>
            {" "}
            <div onClick={() => setOpenPoints(!openPoints)}>
              {openPoints ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </div>
          </div>
        </div> 


        {openPoints && (
          <div>
            {values?.sections?.experience?.data[0]?.role && (
            <div className={styles.proffestionald}>
              <div className={styles.headingiro}>
                {" "}
                {values?.sections?.experience?.data[0]?.role} at{" "}
                {values.sections.experience?.data[0]?.company}
              </div>

              {point.map((data, index) => {
                return (
                  <ul key={index}>
                    <li>
                      {data}{" "}
                      <span onClick={() => addExper(data)}>
                        <PlusCircle color={"blue"} size={14} />
                      </span>{" "}
                    </li>
                  </ul>
                );
              })}
              <div className={styles.dfroore}>
                <span
                  className={styles.aibuttonf}
                  onClick={() => generatePoint()}
                >
                  <img
                    src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1731091848/all/sm89cm7yyer7emxfaoje.svg"
                    alt="re-genererate"
                  />{" "}
                  Re-Genereratee Points
                </span>
              </div>
            </div>
          )}
          {values?.sections?.projects?.data[0]?.title && (
            <div className={styles.proffestionald}>
              <div className={styles.headingiro}>
                {values?.sections?.projects?.data[0]?.title} at{" "}
                {values.sections.projects?.data[0]?.organisation}
              </div>
              {pointProject.map((data, index) => {
                return (
                  <ul key={index}>
                    <li>
                      {data}{" "}
                      <span onClick={() => addProject(data)}>
                        <PlusCircle color={"blue"} size={14} />
                      </span>
                    </li>
                  </ul>
                );
              })}
              <div className={styles.dfroore}>
                <span
                  className={styles.aibuttonf}
                  onClick={() => generatePointProject()}
                >
                  <img
                    src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1731091848/all/sm89cm7yyer7emxfaoje.svg"
                    alt="re-genererate"
                  />{" "}
                  Re-Genereratee Points
                </span>
              </div>
            </div>
          )}
        </div>
        
      )}
      </div>
    );
  };

  const reviewContainer = () => {
    return (
      <div>
        <div className={`${styles.flfo}   `}>
          <div className={styles.ddepal}>Relivance Experience Keywords</div>
          <div>
            <div className={styles.dd}>
              {response.suggestions.relivance_Experience?.map((item, index) => (
                <span key={index} className={styles.dfdf}>
                  {item}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };


  useEffect(() => {
    if (response) {
      generatePointProject();
      generatePoint();
      getResoponse();
    }
  }, [response]);

  return (
    <div className={styles.main}>
      {response && ScoreContainer()}
      {SwitchATSEditor()}
      {TipContainer()}
      {!response && JobDescriptionContainer()}

      {response && (
        <div className={styles.alllor}>
          {MissingSkillsContainer()}
          {MissingKeywordsContainer()}
          {proffestionalSummaryContainer()}
          {AIgeneratedPointsContainer()}
        </div>
      )}
      <BottomBar/>
      <Model modalShow={modalShow} setModalShow={setModalShow} />
    </div>
  );
};

export default Matching;
