import React from "react";
 
import styles from "../../assets/HomePage/Partofmyfuse.module.css";
import { useNavigate } from "react-router-dom";
const Partofmyfuse = () => {
  const navigate = useNavigate(); 
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.Inner}>
          <div className={styles.left}>
            <img
              src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1723791983/all/sir8pfdrktwgwqosug89.png"
              alt="asdfas"
            />
          </div>
          <div className={styles.center}>
            
              <h4 className={styles.head}>
                <span>Looking for a new opportunity?</span>
              </h4>
              <p className={styles.pad}>Get access to over 5000 new job openings everyday across India.</p>
          </div>
          <div className={styles.right}>  
            <spna className={styles.butot}  onClick={()=>navigate('/jobs')}   >
            View Jobs
            </spna>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partofmyfuse;
