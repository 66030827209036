import React, { useEffect, useState } from 'react'
import MyResponsiveNavbar from './Navbar'
import { useAuth } from '../ResumeBuilder/builder/components/context/auth';
import userIcon from "../assets/userIcon.webp"
import HelmetConfig from '../components/HelmetConfig';
import styles from "../assets/Account.module.css"
import Left from '../components/Account.js/Left';
import Right from '../components/Account.js/Right';
import Last from '../components/Account.js/Last';
const Account = () => {
    const {auth , setAuth} = useAuth();
    const [imgSrc, setImgSrc] = useState(
      userIcon
    );
     
    useEffect(() => {
      const storedAuth = JSON.parse(localStorage.getItem("auth"));
      if (storedAuth && storedAuth.pic) {
        setImgSrc(storedAuth.pic);
      }
    }, []);
  return (
    <div >
        <HelmetConfig
           title="MyFuse.in-Account"
           description="Find the best job opportunities on MyFuse.in. Search jobs by industry, location, and more."
           keywords="job portal, job search, careers, employment, jobs, MyFuse.in, job opportunities"
           canonical="https://myfuse.in/"
        />
        <MyResponsiveNavbar/> 
        <div className={styles.main}>
         {/* left one */} 
           <div className={`${styles.leftone} d-none d-lg-block w-lg-100 `}><Left imgSrc={imgSrc} auth={auth}/></div>
         {/* Right One  */} 
         <div className={styles.RightOne}> <Right imgSrc={imgSrc} auth={auth} setAuth={setAuth}/></div>
          <div className={`${styles.last} d-none d-lg-block w-lg-100 `}> <Last imgSrc={imgSrc} auth={auth}/></div>
          </div>
    </div>
  )
}

export default Account