import React from 'react'
import styles from "../assets/tnc.module.css"
import HelmetConfig from '../components/HelmetConfig'
const Tnc = () => {
  return (
    <div  className={styles.cont}>

<HelmetConfig
          title="Terms and Conditions | MyFuse - Your Partner in Professional Growth"
          description="Read the terms and conditions for using MyFuse’s services, including job search assistance, resume building, and professional career advice to ensure a seamless experience."
          keywords="
          Myfuse Terms of Service, Terms and Conditions Myfuse, User Agreement Myfuse.in, Legal Terms Myfuse, Myfuse Service Agreement,
          Myfuse Terms and Conditions Explained, Understanding Myfuse’s Legal Terms, Terms of Use for Myfuse.in, Legal Agreement for Myfuse Users,
          What Are Myfuse’s Terms and Conditions?,
          Terms & Conditions, User Agreement, Legal Terms, Service Terms, Conditions of Use,
          Legal Documents, User Terms, Service Agreement, Website Terms, Myfuse Policies,
          Myfuse Legal Guidelines, Terms of Use for Job Seekers, Agreement for Using Myfuse.in, Myfuse Service Policies, Legal Information Myfuse,
          Understanding Myfuse’s Terms of Service, Myfuse User Agreement Details, Myfuse Legal Terms Overview, Conditions for Using Myfuse.in,
          Legal Obligations for Myfuse Users,
          User Policies, Myfuse Legal Notice, Service Conditions, Website Usage Terms, Myfuse Legal Agreement
        "
          canonical="https://myfuse.in/contactus"
        />

    <h1>Terms & Conditions</h1>
    <p>Welcome to MyFuse (245 INTERACTIVE SUP TECH PRIVATE LIMITED)!</p>
    <p>These terms and conditions outline the rules and regulations for the use of 245 INTERACTIVE SUP TECH PRIVATE LIMITED's Website, located at <a href="https://www.myfuse.in">https://www.myfuse.in</a>.</p>
    <p>By accessing this website, we assume you accept these terms and conditions. Do not continue to use MyFuse if you do not agree to take all of the terms and conditions stated on this page.</p>

    <h4>Terms of Use</h4>
    <p>Your use of Myfuse products, software, services and websites (referred to collectively as the “Services” in this document) is subject to the terms of a legal agreement between you and MyFuse. Please read the following terms and conditions very carefully as your use of services is subject to your acceptance of and compliance with the following terms and conditions ("Terms"). 

     By subscribing to or using any of our services, you agree that you have read, understood and are bound by the Terms, regardless of how you subscribe to or use the services. If you do not want to be bound by the Terms, you must not subscribe to or use our services. 

     In these Terms, references to "you", "User", “Visitor” shall mean the end user accessing the Website, its contents and using the Services offered through the Website, and "we", "us" and "our" shall mean myfuse.in and its affiliates.</p>

    <h4>User Agreement</h4>
    <p>These Terms of Use govern your use of Services offered through https://www.myfuse.in (“Site”). You agree to access "the site", subject to the terms and conditions of use as set out here. You may not use the Services if you do not accept the Terms.  <br></br>
      Myfuse.in may add to or change or update these Terms of Use, from time to time entirely at its own discretion. You are responsible for checking these Terms of Use periodically to remain in compliance with these terms. Your use of a Site after any amendment to the Terms of Use shall constitute your acceptance of these terms and you also agree to be bound by any such changes/revisions</p>

    <h4>Your passwords and account security</h4>
    <p>You agree and understand that you are responsible for maintaining the confidentiality of passwords associated with any account you use to access the Services.</p>
    <p>Accordingly, you agree that you will be solely responsible to Myfuse for all activities that occur under your account.</p>
    <p>If you become aware of any unauthorized use of your password or of your account, you agree to notify Myfuse immediately at <a href="mailto:info@myfuse.in">info@myfuse.in</a></p>

    <h4>License</h4>
    <p>Unless otherwise stated, 245 INTERACTIVE SUP TECH PRIVATE LIMITED and/or its licensors own the intellectual property rights for all material on MyFuse. All intellectual property rights are reserved. You may access this from MyFuse for your own personal use subjected to restrictions set in these terms and conditions.
</p>
     You Must Not:
    <ul>

        <li>Copy or republish material from MyFuse</li>
        <li>Sell, rent, or sub-license material from MyFuse</li>
        <li>Reproduce, duplicate or copy material from MyFuse</li>
        <li>Redistribute content from MyFuse</li>
    </ul>
    <p>This Agreement shall begin on the date hereof.</p>
 
    <p>Parts of this website offer users an opportunity to post and exchange opinions and information in certain areas of the website. 245 INTERACTIVE SUP TECH PRIVATE LIMITED does not filter, edit, publish or review Comments before their presence on the website. Comments do not reflect the views and opinions of 245 INTERACTIVE SUP TECH PRIVATE LIMITED , its agents, and/or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions. To the extent permitted by applicable laws, 245 INTERACTIVE SUP TECH PRIVATE LIMITED shall not be liable for the Comments or any liability, damages, or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</p>
    <p>245 INTERACTIVE SUP TECH PRIVATE LIMITED reserves the right to monitor all Comments and remove any Comments that can be considered inappropriate, offensive, or causes breach of these Terms and Conditions.</p>

    <h4>You warrant and represent that:</h4>
    <ul>
        <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
        <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent, or trademark of any third party;</li>
        <li>The Comments do not contain any defamatory, libelous, offensive, indecent, or otherwise unlawful material, which is an invasion of privacy.</li>
        <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
    </ul>
    <p>You hereby grant 245 INTERACTIVE SUP TECH PRIVATE LIMITED a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats, or media.</p>

    <h4>User Conduct and Rules</h4>
    <p>You agree and undertake to use the Website and the Service only to post and upload messages and material that are proper. By way of example, and not as a limitation, you agree and undertake that when using a Service, you will not:</p>
    <ul>
        <li>Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of others;</li>
        <li>Publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information;</li>
        <li>Upload files that contain software or other material protected by intellectual property laws unless you own or control the rights thereto or have received all necessary consents;</li>
        <li>Upload or distribute files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of the Website or another's computer;</li>
        <li>Conduct or forward surveys, contests, pyramid schemes or chain letters;</li>
        <li>Download any file posted by another user of a Service that you know, or reasonably should know, cannot be legally distributed in such manner;</li>
        <li>Falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded;</li>
        <li>Violate any code of conduct or other guidelines, which may be applicable for or to any particular Service;</li>
        <li>Violate any applicable laws or regulations for the time being in force in or outside India; and</li>
        <li>Violate any of the terms and conditions of this Agreement or any other terms and conditions for the use of the Website contained elsewhere herein.</li>
    </ul>

    <h4>Hyperlinking to our Content</h4>
    <p>The following organizations may link to our Website without prior written approval:</p>
    <ul>
        <li>Government agencies;</li>
        <li>Search engines;</li>
        <li>News organizations;</li>
        <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
        <li>System-wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
    </ul>
    <p>These organizations may link to our home page, to publications, or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.</p>

    <h4>Governing Law</h4>
    <p className='text-bolder'>These terms shall be governed by and constructed in accordance with the laws of India without reference to conflict of laws principles and disputes arising in relation hereto shall be subject to the exclusive jurisdiction of the courts at Bengaluru.</p>

    <h4>Content Liability</h4>
    <p>We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are raised on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>

    <h4>Reservation of Rights</h4>
    <p>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>

    <h4>Removal of links from our website</h4>
    <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us at any moment. We will consider requests to remove links, but we are not obligated to or so or to respond to you directly.</p>
    <p>We do not ensure that the information on this website is correct. We do not warrant its completeness or accuracy, nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>

    <h4>Disclaimer</h4>
    <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>
    <ul>
        <li>limit or exclude our or your liability for death or personal injury;</li>
        <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
        <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
        <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
    </ul>
    <p>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort, and for breach of statutory duty.</p>
    <p>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
</div>
  )
}

export default Tnc