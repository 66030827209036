import React from "react";
import styles from "../../assets/HomePage/Networking.module.css";
import { Book, BookCheck, BrainCircuit, Briefcase, Bug, Building, Database, EggFried, GalleryHorizontalEnd, IceCream } from "lucide-react";
const NetworkinProblem = () => {
    let arrayObject = [
        {
          name: "Not Able to Find New Jobs",
          img:  <Briefcase />,
          color: '#f3f1ff'
        },
        {
          name: "Not able to find the right resources",
            img:  <EggFried />,
          color: '#ffe4e1'
        },
        {
          name: "Lack of Networking Opportunities",
            img:  <GalleryHorizontalEnd />,
          color: '#e6e6fa'
        },
        {
          name: "Insufficient Skill Development",
            img:  <Building />,
          color: '#f0f8ff'
        },
        {
          name: "Work-Life Balance Issues",
            img:  <Book />,
          color: '#fafad2'
        },
        {
          name: "Difficulty in Career Transition",
            img:  <IceCream />,
          color: '#f5f5dc'
        },
        {
          name: "Job Insecurity",
            img:  <Bug />,
          color: '#e0ffff'
        },
        {
          name: "Limited Advancement Opportunities",
            img:  <BookCheck />,
          color: '#faf0e6'
        },
        {
          name: "Lack of Mentorship",
            img:  <BrainCircuit />,
          color: '#fff0f5'
        },
        {
          name: "Workplace Discrimination",
            img:  <Database />,
          color: '#f0fff0'
        },
      ];
      
      let arrayObject2 = [
        {
          name: "Lack of Clear Goals",
          img:  <IceCream />,
          color: '#ffe4b5'
        },
        {
          name: "Inadequate Training Programs",
          img:  <Bug />,
          color: '#ffebcd'
        },
        {
          name: "Poor Work Environment",
          img:  <BookCheck />,
          color: '#ffdab9'
        },
        {
          name: "Limited Job Flexibility",
          img:  <Database />,
          color: '#eee8aa'
        },
        {
          name: "High Stress Levels",
          img:  <BrainCircuit />,
          color: '#98fb98'
        },
        {
          name: "Lack of Recognition",
          img:  <Building />,
          color: '#afeeee'
        },
        {
          name: "Poor Management",
          img:  <Building />,
          color: '#db7093'
        },
        {
          name: "Uncompetitive Salaries",
          img:  <EggFried />,
          color: '#ffefd5'
        },
        {
          name: "Lack of Career Growth",
          img:  <Building />,
          color: '#ffd700'
        },
        {
          name: "Workplace Bullying",
          img:  <Book />,
          color: '#e6e6fa'
        },
      ];
      

  return (
    <div className={styles.main}>
      <div className={styles.inner}> 
         <div className={styles.dlo}>
            <div className={styles.foo}>
              <h2 className={styles.headd}>
                {" "}
                Do you face any of these  problems?{" "}
              </h2> 
              <span className="_ngcontents">
              Our job platform helps with balancing responsibilities, managing stress, and career changes
              </span>
            </div>
         </div>
       
      
        <div className={styles.mak}>
            {Object.keys(arrayObject)?.map((ele,index)=>{
                return (
                    <div className={styles.inn} key={index}>
                            <span className={styles.eloo} style={{backgroundColor:arrayObject[ele].color}}>  {arrayObject[ele].img} </span>  
                            <span className={styles.innni}>{arrayObject[ele].name}</span>
                    </div>
                )
            })}
        </div>
        <div className={styles.mak}>
            {Object.keys(arrayObject2).map((ele,index)=>{
                return (
                    <div className={styles.inne} key={index}>
                            <span className={styles.eloo} style={{backgroundColor:arrayObject2[ele].color}}> {arrayObject2[ele].img} </span>  
                            <span className={styles.innni}>{arrayObject2[ele].name}</span>
                    </div>
                )
            })}
        </div>
      </div>
    </div>
  );
};

export default NetworkinProblem;
