import React, { createContext, useContext, useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';

const CREDIT_STORAGE_KEY = 'resume_builder_credits';
const CREDIT_SALT = process.env.REACT_APP_CREDIT_SALT || 'default-salt';

const CreditsContext = createContext();

export const CreditsProvider = ({ children }) => {
  const [credits, setCredits] = useState(0);

  const encryptValue = (value) => {
    return CryptoJS.AES.encrypt(value.toString(), CREDIT_SALT).toString();
  };

  const decryptValue = (encrypted) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encrypted, CREDIT_SALT);
      return parseInt(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
      console.error('Error decrypting credits:', error);
      return 0;
    }
  };

  const getCreditsLeft = () => {
    const encryptedCredits = localStorage.getItem(CREDIT_STORAGE_KEY);
    if (!encryptedCredits) {
      const initialCredits = encryptValue(5);
      localStorage.setItem(CREDIT_STORAGE_KEY, initialCredits);
      return 5;
    }
    return decryptValue(encryptedCredits);
  };

  const updateCredits = (newCredits) => {
    const encrypted = encryptValue(newCredits);
    localStorage.setItem(CREDIT_STORAGE_KEY, encrypted);
    setCredits(newCredits);
  };

  useEffect(() => {
    setCredits(getCreditsLeft());
  }, []);

  return (
    <CreditsContext.Provider value={{ credits, updateCredits, getCreditsLeft }}>
      {children}
    </CreditsContext.Provider>
  );
};

export const useCredits = () => {
  const context = useContext(CreditsContext);
  if (!context) {
    throw new Error('useCredits must be used within a CreditsProvider');
  }
  return context;
}; 