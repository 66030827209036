import React from "react";
import styles from "../../assets/HomePage/Top.module.css";
import { ArrowRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../ResumeBuilder/builder/components/context/auth";
// import CompanyScroll from "./CompanyScroll";
const Top = () => {
  const navigate = useNavigate(); 
  const {auth } = useAuth();
  const handlesubmit = () =>{
      navigate('/airesume');  
     }
     const handlesubmitjob = () =>{
      navigate('/jobs');  
     }
  return (
    <div className={styles.main}>
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-11 col-md-6 d-flex justify-content-center  align-items-center "  >
               <div className={styles.leftSection}> 
               <p style={{fontWeight:"600"}}>Best Job Portal and <span style={{background:"linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%)" , color:'transparent' , backgroundClip:'text'} }>AI Resume Maker</span> </p>
                
               <div className="col-11 col-md-5 d-lg-none">
            <div className={styles.imagecon}>
            <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/f_auto,q_auto/v1/all/bsqgtfftmwkfhdickjmy" className="img-fluid object-contain  "  style={{height:"14rem"}} alt="ds"  />
            </div>
          </div>

               <h2 className={styles.heading}>
               Elevate your career
              with MyFuse<span style={{color:"rgb(249, 111, 89)"}}>.</span>In
               </h2> 
               <p>Explore abundant career opportunities and partner with your desired companies!</p>
                
              
                {auth.token!=="" && (
                  <div className={styles.statuscontainerre}> 
                  <div className={styles.statusinnerconteiner}>

                  
                                <div className={styles.lefttotla}>
                          <div className={styles.imgcontainer}>
                            <img
                              width="37"
                              height="37"
                              alt="Ashutosh Kumar"
                              src={auth?.user?.[0]?.images ? auth?.user?.[0]?.images :"https://media.lordicon.com/icons/wired/flat/21-avatar.gif"}
                              className={styles.imgss}
                            />
                          </div>
                          <div className={styles.namecontainer}>
                            <span className={styles.names}>
                               Hy !! {auth?.user?.[0]?.firstname}  {auth?.user?.[0]?.lastname} 
                            </span>
                            <p className={styles.email}>Let's sprinkle some AI magic on this profile!</p>
                          </div> 
                          
                                    </div>
                                  <div className={styles.statusbar}>
                      <div className={styles.online}>
                        <div className={styles.fill} style={{width:"80%"}}></div>
                      </div> 
                      <span className={styles.percent}>
                        80%
                      </span>
                                </div>

                                <div className={styles.completeProfileContainer}>
                                  <span className={styles.completeProfileButton} onClick={()=>navigate('welcome/account')}>Complete Profile</span>
                                </div>
                  </div>
                  </div>
                )}
               
               
                {/* // this is for the buttons  */}
                {auth.token==="" && (
                      <div className={styles.buttons}>
                                      
                                      
                      <span className={styles.buttonAi} onClick={handlesubmit}> <img src="https://internshala.com/static/images/ai_incentive_banners/star_logged_out.svg"  alt="dsd"/>{" "}
                            AI Resume <ArrowRight size={15} /></span>

                            <span className={styles.findjobs} onClick={handlesubmitjob}>Find Jobs</span>
                      </div>

                 )}
              
                
                <div className={styles.truted}>
                    <p>Trusted by job seekers around the globe</p>  
                    <div style={{ display:'flex ' , gap:'10px'}}>
                    <img src="https://cdn.icon-icons.com/icons2/2428/PNG/512/google_black_logo_icon_147125.png" alt="d" style={{width:'2rem'}}/>
                    <img src="https://icones.pro/wp-content/uploads/2021/08/icone-amazon-noir.png" alt="d" style={{width:'2rem'}}/>
                    <img src="https://cdn-icons-png.freepik.com/512/5969/5969147.png" alt="d" style={{width:'2rem'}}/>
                    <img src="https://cdn1.iconfinder.com/data/icons/andriod-app-logo/32/icon_flipkart-512.png" alt="d" style={{width:'2rem'}}/>
                    <img src="https://cdn-icons-png.freepik.com/256/220/220802.png?semt=ais_hybrid" alt="d" style={{width:'2rem'}}/>
                    </div>
                </div>

               </div>
           </div>
          <div className=" d-none d-md-block col-11 col-md-5 ">
            <div className={styles.imagecon}>
              <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1721666887/support-os_g4pwfy.png" className="img-fluid"  alt="sda"/>
            </div>
          </div>
        </div>
        {/* <CompanyScroll/> */}
      </div>
     
    </div>
  );
};

export default Top;
