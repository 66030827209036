import React, { useEffect, useRef, useState } from "react";
import styles from "../../assets/Dashboard/recomanded.module.css";
import axios from "axios";
import { useResume } from "../../ResumeBuilder/builder/components/context/Resume";
import { Bookmark } from "lucide-react";
import slugify from "slugify";

const Recomanded = () => {
  const containerRef = useRef(null); // Reference to the scrollable container

  const [skillfromdbapi, setskillfromdibapi] = useState(""); // Initialize as empty string
  const { values } = useResume();
  const [loading, setLoadingJobs] = useState(false);
  const datalocal = JSON.parse(localStorage.getItem("auth"));
  const [jobs, setJobs] = useState([]);
  const resume = values;
  const role = resume?.sections?.technicalSkills?.data?.[0]?.skills || "react";
  const skill_from_resume = Array.isArray(role) ? role.join(",") : ["react"];
  // Merging resume and database skills
  const mergedskill = `${skill_from_resume},${skillfromdbapi}`;

  // Fetch user skills from DB
  const getUserSkill = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/skillsbyid`,
        {
          headers: {
            Authorization: `Bearer ${datalocal?.token}`,
          },
        }
      );

      if (data?.skillsfromdb?.length > 0) {
        setskillfromdibapi(data.skillsfromdb[0].skills);
      } else {
        console.warn("No skills found in the response");
      }
    } catch (error) {
      console.error("Error fetching user skills:", error);
    }
  };

  // Fetch all jobs based on merged skills
  const getAllJobs = async () => {
    try {
      setLoadingJobs(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/filterjob?skills=${mergedskill}`,
        {
          headers: {
            Authorization: `Bearer ${datalocal?.token}`,
          },
        }
      );
      
      if (data?.success) {
        // The jobs array is now directly in data.jobs
        const sortedJobs = [...data.jobs].sort(
          (a, b) => b.percentageMatched - a.percentageMatched
        );
        setJobs(sortedJobs);
      }
      setLoadingJobs(false);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setLoadingJobs(false);
    }
  };

  // Navigate to job details
  const handleNavigate = async (value, heading) => {
    const slug = `${slugify(heading)}-${value}`;
    window.open(`/jobs/details/${slug}`, "_blank");
  };

  // Fetch user skills once on component mount
  useEffect(() => {
    if (!datalocal?.token) {
      console.error("No authentication token found in local storage");
      return;
    }
    getUserSkill();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch jobs when skills from DB are updated
  useEffect(() => {
    if (skillfromdbapi && datalocal?.token) {
      getAllJobs();
      console.log(jobs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillfromdbapi]); // Only trigger when skills from DB are updated

  const handleScroll = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 200, // Adjust the scroll distance as needed
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.headingContainer}>
        <div className={styles.headingtitle}>Recommended Jobs </div>
      </div>
      <div className={styles.recomandedContainer} ref={containerRef}>
        {jobs?.map((job) => (
          <div 
            className={styles.recomandedCard} 
            onClick={() => handleNavigate(job.id_jobpost, job.jobtitle)}
            style={{ transition: 'all 0.2s ease-in-out' }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = 'translateY(-5px)';
              e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.1)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = 'translateY(0)';
              e.currentTarget.style.boxShadow = 'none';
            }}
            onMouseDown={(e) => {
              e.currentTarget.style.transform = 'scale(0.98)';
            }}
            onMouseUp={(e) => {
              e.currentTarget.style.transform = 'translateY(-5px)';
            }}
          >
            <div className={styles.cardImage}>
              <img
                className="img-fluid"
                src={`https://old.myfuse.in/uploads/logo/${job.companyLogo}`}
                alt="Company Logo"
              /> 
              <Bookmark 
                size={16} 
                color={"#99A3BC"}
                style={{ transition: 'all 0.2s ease' }}
                onMouseEnter={(e) => {
                  e.stopPropagation();
                  e.currentTarget.style.color = '#2563eb';
                  e.currentTarget.style.transform = 'scale(1.2)';
                }}
                onMouseLeave={(e) => {
                  e.stopPropagation();
                  e.currentTarget.style.color = '#99A3BC';
                  e.currentTarget.style.transform = 'scale(1)';
                }}
              />
            </div>
            <div className={styles.cardDetails}>
              <div className={styles.cardTitle}>
                {" "}
                {job.jobtitle.length > 20
                  ? `${job.jobtitle.substring(0, 20)}...`
                  : job.jobtitle}
              </div>
              <div className={styles.cardCompany}>
                {job.companyName.length > 20
                  ? `${job.companyName.substring(0, 20)}...`
                  : job.companyName}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className={styles.scroll}>
        <img
          onClick={handleScroll}
          src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729442516/all/v95xfskcuxn9hcz35kvb.svg"
          alt="next"
        />
      </div>
    </div>
  );
};

export default Recomanded;
