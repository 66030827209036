import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "../../assets/ResumeHomePage/ResumeSlider.module.css";
import { useResume } from "../../ResumeBuilder/builder/components/context/Resume";
import { BookOpenText, Bot, DropletIcon, MoveUpRight } from "lucide-react";
const ResumeSlider = () => {
  const { templates } = useResume();
  const handleNavigate = () => {
    window.open('/resume-examples', '_blank');
}
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    focusOnSelect: true,
    dots: true,
    cssEase: "ease-in-out",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={styles.sliderContainer}>
      <div>
        <h2 className={styles.heao}> Job-winning resume templates</h2>
        <p className={styles.ddfaf}>
          Each resume template is designed to follow the exact rules you need to
          get hired faster.
        </p>
      </div>
      <Slider {...settings}>
        {Object.values(templates).map((template, index) => (
          <div key={index} className={`${styles.eachSlide} slick-slide`} >
            <img
              src={template.preview}
              alt="preveiw"
              className={` ${styles.templateImage}`}
            /> 
           
          </div>
        ))}
      </Slider>
      <div className={styles.downContainer}>
        <div className={styles.innerCard}>
          <DropletIcon color={"rgb(107, 87, 255)"} />
          <span className={styles.data}>
            ATS-friendly professionally <br></br>
            designed resumes
          </span>
        </div>
        <div className={styles.innerCard}>
          <Bot color={"rgb(107, 87, 255)"} />
          <span className={styles.data}>
            AI-powered resumes for <br></br> better results.
          </span>
        </div>
        <div className={styles.innerCard}>
          <BookOpenText color={"rgb(107, 87, 255)"} />
          <span className={styles.data}>
            Two-column, single-column, <br></br>
            and multi-page layouts
          </span>
        </div>
      </div>
      <div className={styles.doroo} >
        <span onClick={handleNavigate}>Browse Resume Templates  <span><MoveUpRight size={15}/></span>  </span>
      </div>
    </div>
  );
};

export default ResumeSlider;
